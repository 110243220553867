import React from 'react';

const VIcon = ({
  width = '10',
  height = '8'
}) =>
  <svg width={width} height={height} viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.86061 0L3.39522 5.64848L1.13899 3.32606L0 4.50182L3.39683 8L10 1.17576L8.86061 0Z" fill="#00A21F"/>
  </svg>;

export default VIcon;