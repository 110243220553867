import { createSelector } from '@reduxjs/toolkit';

const selectDevicesSlice = (state) => state.devices;

export const selectDevicesCoverage = createSelector(
  selectDevicesSlice,
  (devices) => devices.coverage,
);

export const selectMessageByUnits = createSelector(
  selectDevicesSlice,
  (devices) => devices.messageByUnits,
);
