import { Box, styled, Typography } from '@mui/material';

export const StyledTab = styled(Box)(({ theme, background }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flex: '1 0 33%',
  position: 'relative',
  padding: '22px 16px',
  background,
  cursor: 'pointer',
  '&:not(:last-of-type)': {
    borderRight: `1px solid ${theme.palette.border.main}`,
  },
}));

export const PointerContainer = styled(Box)(() => ({
  position: 'absolute',
  bottom: -6,
  width: 11,
  height: 11,
  zIndex: 2,
}));

export const Pointer = styled(Box)(({ theme, color }) => ({
  width: '100%',
  height: '100%',
  transform: ' scaleX(0.7)',
  '&::after': {
    background: color,
    display: 'block',
    content: '" "',
    width: '100%',
    height: '100%',
    borderBottom: `1px solid ${theme.palette.border.main}`,
    borderRight: `1px solid ${theme.palette.border.main}`,
    transform: 'rotate(45deg)',
  },
}));

export const Title = styled(Typography)({
  fontWeight: 700,
  whiteSpace: 'nowrap',
});
