/* eslint-disable no-mixed-operators */
import React from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Typography, useTheme } from '@mui/material';

import SeniorCategoryCardList from './components/SeniorCategoryCardList/SeniorCategoryCardList';
import OptionToggler from './components/OptionToggler/OptionToggler';
import TodoList from './components/TodoList';
import DevicesCoverage from './components/DevicesCoverage';

import { selectUser } from '../../redux/User';
import { selectSeniorsTotal } from '../../redux/Seniors';
import { loadTodos, selectTodos } from '../../redux/Todos';
import English from '../../languages/English';
import {
  Wrapper,
  Top,
  TopTitle,
  TopWidgets,
  Body,
  BodySection,
  BodyHeader,
  TooltipCircle,
} from './dashboard.style';
import CoverageTooltip from './components/CoverageTooltip';
import { options } from './dashboard.utils';
import { useQueryObject } from '../../utilities/hooks';
import NiceModal from '@ebay/nice-modal-react';
import EventsModal from '../../components/modals/EventsModal';

const DashboardView = () => {
  const theme = useTheme();
  const user = useSelector(selectUser);
  const seniorSum = useSelector(selectSeniorsTotal);
  const todos = useSelector(selectTodos);
  const loadingTodos = useSelector(loadTodos.isLoading);
  const [searchParams, setSearchParams] = useSearchParams();

  const { insightId, carelogId } = useQueryObject();
  if (insightId) {
    searchParams.delete('insightId');
    setSearchParams(searchParams);
    NiceModal.show(EventsModal, { insight_id: insightId, cta: true });
  } else if (carelogId) {
    searchParams.delete('carelogId');
    setSearchParams(searchParams);
    NiceModal.show(EventsModal, { care_log_id: carelogId });
  }

  return (
    <Wrapper>
      <Top>
        <TopTitle>
          <Typography variant="h2">
            {' '}
            Welcome Back, {user.username} 👋🏽
          </Typography>
          <Typography variant="subtitle1" color={theme.palette.sensiGrey.main}>
            {new Date().toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })}{' '}
            | {seniorSum} Senior{seniorSum === 1 ? '' : 's'}
          </Typography>
        </TopTitle>
        <TopWidgets>
          <OptionToggler options={options} />
        </TopWidgets>
      </Top>
      <Body>
        <SeniorCategoryCardList />
      </Body>
      <Body>
        <BodySection>
          <BodyHeader>
            <Typography variant="h3">{`${English.myToDoList} ${
              !loadingTodos ? `(${todos.length})` : ''
            }`}</Typography>
          </BodyHeader>
          <TodoList />
        </BodySection>
        <BodySection>
          <BodyHeader>
            <Typography variant="h3">Home Data Coverage</Typography>
            <TooltipCircle>
              ?<CoverageTooltip />
            </TooltipCircle>
          </BodyHeader>
          <DevicesCoverage />
        </BodySection>
      </Body>
    </Wrapper>
  );
};

export default DashboardView;
