import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Collapse, Stack } from '@mui/material';

import { editComment } from '../../../../../../redux/Comments';
import Colors from '../../../../../../theme/Colors';
import { Container, StyledName, StyledTop, StyledDate, StyledActions, IconContainer } from './Comment.style';
import { formatDate } from '../../../../../../utilities/dates';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import { selectUser } from '../../../../../../redux/User';
import CommentInput from '../CommentInput/CommentInput';

export const Comment = ({ comment, is_carelog, assignees, setCount }) => {
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const user = useSelector(selectUser);
  const inputRef = useRef(null);
  const handleEditComment = () => {
    setEdit(!edit);
    const value = inputRef.current.getEditorState().getCurrentContent().getPlainText();
    if (edit && value !== comment.comment) {
      dispatch(editComment({ id: comment.id, item_id: is_carelog ? comment.care_log_id : comment.insight_id, comment: value, is_carelog, tags: inputRef.current.getTaggedUsers() }));
    }
  };

  const handleDeleteComment = () => {
    dispatch(editComment({ id: comment.id, item_id: is_carelog ? comment.care_log_id : comment.insight_id, comment: null, is_carelog }));
    setDeleted(true);
    setCount(prevCount => prevCount - 1);
  };

  useEffect(() => {
    if (edit && inputRef.current) {
      inputRef.current.focus();
    }
  }, [edit]);


  return (
    <Collapse in={!deleted}>
      <Container>
        <StyledTop>
          <StyledName> {comment?.dashboard_users?.first_name} {comment?.dashboard_users?.last_name} </StyledName>
          <StyledDate> {formatDate(new Date(comment?.updated_at_utc))} </StyledDate>
        </StyledTop>
        { +user.id === +comment?.dashboard_users?.id ? <StyledActions>
          <Stack direction='row'>
            <IconContainer color={edit ? Colors.white : Colors.sensiPurple} bgcolor={edit && Colors.green} onClick={handleEditComment}>
              { edit ? <CheckIcon color={Colors.white} /> : <EditIcon /> }
            </IconContainer>
            <IconContainer color={Colors.red} onClick={handleDeleteComment}>
              <DeleteIcon />
            </IconContainer>
          </Stack>
        </StyledActions> : '' }
        <CommentInput readOnly={!edit} variant="standard" inputRef={inputRef} assignees={assignees} defaultValue={comment.comment} />
      </Container>
    </Collapse>
  );
};
