const BlurredBackground = ({ className = '', children, onClick }) => {
  return (
    <div
      className={`blurred-background ${className} relative`}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default BlurredBackground;
