import React from 'react';

import { Skeleton, Box, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Colors from '../../../../../theme/Colors';

const ItemSkeleton = ({ index }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        borderBottom: `1px solid ${theme.palette.border.main}`,
        backgroundColor: index % 2 === 0 ? undefined : Colors.whiteGrey,
        padding: '19px 24px 24px',
      }}
    >
      <Grid gap="5px" sx={{ marginBottom: '12px' }} container>
        <Skeleton variant="rounded" width={24} height={24} />
        <Skeleton variant="rounded" width={262} height={24} />
      </Grid>
      <Grid gap="12px" container>
        <Skeleton variant="rounded" width={98} height={32} />
        <Skeleton variant="rounded" width={130} height={32} />
      </Grid>
    </Box>
  );
};

const TodosSkeleton = ({ amount = 4 }) => {
  let i = 0;
  const content = [];

  while (i < amount) {
    content.push(<ItemSkeleton index={i} key={i} />);
    i++;
  }

  return content;
};

export default TodosSkeleton;
