import { styled, IconButton as MuiIconButton } from '@mui/material';
import Colors from '../../../../../../theme/Colors';

export const Form = styled('form')({
  display: 'flex',
  padding: 0,
  border: `1px solid ${Colors.borderGrey}`,
  borderRadius: '5px'
});

export const IconButton = styled(MuiIconButton)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: Colors.white,
  borderRadius: '0 5px 5px 0',
  padding: '10px'
}));