import { Typography, styled, Box } from '@mui/material';
import Colors from '../../../../theme/Colors';

export const TranscriptBox = styled(Box)(({ }) => ({
  display: 'grid',
  gridTemplateColumns: '12fr 1fr',
}));

export const TranscriptContainer = styled('p')(({ variant, isFullTranscriptVisible }) => (
  {
    margin: variant === 'filled' ? '0px 10% 9px 17px' : '5px',
    lineHeight: '16px',
    ...(isFullTranscriptVisible ? {} : {
      display: 'flex',
      flexDirection: 'row',
    })
  }));

export const TranscriptText = styled('span')(({ theme }) => ({
  margin: '0',
  padding: '0',
  color: theme.palette.sensiGrey.main,
  fontFamily: 'OpenSans-Custom',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: '400',
}));

export const ViewTranscriptButton = styled(Typography)(() => ({
  color: Colors.skyBlue,
  fontFamily: 'OpenSans-Custom',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: '16px',
  marginLeft: '5px',
  cursor: 'pointer',
  '&:hover': {
    textDecorationLine: 'underline',
  },
}));

export const ReadContainer = styled(Box)(({ }) => ({
  display: 'inline-flex',
  alignItems: 'center',
}));

export const ReadText = styled(Typography)({
  color: Colors.sensiGreen,
  fontWeight: 600,
  fontSize: 11,
  marginLeft: '5px'
});

export const ReadContainerSpan = styled(Typography)(({ }) => ({
  whiteSpace: 'nowrap',
  lineHeight: '16px',
  marginLeft: '5px'
}));

export const CopyWrapper = styled(Box)(({ }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',
  marginBottom: '9px'
}));

