import React from 'react';

import {
  styled,
  FormControl,
  Select,
  OutlinedInput,
  MenuItem,
} from '@mui/material';
import ArrowDropDownIcon from './ArrowDropDownIcon';

import Colors from '../../../../../theme/Colors';

const Placeholder = styled('em')(() => ({
  fontWeight: 700,
  fontSize: 12,
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  padding: '6px 15px',
  borderRadius: 8,
  backgroundColor: Colors.white,
  fontSize: 12,
  lineHeight: '20px',
  '& > .MuiOutlinedInput-notchedOutline': {
    borderRadius: 8,
    borderColor: theme.palette.border.main,
  },
  '& > .MuiInputBase-input': {
    padding: '0',
  },
  '& .MuiSelect-icon': {
    color: theme.palette.black.main,
  },
}));

const StyledMenuItem = styled(MenuItem)({
  fontSize: 12,
});

const SelectInput = ({
  name,
  value,
  onChange,
  options = [],
  label,
  getValue = (item) => item?.id,
  getLabel = (item) => item?.username,
}) => {
  return (
    <FormControl sx={{ minWidth: '130px' }}>
      <StyledSelect
        id={name}
        labelId={name}
        value={value}
        onChange={(evt) => onChange(evt.target.value)}
        renderValue={(selected) => {
          const item = options.find((option) => getValue(option) === selected);
          if (!selected || !item) return <Placeholder>{label}</Placeholder>;
          return getLabel(item);
        }}
        input={<OutlinedInput />}
        IconComponent={ArrowDropDownIcon}
        MenuProps={{
          sx: {
            '& .MuiMenu-paper': {
              maxHeight: '250px',
              '&::-webkit-scrollbar': {
                width: '8px',
              },
              '&::-webkit-scrollbar-track': {
                WebkitBoxShadow: 'none',
                backgroundColor: 'transparent',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#d9d9d9',
                borderRadius: '20px',
              },
            },
          },
        }}
        displayEmpty
      >
        <StyledMenuItem value="" disabled>
          <em>{label}</em>
        </StyledMenuItem>
        {options.map((option) => (
          <StyledMenuItem key={getValue(option)} value={getValue(option)}>
            {getLabel(option)}
          </StyledMenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  );
};

export default SelectInput;
