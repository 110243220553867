import React from 'react';
import {
  StyledTextField,
  TitleContainer,
  TitleText,
  Container,
  StyledAutocomplete
} from './AutoCompleteDropdown.style';
import { ArrowDownIcon } from '../../../assets/icons';

const AutocompleteDropdown = ({
  containerStyle = {},
  titleStyle = {},
  options = [],
  label = '',
  loading = false,
  title,
  onChange,
  value,
  popupIcon = <ArrowDownIcon style={{ margin: '5px' }} />,
  listStyle = {},
  textStyle = {},
  disabled
}) => {
  return (
    <Container containerStyle={containerStyle}>
      {title && (
        <TitleContainer>
          <TitleText titleStyle={titleStyle}>{title}</TitleText>
        </TitleContainer>
      )}
      <StyledAutocomplete
        disablePortal
        disabled={disabled}
        loading={loading}
        id="combo-box-demo"
        options={options}
        value={value}
        popupIcon={popupIcon}
        getOptionLabel={(option) => option.name || option.username || ''}
        onChange={onChange}
        ListboxProps={{ style: listStyle }}
        renderInput={(params) => (
          <StyledTextField
            textStyle={textStyle}
            placeholder={label}
            {...params}
            variant="outlined"
          />
        )}
      />
    </Container>
  );
};

export default AutocompleteDropdown;
