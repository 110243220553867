import React from 'react';
import { Wrapper, Bullet } from './Recommendations.style';
import { Typography } from '@mui/material';
const Recommendations = ({ recommendation, setCount }) => {
  const recommendations = recommendation?.split('*');
  setCount(recommendations?.length - 1);
  return (
    <Wrapper variant='container'>
      {recommendations?.map((item, i) =>
        <React.Fragment key={i}>
          <Typography variant="body3" color="inherit" component="span">
            {item}
          </Typography>
          {i < recommendations?.length - 1 && <Bullet>&bull;</Bullet>}
        </React.Fragment>
      )}
    </Wrapper>
  );
};

export default Recommendations;
