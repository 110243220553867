import {
  styled,
  Paper, Box,
} from '@mui/material';
import Colors from '../../../../theme/Colors';

export const Wrapper = styled(Paper)(() => ({
  border: 0,
  marginTop: '29px'
}));


export const EventTrend = styled(Box)(() => ({
  color: Colors.sensiGrey,
  fontFamily: 'OpenSans-Custom',
  fontSize: 12,
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
}));
