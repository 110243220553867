
import { Menu } from '@headlessui/react';
import { get } from 'lodash';
import { useMemo } from 'react';
import DatePicker from 'react-datepicker';

import { sortByName } from '../../../utilities/helpers';
import dayjs from '../../../utilities/dayjs';

const FilterItemList = ({ list, filter = {}, filterState, update }) => {
  const currentTypeComponent = useMemo(() => {
    switch (filter.type) {
      case 'date':
        return (
          <div className="relative">
            <DatePicker
              renderCustomHeader={({
                date,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled
              }) => (
                <>
                  <div className="react-datepicker__current-month">
                    <button
                      className="react-datepicker__navigation react-datepicker__navigation--previous"
                      onClick={decreaseMonth}
                      disabled={prevMonthButtonDisabled}
                    >
                      <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous">
                        Previous month
                      </span>
                    </button>

                    <div className="react-datepicker__current-month--month-text">
                      {dayjs(date).format('MMMM')}
                    </div>
                    <div className="react-datepicker__current-month--year-text">
                      {dayjs(date).format('YYYY')}
                    </div>

                    <button
                      className="react-datepicker__navigation react-datepicker__navigation--next"
                      onClick={increaseMonth}
                      disabled={nextMonthButtonDisabled}
                    >
                      <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next">
                        Next month
                      </span>
                    </button>
                  </div>

                  <div className="react-datepicker__day-names__display">
                    <div className="react-datepicker__day-name">Sun</div>
                    <div className="react-datepicker__day-name">Mon</div>
                    <div className="react-datepicker__day-name">Tue</div>
                    <div className="react-datepicker__day-name">Wed</div>
                    <div className="react-datepicker__day-name">Thu</div>
                    <div className="react-datepicker__day-name">Fri</div>
                    <div className="react-datepicker__day-name">Sat</div>
                  </div>
                </>
              )}
              calendarClassName={'shadow-lg'}
              selected={get(filterState, 'date.value.startDate', dayjs().toDate())}
              onChange={([start, end]) =>
                update(filter.name, { value: { startDate: start, endDate: end } })
              }
              startDate={get(filterState, 'date.value.startDate', dayjs().toDate())}
              endDate={get(filterState, 'date.value.endDate', dayjs().toDate())}
              selectsRange
              inline
            />
          </div>
        );
      case 'common':
      default:
        return (
          <>
            {list
              .filter(({ value }) => value)
              .sort((a, b) => (filter.sort ? sortByName(a, b, 'label') : 1
              ))
              .map((item, i) => (
                <Menu.Item
                  key={`${item.value}${i}`}
                  onClick={() => update(filter.name, item)}
                >
                  <div
                    className={`filter-list-item
                  ${
                get(filterState, `${filter.name}.value`, '') === item.value ? 'selected' : ''
                }
                  text-center filter-select-item cursor-pointer group w-full px-2 py-2 text-sm`}
                  >
                    {item.label}
                  </div>
                </Menu.Item>
              ))}
          </>
        );
    }
  }, [filter.type, filter.name, filter.sort, filterState, list, update]);

  return (
    <Menu.Items
      className={`filter-item-list absolute ${
        filter.type === 'date' ? 'date-filter-container h-0' : 'w-full max-h-60 overflow-y-auto overflow-x-hidden'
      } right-0 mt-2 origin-top-right bg-white rounded-md z-10 focus:outline-none`}
    >
      {currentTypeComponent}
    </Menu.Items>
  );
};

export default FilterItemList;
