/* eslint-disable max-lines */
import { useCallback, useEffect, useMemo, useReducer } from 'react';
import { updateEvent } from '../../../redux/Seniors';
import { useLocation } from 'react-router-dom';
import {
  careTitles,
  healthTitles,
  HIGH_LEVEL_ALERT,
  CARELOG_NOTIFICATION,
  INSIGHT_NOTIFICATION,
  TASK_STATUS,
  ASSETS_BUCKET,
} from '../../../utilities/const';
import agent from '../../../utilities/agent';
import dayjs from '../../../utilities/dayjs';
import {
  AlertIcon,
  ArrowIcon,
  CircleIcon,
  CommentsIcon,
  PaperPlaneIcon,
  HighlightAlertIcon,
  HighlightCareIcon,
  HighlightHealthIcon,
  HighlightBulbIcon,
  HighlightSystemIcon,
  ThinCheckIcon,
  TimeIcon,
  MapPointIcon,
  CalendarIcon,
  QuestionMarkIcon
} from '../../../assets/icons';
import CircleLoader from '../../common/loaders/circleLoader';
import AudioPlayer from '../../audioPlayer';
import { useCustomerState } from '../../../contexts/customers';
import CheckBox from '../../common/checkbox';
import { getUtcDateTime } from '../../../utilities/dates';
import PopoverSelect from '../../common/popoverSelect';
import { parseInsightText } from '../../../utilities/helpers';
import DeviceLocation from './DeviceLocation';
import EVENT_TYPES from '../../../services/EVENT_TYPES';
import { EventService } from '../../../services';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../../redux/User';
import English from '../../../languages/English';
import { createTodo, editTodo, selectTodos } from '../../../redux/Todos';
import { findItemByAssigneeId } from '../../../views/dashboard/dashboard.utils';
import LabelsTooltip from '../../../views/dashboard/components/LabelsTooltip/LabelsTooltip';
import { TooltipHover } from '../../../views/dashboard/components/EventView/EventView.style';

const initialState = {
  commentOpen: false,
  commentReload: true,
  comments: [],
  insights: [],
  insightOpened: false,
  loadingBottomSectionData: false,
  loadingChildCarelogs: false,
  actionsOpen: false,
  newComment: '',
  newAction: '',
  newMessage: ''
};

const stateReducer = (state, data) => {
  return {
    ...state,
    ...data
  };
};

const HighlightItem = ({
  highlight,
  onUpdate = () => {},
  switchResolveModalHandler = () => {},
  isNotificationModal,
}) => {
  const reduxDispatch = useDispatch();
  const [{ territories, MixpanelUserData, actionTakenEnums, currentTerritories, currentCustomer }] =
    useCustomerState();
  const user = useSelector(selectUser);
  const id = highlight?.is_carelog ? { care_log_id: highlight?.id } : { insight_id: highlight?.id };
  const todos = useSelector(selectTodos);
  const location = useLocation();
  const disableInput = (+highlight.assignee_id !== +user?.id) || (highlight.status === TASK_STATUS.DONE.status);

  const page = useMemo(() => {
    if (isNotificationModal) return 'notifications center';
    return location.pathname.startsWith('/dashboard/territory') ? 'events history' : 'client page';
  }, [location.pathname]);

  const insightNotificationHeard = (data) => {
    EventService.fireEvent(EVENT_TYPES.INSIGHT_NOTIFICATION_HEARD, {
      [highlight.is_carelog ? 'carelog_id' : 'insight_id']: id[highlight.is_carelog ? 'care_log_id' : 'insight_id'],
      category: highlight.senior_category,
      unit_id: highlight.unit_id,
      ...data,
      page,
    });
  };
  const notificationHeard = (data) => {
    EventService.fireEvent(EVENT_TYPES.NOTIFICATION_HEARD, {
      ...data,
      category: highlight.senior_category,
      unit_id: highlight.unit_id,
      page,
      is_related_event: false
    });
  };

  const assignees = useMemo(() => {
    const currentTerr = territories.find(
      ({ id }) => id === highlight.territory_id
    );
    return (currentTerr?.assignees || [])
      .reduce((acc, item) => {
        const duplicatedOption = acc.find(({ id }) => id === item.id);
        if (duplicatedOption) return acc;
        return acc.concat([item]);
      }, [])
      .map(({ id: value, username: label }) => ({
        value,
        label
      }));
  }, [highlight.territory_id, territories]);

  const [
    {
      commentOpen,
      commentReload,
      comments,
      loadingBottomSectionData,
      newComment,
      insightOpened,
      loadingChildCarelogs,
      actionsOpen,
      newAction,
      newMessage
    },
    dispatch
  ] = useReducer(stateReducer, initialState);

  const isSimple = useMemo(() => highlight.is_carelog, [highlight]);

  const labelType = useMemo(() => {
    switch (highlight.is_carelog) {
      case true:
        if (highlight.is_real_time) {
          return HIGH_LEVEL_ALERT;
        }
        return CARELOG_NOTIFICATION;
      default:
        return INSIGHT_NOTIFICATION;
    }
  }, [highlight]);

  const handleCommentsOpen = useCallback(() => {
    dispatch({ commentOpen: !commentOpen, actionsOpen: false });
    if (!commentOpen) {
      dispatch({ commentReload: true });
    }
  }, [commentOpen]);

  useEffect(() => {
    dispatch({
      newAction: highlight?.action_taken || '',
      newMessage: highlight?.action_taken_message || '',
    });
  }, [highlight.action_taken, highlight.action_taken_message]);

  const handleActionsOpen = useCallback(() => {
    dispatch({ actionsOpen: !actionsOpen, commentOpen: false });
  }, [actionsOpen]);

  useEffect(() => {
    if (commentOpen && commentReload) {
      dispatch({ loadingBottomSectionData: true });
      const endpoint = isSimple ? '/comments' : '/insightsComments';
      agent
        .get(`${endpoint}/${highlight.id}`)
        .then((res) => {
          dispatch({
            comments: res.data.data.map((item) => ({
              ...item,
              inserted_at_utc: getUtcDateTime(item.inserted_at_utc)
            })),
            commentReload: false
          });

          if (res.data.data.length > highlight.comments_count) {
            highlight.comments_count = res.data.data.length;
            onUpdate(highlight);
          }
        })
        .finally(() => dispatch({ loadingBottomSectionData: false }));
    }
  }, [commentOpen, commentReload]);

  const retoggleComments = useCallback(() => {
    dispatch({ commentReload: true });
  }, []);

  const handleSendMessage = useCallback(
    (e) => {
      e.preventDefault();
      if (newComment.length && !loadingBottomSectionData) {
        dispatch({ loadingBottomSectionData: true });

        const endpoint = isSimple ? '/comments' : '/insightsComments';
        agent
          .post(`${endpoint}/${highlight?.id}`, { comment: newComment })
          .then(() => {
            dispatch({ newComment: '' });
            retoggleComments();
            highlight.comments_count += 1;
            onUpdate(highlight);
            isSimple ?
              EventService.fireEvent(EVENT_TYPES.NOTIFICATION_ADD_COMMENT, {
                ...MixpanelUserData,
                carelog_id: highlight.id,
                label: highlight.title,
                category: highlight.senior_category,
                unit_id: highlight.unit_id,
                comment: newComment,
                page,
                is_related_event: false
              }) :
              EventService.fireEvent(EVENT_TYPES.INSIGHT_ADD_COMMENT, {
                ...MixpanelUserData,
                insight_id: highlight.id,
                label: highlight.title,
                category: highlight.senior_category,
                unit_id: highlight.unit_id,
                comment: newComment,
                page,
              });
          })
          .finally(() => dispatch({ loadingBottomSectionData: false }));
      }
    },
    [
      newComment,
      loadingBottomSectionData,
      isSimple,
      highlight,
      retoggleComments,
      onUpdate,
      MixpanelUserData,
      page,
    ]
  );

  const handleInsightsOpen = useCallback(() => {
    dispatch({ insightOpened: !insightOpened });
    if (!insightOpened) {
      EventService.fireEvent(EVENT_TYPES.INSIGHT_OPEN_RELATED_EVENTS, {
        ...MixpanelUserData,
        label: highlight.title,
        category: highlight.senior_category,
        unit_id: highlight.unit_id,
      });
      dispatch({ loadingChildCarelogs: true });
      agent
        .get(`/insightsCarelogs/${highlight.id}?assignees=${assignees.map(item => item.value).join(',')}`)
        .then(({ data: { data: childCarelogs } }) => {
          highlight.children_events = childCarelogs
            .map(({ care_logs }) => ({
              ...care_logs
            }))
            .map((item) => ({
              ...item,
              ts: getUtcDateTime(item.ts)
            }))
            .sort((a, b) => dayjs(b.ts) - dayjs(a.ts));
          onUpdate(highlight);
        })
        .finally(() => dispatch({ loadingChildCarelogs: false }));
    } else {
      dispatch({ childCarelogs: [] });
    }
  }, [
    insightOpened,
    MixpanelUserData,
    highlight,
    assignees,
    onUpdate,
  ]);
  /* eslint-disable max-lines */
  const handleHighlightUpdate = useCallback(
    async ({ name, value }) => {
      let promise = null, data = null;
      const endpoint = isSimple ? '/carelogs' : '/insights';
      switch (name) {
        case 'assignee_id':
          promise = agent.post(`${endpoint}/assignee/${highlight.id}`, {
            assignee: value,
          });
          const selectedAssignee = assignees.find(
            ({ value: val }) => val === value
          );
          data = {
            assignee_id: value,
            username: selectedAssignee?.label,
          };

          const todo = todos.find(todo => +todo.insight_id === highlight?.id || +todo.care_log_id === highlight?.id);
          if (!todo) {
            await reduxDispatch(createTodo({ ...id, user_id: value, name: `${English.review} ${highlight?.unit_name} ${highlight?.title}` }));
            promise = agent.post(`${endpoint}/status/${highlight.id}`, {
              status: TASK_STATUS.TODO.id,
            });
            highlight.status = TASK_STATUS.TODO.status;
          } else {
            const territoryItem = findItemByAssigneeId(currentTerritories, +value);
            reduxDispatch(
              editTodo({
                id: todo.id,
                payload: {
                  assignee_id: +value,
                  territory_id: territoryItem?.id,
                  office_id: territoryItem?.office_id,
                  timezone: territoryItem?.tz,
                  customer_id: currentCustomer?.id,
                },
              }),
            );
          }
          isSimple ?
            EventService.fireEvent(EVENT_TYPES.NOTIFICATION_ASSIGN_USER, {
              ...MixpanelUserData,
              carelog_id: highlight.id,
              label: highlight.title,
              assignee: selectedAssignee?.label,
              category: highlight.senior_category,
              unit_id: highlight.unit_id,
              page,
              is_related_event: false
            }) :
            EventService.fireEvent(EVENT_TYPES.INSIGHT_ASSIGN_USER, {
              ...MixpanelUserData,
              insight_id: highlight.id,
              label: highlight.title,
              assignee: selectedAssignee?.label,
              category: highlight.senior_category,
              unit_id: highlight.unit_id,
              page,
            });
          break;
        case 'include_in_report':
          promise = agent.post(
            `${endpoint}/addToFamilyReport/${highlight.id}`,
            {
              addToFamilyReport: value
            }
          );
          data = {
            include_in_report: value
          };
          isSimple ?
            EventService.fireEvent(EVENT_TYPES.NOTIFICATION_ADD_TO_FAMILY_REPORT, {
              ...MixpanelUserData,
              carelog_id: highlight.id,
              label: highlight.title,
              category: highlight.senior_category,
              unit_id: highlight.unit_id,
              value,
              page,
              is_related_event: false
            }) :
            EventService.fireEvent(EVENT_TYPES.INSIGHT_ADD_TO_FAMILY_REPORT, {
              ...MixpanelUserData,
              insight_id: highlight.id,
              label: highlight.title,
              category: highlight.senior_category,
              unit_id: highlight.unit_id,
              value,
              page,
            });
          break;
        default:
          break;
      }
      if (promise) {
        return promise.then(() => {
          highlight[name] = value;
          onUpdate(highlight);
          reduxDispatch(updateEvent({
            filter: (item) => item.is_carelog === isSimple && item.id === highlight.id,
            data
          }));
        });
      }
      return new Promise();
    },
    [
      MixpanelUserData,
      page,
      assignees,
      highlight,
      isSimple,
      onUpdate
    ]
  );

  const handleEventAction = useCallback(() => {
    if (!newAction && !newMessage) return;
    const actionTaken = actionTakenEnums.find(
      ({ name }) => name === newAction,
    )?.id;

    dispatch({ loadingBottomSectionData: true });
    const endpoint = !isSimple ? '/insights' : '/carelogs';
    agent.post(`${endpoint}/addToFamilyReport/${highlight.id}`, {
      actionTaken,
      actionTakenMessage: newMessage,
    })
      .then(() => {
        highlight.action_taken = newAction;
        highlight.action_taken_message = newMessage;
        reduxDispatch(updateEvent({
          filter: item => item.is_carelog === isSimple && item.id === highlight.id,
          data: {
            action_taken: newAction,
            action_taken_message: newMessage,
          }
        }));
        isSimple
          ?
          EventService.fireEvent(EVENT_TYPES.NOTIFICATION_ADD_ACTION_TAKEN, {
            ...MixpanelUserData,
            label: highlight.title,
            action_type: newAction,
            action_message: newMessage,
            carelog_id: highlight.id,
            category: highlight.senior_category,
            unit_id: highlight.unit_id,
            page,
            is_related_event: false
          }) :
          EventService.fireEvent(EVENT_TYPES.INSIGHT_ADD_ACTION_TAKEN, {
            ...MixpanelUserData,
            insight_id: highlight.id,
            label: highlight.title,
            action_type: newAction,
            action_message: newMessage,
            category: highlight.senior_category,
            unit_id: highlight.unit_id,
            page,
          });
        onUpdate(highlight);
      })
      .finally(() => dispatch({ loadingBottomSectionData: false }));
  }, [
    actionTakenEnums,
    MixpanelUserData,
    isSimple,
    highlight,
    newAction,
    newMessage,
    page,
    onUpdate
  ]);

  const updateChildEventStatus = useCallback(
    (event) => {
      const currentChild = highlight.children_events.find(
        ({ id }) => event.id === id
      );
      Object.assign(currentChild, { ...event });
      onUpdate(highlight, currentChild.id);
    },
    [highlight, onUpdate]
  );

  const renderChildrenEvent = useCallback(
    (item) => (
      <div key={`id=${item.id}`} className="highlight-content-body ">
        <div className="highlight-content-header w-full flex justify-between items-center">
          <span className="highlight-text">
            <>
              <strong>{item.labels}</strong>{' '}
            </>
          </span>
          <div className="highlight-title-sublabel flex items-center">
            <CalendarIcon className="mx-2" />
            {dayjs(item.ts).format('MMMM DD, YYYY')}
            <TimeIcon className="mx-2" />
            <span style={{ minWidth: 52, textAlign: 'end' }}>
              {dayjs(item.ts).format('h:mm A')}
            </span>
          </div>
        </div>
        <div className="highlight-content-data flex w-full">
          <div className="highlight-data-item flex-1 mr-4">
            <span>Recording</span>
            <AudioPlayer
              src={item.file_name}
              id={item.id}
              highlight={item}
              onUpdate={updateChildEventStatus}
              MixPanelTrackFunc={insightNotificationHeard}
            />
          </div>
          <div className="highlight-data-item flex-1">
            <span>Status</span>
            <div className="flex items-center">{handleStatus(item.status)}</div>
          </div>
        </div>
      </div>
    ),
    [updateChildEventStatus]
  );

  const highlightHeader = useMemo(() => {
    return highlight.is_real_time && highlight.is_carelog // check for red event
      ? {
        title: 'High Severity Notification',
        subtitle: highlight.title || '',
        icon: HighlightAlertIcon
      }
      : !highlight.is_real_time && highlight.is_carelog
        ? {
          /// check for simple event
          title: highlight.title || '',
          subtitle: '',
          icon: healthTitles.includes((highlight.title || '').toLowerCase()) ? HighlightHealthIcon : careTitles.includes((highlight.title || '').toLowerCase()) ? HighlightCareIcon : HighlightSystemIcon
        }
        : {
          // data for insights
          title: highlight.title || '',
          subtitle: '',
          icon: HighlightBulbIcon
        };
  }, [highlight]);

  const handleStatus = useCallback((status) => {
    if (status) {
      switch (status) {
        case '1' || TASK_STATUS.TODO.id || TASK_STATUS.TODO.status:
          return TASK_STATUS.TODO.status;
        case '3' || TASK_STATUS.DONE.id || TASK_STATUS.DONE.status:
          return TASK_STATUS.DONE.status;
        case undefined:
          return '';
        default:
          return status;
      }
    }
    return '';
  },[highlight.is_carelog, highlight.status]);

  return (
    <>
      <div
        id={`highlight-item-${highlight.id}`}
        className={`${labelType} ${
          highlight.status === TASK_STATUS.DONE.status ? 'highlight-done' : ''
        } highlight-label w-full flex flex-col relative`}
      >
        <div className="highlight-header relative">
          <div className="icon-container flex items-center justify-center absolute">
            <highlightHeader.icon />
          </div>
          <div className={'label-time-and-type flex items-center'}>
            <div className="highlight-title flex justify-between w-full">
              <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                <span className="main-title">{highlightHeader.title}</span>
                {highlightHeader.subtitle ? (
                  <>
                    <span className="title-divider">|</span>
                    <span className="sub-title">
                      {highlightHeader.subtitle}
                    </span>
                  </>
                ) : (
                  ''
                )}
                {highlight.is_carelog && highlight?.tooltip && <div style={{ marginLeft: '10px' }}>
                  <TooltipHover>
                    <QuestionMarkIcon />
                    <LabelsTooltip
                      title={highlightHeader.subtitle ? highlightHeader.subtitle : highlightHeader.title}
                      body={highlight.tooltip ? highlight.tooltip : ''}
                      icon={`${ASSETS_BUCKET}/${highlight?.label_id}.svg`}
                    />
                  </TooltipHover>
                </div>}
              </div>
              <div className="flex items-center">
                {highlight.location ? (
                  <div className="highlight-title-sublabel flex items-center mx-2">
                    <MapPointIcon className="mr-2" />
                    {highlight.location}
                  </div>
                ) : (
                  ''
                )}

                <div className="highlight-title-sublabel flex items-center mx-2">
                  <CalendarIcon className="mr-2" />
                  {highlight.date_label}
                </div>
                <div className="highlight-title-sublabel flex items-center ml-2">
                  <TimeIcon className="mr-2" />
                  {dayjs(highlight.ts).format('h:mm A')}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`highlight-content ${
            commentOpen ? 'to-comments' : 'to-highlight'
          } w-full relative flex flex-col`}
        >
          <div className="highlight-content-body pt-2 pb-4 px-8">
            {!!isSimple && <DeviceLocation location={highlight.room_name} />}
            <div className="highlight-content-data flex w-full">
              <div className="highlight-data-col flex flex-col flex-1">
                <div className="highlight-data-item">
                  <span>Name</span>
                  <div>{highlight.unit_name}</div>
                </div>
              </div>
              <div className="highlight-data-col items-start flex-4">
                {[
                  {
                    title: 'Trend Analysis',
                    text: highlight.trend || ''
                  },
                  {
                    title: 'Recommendation',
                    text: highlight.recommendation || ''
                  },
                  {
                    title: 'Indication',
                    text: highlight.indication || ''
                  }
                ]
                  .filter(({ text }) => (text || '').length)
                  .map(({ title, text }) => (
                    <div
                      key={`${title}${Math.random(0, 1)}`}
                      className="highlight-data-item no-width-limit"
                    >
                      <span>{title}</span>
                      <div className="w-full">{parseInsightText({ text })}</div>
                    </div>
                  ))}
                <div className="highlight-data-row flex flex-row w-full">
                  {isSimple ? (
                    <div className="highlight-data-item flex-1">
                      <span>Recording</span>
                      <AudioPlayer
                        highlight={highlight}
                        onUpdate={onUpdate}
                        src={highlight.file_name}
                        id={highlight.id}
                        prefix={'child-'}
                        MixPanelTrackFunc={notificationHeard}
                      />
                    </div>
                  ) : (
                    <div className="highlight-data-item flex-1">
                      <span>Related events</span>
                      <div
                        className={`related-insights-label flex items-center justify-between cursor-pointer ${
                          insightOpened ? 'opened' : ''
                        }`}
                        onClick={handleInsightsOpen}
                      >
                        Insight Events ({highlight.related_events_count || 0})
                        <ArrowIcon
                          className={`${
                            insightOpened ? 'rotate-270' : 'rotate-90'
                          }`}
                        />
                      </div>
                    </div>
                  )}
                  <div className="highlight-data-item flex-1">
                    <span>Status</span>
                    <div className="flex !p-0">
                      <div className="highlight-select !justify-between">
                        {handleStatus(highlight.status)}
                      </div>
                    </div>
                  </div>
                  <div className="highlight-data-item flex-1">
                    <span>Assignee</span>
                    {highlight.status === TASK_STATUS.DONE.status ? (
                      <div>
                        <>
                          {assignees.find(
                            ({ value }) => value === highlight.assignee_id
                          )?.label || '—'}
                        </>
                      </div>
                    ) : (
                      <PopoverSelect
                        options={assignees}
                        value={highlight.assignee_id}
                        label="Assign"
                        name="assignee_id"
                        onChange={handleHighlightUpdate}
                        containerClassName="flex items-center justify-center"
                        className={`
                        highlight-select !justify-between
                         ${(highlight.assignee_id && disableInput) ? 'disabled' : ''}
                         `}
                        withApiCallback={true}
                      />
                    )}
                  </div>
                </div>
                {insightOpened ? (
                  <div
                    className={`children-events-container flex flex-col w-full mt-4 relative ${
                      loadingChildCarelogs ? 'loading' : ''
                    }`}
                  >
                    {highlight?.children_events?.length ? (
                      highlight?.children_events?.map(renderChildrenEvent)
                    ) : !loadingChildCarelogs ? (
                      <div className="no-events w-full h-full flex items-center justify-center">
                        No related events found
                      </div>
                    ) : (
                      ''
                    )}
                    <CircleLoader show={loadingChildCarelogs} />
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div className="highlight-data-col flex- flex justify-end items-end flex-1">
                {highlight.status === TASK_STATUS.DONE.status ? (
                  <div className="highlight-review-button done flex items-end">
                    <div className={'flex items-center'}>
                      <CheckBox
                        value={highlight.include_in_report}
                        onChange={(value) =>
                          handleHighlightUpdate({
                            name: 'include_in_report',
                            value
                          })
                        }
                        label="Add to Care Report"
                      />
                    </div>
                  </div>
                ) : (
                  <div
                    className={`highlight-review-button flex items-end cursor-pointer 
                    ${disableInput ? 'disabled' : ''}`}
                    onClick={() => switchResolveModalHandler(highlight)}
                  >
                    <div className="label-content flex items-center">
                      <div className="highlight-review-icon flex items-center justify-center mr-2">
                        <ThinCheckIcon />
                      </div>
                      Mark Reviewed
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="highlight-bottom-menu flex items-center justify-between w-full">
          <div
            id={`comments-item-${highlight.id}`}
            className={`highlight-comment-button ${
              commentOpen ? 'opened' : ''
            } py-1 flex items-center select-none cursor-pointer flex-1`}
            onClick={handleCommentsOpen}
          >
            <CommentsIcon />
            <div className="mx-2">Comments ({highlight.comments_count})</div>

            <ArrowIcon
              className={`${!commentOpen ? 'rotate-90' : 'rotate-270'}`}
            />
          </div>
          <div className="divider">|</div>
          <div
            id={`actions-item-${highlight.id}`}
            className={`highlight-comment-button ${
              actionsOpen ? 'opened' : ''
            } py-1 flex items-center select-none cursor-pointer flex-1`}
            onClick={handleActionsOpen}
          >
            <AlertIcon />
            <div className="mx-2">Actions</div>

            <ArrowIcon
              className={`${!actionsOpen ? 'rotate-90' : 'rotate-270'}`}
            />
          </div>
        </div>

        <div
          className={`highlight-comment-container ${
            commentOpen || actionsOpen ? 'opened' : ''
          }`}
        >
          {commentOpen ? (
            <div className={'comments-list flex flex-col w-full p-4 relative'}>
              {comments.length ? (
                comments
                  .sort((a, b) => a.id - b.id)
                  .map((comment) => (
                    <div
                      className="comment-item flex w-full relative mb-2 last:mb-0"
                      key={comment.id}
                    >
                      <div className="comment-point">
                        <CircleIcon />
                      </div>
                      <div className="comment-body ml-2 flex flex-col">
                        <div className="comment-text-container flex w-full">
                          <span className="user-label">
                            {`${
                              comment.dashboard_users.first_name ? comment.dashboard_users.first_name
                                .charAt(0)
                                .toUpperCase() +
                                  comment.dashboard_users.first_name.slice(1) : ''
                            } ${
                              `${comment.dashboard_users.last_name
                                .charAt(0)
                                .toUpperCase()}.` || ''
                            }`}
                          </span>

                          <p className="comment-text">
                            {comment.care_log_comment || comment.comment}
                          </p>
                        </div>

                        <span className="comment-date-label mt-2 flex items-center">
                          {dayjs(dayjs.utc(comment.inserted_at_utc).toDate())
                            .local()
                            .format('MMM DD, YYYY')}
                          <TimeIcon className="mx-2" />
                          {dayjs(dayjs.utc(comment.inserted_at_utc).toDate())
                            .local()
                            .format('h:mm A')}
                        </span>
                      </div>
                    </div>
                  ))
              ) : (
                <div className="no-comments-message flex items-center justify-center w-full">
                  {!loadingBottomSectionData ? 'No comments' : ''}
                </div>
              )}

              <form
                className="comment-input-container w-full mt-4 relative"
                onSubmit={handleSendMessage}
              >
                <input
                  placeholder="Type your message..."
                  className="w-full border-none"
                  value={newComment}
                  onChange={(e) => dispatch({ newComment: e.target.value })}
                  disabled={loadingBottomSectionData}
                />
                <button type="submit" className="send-comment-btn absolute">
                  <PaperPlaneIcon />
                </button>
              </form>

              <CircleLoader
                show={loadingBottomSectionData}
                className={'round-border'}
              />
            </div>
          ) : (
            ''
          )}

          {actionsOpen ? (
            <div className="actions-panel w-full p-4">
              <div className="actions-container flex flex-col w-1/2">
                <div>Were there any actions taken?</div>
                <PopoverSelect
                  containerClassName={'mt-4 mb-5'}
                  value={newAction}
                  options={actionTakenEnums.map(({ name }) => ({
                    label: name,
                    value: name,
                  }))}
                  onChange={(v) => dispatch({ newAction: v })}
                  label={'Choose an action'}
                />

                <div className="input-container relative">
                  <input
                    id="resolve-input"
                    className="w-full"
                    placeholder="Or type anything..."
                    value={newMessage}
                    onChange={(e) => dispatch({ newMessage: e.target.value })}
                    autoComplete="off"
                  />
                  <label
                    htmlFor="resolve-input"
                    className="input-icon absolute cursor-text send-text-icon"
                  >
                    <PaperPlaneIcon />
                  </label>
                </div>

                <button
                  className={`send-btn cursor-pointer mt-4 ${
                    !newAction && !newMessage ? 'disabled' : ''
                  }`}
                  onClick={handleEventAction}
                >
                  Save
                </button>

                <CircleLoader
                  show={loadingBottomSectionData}
                  className={'round-border'}
                />
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  );
};

export default HighlightItem;
