import { Auth, Amplify } from 'aws-amplify';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { clearUserData, setError, setLoading, setNavigateLinkAfterLogin, setPassword, setUserData } from './UserSlice';
import { MFA_TYPE } from '../../utilities/const';
import EVENT_TYPES from '../../services/EVENT_TYPES';
import { EventService, MixpanelService } from '../../services';
import LaunchDarklyService from '../../services/LaunchDarklyService';

export const setNewPassword = createAsyncThunk(
  'user/setNewPassword',
  async ({ password, formState }, { dispatch, getState }) => {
    try {
      dispatch(setLoading(true));
      const { user } = getState();
      if (formState === 'newPassword') {
        const completeUser = await Auth.completeNewPassword(user.user, password || user.password, user?.user?.challengeParam?.requiredAttributes);
        dispatch(setUserData(completeUser));
        return completeUser;
      } else {
        await dispatch(setPassword(password));
        return dispatch(forgotRequest());
      }
    } catch (error) {
      console.log(error);
      dispatch(setError(error.message));
    }
  },
);

export const signIn = createAsyncThunk(
  'user/signIn',
  async (userInfo, { dispatch }) => {
    const { email, password, remember30Days } = userInfo;
    Amplify.configure({
      Auth: {
        cookieStorage: {
          domain: window.location.hostname,
          expires: remember30Days !== false ? 30 : 1,
          secure: window.location.hostname !== 'localhost',
        },
      },
    });

    const user = await Auth.signIn(email.toLowerCase(), password);
    dispatch(setUserData(user));
    return user;
  });

export const verifyCode = createAsyncThunk(
  'user/verifyCode',
  async ({ code }, { getState, dispatch }) => {
    let { user } = getState();
    if (!user) {
      user = (await Auth.currentSession()).idToken?.payload;
    }
    const loggedUser = await Auth.confirmSignIn(
      user.user, // Return object from Auth.signIn()
      code, // Confirmation code
      MFA_TYPE, // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
    );

    const loggedUserPayload = loggedUser?.signInUserSession?.idToken.payload;
    window.Appcues.identify(loggedUserPayload.id, {
      firstSeen: new Date(loggedUserPayload.firstSeen),
    });
    hj('identify', loggedUserPayload?.id, { email: user?.email });
    await MixpanelService.register({ user: loggedUserPayload });
    EventService.fireEvent(EVENT_TYPES.LOGIN);
    LaunchDarklyService.register({ user: loggedUserPayload });
    dispatch(setUserData(loggedUser));
    return loggedUser;
  });


export const signOut = createAsyncThunk(
  'user/signOut',
  async (_, { dispatch, getState }) => {
    await Auth.signOut();
    const { user } = getState();
    EventService.fireEvent(EVENT_TYPES.LOGOUT, {
      email: user?.email,
      first_name: user.user?.signInUserSession?.idToken?.payload?.firstName,
      id: user.user?.signInUserSession?.idToken?.payload?.id,
      last_name: user.user?.signInUserSession?.idToken?.payload?.lastName,
      username: user.user?.signInUserSession?.idToken?.payload?.username,
    });
    dispatch(setNavigateLinkAfterLogin(''));
    dispatch(clearUserData());
  });

export const forgotRequest = createAsyncThunk(
  'user/forgotRequest',
  async (_, { dispatch, getState }) => {
    dispatch(setLoading(true));
    const { user } = getState();
    return Auth.forgotPassword(user.email);
  },
);

export const forgotConfirm = createAsyncThunk(
  'user/forgotConfirm',
  async ({ code }, { getState }) => {
    const { user } = getState();
    const res = await Auth.forgotPasswordSubmit(user.email, code, user.password);
    if (res === 'SUCCESS') {
      return Auth.signIn(user.email, user.password);
    }
  },
);

