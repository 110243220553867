import React from 'react';
import { CheckActionIcon } from '../../../../assets/icons';
import Colors from '../../../../theme/Colors';
import English from '../../../../languages/English';
import ActionTooltip from '../ActionTooltip';
import { ReadContainer, ReadContainerSpan, ReadText } from './CarelogAction.style';
import { actionMap } from '../../../../utilities/const';
import withTooltip from '../../../../components/common/withTooltip';


const CarelogActionComponent = ({ actionTypeId, onMouseEnter, onMouseLeave }) => {
  const action = actionMap[actionTypeId];
  const actionText = English[action];

  return (
    <ReadContainerSpan component="span" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <ReadContainer>
        <CheckActionIcon width="10" height="8" color={Colors.sensiGreen} />
        <ReadText>{actionText}</ReadText>
      </ReadContainer>
    </ReadContainerSpan>
  );
};

const CarelogActionWithTooltip = withTooltip(
  CarelogActionComponent,
  ActionTooltip
);
export default CarelogActionWithTooltip;
