import { createSlice } from '@reduxjs/toolkit';
import { getEventsBySenior, getRelatedEvents, getSeniorsByCategories } from './SeniorsThunk';

const initialState = {
  seniors: [],
  events: [],
  eventsBackup: [],
  isRelatedEvent: false,
  relatedEvents: { data: [], total_count: 0 },
};

export const seniorsSlice = createSlice({
  name: 'seniors',
  initialState,
  reducers: {
    setIsRelatedEvent: (state, action) => {
      state.isRelatedEvent = action.payload;
    },
    updateEvent: (state, action) => {
      const { filter, data } = action.payload;
      state.events = state.events.map(item => (filter(item) ? { ...item, ...data } : item));
      state.relatedEvents.data = state.relatedEvents.data.map((item) =>
        (filter(item) ? { ...item, ...data } : item)
      );
    },
    updateEvents: (state) => {
      state.events = state.eventsBackup;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSeniorsByCategories.fulfilled, (state, { payload }) => {
      state.seniors = payload;
    });
    builder.addCase(getEventsBySenior.pending, (state) => {
      // Backup current events before fetching new ones
      state.eventsBackup = state.events;
    });
    builder.addCase(getEventsBySenior.fulfilled, (state, { payload }) => {
      state.events = payload;
    });
    builder.addCase(getRelatedEvents.fulfilled, (state, action) => {
      state.relatedEvents.data = action.payload;
      state.relatedEvents.total_count = action.payload.length;
    });
  }
});

export const {
  updateEvent,
  updateEvents,
  setIsRelatedEvent
} = seniorsSlice.actions;

export default seniorsSlice.reducer;
