import React from 'react';
import { Chip, Grid, Stack, Typography } from '@mui/material';
import { Calendar, LocationIcon } from '../../../../assets/icons';
import dayjs from '../../../../utilities/dayjs';
import { DevicesIcon } from '../../../../components/icons';


const wordsFromDate = (date) => {
  const now = dayjs().utc();
  const startOfDay = dayjs().utc().startOf('day');
  const inputDate = dayjs.utc(date);

  if (inputDate.isSame(now, 'day')) {
    return 'Today, ';
  } else if (inputDate.isSame(startOfDay.subtract(1, 'day'), 'day')) {
    return 'Yesterday, ';
  } else if (inputDate.isBetween(startOfDay.subtract(6, 'day'), startOfDay.subtract(2, 'day'), 'day', '[]')) {
    return `${Math.ceil(startOfDay.diff(inputDate, 'hour') / 24)} Days ago, `;
  } else if (inputDate.isSame(startOfDay.subtract(7, 'day'), 'day')) {
    return 'A week ago, ';
  } else {
    return '';
  }
};

const TitleDetails = ({ event }) => <Grid container wrap='nowrap' columnSpacing={1} marginTop={2}>
  <Grid item>
    <Chip variant='square' label={
      <Stack direction='row'>
        <Calendar />
        <Typography variant='h3' paddingLeft={1}
          fontSize={10}>{wordsFromDate(event?.ts) + dayjs.utc(event?.ts).format('MMM D, YYYY, hh:mm A')}</Typography>
      </Stack>
    } />
  </Grid>
  {event?.is_carelog && <Grid item>
    <Chip variant='square' label={<Stack direction='row'>
      <DevicesIcon width='8' height='11' />
      <Typography variant='h3' paddingLeft={1} fontSize={10}>{event?.room_name}</Typography>
    </Stack>} />
  </Grid>}
  <Grid item>
    <Chip variant='square' label={
      <Stack direction='row'>
        <LocationIcon />
        <Typography variant='h3' paddingLeft={1}
          fontSize={10}>{event?.office_name}, {event?.territory_name}</Typography>
      </Stack>} />
  </Grid>
</Grid>;

export default TitleDetails;
