import { styled, Paper, Typography, Box } from '@mui/material';
import { CoverageIcon as UnstyledCoverageIcon } from '../../../../assets/icons';

import Colors from '../../../../theme/Colors';

export const Wrapper = styled(Paper)(() => ({
  display: 'none',
  marginLeft: -100,
  width: 445,
  padding: '21px 23px',
  overflow: 'hidden',
  position: 'sticky',
  zIndex: 100,
  boxShadow: '0px 4px 10px 0px rgba(0,0,0,0.25)',
}));

export const Title = styled(Typography)({
  marginBottom: 32,
  color: Colors.sensiGrey,
  fontWeight: 700,
  lineHeight: 'normal',
});

export const CoverageContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: 20,
  width: '100%',
});

export const CoverageItem = styled(Box)({
  flex: '2 0',
  display: 'block',
  textAlign: 'left',
  marginBottom: 27,
  '&:nth-of-type(1)': {
    minWidth: 101,
    flex: '1 0',
  },
  '&:nth-of-type(3)': {
    minWidth: 96,
    flex: '1 0',
  },
});

export const ItemDivider = styled(Box)(({ theme }) => ({
  flexGrow: 0,
  height: 81,
  width: 1,
  background: theme.palette.border.light,
}));

export const CoverageIcon = styled(UnstyledCoverageIcon)({
  marginBottom: 14,
  width: 25,
  height: 25,
});

export const Text = styled(Typography)({
  color: Colors.sensiGrey,
  fontSize: 10,
  lineHeight: 'normal',
});

export const CoverageTitle = styled(Text)({
  fontWeight: 700,
});

export const CoverageText = styled(Text)({
  position: 'absolute',
  height: 27,
});
