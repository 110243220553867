import React, { useState } from 'react';
import MUIModal from '../MUIModal';
import './SubmitCareReportModal.style.scss';
import { XSign } from '../../../assets/icons';
import English from '../../../languages/English';
import TextAreaInput from '../../TextAreaInput/TextAreaInput';
import ToggleButton from '../../common/toggleButton';
import BasicButtons from '../../common/Button/Button';
import Colors from '../../../theme/Colors';
import Input from '../../common/Input/Input';
import SignatureInput from '../../SignatureInput/SignatureInput';

import { useTakeawaysContext } from '../../../contexts/takeaways';
import { EventService } from '../../../services';
import EVENT_TYPES from '../../../services/EVENT_TYPES';
import { useCustomerState } from '../../../contexts/customers';

const SubmitCareReportModal = ({ open, onClose, onSubmit, isDisabled, reportData }) => {
  const { takeaways } = useTakeawaysContext();
  const [signature, setSignature] = useState(reportData?.caregiver_name || '');
  const [personalNote, setPersonalNote] = useState(reportData?.report_end_text || English.defaultPersonalNoteText);
  const [includeEndReport, setIncludeEndReport] = useState(reportData ? reportData?.show_report_end_text : true);
  const [includeSignature, setIncludeSignature] = useState(reportData ? reportData?.show_signature : true);
  const [{ MixpanelUserData }] = useCustomerState();

  const handleIncludeEndReport = (e) => {
    EventService.fireEvent(EVENT_TYPES.INCLUDE_END_REPORT_TEXT, {
      ...MixpanelUserData,
      value: e
    });
    setIncludeEndReport(e);
  };

  const handleIncludeSignature = (e) => {
    EventService.fireEvent(EVENT_TYPES.INCLUDE_SIGNATURE, {
      ...MixpanelUserData,
      value: e
    });
    setIncludeSignature(e);
  };

  const toggleEndReport = () => <ToggleButton defaultToggle={includeEndReport} onToggle={(e) => handleIncludeEndReport(e)} />;

  const toggleSignature = () => <ToggleButton defaultToggle={includeSignature} onToggle={(e) => handleIncludeSignature(e)} />;

  const handleSubmit = () => {
    onSubmit({ takeaways, signature, personalNote, includeEndReport, includeSignature });
    onClose();
    EventService.fireEvent(EVENT_TYPES.DONE_CREATING_CARE_REPORT, {
      ...MixpanelUserData,
    });
  };

  const onBlur = (e) => {
    EventService.fireEvent(EVENT_TYPES.ADD_CAREGIVER_NAME, {
      ...MixpanelUserData,
      caregiver_name: e.target.value
    });
  };


  return (
    <MUIModal open={open} handleClose={onClose}>
      <div className="box">
        <div className="closeButton" onClick={onClose}>
          <XSign />
        </div>
        <div className="titleContainer">
          <span className="title">{English.almostDone}</span>
          <span className="subTitle">{English.doneGeneratorTitle}</span>
        </div>
        <div className="inputsContainer">
          <Input value={signature} setValue={setSignature} onBlur={onBlur} />
          <TextAreaInput
            containerStyle="endReportContainer"
            customStyle="endReportInput"
            renderRightTitle={toggleEndReport}
            title={English.endReport}
            onChange={(e) => setPersonalNote(e.target.value)}
            value={personalNote}
          />

          <SignatureInput
            containerStyle="signatureInputContainer"
            customStyle="signatureInput"
            renderRightTitle={toggleSignature}
            title={English.signature}
            value={signature}
          />
          <BasicButtons
            customStyle={{
              marginTop: '50px',
              marginBottom: '25px',
              alignSelf: 'center',
              backgroundColor: Colors.sensiPurple,
            }}
            onClick={() => handleSubmit()}
            disabled={isDisabled}
            text={English?.done}
          />
        </div>
      </div>
    </MUIModal>
  );
};

export default SubmitCareReportModal;
