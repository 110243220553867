/* eslint-disable max-lines */
/* eslint-disable max-len */
import React from 'react';

const EmptyImage = (props) => (
  <svg
    width="156"
    height="148"
    viewBox="0 0 156 148"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_934_20735)">
      <path
        d="M147.699 107.36C159.065 80.4178 160.503 42.3443 140.913 18.6917C130.108 5.6482 113.108 1.31139 96.8793 0.343615C82.3367 -0.52507 66.768 -0.178257 53.555 6.71176C31.1571 18.3911 30.6061 46.1626 13.83 62.5487C0.300192 75.7606 -5.30835 94.5876 6.23865 119.36C17.7857 144.132 39.2301 149.747 60.3446 141.82C81.4591 133.893 91.0266 132.242 116.43 136.205C128.106 138.028 136.578 129.256 141.701 119.806C143.899 115.753 145.911 111.601 147.699 107.364V107.36Z"
        fill="#E8F1FF"
      />
      <path
        opacity="0.7"
        d="M147.699 107.36C159.065 80.4178 160.503 42.3443 140.913 18.6917C130.108 5.6482 113.108 1.31139 96.8793 0.343615C82.3367 -0.52507 66.768 -0.178257 53.555 6.71176C31.1571 18.3911 30.6061 46.1626 13.83 62.5487C0.300192 75.7606 -5.30835 94.5876 6.23865 119.36C17.7857 144.132 39.2301 149.747 60.3446 141.82C81.4591 133.893 91.0266 132.242 116.43 136.205C128.106 138.028 136.578 129.256 141.701 119.806C143.899 115.753 145.911 111.601 147.699 107.364V107.36Z"
        fill="#E8F1FF"
      />
      <g opacity="0.2">
        <path
          d="M10.6595 54.1492V44.0025C10.6595 43.3055 11.2237 42.7407 11.9198 42.7407H27.7458C28.4419 42.7407 29.0061 43.3055 29.0061 44.0025V58.5851H10.6562V56.3457"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.8905 46.1858H28.7785"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.1728 49.1716H12.4939V51.236H27.1751V49.1716H17.9144"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27.1718 53.5349H12.4939V55.6026H27.1718V53.5349Z"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.2">
        <path
          d="M90.0237 4.32036C88.2059 2.67878 85.7975 1.67798 83.1582 1.67798C77.5002 1.67798 72.9111 6.27243 72.9111 11.937C72.9111 14.7611 74.0493 17.3143 75.8902 19.1706"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M77.0581 20.1813C78.7638 21.4463 80.8719 22.1961 83.1582 22.1961C88.8162 22.1961 93.4054 17.6016 93.4054 11.937C93.4054 9.33758 92.4387 6.96274 90.8485 5.15601"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M87.8463 11.937H83.1582V19.9071"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g opacity="0.2">
        <path
          d="M10.6331 19.0352C8.16208 19.415 6.27167 21.5553 6.27167 24.135C6.27167 26.9854 8.57777 29.2942 11.4249 29.2942C12.0617 29.2942 12.672 29.1786 13.2329 28.9672"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.9055 27.9367C15.9316 26.9921 16.5749 25.6379 16.5749 24.135C16.5749 21.4992 14.5987 19.3259 12.0518 19.0154"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.4216 21.5059V26.7609"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M14.0478 24.135H8.79883"
          stroke="black"
          strokeMiterlimit="10"
        />
      </g>
      <path
        d="M140.514 133.233H22.0746L22.2131 35.6195C22.2131 33.6609 23.7967 32.0754 25.7531 32.0754H137.112C139.069 32.0754 140.652 33.6609 140.652 35.6195L140.514 133.233Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M54.6437 40.749H37.828V44.2139H54.6437V40.749Z"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M105.622 45.469H136.291"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.7883 45.469H103.834"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M137.116 32.0754H25.7531C23.7967 32.0754 22.2131 33.6609 22.2131 35.6195V38.3181H140.652V35.6195C140.652 33.6609 139.069 32.0754 137.112 32.0754H137.116Z"
        fill="#263238"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.6663 35.5404C28.6663 34.9491 28.1879 34.4702 27.5974 34.4702C27.0068 34.4702 26.5284 34.9491 26.5284 35.5404C26.5284 36.1316 27.0068 36.6105 27.5974 36.6105C28.1879 36.6105 28.6663 36.1316 28.6663 35.5404Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.9387 35.5404C32.9387 34.9491 32.4603 34.4702 31.8698 34.4702C31.2792 34.4702 30.8008 34.9491 30.8008 35.5404C30.8008 36.1316 31.2792 36.6105 31.8698 36.6105C32.4603 36.6105 32.9387 36.1316 32.9387 35.5404Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.1422 36.6105C36.7325 36.6105 37.2111 36.1314 37.2111 35.5404C37.2111 34.9493 36.7325 34.4702 36.1422 34.4702C35.5518 34.4702 35.0732 34.9493 35.0732 35.5404C35.0732 36.1314 35.5518 36.6105 36.1422 36.6105Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.049 40.9539H34.1197"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.049 42.3147H34.1197"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.049 43.6787H34.1197"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.9892 52.9866V48.6135H29.0952V48.1213H31.4276V48.6135H30.5336V52.9866H29.9925H29.9892Z"
        fill="#263238"
      />
      <path
        d="M32.0842 52.0088L31.8961 52.9898H31.3979L32.3283 48.1245H33.1135L34.0439 52.9898H33.5028L33.3148 52.0088H32.0875H32.0842ZM32.1535 51.5431H33.2356L32.6879 48.7356L32.1535 51.5431Z"
        fill="#263238"
      />
      <path
        d="M36.571 49.2906V49.4227H36.0563V49.2641C36.0563 48.8248 35.8914 48.5705 35.5021 48.5705C35.1128 48.5705 34.9478 48.8281 34.9478 49.2575C34.9478 50.3475 36.5776 50.4532 36.5776 51.8206C36.5776 52.5572 36.2246 53.0361 35.4823 53.0361C34.74 53.0361 34.3936 52.5572 34.3936 51.8206V51.5432H34.9016V51.8504C34.9016 52.2897 35.0831 52.544 35.4724 52.544C35.8617 52.544 36.0398 52.2864 36.0398 51.8504C36.0398 50.767 34.4166 50.6613 34.4166 49.2939C34.4166 48.5441 34.7631 48.0784 35.4922 48.0784C36.2213 48.0784 36.5743 48.5573 36.5743 49.2939L36.571 49.2906Z"
        fill="#263238"
      />
      <path
        d="M37.8742 50.8759L37.5904 51.3548V52.9898H37.0494V48.1245H37.5904V50.4729L38.8804 48.1245H39.4281L38.2008 50.3408L39.4709 52.9898H38.9167L37.8742 50.8759Z"
        fill="#263238"
      />
      <path
        d="M41.9585 49.2906V49.4227H41.4438V49.2641C41.4438 48.8248 41.2789 48.5705 40.8896 48.5705C40.5003 48.5705 40.3353 48.8281 40.3353 49.2575C40.3353 50.3475 41.9651 50.4532 41.9651 51.8206C41.9651 52.5572 41.6121 53.0361 40.8698 53.0361C40.1275 53.0361 39.7811 52.5572 39.7811 51.8206V51.5432H40.2891V51.8504C40.2891 52.2897 40.4706 52.544 40.8599 52.544C41.2492 52.544 41.4273 52.2864 41.4273 51.8504C41.4273 50.767 39.8042 50.6613 39.8042 49.2939C39.8042 48.5441 40.1506 48.0784 40.8797 48.0784C41.6088 48.0784 41.9618 48.5573 41.9618 49.2939L41.9585 49.2906Z"
        fill="#263238"
      />
      <path
        d="M103.402 80.3022H28.8708V100.239H103.402V80.3022Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.745 87.8794H62.0834"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.7266 87.8794H56.1812"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.5659 87.8794H42.9879"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.3215 92.824H54.6405"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.8163 92.824H48.5437"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.6495 92.824H41.081"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.5659 92.824H37.2606"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M103.402 105.739H28.8708V125.675H103.402V105.739Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.745 113.738H62.0834"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.7266 113.738H56.1812"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.5659 113.738H42.9879"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.3215 118.683H54.6405"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.8163 118.683H48.5437"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.6495 118.683H41.081"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.5659 118.683H37.2606"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M135.704 54.8726H109.631C108.771 54.8726 108.073 55.5706 108.073 56.4316V63.8104C108.073 64.6714 108.771 65.3694 109.631 65.3694H135.704C136.564 65.3694 137.261 64.6714 137.261 63.8104V56.4316C137.261 55.5706 136.564 54.8726 135.704 54.8726Z"
        fill="#6C5CE7"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M113.669 61.8086L113.481 62.7896H112.982L113.913 57.9243H114.698L115.628 62.7896H115.087L114.899 61.8086H113.672H113.669ZM113.738 61.3429H114.82L114.272 58.5354L113.738 61.3429Z"
        fill="white"
      />
      <path
        d="M117.189 57.9243C117.944 57.9243 118.294 58.3834 118.294 59.1332V61.5873C118.294 62.3305 117.948 62.7896 117.189 62.7896H116.051V57.9243H117.189ZM117.182 62.2975C117.572 62.2975 117.76 62.053 117.76 61.6104V59.1068C117.76 58.6609 117.572 58.4198 117.176 58.4198H116.592V62.2975H117.182Z"
        fill="white"
      />
      <path
        d="M119.937 57.9243C120.693 57.9243 121.042 58.3834 121.042 59.1332V61.5873C121.042 62.3305 120.696 62.7896 119.937 62.7896H118.799V57.9243H119.937ZM119.93 62.2975C120.32 62.2975 120.508 62.053 120.508 61.6104V59.1068C120.508 58.6609 120.32 58.4198 119.924 58.4198H119.34V62.2975H119.93Z"
        fill="white"
      />
      <path
        d="M123.332 62.7896V58.4165H122.438V57.9243H124.77V58.4165H123.876V62.7896H123.335H123.332Z"
        fill="white"
      />
      <path
        d="M125.43 61.8086L125.242 62.7896H124.744L125.674 57.9243H126.459L127.39 62.7896H126.849L126.661 61.8086H125.433H125.43ZM125.499 61.3429H126.582L126.034 58.5354L125.499 61.3429Z"
        fill="white"
      />
      <path
        d="M129.914 59.0904V59.2225H129.399V59.0639C129.399 58.6247 129.234 58.3703 128.845 58.3703C128.455 58.3703 128.291 58.628 128.291 59.0573C128.291 60.1473 129.92 60.253 129.92 61.6205C129.92 62.357 129.567 62.836 128.825 62.836C128.083 62.836 127.736 62.357 127.736 61.6205V61.343H128.244V61.6502C128.244 62.0895 128.426 62.3438 128.815 62.3438C129.204 62.3438 129.383 62.0862 129.383 61.6502C129.383 60.5668 127.759 60.4611 127.759 59.0937C127.759 58.3439 128.106 57.8782 128.835 57.8782C129.564 57.8782 129.917 58.3571 129.917 59.0937L129.914 59.0904Z"
        fill="white"
      />
      <path
        d="M131.22 60.6757L130.936 61.1546V62.7896H130.395V57.9243H130.936V60.2727L132.226 57.9243H132.774L131.547 60.1406L132.817 62.7896H132.263L131.22 60.6757Z"
        fill="white"
      />
      <path
        d="M112.243 13.0435C112.243 13.0435 108.41 14.7346 106.414 15.2697C104.421 15.8081 102.273 16.6536 101.736 18.3414C101.198 20.0293 98.8028 26.5229 98.651 27.9069C98.4992 29.2908 100.97 31.7813 100.97 31.7813H132.88C132.88 31.7813 133.47 23.6989 132.167 20.9343C130.864 18.1697 126.895 15.8081 125.747 15.3489C124.595 14.8898 121.606 13.4299 120.379 13.1987C119.152 12.9675 113.629 12.5084 112.25 13.0435H112.243Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M101.666 24.234C101.666 24.234 103.534 23.5734 105.296 24.8946L101.666 24.234Z"
        fill="white"
      />
      <path
        d="M101.666 24.234C101.666 24.234 103.534 23.5734 105.296 24.8946"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M104.223 24.8945C104.223 24.8945 105.295 24.8945 106.203 25.7203L104.223 24.8945Z"
        fill="white"
      />
      <path
        d="M104.223 24.8945C104.223 24.8945 105.295 24.8945 106.203 25.7203"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M107.483 21.2051C107.743 21.6411 107.928 22.2092 107.866 22.9094C107.701 24.8251 107.057 26.2124 107.057 26.2124"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M106.371 20.0886C106.371 20.0886 106.78 20.3132 107.169 20.769"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M108.327 21.1125C108.327 21.1125 108.941 22.9721 108.595 24.475"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M124.902 22.2588C124.902 22.2588 124.134 24.9474 124.213 25.7897"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M112.702 19.633L114.107 22.1697L115.305 26.0474L116.994 22.5231L118.614 19.7023L115.447 19.2102L112.702 19.633Z"
        fill="#6C5CE7"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M114.107 22.1697L116.994 22.5231"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M112.55 15.1177C112.55 15.1177 112.013 15.808 111.706 16.7295C111.399 17.6511 110.861 20.7988 110.861 20.7988C110.861 20.7988 113.853 20.6436 114.619 20.6436C115.384 20.6436 116.612 20.7195 116.842 20.7195C117.073 20.7195 120.524 22.7938 120.524 22.7938C120.524 22.7938 121.138 19.2596 120.448 17.2646C119.759 15.2663 115.081 12.8882 112.547 15.1144L112.55 15.1177Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M114.164 19.4744L115.378 22.0309L117.845 19.2432C117.845 19.2432 115.546 19.9335 114.164 19.4744Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M124.447 48.2996L123.266 50.4927C123.266 50.4927 121.801 50.7966 121.646 51.1038C121.491 51.411 120.726 52.7156 120.343 53.0988C119.96 53.4819 119.191 54.0203 119.884 54.3275C120.574 54.6347 121.801 53.2507 121.801 53.2507L122.491 52.8676C122.491 52.8676 122.593 53.7396 122.266 54.6809C121.814 55.979 121.293 57.5578 121.362 57.723C121.702 58.5487 122.237 57.9641 122.237 57.9641L123.266 55.5562L123.447 55.4406C123.447 55.4406 123.24 58.192 123.24 58.2052C123.2 58.7106 123.645 59.2985 124.035 58.7832L124.955 55.8667V58.4926C124.955 58.8955 125.328 59.2324 125.717 59.1234C125.747 59.1168 125.773 59.1069 125.8 59.0937C126.106 58.9385 126.212 55.7379 126.212 55.7379L126.852 58.0566C126.921 58.3076 127.192 58.4694 127.433 58.3704C127.495 58.3439 127.542 58.3076 127.561 58.2481C127.637 58.0169 127.41 54.3308 127.41 54.3308L127.258 50.4927L127.489 49.1881L124.454 48.3029L124.447 48.2996Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M125.899 22.566C125.899 22.566 124.671 25.1753 124.826 26.0968C124.978 27.0184 128.554 35.8274 128.554 35.8274L124.11 48.6496L127.637 49.4952C127.637 49.4952 133.665 40.5243 134.305 38.5953C134.843 36.9769 134.763 35.6788 134.763 35.2164C134.763 34.754 133.338 24.2571 132.494 22.414C131.649 20.571 130.577 19.4182 130.577 19.4182"
        fill="white"
      />
      <path
        d="M125.899 22.566C125.899 22.566 124.671 25.1753 124.826 26.0968C124.978 27.0184 128.554 35.8274 128.554 35.8274L124.11 48.6496L127.637 49.4952C127.637 49.4952 133.665 40.5243 134.305 38.5953C134.843 36.9769 134.763 35.6788 134.763 35.2164C134.763 34.754 133.338 24.2571 132.494 22.414C131.649 20.571 130.577 19.4182 130.577 19.4182"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M129.089 35.5203C129.089 35.5203 130.468 33.6013 131.312 32.3726"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M131.085 31.6821L129.396 33.8324"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M124.414 47.4209L128.175 48.3424"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M99.8486 25.7237C99.5913 25.945 99.2449 26.2885 98.8984 26.7906C98.2089 27.7881 98.5916 29.9383 99.0535 30.6286C99.5121 31.3189 101.353 31.7814 101.353 31.7814H116.249L116.549 29.2612C116.232 29.2546 116.008 29.248 116.008 29.248C116.008 29.248 113.322 28.4024 111.405 27.864C109.489 27.3256 105.714 25.7303 104.58 25.4826C103.53 25.2547 102.587 25.2943 101.937 25.3802"
        fill="white"
      />
      <path
        d="M99.8486 25.7237C99.5913 25.945 99.2449 26.2885 98.8984 26.7906C98.2089 27.7881 98.5916 29.9383 99.0535 30.6286C99.5121 31.3189 101.353 31.7814 101.353 31.7814H116.249L116.549 29.2612C116.232 29.2546 116.008 29.248 116.008 29.248C116.008 29.248 113.322 28.4024 111.405 27.864C109.489 27.3256 105.714 25.7303 104.58 25.4826C103.53 25.2547 102.587 25.2943 101.937 25.3802"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M118.759 31.7813L118.683 31.6855C118.683 31.6855 120.231 31.5435 120.442 31.4742C120.653 31.4048 121.286 31.3321 121.286 31.3321L123.523 31.7813H125.744L125.513 31.3982L123.517 30.1695L121.808 29.314C120.029 28.4255 117.892 29.5221 116.001 29.2479C116.001 29.2479 116.315 29.2942 116.358 29.2942L116.061 31.7813H118.759Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M121.854 30.0637L125.371 31.6161"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M115.025 29.1489L114.929 31.7054"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M112.092 10.7381C112.092 10.7381 111.095 9.4334 111.02 7.81825C110.944 6.20309 111.709 5.89921 111.709 5.89921C111.709 5.89921 110.558 4.05615 113.088 2.98268C115.619 1.90921 119.455 1.29486 121.603 2.44429C123.751 3.59704 124.516 6.28236 124.44 8.12542C124.365 9.96849 123.289 12.1187 123.289 12.1187L121.603 14.193C121.603 14.193 120.531 14.5761 120.3 14.117C120.069 13.6579 118.766 12.274 118.076 12.274C117.387 12.274 115.622 12.274 114.701 11.8908C113.781 11.5077 112.095 10.7381 112.095 10.7381H112.092Z"
        fill="#263238"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M111.937 8.43581C111.937 8.43581 111.861 13.1194 111.861 14.1169C111.861 15.1144 111.861 16.1152 112.319 17.1127C112.778 18.1102 113.164 20.7989 115.233 20.9508C117.301 21.1027 120.293 18.9558 120.831 17.8031C121.369 16.6503 121.676 14.6553 121.676 14.6553C121.676 14.6553 123.517 13.4266 123.823 11.9667C124.13 10.5068 123.517 9.81645 123.134 10.5068C122.751 11.1971 122.213 12.9642 121.983 13.1161C121.752 13.2714 121.445 12.1946 121.445 12.1946C121.445 12.1946 120.293 12.657 120.142 12.1946C119.99 11.7322 120.755 9.50597 120.755 9.50597C120.755 9.50597 118.225 10.1963 117.687 10.1963C117.149 10.1963 116.001 9.81315 116.001 9.81315L118.225 11.1938C118.225 11.1938 116.691 11.8841 115.157 11.425C113.623 10.9626 112.471 8.4292 112.471 8.4292H111.933L111.937 8.43581Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M114.239 13.3506V14.2721C114.239 14.2721 113.243 16.8815 113.395 17.3439C113.547 17.8063 114.929 17.5751 115.236 17.4199"
        fill="white"
      />
      <path
        d="M114.239 13.3506V14.2721C114.239 14.2721 113.243 16.8815 113.395 17.3439C113.547 17.8063 114.929 17.5751 115.236 17.4199"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M114.084 18.1104C114.084 18.1104 115.543 19.0319 117.077 18.1863L114.084 18.1104Z"
        fill="white"
      />
      <path
        d="M114.084 18.1104C114.084 18.1104 115.543 19.0319 117.077 18.1863"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M116.311 14.6553C116.311 14.6553 117.463 15.5768 118.997 15.0384L116.311 14.6553Z"
        fill="white"
      />
      <path
        d="M116.311 14.6553C116.311 14.6553 117.463 15.5768 118.997 15.0384"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M116.387 13.2746C116.387 13.2746 118.073 12.6603 119.149 13.813L116.387 13.2746Z"
        fill="white"
      />
      <path
        d="M116.387 13.2746C116.387 13.2746 118.073 12.6603 119.149 13.813"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M112.474 13.4265C112.474 13.4265 112.781 14.1168 114.239 14.2721L112.474 13.4265Z"
        fill="white"
      />
      <path
        d="M112.474 13.4265C112.474 13.4265 112.781 14.1168 114.239 14.2721"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M113.933 12.6602C113.933 12.6602 113.395 11.7387 112.629 12.0458L113.933 12.6602Z"
        fill="white"
      />
      <path
        d="M113.933 12.6602C113.933 12.6602 113.395 11.7387 112.629 12.0458"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M115.305 26.0474L113.124 45.568L115.938 49.515L118.545 45.568L115.305 26.0474Z"
        fill="#6C5CE7"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.682 73.6072V76.821C30.682 76.821 63.5349 75.4767 103.398 74.8095L30.682 73.6072Z"
        fill="#263238"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M103.402 54.8726H28.8708V74.8094H103.402V54.8726Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.7449 62.3901H62.0833"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.7265 62.3901H56.1811"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.5659 62.3901H42.9879"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.3215 67.3313H54.6404"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.8163 67.3313H48.5436"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.6495 67.3313H41.0809"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.5659 67.3313H37.2605"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.4427 78.0693C35.4427 78.0693 36.5446 75.1991 36.7656 74.9051C36.9867 74.6111 37.3529 73.8746 38.016 73.8746C38.6791 73.8746 39.8536 73.9472 40.3683 73.7259C40.883 73.5046 41.5428 73.5046 41.3976 73.9472C41.2525 74.3898 40.3683 74.5352 40.3683 74.5352L44.192 74.8291C44.192 74.8291 43.0901 75.8597 42.5754 76.081C42.0608 76.3023 40.9589 76.5235 40.9589 76.5235C40.9589 76.5235 40.078 77.1115 39.4874 77.2601C38.8969 77.4088 38.016 77.4814 38.016 77.4814L37.2077 78.9545L35.4427 78.0726V78.0693Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.3683 74.5352L38.6791 74.9778"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.3305 65.3364L25.0801 67.4701L25.8158 69.1645L27.8712 67.6187L28.9005 66.8822V62.4661L26.3305 65.3364Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.0335 66.5157C26.0335 66.5157 26.6208 63.5 26.9144 63.1301C27.208 62.7602 28.3858 61.8783 28.752 61.8056C29.1182 61.7329 29.6329 61.9542 29.3392 62.3935C29.0456 62.8361 28.0889 63.4967 28.0889 63.4967C28.0889 63.4967 28.6035 64.9699 28.2373 65.7791C27.8711 66.5883 27.0628 66.955 27.0628 66.955"
        fill="white"
      />
      <path
        d="M26.0335 66.5157C26.0335 66.5157 26.6208 63.5 26.9144 63.1301C27.208 62.7602 28.3858 61.8783 28.752 61.8056C29.1182 61.7329 29.6329 61.9542 29.3392 62.3935C29.0456 62.8361 28.0889 63.4967 28.0889 63.4967C28.0889 63.4967 28.6035 64.9699 28.2373 65.7791C27.8711 66.5883 27.0628 66.955 27.0628 66.955"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.1527 66.8821L26.403 70.0463L20.006 74.905L18.6105 69.901L25.1527 66.8821Z"
        fill="#6C5CE7"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.2395 119.964L22.4869 123.102C22.4869 123.102 20.8374 126.57 21.0023 129.626C21.1673 132.681 21.4972 141.516 21.4972 142.012C21.4972 142.507 20.6724 143.085 21.2498 145.15C21.4114 145.724 22.8168 145.315 24.219 146.223C25.6211 147.131 27.3532 147.71 28.4254 147.71C29.4976 147.71 30.075 147.71 30.075 147.214C30.075 146.719 28.5079 146.058 27.848 145.397C27.1882 144.737 25.1262 142.012 24.9613 141.682C24.7963 141.351 25.5386 136.479 26.0335 134.58C26.5284 132.681 27.9305 128.139 28.5079 124.919C29.0852 121.699 29.3327 119.056 29.3327 119.056L22.2395 119.964Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.2498 145.15L21.4147 147.379H22.5694L23.0643 145.563C23.0643 145.563 21.6621 145.067 21.2498 145.15Z"
        fill="#263238"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.4254 147.71C29.491 147.601 30.0749 147.71 30.0749 147.214C30.0749 146.719 28.5079 146.058 27.848 145.398C27.3334 144.882 25.9741 143.122 25.3176 142.21C25.0042 142.445 24.605 142.643 24.1365 142.673C22.8993 142.755 21.4972 142.012 21.4972 142.012C21.4972 142.507 20.6724 143.085 21.2497 145.15C21.4114 145.725 22.863 145.622 23.9946 146.98C25.0635 148.265 28.2637 147.723 28.4254 147.71Z"
        fill="#263238"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5895 117.57L12.7545 122.194C12.7545 122.194 10.61 125.084 10.1976 127.892C9.78524 130.699 8.38311 141.682 8.38311 142.755C8.38311 143.829 7.72328 147.297 8.05319 147.544C8.38311 147.792 12.1771 147.544 12.1771 147.544C12.1771 147.544 12.3421 147.214 12.3421 146.388C12.3421 145.563 11.6822 142.838 11.8472 142.177C12.0122 141.516 12.5895 139.452 12.5895 139.452C12.5895 139.452 15.1464 132.764 16.2186 130.699C17.2908 128.635 18.1981 125.662 18.7754 123.845C19.3528 122.029 19.8476 119.469 19.8476 119.469L12.5895 117.57Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.8307 142.303C10.6859 140.691 8.81529 141.675 8.44909 141.89C8.4095 142.316 8.38311 142.616 8.38311 142.755C8.38311 143.829 7.72328 147.297 8.05319 147.544C8.38311 147.792 12.1771 147.544 12.1771 147.544C12.1771 147.544 12.3421 147.214 12.3421 146.388C12.3421 145.606 11.7515 143.125 11.8274 142.303H11.8307Z"
        fill="#263238"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1771 147.544C12.0122 147.049 10.8575 145.067 10.8575 145.067L8.71303 145.15C7.97072 145.975 8.0532 147.544 8.0532 147.544C8.38312 147.792 12.1771 147.544 12.1771 147.544Z"
        fill="#8C8C8C"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.8575 145.067C10.8575 145.067 10.1151 144.406 9.5378 144.654C8.96045 144.902 8.71301 145.15 8.71301 145.15L8.79549 147.627H10.2801L10.8575 145.067Z"
        fill="#263238"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.775 101.137C10.775 101.137 10.1151 102.789 10.0327 104.936C9.95018 107.083 10.5275 117.652 10.5275 117.652C10.5275 117.652 14.6515 120.46 21.5797 120.708C28.5079 120.955 31.7245 119.056 31.7245 119.056C31.7245 119.056 31.5596 106.34 30.7348 103.284C29.91 100.229 28.4254 98.9905 28.4254 98.9905C28.4254 98.9905 25.1262 99.9814 20.095 99.8989C15.0639 99.8163 11.5998 98.908 11.5998 98.908L10.775 101.137Z"
        fill="#263238"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9524 75.7144C12.9524 75.7144 10.8937 79.5426 10.6001 82.3369C10.3065 85.1345 13.025 93.6694 13.025 93.9634C13.025 94.2574 10.158 101.616 10.158 101.616C10.158 101.616 13.4671 103.09 17.1423 103.899C20.8176 104.708 24.5654 104.193 25.3011 103.971L26.2578 101.101L26.624 103.529C26.624 103.529 26.624 103.601 27.7985 103.308C28.973 103.014 30.4445 101.762 30.4445 101.762C30.4445 101.762 28.5342 97.273 27.5775 96.0212C26.6207 94.7693 25.4462 91.899 25.4462 91.899L25.8883 88.2195C25.8883 88.2195 29.1974 90.9412 29.491 91.0171C29.7846 91.0931 34.3407 85.7192 35.2975 84.1734C36.2542 82.6276 37.7982 78.6541 37.7982 78.6541L35.5944 77.5509L34.7135 78.4328L29.4943 84.1007C29.4943 84.1007 26.3337 80.4938 24.7897 79.9059C23.2457 79.318 20.3062 78.4328 20.3062 78.4328L14.3512 77.0356L14.7933 75.7838C14.7933 75.7838 14.4997 74.9019 12.9557 75.7111L12.9524 75.7144Z"
        fill="#6C5CE7"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.8883 88.2229L22.5694 86.2742L25.5914 89.9306L25.8883 88.2229Z"
        fill="#263238"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.9205 92.7776C19.2471 93.1574 19.587 93.5009 19.9334 93.7883"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.8725 85.5903C14.8725 85.5903 16.2911 89.25 18.2442 91.9254"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.4396 91.136C16.4396 91.136 17.4029 92.8238 19.2109 94.7527"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.5068 64.1605C22.5068 64.1605 22.873 64.8244 23.1699 66.0003C23.4668 67.1762 24.4928 67.7674 24.859 67.6914C25.2253 67.6154 25.2253 68.5006 25.0075 68.9432C24.7898 69.3858 24.4203 69.7525 24.4203 69.7525C24.4203 69.7525 24.3477 71.5196 24.1266 72.8441C23.9056 74.1686 23.833 75.1991 23.3183 75.1991H20.6724C20.6724 75.1991 20.4514 76.375 20.3788 77.3328C20.3062 78.2907 20.3062 78.436 20.3062 78.436L14.3513 77.0389L15.0144 74.9778C15.0144 74.9778 11.2666 73.2867 13.1042 70.1951C14.9418 67.1035 19.2802 63.2027 22.5134 64.1605H22.5068Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.6692 75.1992L19.4518 75.1001L20.4151 76.7879L20.6692 75.1992Z"
        fill="#263238"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.2718 71.3708C24.2718 71.3708 23.6845 71.8134 22.8004 71.4435"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.9921 66.8094C21.9921 66.8094 22.1405 65.4123 22.8729 65.4849"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.1567 67.391C23.2821 67.7774 23.2722 68.1275 23.1369 68.1705C23.0016 68.2134 22.7905 67.936 22.6684 67.5495C22.5431 67.1631 22.5529 66.8129 22.6882 66.77C22.8235 66.7271 23.0346 67.0045 23.1567 67.391Z"
        fill="#263238"
      />
      <path
        d="M22.5068 61.9509C22.5068 61.9509 22.5232 61.974 22.5331 61.984C23.3117 62.998 23.1798 64.1276 22.5793 65.1878C21.3587 67.3513 17.624 69.3628 17.624 71.153C17.624 72.9432 19.5045 72.8475 19.5045 72.8475C19.5045 72.8475 20.2567 74.4461 18.3762 75.8598C16.4957 77.2702 12.6423 77.6467 10.1976 75.1067C9.48503 74.3668 9.03965 73.4651 8.76912 72.5568C8.02681 70.0796 7.92124 67.3216 9.01326 65.0755C9.93042 63.1928 10.9861 61.6669 12.9029 60.7222C14.4074 59.9823 16.7827 59.7974 18.462 59.8932C19.2736 59.9394 20.0984 60.1046 20.8308 60.4679C21.4444 60.7718 22.0845 61.4092 22.5068 61.9476V61.9509Z"
        fill="#263238"
        stroke="#263238"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.3666 74.3668C17.9803 74.2645 18.4257 74.0696 18.4257 74.0696"
        stroke="#757575"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.5768 66.1094C20.1314 66.7568 19.521 67.4306 18.6863 68.0185C16.0965 69.8351 13.2461 71.7377 14.5393 73.4651C15.0507 74.1488 15.7666 74.3867 16.4429 74.423"
        stroke="#757575"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.7083 63.5198C21.7083 63.5198 21.606 64.1473 21.2101 64.9995"
        stroke="#757575"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5257 68.6096C15.1067 69.1942 14.5723 69.8813 13.9356 70.6112C12.121 72.6855 12.3817 76.9232 16.4396 76.5797"
        stroke="#757575"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.7003 66.8062C16.7003 66.8062 16.5089 67.153 16.1328 67.7244"
        stroke="#757575"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_934_20735">
        <rect width="156" height="148" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default EmptyImage;
