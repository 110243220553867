import { styled, IconButton as MuiIconButton, List } from '@mui/material';

const Form = styled('form')(({ theme }) => ({
  display: 'flex',
  padding: '20px 24px',
  borderBottom: `1px solid ${theme.palette.border.main}`,
}));

const IconButton = styled(MuiIconButton)(() => ({
  padding: '0px',
}));

const Input = styled('input')(({ theme }) => ({
  flex: '1 0',
  marginLeft: '6px',
  padding: 0,
  border: 'none',
  '&::placeholder': {
    color: theme.palette.sensiGrey.main,
  },
}));

const StyledList = styled(List)(({ theme }) => ({
  maxHeight: 250,
  overflow: 'hidden auto',
  '&::-webkit-scrollbar': {
    width: theme.spacing(1),
  },
  '&::-webkit-scrollbar-track': {
    WebkitBoxShadow: 'none',
    backgroundColor: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#d9d9d9',
    borderRadius: 20,
  },
}));

export { Form, Input, IconButton, StyledList };
