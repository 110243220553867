/* eslint-disable max-len */
/* eslint-disable indent */
/* eslint-disable quotes */
import {
  styled,
  Box,
  Checkbox as MuiCheckbox,
  Typography,
  Button as MuiButton,
  LinearProgress,
} from '@mui/material';
import Colors from '../../../../theme/Colors';

const Container = styled(Box)(({ theme, index }) => ({
  position: 'relative',
  padding: '22px 24px',
  borderBottom: `1px solid ${theme.palette.border.main}`,
  backgroundColor: index % 2 === 0 ? undefined : '#FAFAFA',
}));

const Row = styled(Box)({
  display: 'flex',
  gap: 10,
  ':not(:last-child)': {
    marginBottom: 14,
  },
});

const UncheckedIcon = styled('span')(({ theme, checked }) => ({
  width: '100%',
  height: '100%',
  border: `1px solid ${
    checked ? theme.palette.primary.main : theme.palette.border.main
  }`,
  borderRadius: 2,
  backgroundColor: Colors.white,
}));

const CheckedIcon = styled(UncheckedIcon)(({ theme }) => ({
  '&:before': {
    display: 'block',
    width: '100%',
    height: '100%',
    padding: 4,
    backgroundImage: `url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M2.99994 4.33351L7.33298 0L8 0.666549L2.99994 5.66661L0 2.66667L0.666549 2.00012L2.99994 4.33351Z' fill='${encodeURIComponent(
      theme.palette.primary.main,
    )}' /%3E %3C/svg%3E")`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundOrigin: 'content-box',
    content: '""',
  },
}));

const Checkbox = styled(MuiCheckbox)({
  flexShrink: 0,
  width: 19,
  height: 19,
  padding: 0,
});

const Title = styled(Typography)({
  fontWeight: 700,
  lineHeight: '19px',
});

const ActionButton = styled(MuiButton)(({ theme, background }) => ({
  padding: '6px 15px',
  borderRadius: 8,
  backgroundColor: background,
  color: theme.palette.black.main,
  boxShadow: 'none',
  fontWeight: 700,
  fontSize: 12,
  lineHeight: '20px',
  textTransform: 'none',

  '&:hover': {
    backgroundColor: background,
  },
  '& > .MuiButton-startIcon': {
    fontSize: 15,
  },
  '& .MuiSvgIcon-root': {
    fontSize: 'inherit',
  },
}));

const Progress = styled(LinearProgress)({
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: 8,
  background: 'transparent',
  '& > .MuiLinearProgress-bar': {
    background:
      'linear-gradient(90deg, #95D5F5 -6.56%, #9083F4 56.71%, #D783F4 100%)',
  },
});

const AnimationContainer = styled(Box)({
  position: 'absolute',
  right: 0,
  bottom: 0,
  height: 'calc(100% - 8px)',
  width: 118,
  pointerEvents: 'none',
});

export {
  Container,
  Row,
  Checkbox,
  UncheckedIcon,
  CheckedIcon,
  Title,
  ActionButton,
  Progress,
  AnimationContainer,
};
