import React, { useState } from 'react';
import { Accordion } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { StyledDetails, StyledSummary } from './EventAccordionItem.style';


const EventAccordionItem = ({ summary, onChange = () => {}, children, showCount = true }) => {
  const [count, setCount] = useState(0);
  if (!React.isValidElement(children)) {
    return null;
  }
  return (
    <Accordion elevation={0} onChange={(_, expanded) => onChange(expanded)}>
      <StyledSummary expandIcon={<ExpandMoreIcon />}>
        {summary} {showCount && `(${count})`}
      </StyledSummary>
      <StyledDetails>{React.cloneElement(children, { setCount })}</StyledDetails>
    </Accordion>
  );
};

export default EventAccordionItem;
