/* eslint-disable max-len */
import React from 'react';
import colors from '../../theme/Colors';
const TrendIcon = ({
  color = colors.sensiGrey,
  ...props
}) => {
  return (
    <svg width={props.width || '18'} height={props.height || '18'} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.75 15.75H3.75C2.925 15.75 2.25 15.075 2.25 14.25V2.25H3.75V14.25H15.75V15.75Z" fill={color}/>
      <path d="M5.77498 11.025L4.72498 9.97498L8.99998 5.77498L11.25 7.94998L14.475 4.72498L15.525 5.77498L11.25 10.05L8.99998 7.87498L5.77498 11.025Z"
        fill={color}
      />
    </svg>
  );
};

export default TrendIcon;
