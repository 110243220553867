import { useEffect, useState } from 'react';

import { getUtcDateTime } from '../utilities/dates';
import { buildQueryString } from '../utilities/helpers';
import agent from '../utilities/agent';

export const useLoadClientCarelogs = ({
  clientId: id,
  territoryId,
  startDate,
  endDate,
  triggerReload = false,
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id && territoryId) {
      // setData([]); // NOTE: this may be optional to totally refresh data array
      setLoading(true);

      agent
        .get(
          `/carelogs/${territoryId}${buildQueryString({
            unitId: id,
            fromDate: startDate,
            toDate: endDate,
          })}`,
        )
        .then(
          ({
            data: {
              data: [carelogs]
            }
          }) => {
            return setData(
              carelogs.map((item) => ({
                ...item,
                ts: getUtcDateTime(item.ts)
              }))
            );
          }
        )
        .finally(() => setLoading(false));
    }
  }, [endDate, id, startDate, territoryId, triggerReload]);

  return [data, loading];
};

export const useLoadClientInsights = ({
  clientId: id,
  territoryId,
  reportId,
  startDate,
  endDate,
  triggerReload = false,
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id && territoryId) {
      // setData([]); // NOTE: this may be optional to totally refresh data array
      setLoading(true);
      const url = reportId ? `/insights/reportInsights/${reportId}/${territoryId}` : `/insights/${territoryId}`;
      agent.get(
        `${url}${buildQueryString({
          unitId: id,
          fromDate: startDate,
          toDate: endDate
        })}`
      )
        .then(
          ({
            data: {
              data: [insights]
            }
          }) => {
            return setData(
              insights.map((item) => ({
                ...item,
                labels: item.title,
                assignee_id: item.dashboard_users?.id,
                status: item.task_status_enum?.status,
                ts: getUtcDateTime(item.ts),
                highlights: []
              }))
            );
          }
        )
        .finally(() => setLoading(false));
    }
  }, [endDate, id, startDate, territoryId, triggerReload]);

  return [data, loading];
};

export const useLoadFamilyReport = ({ id, trigger }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      setData([]);
      setLoading(true);

      agent
        .get(`/familyreport/${id}`)
        .then(({ data: { data } }) => {
          return setData(
            data.map((item) => ({
              ...item,
              inserted_by_username: `${item.dashboard_users_dashboard_usersToreports_inserted_by?.first_name} ${item.dashboard_users_dashboard_usersToreports_inserted_by?.last_name[0]}.`,
              inserted_at: item.inserted_at,
              report: {
                ...item.report,
                from_date: getUtcDateTime(item.report.from_date),
                to_date: getUtcDateTime(item.report.to_date)
              }
            }))
          );
        })
        .finally(() => setLoading(false));
    }
  }, [id, trigger]);

  return [data, loading];
};
