import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocalStorage } from '@uidotdev/usehooks';
import {
  OutlineCheckSquare,
  OutlineSquare,
  SearchIcon,
} from '../../../assets/icons';
import { useNavigateTo } from '../../../utilities/hooks';
import { SECONDARY_QUERY_PARAMS } from '../../../utilities/const';
import CircleLoader from '../../common/loaders/circleLoader';

const SearchPopupContent = ({ className = '', type, isGrouped, isMultiple = false, onSelect, selected = {}, options, customEffect, customWatch, loading, close }) => {
  const navigate = useNavigateTo();
  const [search, setSearch] = useState('');

  const [,setLastSelectedCustomer] = useLocalStorage('lastSelectedCustomer', []);
  const [,setLastSelectedOffice] = useLocalStorage('lastSelectedOffice', []);
  const [,setLastSelectedTerritory] = useLocalStorage('lastSelectedTerritory', []);

  const typeToSetterMap = {
    customer: setLastSelectedCustomer,
    office: setLastSelectedOffice,
    territory: setLastSelectedTerritory,
  };

  useEffect(() => {
    if (customEffect) customEffect(customWatch);
  }, [customWatch, customEffect]);

  const saveSelection = (type, items) => {
    const ids = (Array.isArray(items) ? items.map(item => item.id) : [items.id]).filter(Boolean);
    const setter = typeToSetterMap[type];
    if (setter) {
      setter(ids);
    }
  };

  const handleSelectItem = useCallback(
    (item) => {
      const selectedItem = onSelect(type, item);
      const url = window.location.pathname.includes('/client')
        ? '/dashboard/territory'
        : '';

      if (type === 'client') {
        navigate({ url: `${type}/${selectedItem.id}`, omit: SECONDARY_QUERY_PARAMS });
      } else if (type === 'customer') {
        const [customer, offices, terrs] = selectedItem;
        saveSelection('customer', customer);
        saveSelection('office', offices);
        saveSelection('territory', terrs);

        navigate({
          url,
          params: {
            customerId: customer.id,
            officesIds: offices.map(({ id }) => id).join(','),
            territoryIds: terrs.map(({ id }) => id).join(','),
          },
          omit: SECONDARY_QUERY_PARAMS,
        });
        close();
      }
    },
    [onSelect, type, navigate]
  );

  const handleSelectMultipleItems = useCallback(
    (item) => {
      const selectedItems = onSelect(type, item);
      saveSelection(type, selectedItems);

      const url = window.location.pathname.includes('/client')
        ? '/dashboard/territory'
        : '';

      if (type === 'territory') {
        saveSelection('territory', selectedItems);
        navigate({
          url,
          params: {
            territoryIds: selectedItems.map(({ id }) => id).join(','),
          },
          omit: SECONDARY_QUERY_PARAMS,
        });
      } else if (type === 'office') {
        const [offices, terrs] = selectedItems;
        saveSelection('office', offices);
        saveSelection('territory', terrs);
        navigate({
          url,
          params: {
            officesIds: offices.map(({ id }) => id).join(','),
            territoryIds: terrs.map(({ id }) => id).join(','),
          },
          omit: SECONDARY_QUERY_PARAMS,
        });
      }
    },
    [onSelect, type, navigate]
  );

  const filteredList = useMemo(() => {
    if (!search) return options;
    const lowercasedSearch = search.toLowerCase();

    return isGrouped
      ? options
        .filter(([group_name, sub_options]) =>
          group_name.toLowerCase().includes(lowercasedSearch) ||
          sub_options.some(({ name }) => name.toLowerCase().includes(lowercasedSearch))
        )
        .map(([group_name, sub_options]) => [
          group_name,
          sub_options.filter(({ name }) => name.toLowerCase().includes(lowercasedSearch)),
        ])
      : options.filter(({ name }) => name.toLowerCase().includes(lowercasedSearch));
  }, [isGrouped, options, search]);

  const isItemSelected = (item) => {
    return isMultiple
      ? selected.includes(item.id)
      : selected.id === item.id;
  };

  const renderListItems = (items) =>
    items.map((item) => (
      <div
        className={`search-list-item flex items-center px-2 py-2 cursor-pointer ${
          isItemSelected(item) ? 'selected' : ''
        }`}
        key={item.id}
        onClick={() => (isMultiple ? handleSelectMultipleItems(item) : handleSelectItem(item))}
      >
        { isMultiple && <div className="search-list-item-subicon">
          {isItemSelected(item) ? <OutlineCheckSquare /> : <OutlineSquare />}
        </div> }
        <span>{item.name}</span>
      </div>
    ));

  return (
    <div className={`${className} flex flex-col bg-white ring-1 ring-black ring-opacity-5`}>
      <div className="search-list-input relative">
        <label htmlFor="search-input" className="absolute">
          <SearchIcon />
        </label>
        <input
          id="search-input"
          className="w-full"
          placeholder="Search..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          autoComplete="off"
        />
      </div>
      <div className="search-list-container relative">
        {isGrouped
          ? filteredList.map(([group_name, sub_options]) => (
            <div key={group_name} className="search-list-group">
              <div className="search-list-group-header py-2 px-4">
                {group_name}
              </div>
              {renderListItems(sub_options)}
            </div>
          ))
          : renderListItems(filteredList)}
        <CircleLoader show={loading} />
      </div>
    </div>
  );
};

export default SearchPopupContent;