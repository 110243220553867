import { styled, Paper, Typography, Box } from '@mui/material';

import Colors from '../../../../theme/Colors';

export const Wrapper = styled(Paper)(() => ({
  width: 182,
  maxHeight: 273,
  padding: '21px 23px',
  overflow: 'auto',
  borderRadius: 0,
  boxShadow: '0px 4px 10px 0px rgba(0,0,0,0.25)',
}));

export const Title = styled(Typography)({
  fontSize: 10,
  marginBottom: 15,
  marginLeft: 5,
  color: Colors.blue,
  fontWeight: 700,
  lineHeight: 'normal',
});

export const HorizontalDivider = styled(Box)(({ theme }) => ({
  flexGrow: 0,
  width: '100%',
  height: 1,
  background: theme.palette.border.light,
}));

export const ActionContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: 10,
  width: '100%',
  flexDirection: 'column',
});