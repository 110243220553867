import { NotificationLoaderCircle } from '../../../assets/icons';

const NotificationsLoader = ({ show, className = '' }) => {
  return (
    <>
      {show ? (
        <div
          className={`notifications-loader-container ${className} absolute inset-0 flex flex-col items-center justify-center w-full h-full z-20`}
        >
          <NotificationLoaderCircle className="loader-circle" />
          <div className="loader-label">Loading</div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default NotificationsLoader;
