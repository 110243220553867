import dayjs from './dayjs';

export const getUtcDateTime = (date) => {
  return dayjs(date).utc().format('YYYY-MM-DD HH:mm:ss.SSSSSS');
};

export const formatDate = (date) => {
  return date.toLocaleString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });
};