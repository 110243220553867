import * as React from 'react';
import Button from '@mui/material/Button';
import style from './Button.style';
import Colors from '../../../theme/Colors';

const BasicButtons = ({
  text = '',
  disabled = false,
  onClick,
  variant = 'text',
  customStyle = {
    backgroundColor: disabled ? Colors.disableButtonGrey : Colors.sensiPurple,
  },
}) => {
  return (
    <Button
      style={{ ...style, ...customStyle }}
      disabled={disabled}
      variant={variant}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};

export default BasicButtons;
