import { useCallback, useEffect, useMemo, useReducer } from 'react';
import { useParams } from 'react-router-dom';

import CircleLoader from '../../../components/common/loaders/circleLoader';
import HeaderFilter from '../../../components/general/HeaderFilter/HeaderFilter';
import HighlightItem from '../../../components/general/HighlightItem/HighlightItem';
import ResolveModal from '../../../components/modals/ResolveModal';
import { useCustomerState } from '../../../contexts/customers';
import { useLoadNotificationsHistory } from '../../../hooks/carelogs_and_insights';
import { WarningIcon } from '../../../assets/icons';
import { dashboardOffsetStep } from '../../../utilities/const';
import { onBottomScrollHandler, sortByName } from '../../../utilities/helpers';

import {
  getDefaultFilterOpts,
  initialState,
  stateReducer
} from '../pageUtils';
import dayjs from '../../../utilities/dayjs';
import EVENT_TYPES from '../../../services/EVENT_TYPES';
import { EventService } from '../../../services';
import { useDispatch } from 'react-redux';
import { loadTodos } from '../../../redux/Todos';

const ClientHistoryTab = ({ setChildFunc, currentClient }) => {
  const { id } = useParams();
  const [{ territories, triggerCarelogsReload }] = useCustomerState();

  const [
    {
      accNotificationsList,
      highLevelAlertList,
      highlights,
      filterOpts,
      filter,
      currentHighlight,
      resolveModalOpen,
      offset,
      allowDataSave
    },
    dispatch
  ] = useReducer(stateReducer, initialState);

  const reduxDispatch = useDispatch();

  useEffect(() => {
    reduxDispatch(loadTodos());
  }, []);


  const currentClientTerritoryAssignees = useMemo(() => {
    return currentClient?.id ? territories.find(({ id }) => id === currentClient?.territories?.id)
      ?.assignees || [] : [];
  }, [territories, currentClient]);

  useEffect(() => {
    dispatch({
      offset: 0
    });
  }, [triggerCarelogsReload]);

  const [
    loadedCarelogsAndInsights,
    loadingCarelogsAndInsights,
    totalNotificationsCount
  ] = useLoadNotificationsHistory({
    territoryIds: currentClient?.territories?.id || '',
    filter,
    offset,
  });

  const loadedNotificationTitles = useMemo(() => {
    return accNotificationsList
      .filter(({ is_carelog }) => is_carelog)
      .reduce((acc, { title }) => {
        if (!acc.includes(title)) acc.push(title);
        return acc;
      }, [])
      .sort((a, b) => sortByName(a, b));
  }, [accNotificationsList]);

  useEffect(() => {
    dispatch({
      ...initialState,
      filter: {
        unitId: {
          value: id
        }
      },
      filterOpts: getDefaultFilterOpts()
    });
  }, [id]);

  useEffect(() => {
    dispatch({ allowDataSave: true });
  }, [loadedCarelogsAndInsights]);

  useEffect(() => {
    if (allowDataSave) {
      // NOTE: THIS PART IS RESPONSIBLE TO UNDERSTAND - SHOULD IT FILL FIRST DATA, ACCUMULATE WITH NEW DATA OR STAY AS IT IS
      const total = offset === 0 ? [...loadedCarelogsAndInsights]
        : !accNotificationsList.find(({ id }) => id === loadedCarelogsAndInsights[0]?.id)
          ? [...accNotificationsList, ...loadedCarelogsAndInsights.filter(({ is_critical_alert }) => !is_critical_alert)]
          : accNotificationsList;


      const tempHighLevelAlertList = total.filter(({ is_critical_alert }) => is_critical_alert);

      const notifications = total.filter(({ is_critical_alert }) => !is_critical_alert);

      dispatch({
        accNotificationsList: total,
        highLevelAlertList: tempHighLevelAlertList,
        highlights: notifications,
        allowDataSave: false
      });
    }
  }, [
    offset,
    loadedCarelogsAndInsights,
    totalNotificationsCount,
    allowDataSave,
    accNotificationsList
  ]);

  useEffect(() => {
    const labelFilter = filterOpts.find(({ id }) => id === 'byLabel');
    const asigneesFilter = filterOpts.find(({ id }) => id === 'byAssign');
    labelFilter.options = loadedNotificationTitles.map((v) => ({
      value: v,
      label: v
    }));

    asigneesFilter.options = currentClientTerritoryAssignees.map(
      ({ id, username }) => ({ value: id, label: username })
    );
    dispatch({ filterOpts });
  }, [
    accNotificationsList,
    currentClientTerritoryAssignees,
    filterOpts,
    loadedNotificationTitles
  ]);

  const showNoMore = useMemo(() => {
    return accNotificationsList.filter(({ is_critical_alert }) => !is_critical_alert)?.length === totalNotificationsCount;
  }, [accNotificationsList, totalNotificationsCount]);

  const handleSetFilter = useCallback(
    (v = {}, field) => {
      dispatch({
        filter: { unitId: { value: id }, ...v },
        accNotificationsList: [],
        offset: 0
      });

      if (field) {
        const payload = {
          value: v[field]?.value,
        };
        switch (field) {
          case 'notificationType':
            EventService.fireEvent(EVENT_TYPES.FILTER_BY_TYPE_CLIENT_HISTORY,payload);
            break;
          case 'status':
            EventService.fireEvent(EVENT_TYPES.FILTER_BY_STATUS_CLIENT_HISTORY,payload);
            break;
          case 'assigneeId':
            EventService.fireEvent(EVENT_TYPES.FILTER_BY_ASSIGNEE_CLIENT_HISTORY,payload);
            break;
          case 'labels':
            EventService.fireEvent(EVENT_TYPES.FILTER_BY_LABEL_CLIENT_HISTORY,payload);
            break;
          case 'date':
            v[field]?.value?.startDate && v[field]?.value?.endDate &&
              EventService.fireEvent(EVENT_TYPES.FILTER_BY_DATE_CLIENT_HISTORY, {
                start_date: dayjs(v[field]?.value?.startDate).format('YYYY-MM-DD'),
                end_date: dayjs(v[field]?.value?.endDate).format('YYYY-MM-DD')
              });
            break;
          default:
            break;
        }
      }
    },
    [id]
  );

  const switchResolveModalHandler = useCallback(
    (highl) => {
      dispatch({
        currentHighlight: highl || null,
        resolveModalOpen: !resolveModalOpen
      });
    },
    [resolveModalOpen]
  );

  const handleUpdateHighlights = useCallback(
    (highlight, globalUpdateId) => {
      if (highlight) {
        const updatedHighlight = accNotificationsList.find(
          ({ id, is_carelog: IC }) =>
            id === highlight.id && IC === highlight.is_carelog
        );
        accNotificationsList[
          accNotificationsList.indexOf(updatedHighlight)
        ] = highlight;

        if (globalUpdateId) {
          const currentHighlight = accNotificationsList
            .filter(({ children_events }) => !children_events)
            .find(
              (event) =>
                event.id === globalUpdateId &&
                event.is_carelog === highlight.is_carelog
            );
          if (currentHighlight) {
            currentHighlight.heard = highlight.heard;
          }
          accNotificationsList
            .filter(({ children_events }) => children_events)
            .forEach(({ children_events }) => {
              children_events.forEach((event) => {
                if (event.id === globalUpdateId && event.is_carelog === highlight.is_carelog) {
                  event.heard = highlight.heard;
                }
              });
            });
        }
      }
      dispatch({
        accNotificationsList
      });
    },
    [accNotificationsList]
  );

  const handleHighlightDone = useCallback(
    ({ added, actionTaken, actionTakenMessage }) => {
      currentHighlight.status = 'Done';
      currentHighlight.include_in_report = added;
      currentHighlight.action_taken = actionTaken;
      currentHighlight.action_taken_message = actionTakenMessage;
      handleUpdateHighlights(currentHighlight);
    },
    [currentHighlight, handleUpdateHighlights]
  );

  const handleFilterButtonClick = useCallback((isOpened) => {
    isOpened && EventService.fireEvent(EVENT_TYPES.FILTER_CLIENTS_HISTORY);
  }, []);

  const isEmpty = useMemo(() => {
    return !accNotificationsList.length;
  }, [accNotificationsList]);

  useEffect(() => {
    setChildFunc(() => (e) => {
      if (currentClient?.id && !showNoMore && !loadingCarelogsAndInsights) {
        onBottomScrollHandler({
          callback: () => {
            dispatch({
              offset: offset + dashboardOffsetStep
            });
          },
          event: e.target,
        });
      }
    });
  }, [currentClient, showNoMore, offset, loadingCarelogsAndInsights]);

  return (
    <>
      <HeaderFilter
        filterOpts={filterOpts}
        filter={Object.entries(filter).reduce((acc, [k, v]) => {
          if (k !== 'unitId') acc[k] = v;
          return acc;
        }, {})}
        setFilter={handleSetFilter}
        onFilterBtnClick={handleFilterButtonClick}
      />

      <div
        className="highlights-list-container mt-6 flex flex-col relative"
      >
        {highLevelAlertList && highLevelAlertList.length ? (
          <div
            className={`highlights-list critical-list w-full ${
              isEmpty ? '!border-0' : ''
            } !min-h-0 pt-0 flex flex-col relative`}
          >
            {highLevelAlertList.map((highlight, i) => (
              <HighlightItem
                highlight={highlight}
                key={`${highlight.familyName}${i}`}
                onUpdate={handleUpdateHighlights}
                switchResolveModalHandler={switchResolveModalHandler}
              />
            ))}
          </div>
        ) : (
          ''
        )}
        <div
          className={`highlights-list ${
            isEmpty ? 'empty' : ''
          } w-full flex flex-col`}
        >
          {highlights.map((highlight, i) => (
            <HighlightItem
              highlight={highlight}
              key={`${highlight.familyName}${i}`}
              onUpdate={handleUpdateHighlights}
              switchResolveModalHandler={switchResolveModalHandler}
            />
          ))}
        </div>
      </div>

      {loadingCarelogsAndInsights ? (
        <div
          className="getting-data-label flex items-center p-4"
        >
          <CircleLoader show={loadingCarelogsAndInsights} className="!static mr-4 z-10" />
          Getting more data
        </div>
      ) : (
        ''
      )}

      {!loadingCarelogsAndInsights && showNoMore ? (
        <div className="no-more-data-label flex items-center p-4">
          <WarningIcon /> No more data to display
        </div>
      ) : (
        ''
      )}

      {resolveModalOpen ? (
        <ResolveModal
          toggle={switchResolveModalHandler}
          highlight={currentHighlight}
          callback={handleHighlightDone}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default ClientHistoryTab;
