import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TranscriptContainer, TranscriptText, ViewTranscriptButton, TranscriptBox, CopyWrapper } from './Transcription.style';
import English from '../../../../languages/English';
import EventService from '../../../../services/EventService';
import EVENT_TYPES from '../../../../services/EVENT_TYPES';
import Copy from '../Copy';
import agent from '../../../../utilities/agent';
import { CARE_LOG_ACTIONS_TYPES } from '../../../../utilities/const';
import { updateEvent } from '../../../../redux/Seniors';
import dayjs from '../../../../utilities/dayjs';
import CarelogAction from '../CarelogAction';
import ActionTooltip from '../ActionTooltip';
import withTooltip from '../../../../components/common/withTooltip';
import { fetchCarelogsActions } from '../../../../redux/CarelogsActions';
import { selectCarelogActions } from '../../../../redux/CarelogsActions/selectors';

const Transcription = ({
  event,
  variant,
  transcription,
  read,
  fromRelatedEvent = false,
}) => {
  const dispatch = useDispatch();
  const [isFullTranscriptVisible, setIsFullTranscriptVisible] = useState(
    !!read,
  );
  const [showReadContainer, setShowReadContainer] = useState(!!read);
  const carelogActions = useSelector(
    selectCarelogActions({
      id: event.id,
      actionId: CARE_LOG_ACTIONS_TYPES.READ,
    }),
  );

  const handleViewTranscriptClick = () => {
    setIsFullTranscriptVisible(true);
    const readPromise = agent
      .post(`/carelogAction/${event.id}`, {
        actionTypeId: CARE_LOG_ACTIONS_TYPES.READ,
      })
      .then(() => {
        dispatch(
          updateEvent({
            filter: (item) => item.id === event.id && item.is_carelog,
            data: { read_transcription: dayjs().toISOString() },
          }),
        );
        dispatch(
          fetchCarelogsActions({
            id: event.id,
            actionTypeId: CARE_LOG_ACTIONS_TYPES.READ,
          }),
        );
      });
    setTimeout(async () => {
      await readPromise;
      setShowReadContainer(true);
    }, 1000);
    EventService.fireEvent(
      fromRelatedEvent
        ? EVENT_TYPES.INSIGHT_VIEW_TRANSCRIPT_CLICKED
        : EVENT_TYPES.VIEW_TRANSCRIPT_CLICKED,
      { label: event.title, territory: event.territory_name, category: event.senior_category, is_related_event: event.is_related_event, insight_id: event.insightId },
    );
  };

  const displayTranscript = isFullTranscriptVisible
    ? transcription
    : `${transcription.substring(0, 33)}...`;

  const TooltipButton = carelogActions?.length
    ? withTooltip(ViewTranscriptButton, ActionTooltip)
    : ViewTranscriptButton;
  
  return (
    <TranscriptBox>
      <TranscriptContainer
        variant={variant}
        isFullTranscriptVisible={isFullTranscriptVisible}
      >
        <TranscriptText>{displayTranscript}</TranscriptText>
        {!isFullTranscriptVisible && (
          <TooltipButton
            onClick={handleViewTranscriptClick}
            carelogId={event.id}
            actionTypeId={CARE_LOG_ACTIONS_TYPES.READ}
            is_related_event={fromRelatedEvent}
            label={event.title}
          >
            {English.viewTranscript}
          </TooltipButton>
        )}
        {showReadContainer && (
          <CarelogAction
            carelogId={event.id}
            actionTypeId={CARE_LOG_ACTIONS_TYPES.READ}
            is_related_event={fromRelatedEvent}
            label={event.label}
          />
        )}
      </TranscriptContainer>
      {isFullTranscriptVisible && (
        <CopyWrapper>
          <Copy
            text={transcription}
            eventData={event}
            fireEvent={() =>
              EventService.fireEvent(
                fromRelatedEvent
                  ? EVENT_TYPES.INSIGHT_COPY_TRANSCRIPT_CLICKED
                  : EVENT_TYPES.COPY_TRANSCRIPT_CLICKED,
                { label: event.title, territory: event.territory_name, category: event.senior_category, is_related_event: event.is_related_event, insight_id: event.insightId, unit_id: event.unit_id },
              )
            }
          />
        </CopyWrapper>
      )}
    </TranscriptBox>
  );
};

export default Transcription;
