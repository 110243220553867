import ReactTable from 'react-table';
import { useEffect, useState } from 'react';

import CircleLoader from '../../../components/common/loaders/circleLoader';
import { useLoadAgencyRouters } from '../../../hooks/devices';
import { useCustomerState } from '../../../contexts/customers';

const RoutersTab = () => {
  const [{ currentTerritories, territories, offices }] = useCustomerState();

  const [totalList, setTotalList] = useState([]);

  const [routers, loading] = useLoadAgencyRouters({
    id: currentTerritories.map(({ id }) => id).join(','),
  });

  useEffect(() => {
    setTotalList(
      routers
        .map((item) => {
          const terr = territories.find(({ id }) => id === item.territory_id);
          const office = offices.find(({ id }) => id === terr?.office_id);

          return {
            ...item,
            territory: terr?.name || '',
            office: office?.name || ''
          };
        })
        .sort((a, b) => {
          if (!a.location) return 1;
          if (!b.location) return -1;
          return (
            a.office.localeCompare(b.office) +
            a.territory.localeCompare(b.territory)
          );
        })
        .map((item, i) => ({ ...item, id: i + 1 }))
    );
  }, [offices, territories, routers]);

  return (
    <>
      <div className="container content-container highlights-list-container mt-8 flex flex-col relative w-full">
        <ReactTable
          className={'table-auto routers-table'}
          columns={[
            {
              Header: () => (
                <div className="flex items-center justify-left"></div>
              ),
              accessor: 'id',
              width: 120,
              Cell: (row) => (
                <div
                  title={row.value}
                  className="flex items-center justify-center text-[#b5b9c2] w-full"
                >
                  {row.value}
                </div>
              )
            },
            {
              Header: () => (
                <div className="flex items-center justify-center">Office</div>
              ),
              accessor: 'office',
              Cell: (row) => (
                <div
                  title={row.value}
                  className="flex items-center justify-center w-full"
                >
                  {row.value || '—'}
                </div>
              )
            },
            {
              Header: () => (
                <div className="flex items-center justify-center">
                  Territory
                </div>
              ),
              accessor: 'territory',
              Cell: (row) => (
                <div
                  title={row.value}
                  className="flex items-center justify-center w-full"
                >
                  {row.value || '—'}
                </div>
              )
            },
            {
              Header: () => (
                <div className="flex items-center justify-center">Location</div>
              ),
              accessor: 'location',
              Cell: (row) => (
                <div
                  title={row.value}
                  className="flex items-center justify-center w-full"
                >
                  {row.value || 'Inventory'}
                </div>
              )
            },
            {
              Header: () => (
                <div className="flex items-center justify-center">
                  SN
                </div>
              ),
              accessor: 'routers_sn',
              Cell: (row) => (
                <div
                  title={row.value}
                  className="flex items-center justify-center w-full"
                >
                  {row.value || '—'}
                </div>
              )
            }
            // {
            //   Header: () => (
            //     <div className="flex items-center justify-center">
            //       Sim ICCID
            //     </div>
            //   ),
            //   accessor: 'iccid',
            //   Cell: (row) => (
            //     <div
            //       title={row.value}
            //       className="flex items-center justify-center w-full"
            //     >
            //       {row.value || '-'}
            //     </div>
            //   ),
            // },
          ]}
          data={totalList}
          showPagination={false}
          pageSize={totalList.length || 3}
          resizable={false}
          sortable={false}
        />

        <CircleLoader show={loading} />
      </div>
    </>
  );
};

export default RoutersTab;
