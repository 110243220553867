/* eslint-disable no-useless-escape */
import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import CircleLoader from '../../../../components/common/loaders/circleLoader';
import useForm from '../../../../hooks/form-state';
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material';
import English from '../../../../languages/English';
import {
  StyledFormContainer, StyledGeneralErrorTextContainer,
  StyledInputLabel,
  StyledOutlinedInput,
  StyledOutlinedInputHelperText,
} from '../../defaultStyled/default.style';
import Colors from '../../../../theme/Colors';
import { validationPattern } from './NewPasswordForm.Utils';
import { useDispatch, useSelector } from 'react-redux';
import { setNewPassword } from '../../../../redux/User';
import { VisibilityOffIcon, VisibilityOnIcon } from '../../../../assets/icons';
import EVENT_TYPES from '../../../../services/EVENT_TYPES';
import { EventService } from '../../../../services';

const formTemplate = {
  password: '',
};

const NewPasswordForm = ({ handleFormState, formState }) => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [displayErrors, setDisplayErrors] = useState(false);
  const email = useSelector(state => state.user.email);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleFormSubmit = useCallback(
    async (data) => {
      try {
        setLoading(true);
        const res = await dispatch(setNewPassword({ ...data, formState }));
        if (res?.payload?.error) {
          EventService.fireEvent(EVENT_TYPES.FAILED_RESET_PASSWORD, {
            error_message: res?.payload?.error?.message,
            email
          });
          throw res?.payload?.error;
        }
        if (res?.payload?.type === 'user/forgotRequest/fulfilled' && !res?.payload?.username) {
          return handleFormState('forgotPasswordSecond');
        }
        if (res?.payload?.challengeName === 'SMS_MFA') {
          return handleFormState('codeVerification');
        }
        setLoading(false);
        if (res && !res?.payload?.challengeName) {
          navigate('/dashboard');
        } else {
          EventService.fireEvent(EVENT_TYPES.FAILED_RESET_PASSWORD, {
            email
          });
          addErrors({ password: [English.somethingWrong] });
          setDisplayErrors(true);
          setLoading(false);
        }
      } catch (error) {
        EventService.fireEvent(EVENT_TYPES.FAILED_RESET_PASSWORD, {
          error_message: error?.message,
          email
        });
        addErrors({
          password: [error.code === 'LimitExceededException' ? English.tooManyAttempts :  English.generalError]
        });
        setDisplayErrors(true);
        setLoading(false);
      }
    },
    [handleFormState, navigate],
  );

  const {
    data,
    errors,
    handleChange,
    handleValidate,
    handleSubmit,
    resetErrors,
    resetForm,
    addErrors
  } = useForm({
    validations: {
      password: {
        patterns: [
          {
            value: /[a-z]/,
            message: 'Password must contain a lower case letter',
          },
          {
            value: /[A-Z]/,
            message: 'Password must contain an upper case letter',
          },
          {
            value: /\d/,
            message: 'Password must contain a number',
          },
          {
            value: /\S{8,}/,
            message: 'Password must contain at least 8 characters',
          },
        ],
        required: {
          value: true,
          message: 'This field is required',
        },
      },
    },
    onSubmit: handleFormSubmit,
    initialValues: formTemplate,
  });

  useEffect(
    () => () => {
      resetForm();
      setLoading(false);
    },
    [],
  );

  const invalidForm = useMemo(() => {
    return !!Object.keys(errors).length || !password;
  }, [errors]);

  const handleCheckInvalidResponse = useCallback(() => {
    if (errors.password === English.passwordMayBeWrong) {
      resetErrors();
    }
  }, [errors, resetErrors]);

  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
    EventService.fireEvent(EVENT_TYPES.SHOW_PASSWORD, {
      email,
      value : showPassword
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleFormChange = (e) => {
    setPassword(e.target.value);
    handleChange(e);
    handleValidate(e);
  };

  const handleColor = (checkValidationPattern) => {
    const passwordErrors = errors?.password || [];
    const hasPasswordLengthError = passwordErrors.includes(
      checkValidationPattern,
    );
    const isPasswordRequired = passwordErrors.includes(
      validationPattern.REQUIRED,
    );
    return hasPasswordLengthError || isPasswordRequired
      ? Colors.sensiError
      : password.length > 0 && Colors.sensiGreen;
  };

  const submitNewPassword = useCallback(() => {
    EventService.fireEvent(EVENT_TYPES.SUCCESSFULLY_SETTING_NEW_PASSWORD,{ email });
    !!errors.password && setDisplayErrors(true);
  },[email]);

  return (
    <StyledFormContainer onSubmit={handleSubmit}>
      <Box className="login-form-header">
        <Typography variant="h2">{English.setNewPassword}</Typography>
        <Typography fontSize={14} mt={1}>
          {English.passwordRules}
        </Typography>
      </Box>

      <Grid
        container
        columnSpacing={1.5}
        justifyContent="center"
        textAlign="center"
        mb={3.5}
      >
        <Grid color={() => handleColor(validationPattern.PASSWORD_LENGTH)} item>
          <Typography variant="h4">{English.eightPlusChar}</Typography>
          <Typography variant="subtitle2">{English.characters}</Typography>
        </Grid>
        <Grid item>
          <Divider orientation="vertical" />
        </Grid>
        <Grid color={() => handleColor(validationPattern.UPPERCASE_CHAR)} item>
          <Typography variant="h4">{English.aUppercase}</Typography>
          <Typography variant="subtitle2">{English.uppercase}</Typography>
        </Grid>
        <Grid item>
          <Divider color={Colors.sensiLightGrey} orientation="vertical" />
        </Grid>
        <Grid color={() => handleColor(validationPattern.LOWERCASE_CHAR)} item>
          <Typography variant="h4">{English.aLowercase}</Typography>
          <Typography variant="subtitle2">{English.lowercase}</Typography>
        </Grid>
        <Grid item>
          <Divider orientation="vertical" />
        </Grid>
        <Grid color={() => handleColor(validationPattern.ONE_NUMBER)} item>
          <Typography variant="h4">{English.numbers123}</Typography>
          <Typography variant="subtitle2">{English.numbers}</Typography>
        </Grid>
      </Grid>

      <FormControl className="relative">
        <StyledInputLabel
          className={errors.password ? 'error' : ''}
          htmlFor="password"
        >
          {English.password}
        </StyledInputLabel>
        <StyledOutlinedInput
          id="password"
          name="password"
          label={English.password}
          type={showPassword ? 'text' : 'password'}
          value={data.password}
          onChange={handleFormChange}
          onBlur={handleValidate}
          onFocus={handleCheckInvalidResponse}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOffIcon /> : <VisibilityOnIcon />}
              </IconButton>
            </InputAdornment>
          }
          error={!!errors.password}
          disabled={loading}
        />
        {displayErrors && !!errors.password && (
          <StyledOutlinedInputHelperText>
            {errors?.password?.map((msg) => (
              <div key={Math.random(0, 1) * 1000}>{msg}</div>
            ))}
          </StyledOutlinedInputHelperText>
        )}
      </FormControl>

      <Button disabled={invalidForm} className={loading ? 'loading' : ''} onClick={() => submitNewPassword()} type="submit"
        variant="login-submit"
      >
        {loading ? <CircleLoader className="sm" show={true} /> : English.done}
      </Button>
      <StyledGeneralErrorTextContainer>
        {typeof errors.general === 'string' ? errors.general : errors.general?.map((msg) => <div key={Math.random(0, 1) * 1000}>{msg}</div>)}
      </StyledGeneralErrorTextContainer>
    </StyledFormContainer>
  );
};

export default NewPasswordForm;
