import {
  styled,
  Box,
  TextField,
  InputLabel,
  OutlinedInput,
  Link,
} from '@mui/material';
import Colors from '../../../theme/Colors';

const StyledFormContainer = styled((props) => <Box {...props} component="form" />)(() => ({
  display: 'flex',
  flexDirection: 'column',
  margin: '0 auto',
  width: '318px',
  zIndex: '5',
  '& .login-form-header': {
    display: 'flex',
    flexDirection: 'column',
    padding: '1em 2em 0',
    marginBottom: '30px',
    textAlign: 'center',
    '& h3': {
      marginBottom: '0.5rem',
      fontSize: '20px',
      fontWeight: '700',
    },
    '& span': {
      fontSize: '14px',
    }
  }
}));

const StyledInput = styled(TextField)(({ theme }) => ({
  '& label.Mui-focused': {
    color: theme.palette.primary.main,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: theme.palette.primary.main,
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '5px',
    '& .MuiOutlinedInput-input': {
      paddingLeft: '22px',
      fontSize: '13px',
    },
    '&.Mui-focused fieldset': {
      border: `1px solid ${theme.palette.primary.main}`
    },
  },
  '& .MuiFormHelperText-root': {
    marginLeft: 0,
  },
  '& .MuiInputLabel-root': {
    fontSize: '13px',
  }
}));

const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  fontSize: '13px',
  '&.Mui-focused': {
    color: `${theme.palette.primary.main} !important`,
  },
  '&.error': {
    color: theme.palette.error.main,
  }
}));

const StyledOutlinedInput = styled(OutlinedInput)(({ theme }) => ({
  borderRadius: '5px',
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: `1px solid ${theme.palette.primary.main}`
  },
  '&.Mui-focused .visibilityIcon': {
    fill: theme.palette.primary.main
  },
  '& .visibilityIcon': {
    fill: theme.palette.sensiGrey.main
  },
  '& .MuiOutlinedInput-input': {
    paddingLeft: '22px',
    fontSize: '13px',
  },
  '& .MuiIconButton-root': {
    marginRight: '0',
  },
  '&.Mui-focused .MuiIconButton-root': {
    color: theme.palette.primary.main,
  },
}));

const StyledOutlinedInputHelperText = styled(Box)(({ theme }) => ({
  fontSize: '12px',
  marginLeft: '0',
  marginTop: '0.3em',
  color: theme.palette.error.main,
}));

const StyledGeneralErrorTextContainer = styled(Box)(({ theme }) => ({
  fontSize: '11px',
  margin: '6% auto',
  color: theme.palette.error.main,
}));

const ResendButtonsContainer = styled(Link)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  margin: '35px auto',
  color: theme.palette.sensiGrey.main,
}));

const StyledSubtitle = styled(Box)(() => ({
  width: '312px',
  marginLeft: '-11%',
}));

const StyledOutlinedInputWithTooltip = styled(OutlinedInput)(({ theme }) => ({
  borderRadius: '5px',
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: `1px solid ${theme.palette.primary.main}`
  },
  '& .MuiOutlinedInput-input': {
    paddingLeft: '22px',
    fontSize: '13px',
  },
  '& .MuiIconButton-root': {
    width: '27px',
    height: '27px',
    marginRight: '0',
    backgroundColor: Colors.lightViolet,
    color: theme.palette.primary.main,
  },
  '& .MuiIconButton-root svg': {
    fontSize: '16px',
  },
  '&.Mui-focused .MuiIconButton-root': {
    color: theme.palette.primary.main,
  },
}));

export {
  StyledFormContainer,
  StyledInput,
  StyledInputLabel,
  StyledOutlinedInput,
  StyledOutlinedInputHelperText,
  StyledGeneralErrorTextContainer,
  ResendButtonsContainer,
  StyledOutlinedInputWithTooltip,
  StyledSubtitle
};