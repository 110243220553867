import { CloseIcon } from '../../../../assets/icons';

const SelectedFilterLabel = ({ name, label, removeSelectedFilter }) => {
  return (
    <div className="filter-selected-label flex items-center justify-center relative mr-4 mb-3">
      {label}
      <button
        className="clear-button flex items-center justify-center absolute"
        onClick={(e) => {
          e.stopPropagation();
          removeSelectedFilter(name);
        }}
      >
        <CloseIcon />
      </button>
    </div>
  );
};

export default SelectedFilterLabel;
