import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import {
  BackIcon, TrendIcon,
  XSign,
} from '../../../../assets/icons';
import Player from '../../../../components/common/Player';
import English from '../../../../languages/English';
import {
  BackButton,
  BackText, CloseButton,
  StyledDialogContent,
  StyledDialogTitle,
  StyledGridContainer,
  StyledGridItem,
  StyledTaskSection,
  StyledActionSectionContainer,
  LoaderContainer,
  LinkText,
  TooltipHover,
  BetaText,
  StyledTrendIconContainer,
} from './EventView.style';
import Colors from '../../../../theme/Colors';
import EventAccordion from '../EventAccordion';
import { iconMap } from './EventView.utils';
import {
  getEventsBySenior,
  selectEvents, selectIsRelatedEvent,
  updateEvent,
} from '../../../../redux/Seniors';
import EventService from '../../../../services/EventService';
import TitleDetails from '../TitleDetails';
import ActionSection from '../ActionSection';
import LabelsTooltip from '../LabelsTooltip/LabelsTooltip';
import EVENT_TYPES from '../../../../services/EVENT_TYPES';
import { ASSETS_BUCKET, BETA_LABEL_IDS } from '../../../../utilities/const';
import Transcription from '../Transcription';

const EventView = ({ close, index, next, back, done, getAssignees, actionsTaken, eventsLength, id, relatedToInsight }) => {
  const dispatch = useDispatch();
  const events = useSelector(selectEvents);
  const event = events[index] || events?.find(e => e.id === id);
  const loadingEvents = useSelector(getEventsBySenior.isLoading);
  const isRelatedEvent = useSelector(selectIsRelatedEvent);

  const handleCarelogUpdate = async (id, data) => {
    dispatch(updateEvent({
      filter: item => item.id === id && item.is_carelog,
      data
    }));
  };
  const assignees = useMemo(() => getAssignees(event?.territory_id), [getAssignees, event?.id]);

  const handleCarelogTrack = (data) => {
    EventService.fireEvent(EVENT_TYPES.NOTIFICATION_HEARD, {
      ...data,
      label: event?.title,
      page: 'dashboard',
      category: event?.senior_category,
      unit_id: event?.unit_id,
      is_related_event: isRelatedEvent
    });
  };

  return (
    <StyledDialogContent>
      {loadingEvents ?
        <LoaderContainer>
          <CircularProgress />
        </LoaderContainer>
        :
        <StyledGridContainer container spacing={2}>
          <StyledGridItem item xs={7.7}>
            {/* DialogTitle */}
            <StyledDialogTitle>
              <CloseButton onClick={close}>
                <XSign />
              </CloseButton>
              {back && <BackButton onClick={back}>
                <BackIcon />
                <BackText variant='h3'>{relatedToInsight ? English.backToInsight : English.back}</BackText>
              </BackButton>}
              <Grid container columnSpacing={1} alignItems='center'>
                <Grid item>{iconMap[event?.senior_category]}</Grid>
                <Grid item>
                  <Grid item flexDirection='row' display='flex'>
                    <Typography variant='h3'>{event?.title}</Typography>
                    {BETA_LABEL_IDS.includes(event?.label_id) && <BetaText>{English.beta}</BetaText>}
                  </Grid>
                  {event?.is_carelog && event?.tooltip &&
                    <TooltipHover>
                      <LinkText>
                        {English.learnMore}
                      </LinkText>
                      <LabelsTooltip
                        title={event?.title}
                        body={event.tooltip ? event.tooltip : ''}
                        icon={`${ASSETS_BUCKET}/${event?.label_id}.svg`}
                      />
                    </TooltipHover>}
                </Grid>
                {event?.trend && !event?.is_carelog && <Grid item>
                  <StyledTrendIconContainer>
                    <TrendIcon width={21} height={21} color={Colors.skyBlue} />
                  </StyledTrendIconContainer>
                </Grid>}
              </Grid>
              <Box>
                <Typography marginTop={'23px'} color={Colors.sensiGrey} fontWeight={400}
                  variant='subtitle2'>{event?.unit_name}</Typography>
                <Typography marginTop={0.5} color={Colors.sensiGrey} fontWeight={700}
                  variant='subtitle2'>{event?.indication}</Typography>
              </Box>
              <TitleDetails event={event} />
            </StyledDialogTitle>
            {/* Content */}
            {event?.is_carelog && (
              <div style={{ backgroundColor:  Colors.gray, borderRadius: 5, }}>
                <Player
                  key={event.id}
                  variant="filled"
                  filename={event.file_name}
                  carelogId={event.id}
                  heard={event.heard}
                  label={event.title}
                  onUpdate={handleCarelogUpdate}
                  onTrack={handleCarelogTrack}
                />
                {event.transcription && <Transcription
                  event={{ ...event, page: 'dashboard', is_related_event: isRelatedEvent }}
                  variant='filled'
                  key={event.vid}
                  read={event.read_transcription}
                  transcription={event.transcription} />}
              </div>
            )}
            <StyledTaskSection item>
              {event && <EventAccordion event={event} assignees={assignees} />}
            </StyledTaskSection>
          </StyledGridItem>

          {/* Task Details section */}
          <StyledActionSectionContainer item xs={4.3}>
            <ActionSection
              relatedToInsight={relatedToInsight}
              key={event?.id}
              event={event}
              index={index}
              next={next}
              done={done}
              assignees={assignees}
              actionsTaken={actionsTaken}
              eventsLength={eventsLength}
            />
          </StyledActionSectionContainer>
        </StyledGridContainer>}
    </StyledDialogContent>
  );
};

export default EventView;