import React, { useState } from 'react';
import './TextAreaInput.style.scss';

const TextAreaInput = ({
  title,
  value,
  placeholder,
  renderRightTitle,
  readOnly = false,
  customStyle,
  containerStyle,
  style,
  titleStyle,
  onChange
}) => {
  const [focused, setFocused] = useState(false);

  return (
    <div className={`${containerStyle}`}>
      <div className="textareaTitleContainer">
        {title && <span style={titleStyle} className="textareaInputTitle">{title}</span>}
        {renderRightTitle && renderRightTitle()}
      </div>
      <textarea
        className={`recommendation-area ${
          focused ? 'focused' : ''
        } ${customStyle}`}
        style={style}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        value={value}
        readOnly={readOnly}
        placeholder={placeholder}
        onChange={onChange}
      />
    </div>
  );
};

export default TextAreaInput;
