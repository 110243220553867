import React, { useState, useRef, useCallback } from 'react';
import { Popover } from '@mui/material';
import EventService from '../../services/EventService';
import EVENT_TYPES from '../../services/EVENT_TYPES';
import { actionMap } from '../../utilities/const';

const HOVER_DELAY = 300;

const withTooltip = (WrappedComponent, TooltipComponent) => {
  const ComponentWithTooltip = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const timeoutRef = useRef(null);

    const clearCloseTimer = useCallback(() => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
    }, []);

    const startCloseTimer = useCallback(() => {
      clearCloseTimer();
      timeoutRef.current = setTimeout(() => {
        setIsOpen(false);
      }, HOVER_DELAY);
    }, [clearCloseTimer]);

    const handleMouseEnter = useCallback((event) => {
      clearCloseTimer();
      const action = actionMap[props.actionTypeId].toUpperCase();
      EventService.fireEvent(EVENT_TYPES[`HOVER_${action}_ICON`], {
        is_related_event: props.is_related_event,
        label: props.label
      });
      setAnchorEl(event.currentTarget);
      setIsOpen(true);
    }, [props.actionTypeId, props.is_related_event, props.label, clearCloseTimer]);

    const handleMouseLeave = useCallback(() => {
      startCloseTimer();
    }, [startCloseTimer]);

    return (
      <>
        <WrappedComponent
          {...props}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
        <Popover
          sx={{ pointerEvents: 'none' }}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          open={isOpen}
          disableRestoreFocus
        >
          <div
            onMouseEnter={clearCloseTimer}
            onMouseLeave={startCloseTimer}
            style={{ pointerEvents: 'auto' }}
          >
            <TooltipComponent {...props} />
          </div>
        </Popover>
      </>
    );
  };

  const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
  ComponentWithTooltip.displayName = `withTooltip(${wrappedComponentName})`;

  return ComponentWithTooltip;
};

export default withTooltip;