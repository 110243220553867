import React from 'react';

const ThumbDownIcon = ({ color }) => {
  return (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.2164 15.75L12.535 11.25L16.2302 11.25L16.2302 2.25L5.30745 2.25C4.49006 2.25
       3.77682 2.8103 3.60474 3.58594L2.35487 9.21094C2.12618 10.2502 2.96395 11.25 4.05759
        11.25L8.56798 11.25C8.52496 11.6016 8.51591 11.9553 8.24193 12.7617C8.06532 13.2825 8.11513
        13.7615 8.11513 14.0625C8.11513 14.4822 8.28948 14.8689 8.5861 15.1875C8.88272 15.5061
        9.32877 15.75 9.85408 15.75L10.2164 15.75ZM9.56426 14.4844C9.52803 14.458 9.46916 14.4668
        9.43746 14.4316C9.32877 14.3152 9.27443 14.1548 9.27443 14.0625C9.27443 13.6978 9.26311
        13.3572 9.34689 13.1133C9.77936 11.8411 9.85408 10.7227 9.85408 10.7227L9.89031
        10.125L4.05759 10.125C3.67493 10.125 3.4168 9.802 3.49605 9.43945L4.72781 3.81445C4.78668
        3.55078 5.03122 3.375 5.30745 3.375L11.593 3.375L11.593 10.5469L9.56426 14.4844ZM15.0709
        10.125L12.7523 10.125L12.7523 3.375L15.0709 3.375L15.0709 10.125ZM13.9116 5.0625C14.2309
        5.0625 14.4913 4.80981 14.4913 4.5C14.4913 4.19019 14.2309 3.9375 13.9116 3.9375C13.5924
        3.9375 13.332 4.19019 13.332 4.5C13.332 4.80981 13.5924 5.0625 13.9116 5.0625Z" fill={color || '#A7A8B6'}/>
    </svg>

  );
};

export default ThumbDownIcon;