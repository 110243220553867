import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { loadComments, selectComments } from '../../../../redux/Comments';
import { Comment } from './components/Comment';
import { NewComment } from './components/NewComment';
import { StyledList } from './Comments.style';

export const Comments = ({ title, is_carelog, item_id, setCount, assignees }) => {
  const comments = useSelector(selectComments);
  const dispatch = useDispatch();
  useEffect(() => dispatch(loadComments({ is_carelog, item_id })).abort, [item_id]);
  const visibleComments = comments.data?.filter(({ comment }) => comment !== null);
  setCount(visibleComments?.length);

  return (
    <Box>
      <StyledList>
        {visibleComments?.map((comment) => (
          <Comment key={comment.id} comment={comment} is_carelog={is_carelog} assignees={assignees} setCount={setCount} />
        ))}
      </StyledList>
      <NewComment title={title} is_carelog={is_carelog} item_id={item_id} assignees={assignees} />
    </Box>
  );
};
