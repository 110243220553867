import { useParams } from 'react-router-dom';
import ReactTable from 'react-table';
import { useCallback, useState, useEffect, useRef } from 'react';

import {
  BinIcon,
  DownloadIcon,
  WarningIcon,
  EditIcon,
} from '../../../assets/icons';
import CircleLoader from '../../../components/common/loaders/circleLoader';
import { useLoadFamilyReport } from '../../../hooks/clients';
import agent from '../../../utilities/agent';
import { useCustomerState } from '../../../contexts/customers';
import { orderByIdAndDate } from '../../../utilities/helpers';
import { useNavigateTo, useQueryObject } from '../../../utilities/hooks';
import dayjs from '../../../utilities/dayjs';
import { autoReportIds } from '../../../utilities/const';
import { EventService } from '../../../services';
import EVENT_TYPES from '../../../services/EVENT_TYPES';

const statusDict = {
  'Created - sent to care assessment service queue': 0,
  'Ready to download': 1,
  Deleted: 2,
  Error: 3
};

const FamilyReportTab = ({ currentClient }) => {
  const { id } = useParams();
  const [{ MixpanelUserData }] = useCustomerState();
  const { notifiedReportId } = useQueryObject();

  const navigate = useNavigateTo();

  const [reports, loadingReports] = useLoadFamilyReport({ id, trigger: notifiedReportId });

  const [reportsList, setReportsList] = useState([]);
  const intervalRef = useRef();

  // This hook is used to add highlight class for highlighted report in table
  useEffect(() => {
    const notifiedRows = [...document.getElementsByClassName('report-highlighted')].filter(item => !item.classList.contains(notifiedReportId));
    notifiedRows.forEach((row) => {
      row.classList.remove(
        'report-highlighted'
      );
    });
    if (notifiedReportId) {
      const highlightedRowFirstColumn = document.getElementById(`report-${notifiedReportId}`);

      if (highlightedRowFirstColumn) {
        const rowToHighlight = highlightedRowFirstColumn.parentElement.parentElement;
        
        if (!rowToHighlight.classList.contains('report-highlighted')) {
          rowToHighlight.classList.add('report-highlighted');
          rowToHighlight.classList.add(notifiedReportId);
        }
      }
    }
  });

  useEffect(() => {
    setReportsList(
      reports.sort((a, b) => orderByIdAndDate(a, b, 'inserted_at', 'desc'))
    );
  }, [reports]);

  useEffect(() => {
    intervalRef.current && clearInterval(intervalRef.current);
    const processing = reportsList.filter(
      ({ reports_status_enum: { id } }) => !id,
    );


    if (processing.length) {
      intervalRef.current = setInterval(() => {
        processing.forEach(async ({ id }) => {
          const statusResult = await agent.post('/familyreport/status/', {
            reportId: id,
          });

          const { data: { data: statusData } } = statusResult;

          if (statusData === 'Error') {
            EventService.fireEvent(EVENT_TYPES.GENERATE_FAILURE_FAMILY_REPORT, {
              ...MixpanelUserData,
              report_id: id,
              client_id: currentClient?.id,
              loc_id: currentClient?.loc_id,
            });
          }
          const currentReport = reportsList.find(({ id: fId }) => fId === id);
          currentReport.reports_status_enum.id = statusDict[statusData];
          setReportsList(
            [...reportsList].sort((a, b) =>
              orderByIdAndDate(a, b, 'inserted_at', 'desc'),
            ),
          );
        });
      }, 5000);
    }
  }, [reportsList, currentClient]);

  const handleReportDownload = useCallback(
    (reportData) => {
      agent
        .get(`/familyreport/report/${reportData.id}`, {
          responseType: 'blob',
        })
        .then(({ data }) => {
          const file = new Blob([data], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL, '_blank');
          EventService.fireEvent(EVENT_TYPES.DOWNLOAD_FAMILY_REPORT, {
            ...MixpanelUserData,
            file_name: reportData.file_name,
            client_id: id,
            loc_id: currentClient?.loc_id,
            is_automated: autoReportIds.includes(reportData.inserted_by),
          });
        })
        .catch(() => {
          document
            .getElementById(`download-${reportData.id}`)
            .classList.add('error-highlight');
          setTimeout(() => {
            document
              .getElementById(`download-${reportData.id}`)
              .classList.remove('error-highlight');
          }, 5000);
        });
    },
    [MixpanelUserData, id, currentClient],
  );

  const handleReportDelete = useCallback(
    (data) => {
      agent.post(`familyreport/deletereport/${data?.id}`).then(() => {
        EventService.fireEvent(EVENT_TYPES.DELETE_CARE_REPORT,{ value: data?.id });
        setReportsList(
          reportsList
            .filter(({ id }) => id !== data?.id)
            .sort((a, b) => orderByIdAndDate(a, b, 'inserted_at', 'desc'))
        );
      });
    },
    [reportsList],
  );

  const handleReportEditNavigate = useCallback(
    (report) => {
      navigate({
        url: 'report',
        params: {
          startDate: dayjs(report.from_date).format('YYYY-MM-DD'),
          endDate: dayjs(report.to_date)
            .format('YYYY-MM-DD'),
          reportId: report.id
        },
        omit: ['tab', 'notifiedReportId']
      });
    },
    [navigate]
  );

  const renderStatusIcon = (data) => {
    switch (data?.reports_status_enum?.id) {
      case 0:
        return (
          <div className="action-icon cursor-progress relative">
            <CircleLoader show={true} className="sm round-border" />
          </div>
        );
      case 1:
        return (
          <div
            id={`download-${data?.id}`}
            className="action-icon cursor-pointer"
            onClick={() => handleReportDownload(data)}
          >
            <DownloadIcon />
          </div>
        );
      case 2:
        return (
          <div className="action-icon error-highlight">
            <BinIcon />
          </div>
        );
      case 3:
      default:
        return (
          <div className="action-icon error-highlight">
            <WarningIcon />
          </div>
        );
    }
  };

  return (
    <>
      <div className="container content-container highlights-list-container mt-8 flex flex-col relative">
        <ReactTable
          className={'table-auto reports-table'}
          columns={[
            {
              Header: () => (
                <div className="flex items-center justify-center">
                  Date | Time
                </div>
              ),
              accessor: 'inserted_at',
              Cell: (row) => (
                <div
                  id={`report-${row.original?.id}`}
                  title={row.value}
                  className="w-full flex items-center justify-center w-full"
                >
                  {dayjs(row.value).format('MM.DD.YYYY | HH:mm')}
                </div>
              )
            },
            {
              Header: () => (
                <div className="flex items-center justify-center">
                  Report Start Date
                </div>
              ),
              accessor: 'from_date',
              Cell: (row) => (
                <div
                  title={row.value}
                  className="w-full flex items-center justify-center"
                >
                  {dayjs.utc(row.value).format('MM.DD.YYYY')}
                </div>
              )
            },
            {
              Header: () => (
                <div className="flex items-center justify-center">
                  Report End Date
                </div>
              ),
              accessor: 'to_date',
              Cell: (row) => (
                <div
                  title={row.value}
                  className="w-full flex items-center justify-center"
                >
                  {dayjs.utc(row.value).format('MM.DD.YYYY')}
                </div>
              )
            },
            {
              Header: () => (
                <div className="flex items-center justify-center">
                  Generated by
                </div>
              ),
              accessor: 'inserted_by_username',
              Cell: (row) => (
                <div
                  title={row.value}
                  className="w-full flex items-center justify-center"
                >
                  {row.value}
                </div>
              )
            },
            {
              Header: () => (
                <div className="flex items-center justify-center">Download</div>
              ),
              accessor: 'actions',
              Cell: (row) => (
                <div
                  title={row.value}
                  className="w-full flex items-center justify-evenly"
                >
                  {renderStatusIcon(row.original)}
                </div>
              )
            },
            {
              Header: () => (
                <div className="flex items-center justify-center">Edit</div>
              ),
              accessor: 'actions',
              Cell: (row) => {
                const isAvailable = +row.original?.reports_status_enum?.id !== 0;
                return (
                  <div
                    title={row.value}
                    className="w-full flex items-center justify-evenly"
                  >
                    {
                      isAvailable 
                        ? (
                          <div
                            className="cursor-pointer action-icon"
                            onClick={() => handleReportEditNavigate(row.original)}
                          >
                            <EditIcon />
                          </div>
                        ) : (
                          <div className="action-icon cursor-progress relative">
                            <CircleLoader show={true} className="sm round-border" />
                          </div>
                        )
                    }
                  </div>
                );
              }
            },
            {
              Header: () => (
                <div className="flex items-center justify-center">Delete</div>
              ),
              accessor: 'actions',
              Cell: (row) => (
                <div
                  title={row.value}
                  className="w-full flex items-center justify-evenly"
                >
                  <div
                    className="cursor-pointer action-icon"
                    onClick={() => handleReportDelete(row.original)}
                  >
                    <BinIcon />
                  </div>
                </div>
              )
            }
          ]}
          data={reportsList}
          showPagination={false}
          pageSize={reportsList.length || 3}
          resizable={false}
          sortable={false}
        />
        <CircleLoader show={loadingReports} />
      </div>
    </>
  );
};

export default FamilyReportTab;
