const colors = {
  darkBlack: '#121826',
  black: '#1c2538',
  lightBlack: '#7b8aab',
  red: '#fc3356',
  lightRed: '#f5e3e9',
  whiteRed: '#fdf2f2',
  violet: '#9a4cec',
  lightViolet: '#e8defa',
  whiteViolet: '#f6f2fd',
  purple: '#b546eb',
  yellow: '#f79402',
  lightYellow: '#f5e2c8',
  whiteYellow: '#fef9f2',
  blue: '#4ec1ff',
  azure: '#EBF3FF',
  purpleGradient: 'linear-gradient(180deg, #B09FEF 0%, #675EF2 100%)',
  lightBlue: '#dceffa',
  whiteBlue: '#f4f8ff',
  skyBlue: '#2D99FE',
  green: '#56c82f',
  lightGreen: '#def5d7',
  disableButtonGrey: '#9F9F9F',
  darkGray: '#bcc3c9',
  gray: '#f6f8fa',
  transparentGray: 'rgba(246, 248, 250, 0.8)',
  white: '#FFFFFF',
  sensiGrey: '#555770',
  sensiPurple: '#6849C4',
  sensiPurpleHover: '#4A2E9D',
  sensiGold: '#D5AF34',
  sensiError: '#BF0000',
  sensiGreen: '#00A21F',
  sensiLightGreen: '#D8F6DE',
  sensiDarkGreen: '#009951',
  sensiLightPurple: '#E9E7FC',
  sensiDarkPurple: '#6C5CE7',
  sensiLightGrey: '#CECECE',
  borderGrey: '#D4D4D4',
  borderLightGrey: '#E9E9E9',
  backgroundGrey: '#D9D9D9',
  lightGrey: '#E4E4E4',
  lightRose: '#FFE1E6',
  orange: '#F49136',
  lightOrange: '#F7E7D9',
  highPriority: '#F7E9ED',
  borderHighPriority: '#E1B2B2',
  pink: '#FFEFF2',
  whiteGrey: '#FAFAFA',
};

export default colors;
