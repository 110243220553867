import React from 'react';
import { Wrapper, Title, TooltipContainer, TitleWrapper } from './LabelsTooltip.style';

const LabelsTooltip = ({ title, body, icon }) => {
  return (
    <Wrapper variant="container">
      <TitleWrapper>
        <img src={icon} />
        <Title variant="subtitle2" align="left">
          {title.toUpperCase()}
        </Title>
      </TitleWrapper>
      <TooltipContainer>{body}</TooltipContainer>
    </Wrapper>
  );
};

export default LabelsTooltip;
