import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import './styles/index.scss';
import LaunchDarklyService from './services/LaunchDarklyService';
import App from './App';


console.log(
  `Version: ${
    process.env.REACT_APP_VERSION || 'No version for local development'
  }`,
);

Sentry.init({
  dsn: 'https://008a79b38ddb48b3a8aba187897bb1aa@o813857.ingest.sentry.io/6256801',
  integrations: [new BrowserTracing()],
  environment: process.env.REACT_APP_BUILD_ENV || 'local'
});
const LDProvider = await LaunchDarklyService.init();
ReactDOM.render(
  <React.StrictMode>
    <LDProvider>
      <App />
    </LDProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
