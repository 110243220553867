import React, { useEffect } from 'react';

import { useTheme, Grid } from '@mui/material';
import SeniorCategoryCard from '../SeniorCategoryCard/SeniorCategoryCard';
import { SeniorCategorySkeleton } from './components';

import { useDispatch, useSelector } from 'react-redux';
import {
  getSeniorsByCategories,
  selectSeniors,
} from '../../../../redux/Seniors';
import { selectConfig } from '../../../../redux/Config';
import { useSearchParams } from 'react-router-dom';

const SeniorCategoryCardList = () => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const config = useSelector(selectConfig);
  const loading = useSelector(getSeniorsByCategories.isLoading);
  const seniors = useSelector(selectSeniors);

  const [query] = useSearchParams();
  const territoryIds = query.get('territoryIds');

  useEffect(() => {
    if (territoryIds) {
      dispatch(
        getSeniorsByCategories({
          territoriesIds: territoryIds.split(','),
          ts: config?.time,
        }),
      );
    }
  }, [territoryIds, config?.time]);

  return (
    <Grid container spacing={2}>
      {loading && seniors.length === 0 ? (
        <SeniorCategorySkeleton />
      ) : (
        seniors.map((category, index) => (
          <Grid
            item
            sm={12}
            md={6}
            lg={3}
            key={index}
            sx={{ color: index === 0 ? theme.palette.error.main : undefined }}
          >
            <SeniorCategoryCard
              category={category?.name}
              seniors={category?.seniors}
            />
          </Grid>
        ))
      )}
    </Grid>
  );
};

export default SeniorCategoryCardList;
