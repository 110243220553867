import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useMemo,
  useCallback
} from 'react';

import agent from '../utilities/agent';
import { sortByName } from '../utilities/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../redux/User';
import { getActionsTakenLabels } from '../redux/Enums';

// ACTION CONSTS
const OFFICE_REQUEST = 'OFFICE_REQUEST';
const OFFICE_DONE = 'OFFICE_DONE';
const CUSTOMERS_SAVE = 'CUSTOMERS_SAVE';
const OFFICES_SAVE = 'OFFICES_SAVE';
const TERRITORIES_SAVE = 'TERRITORIES_SAVE';
const TERRITORIES_SELECT = 'TERRITORIES_SELECT';
const OFFICES_SELECT = 'OFFICES_SELECT';
const CUSTOMER_SELECT = 'CUSTOMER_SELECT';
const CLIENTS_REQUEST = 'CLIENTS_REQUEST';
const CLIENTS_DONE = 'CLIENTS_DONE';
const CLIENTS_SAVE = 'CLIENTS_SAVE';
const CLIENTS_SELECT = 'CLIENTS_SELECT';
const ACTION_TAKEN_ENUMS_SAVE = 'ACTION_TAKEN_ENUMS_SAVE';
const CARELOGS_TAGGING_CLASSES_SAVE = 'CARELOGS_TAGGING_CLASSES_SAVE';
const TRIGGER_CARELOGS_RELOAD = 'TRIGGER_CARELOGS_RELOAD';

// REDUCER FUNCTION

const initialState = {
  territories: [],
  offices: [],
  customers: [],
  clients: [],
  currentTerritories: [],
  actionTakenEnums: [],
  carelogsTaggingClases: [],
  currentCustomer: {},
  currentOffices: [],
  currentClient: {},
  loadingOffices: false,
  loadingClients: false,
  triggerCarelogsReload: false,
};

const customerReducer = (state, action = {}) => {
  const { type, data } = action;
  switch (type) {
    case TERRITORIES_SAVE: {
      return {
        ...state,
        territories: data
      };
    }
    case OFFICES_SAVE: {
      return {
        ...state,
        offices: data
      };
    }
    case CUSTOMERS_SAVE: {
      return {
        ...state,
        customers: data
      };
    }
    case TERRITORIES_SELECT: {
      return {
        ...state,
        currentTerritories: data
      };
    }
    case OFFICES_SELECT: {
      return {
        ...state,
        currentOffices: data
      };
    }
    case CUSTOMER_SELECT: {
      return {
        ...state,
        currentCustomer: data
      };
    }
    case OFFICE_REQUEST: {
      return {
        ...state,
        loadingOffices: true
      };
    }
    case OFFICE_DONE: {
      return {
        ...state,
        loadingOffices: false
      };
    }
    case CLIENTS_REQUEST: {
      return {
        ...state,
        loadingClients: true
      };
    }
    case CLIENTS_DONE: {
      return {
        ...state,
        loadingClients: false
      };
    }
    case CLIENTS_SAVE: {
      return {
        ...state,
        clients: data
      };
    }
    case CLIENTS_SELECT: {
      return {
        ...state,
        currentClient: data
      };
    }
    case CARELOGS_TAGGING_CLASSES_SAVE: {
      return {
        ...state,
        carelogsTaggingClases: data,
      };
    }
    case ACTION_TAKEN_ENUMS_SAVE: {
      return {
        ...state,
        actionTakenEnums: data,
      };
    }
    case TRIGGER_CARELOGS_RELOAD: {
      return {
        ...state,
        triggerCarelogsReload: data
      };
    }
    default:
      return state;
  }
};

// ACTIONS

export const handleTerritoriesList = (dispatch, data) => {
  dispatch({ type: TERRITORIES_SAVE, data });
};

export const handleOfficesList = (dispatch, data) => {
  dispatch({ type: OFFICES_SAVE, data });
};

export const handleCustomersList = (dispatch, data) => {
  dispatch({ type: CUSTOMERS_SAVE, data });
};

export const handleTerritoriesSelect = (dispatch, data) => {
  dispatch({ type: TERRITORIES_SELECT, data });
};

export const handleOfficesSelect = (dispatch, data) => {
  dispatch({ type: OFFICES_SELECT, data });
};

export const handleCustomerSelect = (dispatch, data) => {
  dispatch({ type: CUSTOMER_SELECT, data });
};

export const handleOfficeRequest = (dispatch) => {
  dispatch({ type: OFFICE_REQUEST });
};

export const handleOfficeDone = (dispatch) => {
  dispatch({ type: OFFICE_DONE });
};

export const handleClientsRequest = (dispatch) => {
  dispatch({ type: CLIENTS_REQUEST });
};

export const handleClientsDone = (dispatch) => {
  dispatch({ type: CLIENTS_DONE });
};

export const handleClientsSave = (dispatch, data) => {
  dispatch({ type: CLIENTS_SAVE, data });
};

export const handleClientSelect = (dispatch, data) => {
  dispatch({ type: CLIENTS_SELECT, data });
};

export const handleCarelogsTaggingClassesSave = (dispatch, data) => {
  dispatch({ type: CARELOGS_TAGGING_CLASSES_SAVE, data });
};

export const handleActionTakenEnumsSave = (dispatch, data) => {
  dispatch({ type: ACTION_TAKEN_ENUMS_SAVE, data });
};

export const handleTriggerCarelogsReload = (dispatch, data) => {
  dispatch({ type: TRIGGER_CARELOGS_RELOAD, data });
};

// REDUCER STATE CONTEXT WRAPPER

export const CustomerContext = createContext();

const CustomersStateWrapper = ({ children }) => {
  const [state, dispatch] = useReducer(customerReducer, initialState);
  const reduxDispatch = useDispatch();
  const user =  useSelector(selectUser);

  const MixpanelUserData = useMemo(() => {
    const result = {
      username: user?.username,
      customer: state.currentCustomer.name,
      territories: state.currentTerritories.map(({ name }) => name),
      offices: state.currentOffices.map(({ name }) => name)
    };
    return result;
  }, [user, state]);

  const handleNavigationUpdate = useCallback(({
    customerId,
    officeIds = [],
    territoryIds = [],
  }) => {
    if (customerId && officeIds?.length && territoryIds?.length) {
      const newCustomer = state.customers?.find(({ id }) => id === customerId);
      const newOffices = state.offices?.filter(({ id }) => officeIds?.includes(id));
      const newTerritories = state.territories?.filter(({ id }) => territoryIds?.includes(id));

      if (newCustomer && newOffices?.length && newTerritories?.length) {
        handleCustomerSelect(dispatch, newCustomer);
        handleOfficesSelect(dispatch, newOffices);
        handleTerritoriesSelect(dispatch, newTerritories);
      }
    }
  }, [
    state.customers,
    state.offices,
    state.territories
  ]);
  
  useEffect(() => {
    if (user?.id) {
      handleOfficeRequest(dispatch);
  
      Promise.all([
        agent.get('/territories'),
        agent.get('/offices'),
        agent.get('/customers'),
      ])
        .then(([responseTerritories, responseOffices, responseCustomers]) => {
          const territories = responseTerritories?.data?.data;
          const offices = responseOffices?.data?.data;
          const customers = responseCustomers?.data?.data;
          console.log('territories: ', territories);
          console.log('offices: ', offices);
          console.log('customers: ', customers);

          if (Array.isArray(offices)) {
            offices.forEach((office) => {
              office.territories = territories
                .filter(({ office_id }) => office_id === office.id)
                .sort((a, b) => sortByName(a, b, 'name'));
            });
          }
          if (territories) {
            handleTerritoriesList(
              dispatch,
              territories.sort((a, b) => sortByName(a, b, 'name'))
            );
          }
          if (offices) {
            handleOfficesList(
              dispatch,
              offices
                .filter(({ territories }) => territories.length)
                .sort((a, b) => sortByName(a, b, 'name'))
            );
          }
          
          if (customers) {
            handleCustomersList(
              dispatch,
              customers.sort((a, b) => sortByName(a, b, 'name'))
            );
          }
          
        })
        .finally(() => handleOfficeDone(dispatch))
        .catch((error) => console.log(error));
    }
  }, [user?.id]);
  
  useEffect(() => {
    if (state.currentTerritories?.length) {
      handleClientsRequest(dispatch);
      agent
        .get(
          `/units?territoryIds=${state.currentTerritories
            .map(({ id }) => id)
            .join(',')}`,
        )
        .then(({ data: { data: clients } }) => {
          handleClientsSave(
            dispatch,
            clients.sort((a, b) =>
              (`${a.name}`).localeCompare(b.name, undefined, { numeric: true })
            )
          );
        })
        .finally(() => handleClientsDone(dispatch));
    }
  }, [state.currentTerritories]);

  useEffect(() => {
    (async () => {
      if (!state?.actionTakenEnums?.length && user?.id) {
        const actionTakenEnums = await reduxDispatch(getActionsTakenLabels()).unwrap();
        handleActionTakenEnumsSave(dispatch, actionTakenEnums);
      }
    })();
  }, [user?.id]);

  return (
    <CustomerContext.Provider
      value={[{ ...state, MixpanelUserData, handleNavigationUpdate }, dispatch]}
    >
      {children}
    </CustomerContext.Provider>
  );
};

export const useCustomerState = () => {
  return useContext(CustomerContext);
};

export default CustomersStateWrapper;
