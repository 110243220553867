import { Box, Card, styled } from '@mui/material';
import Colors from '../../../../theme/Colors';

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  width: '80vw',
  minWidth: '980px',
  minHeight: '80vh',
  justifyContent: 'center',
  alignSelf: 'center',
  borderRadius: 15,
  '& .leftSide': {
    backgroundColor: 'white',
    color: 'black',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  '& .rightSide': {
    background: Colors.purpleGradient,
    width: '50%',
    height: '100%'
  },
  '& .imgTitleWrapper': {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  '& .imgTitle': {
    width: 130,
    height: 26,
  },
  '& .cardContent': {
    width: '100%',
  },
  '& .cardFooter': {
    alignSelf: 'center',
  },
  '& .supportFooter': {
    color: theme.palette.primary.main,
    textAlign: 'center',
    letterSpacing: '-0.005em',
  },
  '& .back': {
    backgroundColor: Colors.sensiLightPurple,
    minWidth: 33,
    minHeight: 33,
  },
}));

const BoxWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  backgroundColor: Colors.azure
}));

export {
  BoxWrapper,
  StyledCard,
};