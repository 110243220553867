import { Menu, Transition } from '@headlessui/react';
import { useMemo } from 'react';

import { CloseIcon } from '../../../assets/icons';
import dayjs from '../../../utilities/dayjs';

import FilterItemList from './FilterItemList';


const FilterItem = ({
  filter = {},
  filterState = {},
  filterUpdate,
  id = '',
  filterClear,
  zIndex = 0
}) => {
  const disabled = useMemo(() => {
    return filter.type === 'select' && !filter.options?.filter(({ value }) => value).length;
  }, [filter.type, filter.options]);

  const filterValueLabel = useMemo(() => {
    if (!filterState[filter.name]?.value) return 'All';
    switch (filter.type) {
      case 'date':
        const { startDate, endDate } = filterState.date.value;
        return startDate && endDate
          ? `${dayjs(startDate).format('DD.MM.YY')} - ${dayjs(endDate).format(
            'DD.MM.YY',
          )}`
          : 'All';
      default:
        return filterState[filter.name].label;
    }
  }, [filter, filterState[filter.name]]);

  const clearButton = useMemo(() => {
    switch (filter.name) {
      case 'date':
        const { value } = filterState.date || {};
        return !!(value?.startDate && value?.endDate) && (
          <div
            className="filter-reset-btn flex items-center justify-center cursor-pointer absolute"
            onClick={(e) => {
              e.stopPropagation();
              filterClear(filter.name);
            }}
          >
            <CloseIcon />
          </div>
        );
      default:
        return !!filterState[filter.name] && (
          <div
            className="filter-reset-btn flex items-center justify-center cursor-pointer absolute"
            onClick={(e) => {
              e.stopPropagation();
              filterClear(filter.name);
            }}
          >
            <CloseIcon />
          </div>
        );
    }
  }, [filter, filterState[filter.name], filterClear]);

  return (
    <Menu
      as="div"
      className={`${id} ${
        disabled ? 'disabled' : ''
      } filter-item-container relative inline-block text-left mr-7 z-10 last-of-type:mr-0`}
      style={zIndex ? { zIndex } : {}}
    >
      {({ open }) => (
        <>
          <div className="filter-item-title mb-2">{filter.title}</div>
          <Menu.Button>
            <div
              className={`filter-btn ${
                open ? 'active' : ''
              }  w-full h-full z-20 justify-center text-left bg-white`}
            >
              {filterValueLabel}
            </div>

            {!open && clearButton}
          </Menu.Button>

          {!disabled && (
            <Transition
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <FilterItemList
                list={filter.options}
                filter={filter}
                filterState={filterState}
                update={filterUpdate}
              />
            </Transition>
          )}
        </>
      )}
    </Menu>
  );
};

export default FilterItem;
