import { Box, styled } from '@mui/material';

export const Container = styled(Box)(({ theme }) => ({
  width: '100%',
  position: 'relative',
  ' > div': {
    flex: '1 0',
    fontSize: '10px',
    padding: 0,
    border: 'none',
    height: '100%',
    width: '100%',
    '&::placeholder': {

    },
  },
  '& .public-DraftEditorPlaceholder-root': {
    position: 'absolute',
    top: '1.5em',
    left: '1em',
    color: theme.palette.sensiGrey.main,
  },
  '& .DraftEditor-editorContainer': {
    marginTop: '9px',
    marginLeft: '0.45rem'
  },
  'form & .DraftEditor-editorContainer': {
    marginTop: '1.5em',
  },
  '& [role="listbox"]': {
  }
}));


export const MentionContainer = styled(Box)({
  backgroundColor: 'transparent'
});
