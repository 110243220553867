import { useCallback } from 'react';

import {
  CheckLinearIcon,
  BellIcon,
  LightBulbIcon,
  ArrowIcon,
  MagnifyingGlassIcon,
} from '../../../assets/icons/index';
import English from '../../../languages/English';
import {
  useTakeawaysContext,
  TAKEAWAY_MODALS,
} from '../../../contexts/takeaways';
import EVENT_TYPES from '../../../services/EVENT_TYPES';
import { EventService } from '../../../services';
import { useCustomerState } from '../../../contexts/customers';

const StageIndicator = ({ className = '', currentStage, setReportStage, isDisabled }) => {
  const { setModal } = useTakeawaysContext();
  const [{ MixpanelUserData }] = useCustomerState();

  const handlePrevStage = useCallback(() => {
    switch (currentStage) {
      case 'carelogsStage':
        setReportStage('');
        break;
      case 'agencyRecommendations':
        setReportStage('carelogsStage');
        break;
      default:
        break;
    }
  }, [currentStage, setReportStage]);

  const handleNextStage = useCallback(() => {
    switch (currentStage) {
      case '':
        setReportStage('carelogsStage');
        break;
      case 'carelogsStage':
        setReportStage('agencyRecommendations');
        break;
      case 'agencyRecommendations':
        setModal(TAKEAWAY_MODALS.SUBMIT);
        EventService.fireEvent(EVENT_TYPES.GENERATE_CARE_REPORT, {
          ...MixpanelUserData,
        });
        break;
      default:
        break;
    }
  }, [currentStage, setReportStage]);

  function handleOnClick() {
    return currentStage === 'agencyRecommendations' && isDisabled ? undefined : handleNextStage();
  }

  return (
    <div
      className={`report-stage-indicator-container ${className} flex flex-col items-center`}
    >
      <div className="stage-line-container flex items-center relative">
        <div className="indicator-progress-line-container z-10 absolute">
          <div className={`actual-progress-bar ${currentStage}`}></div>
        </div>
        <div className="indicator-col flex flex-col items-center justify-center z-20 active">
          <div className="icon-container flex flex-col items-center justify-center">
            <MagnifyingGlassIcon />
          </div>
          <div className="check-icon-container flex flex-col items-center justify-center">
            <CheckLinearIcon />
          </div>
          <span>{English.topCareRecommendations}</span>
        </div>
        <div
          className={`indicator-col flex flex-col items-center justify-center z-20 ${
            ['carelogsStage', 'agencyRecommendations'].includes(currentStage)
              ? 'active'
              : ''
          }`}
        >
          <div className="icon-container flex flex-col items-center justify-center">
            <BellIcon />
          </div>
          <div className="check-icon-container flex flex-col items-center justify-center">
            <CheckLinearIcon />
          </div>
          <span>{English.notableEvents}</span>
        </div>
        <div
          className={`indicator-col flex flex-col items-center justify-center z-20 ${
            currentStage === 'agencyRecommendations' ? 'active' : ''
          }`}
        >
          <div className="icon-container flex flex-col items-center justify-center">
            <LightBulbIcon />
          </div>
          <div className="check-icon-container flex flex-col items-center justify-center">
            <CheckLinearIcon />
          </div>
          <span>{English.mainTakeaways}</span>
        </div>
      </div>
      <div className={`navigation-bar ${ currentStage === 'agencyRecommendations' ? 'last-stage-position' : ''} flex items-center justify-center mt-6 w-full`}>
        <div
          onClick={handlePrevStage}
          className={`nav-button flex items-center justify-center ${
            currentStage === '' ? 'disabled' : ''
          }`}
        >
          <ArrowIcon className="rotate-180 mr-2" />
          {English.previous}
        </div>

        <div
          onClick={() => handleOnClick()}
          className={`nav-button flex items-center justify-center ${currentStage === 'agencyRecommendations' && isDisabled ? 'disabled-next' : ''}`}
        >
          {currentStage === 'agencyRecommendations'
            ? English.generateCareReport
            : English.next}
          <ArrowIcon className="ml-2" />
        </div>
      </div>
    </div>
  );
};

export default StageIndicator;
