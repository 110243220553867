import React, { useState, useMemo, useImperativeHandle, useRef } from 'react';
import { Container, MentionContainer } from './CommentInput.style';
import draftjsStyle from './Draftjs.module.scss';

import Editor from '@draft-js-plugins/editor';
import '@draft-js-plugins/mention/lib/plugin.css';

import createMentionPlugin, { defaultTheme } from '@draft-js-plugins/mention';
import { EditorState } from 'draft-js';

import Colors from '../../../../../../theme/Colors';
import { TAG_SUGGESTION_LIMIT } from '../../../../../../utilities/const';

const customSuggestionsFilter = (
  searchValue,
  suggestions,
) => {
  const value = searchValue.toLowerCase();
  const filteredSuggestions = suggestions.filter(
    (suggestion) => !value || suggestion.name.toLowerCase().indexOf(value) > -1
  );
  const length =
    filteredSuggestions.length < TAG_SUGGESTION_LIMIT ? filteredSuggestions.length : TAG_SUGGESTION_LIMIT;
  return filteredSuggestions.slice(0, length);
};
const CommentInput = ({ inputRef, assignees, placeholder, defaultValue = '', readOnly = false }) => {
  const [editorState, setEditorState] = useState(() => EditorState.createWithText(defaultValue));
  const [open, setOpen] = useState(true);
  const mentions = assignees.map((assignee) => ({ name: assignee.username, id: assignee.id }));
  const [suggestions, setSuggestions] = useState(mentions);
  const [taggedUsers, setTaggedUsers] = useState([]);
  const editor = useRef();

  const setEmptyEditorState = () => {
    setEditorState(EditorState.createEmpty());
    setTaggedUsers([]);
  };

  // make sure to pass it through the ref
  useImperativeHandle(inputRef, () => ({
    getEditorState: () => editorState,
    setEmptyEditorState,
    focus: () => {
      editor.current.focus();
    },
    getTaggedUsers: () => taggedUsers.filter((tag) => editorState.getCurrentContent().getPlainText().includes(tag.name)),
  }));

  const { MentionSuggestions, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      mentionPrefix: '@',
      mentionTrigger: '@',
      entityMutability: 'IMMUTABLE',
      popperOptions: {
        strategy: 'fixed',
        placement: 'bottom-start'
      },
      theme: {
        ...defaultTheme,
        mentionSuggestions: `${defaultTheme.mentionSuggestions} ${draftjsStyle.mentionsContainer}`
      },
      mentionComponent: (mentionProps) => (
        <span className={'mention'} style={{ color: Colors.skyBlue }} onClick={mentionProps.onClick}>
          {mentionProps.children}
        </span>
      )
    });
    const { MentionSuggestions } = mentionPlugin;

    const plugins = [mentionPlugin];
    return { plugins, MentionSuggestions };
  }, []);

  const onSearchChange = ({ value }) => {
    setSuggestions(customSuggestionsFilter(value, mentions));
  };

  const onOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const onChange = (value) => {
    setEditorState(value);
  };
  const onAddMention = (mention) => {
    setTaggedUsers([...taggedUsers, mention]);
    return true;
  };

  return (
    <Container onClick={() => editor.current.focus()}>
      <Editor
        editorKey="MentionEditor"
        placeholder={placeholder}
        editorState={editorState}
        onChange={onChange}
        plugins={plugins}
        readOnly={readOnly}
        ref={editor}
      />
      <MentionContainer>
        <MentionSuggestions
          open={open}
          onOpenChange={onOpenChange}
          suggestions={suggestions}
          onSearchChange={onSearchChange}
          onAddMention={onAddMention}
        />
      </MentionContainer>
    </Container>
  );
};

export default CommentInput;
