import Colors from '../../../theme/Colors';

const style = {
  width: '315px',
  height: '53px',
  borderRadius: '5px',
  color: Colors.white,
  textTransform: 'none',
};

export default style;
