import React from 'react';
import { GoodToKnow, HighPriority, NeedsYourAttention } from '../../../../assets/icons';
import { ThumbDownIcon, ThumbUpIcon } from '../../../../components/icons';

export const iconMap = {
  'High_priority': <HighPriority />,
  'Needs_your_attention': <NeedsYourAttention />,
  'Good_to_know' : <GoodToKnow />,
};

export const options = [
  { value: true, icon: ThumbUpIcon, color: '#54C369' },
  { value: false, icon: ThumbDownIcon, color: '#EF6767' },
];