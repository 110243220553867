import React, { useCallback, useState, useEffect, useMemo } from 'react';

import SharedModal from '../common/modal';
import CheckBox from '../common/checkbox';
import agent from '../../utilities/agent';
import CircleLoader from '../common/loaders/circleLoader';
import { LikeIcon, PaperPlaneIcon } from '../../assets/icons';
import PopoverSelect from '../common/popoverSelect';
import { useCustomerState } from '../../contexts/customers';
import { EventService } from '../../services';
import EVENT_TYPES from '../../services/EVENT_TYPES';
import { deleteTodo, editTodo, selectTodos } from '../../redux/Todos';
import { useDispatch, useSelector } from 'react-redux';
import { updateEvent } from '../../redux/Seniors';
import { TASK_STATUS } from '../../utilities/const';

const ResolveModal = ({ toggle, highlight, callback }) => {
  const [{ MixpanelUserData, actionTakenEnums }] = useCustomerState();
  const dispatch = useDispatch();
  const todos = useSelector(selectTodos);

  const modalClassName = useMemo(() => {
    switch (true) {
      case Boolean(highlight.indication):
        return 'insight-notification-modal';
      case !highlight.indication &&
        highlight.severity === 'High' &&
        highlight.is_real_time:
        return 'critical-notification-modal';
      default:
        return '';
    }
  }, [highlight.indication, highlight.severity, highlight.is_real_time]);
  const [added, setAdded] = useState(!!highlight.include_in_report);
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState('');
  const [action, setAction] = useState('');
  const [opinion, setOpinion] = useState((highlight?.is_helpful === true) ? 'like' : (highlight?.is_helpful === false) ? 'dislike' : undefined);
  
  const handleSetStatusDone = useCallback(() => {
    if (!action && !comment) return;
    const actionTaken = actionTakenEnums.find(({ name }) => name === action)?.id;

    setLoading(true);
    const endpoint = highlight.is_carelog ? '/carelogs' : '/insights';
    Promise.all([
      agent.post(`${endpoint}/status/${highlight?.id}`, {
        status: TASK_STATUS.DONE.id,
        isHelpful: opinion ? opinion === 'like' : undefined
      }),
      agent.post(`${endpoint}/addToFamilyReport/${highlight?.id}`, {
        actionTaken,
        actionTakenMessage: comment,
        addToFamilyReport: added,
      }),
    ])
      .then(() => {
        const page = 'events history';
        dispatch(updateEvent({
          filter: item => (highlight.is_carelog ? (item.id === highlight.id && item.is_carelog) : item.id === highlight.id),
          data: { ['status']: TASK_STATUS.DONE.status }
        }));
        if (highlight.indication) {
          EventService.fireEvent(EVENT_TYPES.INSIGHT_MARK_REVIEWED, {
            ...MixpanelUserData,
            insight_id: highlight.id,
            label: highlight.labels,
            unit_id: highlight.unit_id,
            page
          });
          EventService.fireEvent(EVENT_TYPES.INSIGHT_ADD_ACTION_TAKEN, {
            ...MixpanelUserData,
            insight_id: highlight.id,
            label: highlight.labels,
            unit_id: highlight.unit_id,
            action_type: action,
            action_message: comment,
          });
          opinion &&
            EventService.fireEvent(EVENT_TYPES.INSIGHT_MARK_HELPFUL, {
              ...MixpanelUserData,
              insight_id: highlight.id,
              label: highlight.labels,
              value: opinion === 'like' ? 'yes' : 'no',
              category: highlight.senior_category,
              unit_id: highlight.unit_id,
            });
          EventService.fireEvent(EVENT_TYPES.INSIGHT_ADD_TO_FAMILY_REPORT, {
            ...MixpanelUserData,
            insight_id: highlight.id,
            label: highlight.labels,
            category: highlight.senior_category,
            unit_id: highlight.unit_id,
            value: added,
            page,
          });
        } else {
          EventService.fireEvent(EVENT_TYPES.NOTIFICATION_MARK_REVIEWED, {
            ...MixpanelUserData,
            carelog_id: highlight.id,
            category: highlight.senior_category,
            unit_id: highlight.unit_id,
            page,
          });
          EventService.fireEvent(EVENT_TYPES.NOTIFICATION_ADD_ACTION_TAKEN, {
            ...MixpanelUserData,
            action_type: action,
            action_message: comment,
            carelog_id: highlight.id,
            category: highlight.senior_category,
            unit_id: highlight.unit_id,
            label: highlight.title,
            page,
            is_related_event: false
          });
          opinion &&
          EventService.fireEvent(EVENT_TYPES.NOTIFICATION_MARK_HELPFUL, {
            ...MixpanelUserData,
            carelog_id: highlight.id,
            label: highlight.title,
            value: opinion === 'like' ? 'yes' : 'no',
            category: highlight.senior_category,
            unit_id: highlight.unit_id,
            page,
            is_related_event: false
          });
          EventService.fireEvent(EVENT_TYPES.NOTIFICATION_ADD_TO_FAMILY_REPORT, {
            ...MixpanelUserData,
            carelog_id: highlight.id,
            label: highlight.labels,
            category: highlight.senior_category,
            unit_id: highlight.unit_id,
            value: added,
            page,
            is_related_event: false
          });
        }
        callback({ added, actionTaken: action, actionTakenMessage: comment });
      })
      .then(() => toggle())
      .finally(async () => {
        const todo = todos.find(todo => +todo.insight_id === highlight?.id || +todo.care_log_id === highlight?.id);
        if (todo) {
          const editedTodo = await dispatch(
            editTodo({
              id: todo.id,
              payload: { is_done: true },
            }),
          ).unwrap();
          editedTodo && dispatch(deleteTodo(todo.id));
          dispatch(deleteTodo(todo.id));
        }
        setLoading(false);
      });
  }, [
    action,
    comment,
    actionTakenEnums,
    highlight.indication,
    highlight?.id,
    highlight.labels,
    opinion,
    added,
    callback,
    MixpanelUserData,
    toggle,
  ]);

  useEffect(() => {
    setAction(highlight?.action_taken || '');
    setComment(highlight?.action_taken_message);
  }, [highlight?.action_taken, highlight?.action_taken_message]);

  return (
    <SharedModal
      className={`resolve-modal ${modalClassName} relative`}
      toggle={toggle}
      title={() => (
        <div className="flex flex-col">
          <h3>Great!</h3>
          <h3>
            {highlight.indication ? 'Insight' : 'Notification'} is resolved!
          </h3>
        </div>
      )}
    >
      <div className="modal-body">
        <div className="modal-formImage">Were there any actions taken?</div>
        <PopoverSelect
          containerClassName="mb-4"
          value={action}
          options={actionTakenEnums.map(({ name }) => ({
            label: name,
            value: name,
          }))}
          onChange={setAction}
          label={'Choose an action'}
        />

        <div className="relative">
          <input
            id="resolve-input"
            className="w-full"
            placeholder="Or type anything..."
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            autoComplete="off"
          />
          <label
            htmlFor="resolve-input"
            className="input-icon absolute cursor-text"
          >
            <PaperPlaneIcon />
          </label>
        </div>

        <div className="flex items-center justify-between w-full mt-4">
          <CheckBox
            value={added}
            onChange={setAdded}
            label={'Add to Care Report'}
          />

          <div className="opinion-container flex items-center">
            <div
              onClick={() => setOpinion('dislike')}
              className={`dislike cursor-pointer ${
                opinion === 'dislike' ? 'selected' : ''
              } rotate-180 mr-2`}
            >
              <LikeIcon />
            </div>
            <div
              onClick={() => setOpinion('like')}
              className={`like cursor-pointer ${
                opinion === 'like' ? 'selected' : ''
              } mr-2`}
            >
              <LikeIcon />
            </div>
            Was this {highlight.indication ? 'insight' : 'notification'}{' '}
            helpful?
          </div>
        </div>
      </div>

      <div className="modal-footer">
        <button
          className={`resolve-modal-actions-submit ${
            !action && !comment ? 'disabled' : ''
          }`}
          onClick={handleSetStatusDone}
        >
          Save
        </button>
      </div>
      <CircleLoader show={loading} />
    </SharedModal>
  );
};

export default ResolveModal;
