/* eslint-disable no-useless-escape */
export const MFA_TYPE = 'SMS_MFA';
export const NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED';
export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const strongPassword =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`])\S{8,99}$/;

export const codeRegex = /\d{6,6}/;

export const defaultDateFormat = 'MM/DD/YYYY';

export const healthTitles = [
  'physical distress',
  'physical anomaly',
  'cognitive anomaly',
  'cognitive training',
  'mental distress',
  'difficulty performing task',
  'medication',
  'pain',
  'cardiac',
  'gastrointestinal',
  'respiratory',
  'urinary',
  'cognitive',
  'skin integrity',
  'physical issue',
  'wellness improvement',
  'chronic condition',
  'safety concern',
  'mental health',
  'senses',
  'sleep issues',
  'test results',
  'vital signs',
  'hospitalization',
  'adl difficulty'
];

export const careTitles = [
  'exceptional care',
  'positive interaction',
  'care plan',
  'caregiver dissatisfaction',
  'inadequate care environment',
  'intense interaction',
  'care resistance',
  'client dissatisfaction',
  'unsuccessful communication',
  'caregiver task difficulty',
  'adverse event',
  'care concern',
  'care resistance',
  'positive interaction'
];

export const HIGH_LEVEL_ALERT = 'alert-highlight';
export const CARELOG_NOTIFICATION = 'carelog-highlight';
export const INSIGHT_NOTIFICATION = 'insight-highlight';

export const dashboardOffsetStep = 20;

export const autoReportIds = [416, 328];

export const adminUserString = 'Sensi employee';
export const commonUserString = 'Customer';

export const SECONDARY_QUERY_PARAMS = [
  'tab',
  'startDate',
  'endDate',
  'reportId',
  'notifiedReportId'
];

// ADD NOTIFICATION_STATUSES consts here read/unreaad/deleted
export const NOTIFICATION_STATUS_READ = 'read';
export const NOTIFICATION_STATUS_UNREAD = 'unread';
export const NOTIFICATION_STATUS_DELETED = 'deleted';

// Notification messages types here
export const DEVICES_STATUSES_NOTIFICATIONS = [
  'device_active',
  'device_inactive',
  'device_muted'
];
export const AUTOMATIC_REPORT_NOTIFICATIONS = [
  'automatic_report_created'
];
export const MANUAL_REPORT_NOTIFICATIONS = [
  'report_created',
  'author_report_created'
];
export const MANUAL_REPORT_ERROR_NOTIFICATIONS = [
  'author_report_error'
];
export const CARELOGS_ASSIGN_AND_STATUS_NOTIFICATIONS = [
  'user_to_carelog_assigned',
  'user_to_carelog_unassigned',
  'assigned_carelog_status_change'
];
export const CARELOGS_COMMENTS_NOTIFICATIONS = [
  'following_carelog_comment',
  'assigned_carelog_comment'
];
export const INSIGHTS_ASSIGN_AND_STATUS_NOTIFICATIONS = [
  'user_to_insight_assigned',
  'user_to_insight_unassigned',
  'assigned_insight_status_change'
];
export const INSIGHTS_COMMENTS_NOTIFICATIONS = [
  'following_insight_comment',
  'assigned_insight_comment',
  'tagged_insight_comment'
];
export const TODO_ASSIGNEE_NOTIFICATIONS = [
  'user_assigned_todo'
];

export const TIMES = {
  WEEK: new Date(Date.now() - (7 * 24 * 60 * 60 * 1000)).toISOString(),
  TWO_DAYS: new Date(Date.now() - (48 * 60 * 60 * 1000)).toISOString()
};

export const TASK_STATUS = {
  TODO: { status: 'To Do', id: 1 },
  DONE: { status: 'Done', id: 3 }
};

export const AMPLIFY_ERRORS = [
  'No current user',
  'NotAuthorizedException'
];

export const ASSETS_BUCKET = 'https://assets.sensi.ai/icons/carelogs/dashboard';

export const TAG_SUGGESTION_LIMIT = 10;

export const BETA_LABEL_IDS = [187];

export const CARE_LOG_ACTIONS_TYPES = {
  HEARD: 1,
  READ: 2
};

export const actionMap = {
  [CARE_LOG_ACTIONS_TYPES.HEARD]: 'played',
  [CARE_LOG_ACTIONS_TYPES.READ]: 'read',
};