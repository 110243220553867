import React, { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

import { createComment, loadComments } from '../../../../../../redux/Comments';
import EventService from '../../../../../../services/EventService';
import { useCustomerState } from '../../../../../../contexts/customers';
import English from '../../../../../../languages/English';
import { Form, IconButton } from './NewComment.style';
import CommentInput from '../CommentInput/CommentInput';

import EVENT_TYPES from '../../../../../../services/EVENT_TYPES';
import { selectIsRelatedEvent } from '../../../../../../redux/Seniors';

export const NewComment = ({ title, is_carelog, item_id, assignees }) => {
  const [{ MixpanelUserData }] = useCustomerState();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const isRelatedEvent = useSelector(selectIsRelatedEvent);
  const input = useRef(null);

  const handleSubmit = useCallback(async (evt) => {
    evt.preventDefault();
    setLoading(true);
    const comment = input.current.getEditorState().getCurrentContent().getPlainText();
    if (!comment.length) {
      input.current.focus();
    } else {
      // todo add comment event track
      const eventType = is_carelog
        ? EVENT_TYPES.NOTIFICATION_ADD_COMMENT
        : EVENT_TYPES.INSIGHT_ADD_COMMENT;

      const eventData = {
        ...MixpanelUserData,
        [is_carelog ? 'carelog_id' : 'insight_id']: item_id,
        label: title,
        comment,
        page: 'dashboard',
      };

      if (is_carelog) {
        eventData.is_related_event = isRelatedEvent;
      }

      EventService.fireEvent(eventType, eventData);
      await dispatch(createComment({ is_carelog, item_id, comment, tags: input.current.getTaggedUsers() }));
      if (input.current) {
        input.current.setEmptyEditorState();
        await dispatch(loadComments({ is_carelog, item_id }));
      }
    }
    setLoading(false);
  }, []);

  return (
    <Form onSubmit={handleSubmit}>
      <CommentInput
        inputRef={input}
        placeholder={English.addInternalComment}
        assignees={assignees}
      />
      <IconButton type='submit' disabled={loading} sx={{}}>
        {loading ? (
          <CircularProgress size={24} />
        ) : (
          <SendIcon sx={{ height: '60%', transform: 'rotate(-45deg)' }} />
        )}
      </IconButton>
    </Form>
  );
};
