import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Wrapper,
  Title,
  HorizontalDivider,
  ActionContainer,
} from './ActionTooltip.style';
import ActionTooltipItem from '../ActionTooltipItem';
import English from '../../../../languages/English';
import { selectCarelogActions } from '../../../../redux/CarelogsActions';
import { actionMap } from '../../../../utilities/const';

const ActionTooltip = ({ carelogId: id, actionTypeId: actionId }) => {
  let carelogActions = useSelector(selectCarelogActions({ id, actionId }));
  const action = actionMap[actionId];
  const actionText = English[action];
  if (!carelogActions?.length) {
    carelogActions = [
      { dashboard_users: { username: 'Admin' }, ts: new Date() },
    ];
  }

  return (
    <Wrapper variant="container">
      <Title variant="subtitle2" align="left">
        {[actionText, English.by].join(' ')}:
      </Title>
      <ActionContainer>
        {carelogActions.map((carelogAction, i) => (
          <React.Fragment key={i}>
            <ActionTooltipItem carelogAction={carelogAction} />
            {i < carelogActions.length - 1 && <HorizontalDivider />}
          </React.Fragment>
        ))}
      </ActionContainer>
    </Wrapper>
  );
};

export default ActionTooltip;
