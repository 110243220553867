import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { ArrowIcon } from '../../../../assets/icons';
import UserNotification from '../../UserNotification/UserNotification';
import {
  handleCustomerSelect,
  handleOfficesSelect,
  handleTerritoriesSelect,
  useCustomerState
} from '../../../../contexts/customers';
import SearchPopupContent from '../SearchPopupContent';
import { useLoadInitialData } from '../../../../hooks/initialData';
import { sortByName } from '../../../../utilities/helpers';
import { selectUser, signOut } from '../../../../redux/User';
import { useNavigate } from 'react-router-dom';
import { MixpanelService } from '../../../../services';
import {
  Avatar,
  Box,
  Toolbar
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import LogoImg from '../../../../assets/images/logos/sensi.svg';
import MoreIcon from '@mui/icons-material/MoreVert';
import { useDispatch, useSelector } from 'react-redux';
import { AppBar, Flex, NavigationView, UserNotificationContainer } from './HeaderNavigation.style';
import { menuId, mobileMenuId, renderMenu, renderMobileMenu } from './HeaderNavigation.utils';
import LaunchDarklyService from '../../../../services/LaunchDarklyService';

const HeaderNavigation = ({ openDrawer, setOpenDrawer }) => {
  const navigate = useNavigate();
  const user =  useSelector(selectUser);
  const firstName = user?.firstName;
  const dispatchRedux = useDispatch();
  const [
    {
      territories,
      offices,
      customers,
      currentTerritories,
      currentOffices,
      currentCustomer
    },
    dispatch
  ] = useCustomerState();

  useLoadInitialData();


  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleSignOut = async () => {
    await dispatchRedux(signOut());
    MixpanelService.reset();
    LaunchDarklyService.reset();
    navigate('/login');
  };

  const handleMenuClose = async () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };


  const handleSelectItem = useCallback(
    (type, val, close) => {
      switch (type) {
        case 'territory':
          const isSelectedTerr = !!currentTerritories.find(
            ({ id }) => id === val.id,
          );
          let terrData = [];

          if (isSelectedTerr) {
            if (currentTerritories.length > 1) {
              terrData = currentTerritories.filter(({ id }) => id !== val.id);
            } else {
              return;
            }
          } else {
            terrData = [...currentTerritories, val];
          }

          handleTerritoriesSelect(dispatch, terrData);
          return terrData;

        case 'office':
          const isSelectedOffice = !!currentOffices.find(
            ({ id }) => id === val.id,
          );

          let officeData = [];

          if (isSelectedOffice) {
            if (currentOffices.length > 1) {
              officeData = currentOffices.filter(({ id }) => id !== val.id);
            } else {
              return;
            }
          } else {
            officeData = [...currentOffices, val];
          }

          handleOfficesSelect(dispatch, officeData);

          const initialTerritories = territories.filter(({ office_id }) =>
            officeData.map(({ id }) => id).includes(office_id),
          );
          handleTerritoriesSelect(dispatch, initialTerritories);
          return [officeData, initialTerritories];

        case 'customer':
          handleCustomerSelect(dispatch, val);

          const initialOffices = offices.filter(
            ({ customer_id }) => customer_id === val.id,
          );
          handleOfficesSelect(dispatch, initialOffices);
          const initialOfficeIds = initialOffices.map(o => o.id);
          const initialTerrs = territories.filter(
            ({ office_id }) => initialOfficeIds.includes(office_id),
          );
          handleTerritoriesSelect(dispatch, initialTerrs);
          return [val, initialOffices, initialTerrs];

        default:
          break;
      }
      close && close();
    },
    [territories, offices, currentTerritories, currentOffices]
  );

  const menuItems = useMemo(() => {
    return [
      {
        title: currentCustomer?.name || 'Customer',
        options: customers.filter(({ id }) =>
          offices.find(({ customer_id }) => customer_id === id),
        ),
        type: 'customer',
        selected: currentCustomer,
        withSelection: Boolean(
          customers.filter(({ id }) =>
            offices.find(({ customer_id }) => customer_id === id),
          ).length > 1,
        ),
      },
      {
        title: currentOffices.length ? `${currentOffices[0].name}${
          currentOffices.length > 1 ? ` (+${currentOffices.length - 1})` : ''
        }` : 'Office',
        options: currentCustomer?.id ? offices.filter(
          ({ customer_id }) => customer_id === currentCustomer.id
        ) : [],
        type: 'office',
        selected: currentOffices.map(({ id }) => id),
        withSelection: currentCustomer?.id ? Boolean(
          offices.filter(
            ({ customer_id }) => customer_id === currentCustomer.id
          ).length > 1
        ) : false
      },
      {
        title: currentTerritories.length ? `${currentTerritories[0].name}${
          currentTerritories.length > 1 ? ` (+${currentTerritories.length - 1})` : ''
        }` : 'Territory',
        options: currentOffices.length ? currentOffices.length > 1 ? territories
          .filter(({ office_id }) =>
            currentOffices.map(({ id }) => id).includes(office_id)
          )
          .reduce((acc, terr) => {
            const currentOff = offices.find(
              ({ id }) => id === terr.office_id
            );
            if (!acc.find(([offName]) => offName === currentOff.name)) {
              acc.push([currentOff.name, [terr]]);
            } else {
              acc
                .find(
                  ([currentOffName]) =>
                    currentOffName === currentOff.name
                )[1]
                .push(terr);
              acc
                .find(
                  ([currentOffName]) =>
                    currentOffName === currentOff.name
                )[1]
                .sort((a, b) => sortByName(a, b, 'name'));
            }
            return acc;
          }, [])
          .sort((a, b) => sortByName(a, b, '0')) : territories
          .filter(({ office_id }) =>
            currentOffices.map(({ id }) => id).includes(office_id)
          )
          .sort((a, b) => sortByName(a, b, 'name')) : [],
        type: 'territory',
        isGrouped: currentOffices.length > 1,
        selected: currentTerritories.map(({ id }) => id),
        withSelection: currentOffices.length ? Boolean(
          territories.filter(({ office_id }) =>
            currentOffices.map(({ id }) => id).includes(office_id)
          ).length > 1
        ) : false
      }
    ];
  }, [
    offices,
    territories,
    customers,
    currentOffices,
    currentTerritories,
    currentCustomer,
  ]);

  // NOTE: this function is for navigation bar white lines to be handled to colorize the right ones when appropriate item is opened
  const handleNavEffect = useCallback((navItem, open) => {
    switch (navItem.type) {
      case 'customer':
        if (open) {
          document
            .getElementsByClassName('navigation-divider-first')[0]
            .classList.add('opened');
        } else {
          document
            .getElementsByClassName('navigation-divider-first')[0]
            .classList.remove('opened');
        }

        document
          .getElementsByClassName('navigation-divider-second')[0]
          .classList.remove('opened');
        break;
      case 'office':
        if (open) {
          document
            .getElementsByClassName('navigation-divider-first')[0]
            .classList.add('opened');
          document
            .getElementsByClassName('navigation-divider-second')[0]
            .classList.add('opened');
        } else {
          document
            .getElementsByClassName('navigation-divider-first')[0]
            .classList.remove('opened');
          document
            .getElementsByClassName('navigation-divider-second')[0]
            .classList.remove('opened');
        }
        break;
      case 'territory':
        if (open) {
          document
            .getElementsByClassName('navigation-divider-second')[0]
            .classList.add('opened');
        } else {
          document
            .getElementsByClassName('navigation-divider-second')[0]
            .classList.remove('opened');
        }

        document
          .getElementsByClassName('navigation-divider-first')[0]
          .classList.remove('opened');
        break;
      default:
        break;
    }
  }, []);

  const handleLogoClick = () => {
    navigate('/dashboard');
  };

  return (
    <NavigationView className="navigation-view absolute flex justify-between">
      <AppBar position="fixed" open={openDrawer}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => setOpenDrawer(prev => !prev)}
            edge="start"
            sx={{ marginRight: 1 }}
          >
            <MenuIcon />
          </IconButton>
          <Box component="img" onClick={handleLogoClick} src={LogoImg} alt="#" />

          <div className="navigation-bar">
            {menuItems
              .filter(({ type }) => type)
              .map((navItem) => (
                <Popover
                  className={`navigation-item ${navItem.type} relative`}
                  key={navItem.type}
                >
                  {({ open }) => (
                    <>
                      {navItem.type === 'customer' ? (
                        <>
                          <div className="navigation-divider-first absolute"></div>
                        </>
                      ) : navItem.type === 'territory' ? (
                        <>
                          <div className="navigation-divider-second absolute"></div>
                        </>
                      ) : (
                        ''
                      )}
                      {navItem.withSelection ? (
                        <>
                          <Popover.Button
                            className={`w-full h-full ${open ? 'selected' : ''}`}
                          >
                            <div
                              className={'item-container flex justify-between items-center font-bold cursor-pointer py-2 px-4 w-full'}
                            >
                              <div className="item-title flex items-center">
                                <div className="item-title-text">
                                  {navItem.title}
                                </div>

                                <ArrowIcon
                                  className={`${open ? 'rotate-270' : 'rotate-90'}`}
                                />
                              </div>
                            </div>
                          </Popover.Button>

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                          >
                            <Popover.Panel className="search-popup-container absolute z-10 max-w-sm px-4 mt-3 transform -right-2 sm:px-0 lg:max-w-3xl">
                              {({ close }) => (
                                <SearchPopupContent
                                  selected={navItem.selected}
                                  type={navItem.type}
                                  isGrouped={navItem.isGrouped}
                                  isMultiple={['office', 'territory'].includes(
                                    navItem.type,
                                  )}
                                  onSelect={(type, val) =>
                                    handleSelectItem(
                                      type,
                                      val,
                                      navItem.type === 'customer' ? close : null,
                                    )
                                  }
                                  options={navItem.options}
                                  customEffect={(open) => {
                                    handleNavEffect(navItem, open);
                                  }}
                                  customWatch={open}
                                  close={close}
                                />
                              )}
                            </Popover.Panel>
                          </Transition>
                        </>
                      ) : (
                        <button className={'w-full h-full cursor-default'}>
                          <div
                            className={'item-container flex justify-between items-center font-bold py-2 px-4 w-full'}
                          >
                            <div className="item-title flex items-center">
                              <div className="item-title-text">{navItem.title}</div>
                            </div>
                          </div>
                        </button>
                      )}
                    </>
                  )}
                </Popover>
              ))}
          </div>

          <Flex />
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <UserNotificationContainer>
              <UserNotification />
            </UserNotificationContainer>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <Avatar>
                {firstName.charAt(0).toUpperCase()}
              </Avatar>
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu(isMobileMenuOpen, mobileMoreAnchorEl, handleMobileMenuClose, handleProfileMenuOpen)}
      {renderMenu(anchorEl, isMenuOpen, handleMenuClose, handleSignOut)}
    </NavigationView>
  );
};

export default HeaderNavigation;
