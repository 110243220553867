import { useMemo } from 'react';

import CarelogItem from '../CarelogItem';

const CarelogsStage = ({
  groupedHighs,
  onUpdate,
  isEdit,
  isReportAutomated,
}) => {
  const isEmpty = useMemo(() => {
    return !groupedHighs.length;
  }, [groupedHighs]);

  return (
    <div className="carelogs-section">
      <div className={'highlights-list-container mt-5 flex flex-col relative'}>
        <div
          className={`highlights-list ${
            isEmpty ? 'empty' : ''
          } report-list w-full flex flex-col`}
        >
          {!isEmpty ? (
            groupedHighs.map((highlight) => (
              <CarelogItem
                highlight={highlight}
                key={`${highlight.id}_${highlight.heard}`} // workaround for PBAT-2175 to update heard after listening from Notification modal
                onUpdate={onUpdate}
                isEdit={isEdit}
                isReportAutomated={isReportAutomated}
              />
            ))
          ) : (
            <div className="empty-text mb-8 text-center">
              No notifications were found
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CarelogsStage;
