import React from 'react';

const ThumbUpIcon = ({ color }) => {
  return (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.33247 2.25L6.01387 6.75H2.3186V15.75H13.2414C14.0588 15.75
      14.772 15.1897 14.9441 14.4141L16.194 8.78906C16.4227
      7.74976 15.5849 6.75 14.4912 6.75H9.98084C10.0239 6.39844 10.0329
      6.04468 10.3069 5.23828C10.4835 4.71753 10.4337 4.23853 10.4337 3.9375C10.4337
      3.51782 10.2593 3.1311 9.96273 2.8125C9.66611 2.4939 9.22005 2.25 8.69475 2.25H8.33247ZM8.98457
      3.51562C9.0208 3.54199 9.07967 3.5332 9.11137 3.56836C9.22005 3.68481 9.2744 3.84522 9.2744
       3.9375C9.2744 4.30225 9.28572 4.64282 9.20194 4.88672C8.76947 6.15894 8.69475 7.27734 8.69475
       7.27734L8.65852 7.875H14.4912C14.8739 7.875 15.132 8.198 15.0528 8.56055L13.821
        14.1855C13.7622 14.4492 13.5176 14.625 13.2414 14.625H6.9558V7.45312L8.98457
        3.51562ZM3.4779 7.875H5.7965V14.625H3.4779V7.875ZM4.6372 12.9375C4.31794 12.9375
        4.05755 13.1902 4.05755 13.5C4.05755 13.8098 4.31794 14.0625 4.6372 14.0625C4.95646
        14.0625 5.21685 13.8098 5.21685 13.5C5.21685 13.1902 4.95646 12.9375 4.6372 12.9375Z"
      fill={color || '#A7A8B6'}/>
    </svg>
  );
};

export default ThumbUpIcon;