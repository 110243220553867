/* eslint-disable eqeqeq */
import { useCallback, useState, Fragment, useEffect } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { get } from 'lodash';

import { ArrowIcon } from '../../assets/icons';
import { sortByName } from '../../utilities/helpers';

const PopoverSelect = ({
  value = '',
  options = [],
  onChange = () => {},
  label = 'Select item',
  className = '',
  containerClassName = '',
  selectMenuClassName = '',
  name = '',
  withApiCallback = false,
  isSorted = true
}) => {
  const [selectedValue, setSelectedValue] = useState(value || '');

  useEffect(() => {
    value &&
      setSelectedValue(
        options.find(({ value: val }) => val == value) || {
          label: value,
          value
        }
      );
  }, [value, options]);

  const handleValueSelect = useCallback(
    (val) => {
      if (withApiCallback) {
        onChange({ name, value: val.value }).then(() => setSelectedValue(val));
      } else {
        setSelectedValue(val);
        name ? onChange({ name, value: val.value }) : onChange(val.value);
      }
    },
    [name, onChange, withApiCallback]
  );

  return (
    <Popover
      className={`${containerClassName} select-container w-full relative`}
    >
      {({ open, close }) => (
        <>
          <Popover.Button
            className={`
              relative flex justify-between items-center w-full px-3 text-left cursor-pointer 
              ${className}
            `}
          >
            {get(selectedValue, 'label', '') || label}
            <div className="select-icon ml-2">
              <ArrowIcon className={`${!open ? 'rotate-90' : 'rotate-270'}`} />
            </div>
          </Popover.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Popover.Panel
              className={`options-list
                  absolute left-0 w-full mt-3 z-10 overflow-auto
                  top-6 
                  ${selectMenuClassName}`}
            >
              {options.length ? (
                options
                  .sort((a, b) => (isSorted ? sortByName(a, b, 'label') : 1
                  ))
                  .map((item, i) => (
                    <div
                      key={`${item.label}${i}`}
                      className={`filter-list-item cursor-pointer ${
                        item.value === selectedValue.value ? 'selected' : ''
                      }  text-center select-none relative py-2`}
                      onClick={() => {
                        handleValueSelect(item);
                        close();
                      }}
                    >
                      <span className={'block truncate'}>{item.label}</span>
                    </div>
                  ))
              ) : (
                <div className="filter-empty-item  py-2 px-4 text-center text-[#e6e8f0]">
                  Empty
                </div>
              )}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default PopoverSelect;
