import { Button, Grid, LinearProgress, Stack, styled, Typography } from '@mui/material';
import Colors from '../../../../theme/Colors';

export const StyledTaskDetailsSection = styled(Grid)(({ theme }) => ({
  height: '100%',
  marginTop: theme.spacing(2),
}));

export const StyledStatusSection = styled(Grid)(({ background }) => ({
  flex: 0.05,
  display: 'flex',
  alignItems: 'center',
  background: background,
  flexDirection: 'row',
  justifyContent: 'space-between',
  borderRadius: 5,
  padding: '8px 15px'
}));


export const StyledTaskContentSection = styled(Grid)({
  flex: 0.95,
  marginTop: 7,
  background: '#F3F3F3',
  borderRadius: 5,
  padding: '21px 31px',
  flexDirection: 'column',
  display: 'flex',
  alignItems: 'center',
});


export const StyledNextButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2.25),
  height: 40,
  fontSize: 12,
  fontWeight: 700,
  background: Colors.sensiGreen,
  '&:hover': {
    background: Colors.sensiDarkGreen,
  }

}));


export const CheckboxContainer = styled(Grid)({
  display: 'flex',
  alignItems: 'center'
});

export const Label = styled(Typography)(({ style }) => ({
  color: Colors.sensiGrey,
  fontSize: 10,
  ...style
}));

export const Row = styled(Stack)(({ theme }) => ({
  marginTop: theme.spacing(2),
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  justifyContent:'space-between'
}));

export const StyledProgressContainer = styled(Grid)({
  '& > div > *': {
    margin: '17px 0'
  }
});

export const StyledLinearProgress = styled(LinearProgress)({
  marginTop: '19px !important',
  borderRadius: '30px',
  height: '8px',
  width: '90%',
  float: 'right',
  background: Colors.backgroundGrey,
  '& > span.MuiLinearProgress-bar': {
    borderRadius: '30px',
  }
});