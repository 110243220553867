import React from 'react';
import { Stack, Typography } from '@mui/material';
import { Container, StyledTextField, TitleText } from './TextArea.style';
import Colors from '../../../theme/Colors';

const TextArea = ({
  title,
  placeholder,
  maxCharacterLimit = Infinity, // Default value set to Infinity
  rows,
  width,
  height,
  titleStyle,
  containerStyle,
  text,
  setText,
  ...restProps
}) => {
  const handleChange = (event) => {
    const { value } = event.target;
    if (value.length <= maxCharacterLimit) {
      setText(value);
    }
  };

  return (
    <Container containerStyle={containerStyle}>
      {maxCharacterLimit !== Infinity
        && <Stack direction="row" justifyContent='space-between' marginBottom='4px'>
          <TitleText titleStyle={titleStyle}>{title}</TitleText>
          <Typography variant="subtitle2" color={Colors.sensiGrey} fontSize={10}>
            {text?.length}/{maxCharacterLimit}
          </Typography>
        </Stack>
      }
      <StyledTextField
        multiline
        rows={rows}
        value={text}
        onChange={handleChange}
        inputProps={{ maxLength: maxCharacterLimit }}
        placeholder={placeholder}
        variant="outlined"
        width={width}
        height={height}
        {...restProps}
      />
    </Container>
  );
};

export default TextArea;
