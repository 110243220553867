import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import MainSidebar from '../components/general/MainSidebar/MainSidebar';
import BlurredBackground from '../components/common/blurredBackground';
import TakeawaysProvider from '../contexts/takeaways';
import { useAudioContext } from '../contexts/audio';
import { selectUser } from '../redux/User';
import { EventService, MixpanelService } from '../services';
import EVENT_TYPES from '../services/EVENT_TYPES';
import { Box } from '@mui/material';
import HeaderNavigation from '../components/general/MainSidebar/HeaderNavigation/HeaderNavigation';
import { debounce } from 'lodash';
import SocketService from '../services/SocketService';
import LaunchDarklyService from '../services/LaunchDarklyService';

const DashboardRouter = () => {
  const { pathname } = useLocation();
  const { clearAudio } = useAudioContext();
  const user = useSelector(selectUser);
  const [openDrawer, setOpenDrawer] = useState(true);

  useEffect(() => {
    clearAudio();
  }, [pathname]);

  // useEffect(() => {
  //   user && SocketService.init();
  // }, []);

  useEffect(() => {
    document.title = 'Sensi.ai | Dashboard';

    const mixpanelVisitTimeout = setTimeout(() => {
      MixpanelService.register({ user });
      LaunchDarklyService.register({ user });
      window.Appcues.identify(user?.id, {
        firstSeen: new Date(user?.firstSeen),
      });
      hj('identify', user?.id, { email: user?.email });
      EventService.fireEvent(EVENT_TYPES.DASHBOARD_VISIT);
    }, 6000);


    window.addEventListener('beforeunload', () => {
      EventService.fireEvent(EVENT_TYPES.DASHBOARD_VISIT_END);
    });
    return () => clearTimeout(mixpanelVisitTimeout);
  }, []);


  useEffect(() => {
    const handleResize = debounce(() => {
      const isTabletOrMobile = window.innerWidth <= 900;
      if (isTabletOrMobile && openDrawer) {
        setOpenDrawer(false);
      } else if (!isTabletOrMobile && !openDrawer) {
        setOpenDrawer(true);
      }
    }, 300);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [openDrawer]);


  return (
    <TakeawaysProvider>
      <Box className='main-page-header flex justify-between relative'>
        <Box className='navigation-container flex relative'>
          <HeaderNavigation openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />
        </Box>
      </Box>
      <BlurredBackground className='general-container'>
        <MainSidebar open={openDrawer} setOpen={setOpenDrawer} />
        <Outlet />
      </BlurredBackground>
    </TakeawaysProvider>
  );
};

export default DashboardRouter;
