import React, { useCallback, useState, useEffect, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  FormControl,
  InputAdornment,
  IconButton,
  Checkbox,
  Button,
  FormControlLabel,
  Box,
  Typography,
  Tooltip,
  Grid,
} from '@mui/material';

import CircleLoader from '../../../../components/common/loaders/circleLoader';
import useForm from '../../../../hooks/form-state';
import { emailRegex, NEW_PASSWORD_REQUIRED, MFA_TYPE } from '../../../../utilities/const';
import {
  ForgotPasswordLabel,
} from './LoginForm.style';
import {
  StyledFormContainer,
  StyledInput,
  StyledInputLabel,
  StyledOutlinedInput,
  StyledOutlinedInputHelperText,
  StyledGeneralErrorTextContainer,
} from '../../defaultStyled/default.style';
import {
  CheckboxCheckedIcon,
  CheckboxUncheckedIcon,
  CloseIcon,
  VisibilityOffIcon,
  VisibilityOnIcon,
} from '../../../../assets/icons';
import English from '../../../../languages/English';
import Colors from '../../../../theme/Colors';
import { useDispatch } from 'react-redux';
import { setRemember30Days, signIn } from '../../../../redux/User';
import EVENT_TYPES from '../../../../services/EVENT_TYPES';
import EventService from '../../../../services/EventService';

const formTemplate = {
  email: '',
  password: ''
};

const LoginForm = ({ handleFormState, navigateLinkAfterLogin }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [counter, setCounter] = useState(0);
  const tooltipRef = useRef(null);

  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
    EventService.fireEvent(EVENT_TYPES.SHOW_PASSWORD, {
      value : showPassword
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const navigate = useNavigate();

  const handleFormSubmit = useCallback(
    async (data, isInvalid) => {
      try {
        if (!isInvalid) {
          setLoading(true);
          await dispatch(setRemember30Days(data?.remember30Days === undefined || data?.remember30Days === true));
          const user = await dispatch(signIn(data));
          if (user.error) {
            EventService.fireEvent(EVENT_TYPES.UNSUCCESSFUL_IDENTIFICATION, {
              error_message: user.error.message,
              email: data.email
            });
            throw user.error;
          }

          switch (user?.payload?.challengeName) {
            case NEW_PASSWORD_REQUIRED:
              handleFormState('newPassword');
              break;
            case MFA_TYPE:
              handleFormState('codeVerification');
              break;
            default:
              EventService.fireEvent(EVENT_TYPES.SUCCESSFUL_IDENTIFICATION);
              navigateLinkAfterLogin ? (navigateLinkAfterLogin === '/login' ? navigate('/dashboard') : navigate(navigateLinkAfterLogin)) : navigate('/dashboard');
              break;
          }
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setCounter(prevCount => prevCount + 1);
        if (error.code === 'NotAuthorizedException' || error.code === 'UserNotFoundException') {
          addErrors({
            email: English.emailMayBeWrong,
            password: English.passwordMayBeWrong
          });
        } else {
          addErrors({
            general: error.code === 'LimitExceededException' ? English.tooManyAttempts :  English.generalError
          });
        }
        EventService.fireEvent(EVENT_TYPES.LOGIN_FAILED, { message: English.emailOrPasswordMayBeWrong, email: data.email });
        setLoading(false);
        return;
      }
      setLoading(false);
    },
    [handleFormState, signIn, navigate]
  );

  const {
    data,
    errors,
    handleChange,
    handleValidate,
    handleSubmit,
    resetForm,
    addErrors,
    resetErrors
  } = useForm({
    validations: {
      email: {
        pattern: {
          value: emailRegex,
          message: English.pleaseEnterValidEmail,
        },
        required: {
          value: true,
          message: English.fieldRequired
        }
      },
      password: {
        required: {
          value: true,
          message: English.fieldRequired
        }
      }
    },
    onSubmit: (data) => handleFormSubmit(data, invalidForm),
    initialValues: formTemplate
  });

  const invalidForm = useMemo(() => {
    return !!Object.keys(errors).length || !data.email || !data.password;
  }, [errors, data]);

  const handleCheckInvalidResponse = useCallback(() => {
    if (
      errors.email === English.emailMayBeWrong ||
      errors.password === English.passwordMayBeWrong
    ) {
      resetErrors();
    }
  }, [errors, resetErrors]);

  useEffect(
    () => () => {
      resetForm();
      setLoading(false);
    },
    []
  );

  useEffect(() => {
    const handleMouseDown = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setCounter(prevCount => prevCount - 1);
      }
    };
    window.addEventListener('mousedown', handleMouseDown);
    return () => {
      window.removeEventListener('mousedown', handleMouseDown);
    };
  }, []);

  const handleClickOnForgotPassword = () => {
    EventService.fireEvent(EVENT_TYPES.FORGOT_PASSWORD);
    handleFormState('forgotPasswordFirst');
  };

  const handleCheckbox = (e) => {
    EventService.fireEvent(EVENT_TYPES.REMEMBER_ME, {
      value: e.target.checked
    });
    handleChange(e);
  };

  return (
    <StyledFormContainer onSubmit={handleSubmit}>
      <Box className="login-form-header">
        <Box component="h3">{English.signInTitle}</Box>
        <Box component="span">{English.signInSubtitle}</Box>
      </Box>

      <FormControl>
        <StyledInput
          sx={{ marginBottom: '1em', border: '1px' }}
          label={English.email}
          value={data.email}
          onChange={handleChange}
          onBlur={handleValidate}
          onSelect={handleChange}
          onFocus={handleCheckInvalidResponse}
          autoFocus
          name="email"
          id="email"
          disabled={loading}
          error={!!errors.email}
          helperText={errors.email}
        />
      </FormControl>
      <FormControl className="relative">
        <StyledInputLabel className={errors.password ? 'error' : ''} htmlFor="password">{English.password}</StyledInputLabel>
        <StyledOutlinedInput
          id="password"
          name="password"
          label={English.password}
          type={showPassword ? 'text' : 'password'}
          value={data.password}
          onChange={handleChange}
          onBlur={handleValidate}
          onSelect={handleChange}
          onFocus={handleCheckInvalidResponse}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOffIcon /> : <VisibilityOnIcon />}
              </IconButton>
            </InputAdornment>
          }
          error={!!errors.password}
          disabled={loading}
        />
        {
          !!errors.password && (
            <StyledOutlinedInputHelperText>
              {typeof errors.password === 'string' ? errors.password : errors.password?.map((msg) => <div key={Math.random(0, 1) * 1000}>{msg}</div>)}
            </StyledOutlinedInputHelperText>
          )
        }
      </FormControl>

      <Grid container alignSelf='flex-end' justifyContent='flex-end'>
        <Tooltip
          open={counter >= 2}
          PopperProps={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [80, 0]
                }
              }
            ]
          }}
          title={
            <Grid mb={0.5}>
              <Grid
                onClick={() => setCounter(prevCount => prevCount - 1)}
                alignSelf='flex-end'
                justifyContent='flex-end'
                mt={0.5}
                mb={0.5}
                mr={0.5}
                container
                flex
                ref={tooltipRef}
              >
                <CloseIcon color={Colors.white} />
              </Grid>
              <Typography variant='tooltip'>{English.signInTooltipText}</Typography>
            </Grid>
          }
          arrow
          placement='right'
        >
          <ForgotPasswordLabel onClick={() => handleClickOnForgotPassword()}>
            {English.forgotPassword}
          </ForgotPasswordLabel>
        </Tooltip>
      </Grid>

      <FormControlLabel
        variant="login-form-control-label"
        label={English.remember30Days}
        control={
          <Checkbox
            id="remember30Days"
            name="remember30Days"
            defaultChecked
            onChange={handleCheckbox}
            icon={<CheckboxUncheckedIcon />}
            checkedIcon={<CheckboxCheckedIcon />}
          />
        }
      />

      <Button disabled={invalidForm} className={loading ? 'loading' : ''} type="submit" variant="login-submit">
        {
          loading ? <CircleLoader className="sm" show={true} /> : English.signIn
        }
      </Button>
      <StyledGeneralErrorTextContainer>
        {typeof errors.general === 'string' ? errors.general : errors.general?.map((msg) => <div key={Math.random(0, 1) * 1000}>{msg}</div>)}
      </StyledGeneralErrorTextContainer>
    </StyledFormContainer>
  );
};

export default LoginForm;
