import React from 'react';
import Lottie from 'react-lottie';

const LottieComponent = ({ height, width, lottieSrc, loop = false, autoplay = true, containerStyle }) => {
  const defaultOptions = {
    loop: loop,
    autoplay: autoplay,
    animationData: lottieSrc,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div className={containerStyle}>
      <Lottie options={defaultOptions}
        height={height}
        width={width} />
    </div>
  );
};

export default LottieComponent;