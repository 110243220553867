import { createSelector } from '@reduxjs/toolkit';

const selectSlice = (state) => state.carelogsActions;

export const selectActions = createSelector(
  selectSlice,
  (state) => state.actions,
);

export const selectCarelogActions = ({ id, actionId }) =>
  createSelector(selectActions, (actions) =>
    (actions[id] || []).filter((item) => item.action_type_id === actionId),
  );
