import { useMemo, useCallback } from 'react';
import query from 'query-string';

import {
  DeviceBoxIcon,
  // DisplayDisableNotificationIcon,
  MessageNotificationIcon,
  ReportNotificationIcon,
  ThinCloseIcon,
  VerticalDivideIcon,
} from '../../../assets/icons';
import { useNavigateTo } from '../../../utilities/hooks';
import {
  NOTIFICATION_STATUS_DELETED,
  NOTIFICATION_STATUS_READ,
  NOTIFICATION_STATUS_UNREAD,
  AUTOMATIC_REPORT_NOTIFICATIONS,
  MANUAL_REPORT_NOTIFICATIONS,
  MANUAL_REPORT_ERROR_NOTIFICATIONS,
  DEVICES_STATUSES_NOTIFICATIONS,
  CARELOGS_ASSIGN_AND_STATUS_NOTIFICATIONS,
  CARELOGS_COMMENTS_NOTIFICATIONS,
  INSIGHTS_ASSIGN_AND_STATUS_NOTIFICATIONS,
  INSIGHTS_COMMENTS_NOTIFICATIONS, TODO_ASSIGNEE_NOTIFICATIONS,
} from '../../../utilities/const';
import agent from '../../../utilities/agent';
import dayjs from '../../../utilities/dayjs';
import { useCustomerState } from '../../../contexts/customers';
import { EventService } from '../../../services';
import EVENT_TYPES from '../../../services/EVENT_TYPES';
import NiceModal from '@ebay/nice-modal-react';
import EventsModal from '../../modals/EventsModal';

const NotificationItem = ({
  notification,
  handleNotificationUpdate,
  isReadOnly = false,
  toggle,
}) => {
  const navigate = useNavigateTo();

  const [{ handleNavigationUpdate }] = useCustomerState();
  const {
    id,
    message: { message_type, message, redirect_data },
    customers: { id: customer_id },
    offices: { id: office_id, name: office_name },
    territories: { id: territory_id, name: territory_name },
    notification_status_enum: { status },
    inserted_at,
    timezone,
  } = notification;
  const unit_id = notification?.units?.id;

  const NotificationIcon = useMemo(() => {
    switch (true) {
      case [
        ...AUTOMATIC_REPORT_NOTIFICATIONS,
        ...MANUAL_REPORT_NOTIFICATIONS,
        ...MANUAL_REPORT_ERROR_NOTIFICATIONS,
      ].includes(message_type):
        return ReportNotificationIcon;
      case CARELOGS_ASSIGN_AND_STATUS_NOTIFICATIONS.concat(
        INSIGHTS_ASSIGN_AND_STATUS_NOTIFICATIONS,
      ).includes(message_type):
        return ReportNotificationIcon;
      case DEVICES_STATUSES_NOTIFICATIONS.includes(message_type):
        return DeviceBoxIcon;
      // case message_type === 'display_off':
      //   return DisplayDisableNotificationIcon;
      case CARELOGS_COMMENTS_NOTIFICATIONS.concat(
        INSIGHTS_COMMENTS_NOTIFICATIONS,
      ).includes(message_type):
      default:
        return MessageNotificationIcon;
    }
  }, [message_type]);

  const handleNotificationDelete = useCallback(
    (e) => {
      e.stopPropagation();
      EventService.fireEvent(EVENT_TYPES.REMOVE_NOTIFICATION, {
        notification: notification?.message?.message,
      });

      agent
        .post(`/notifications/updateStatus/${id}`, {
          status: NOTIFICATION_STATUS_DELETED,
        })
        .then(() => {      
          notification.notification_status_enum.status =
            NOTIFICATION_STATUS_DELETED;
          handleNotificationUpdate(notification);
        });
    },
    [handleNotificationUpdate, status, notification, id],
  );

  const notificationClickHandler = useCallback(
    (e) => {
      e.stopPropagation();

      if (status === NOTIFICATION_STATUS_UNREAD) {
        agent
          .post(`/notifications/updateStatus/${id}`, {
            status: NOTIFICATION_STATUS_READ,
          })
          .then(() => {
            notification.notification_status_enum.status =
              NOTIFICATION_STATUS_READ;
            handleNotificationUpdate(notification);
          });
      }

      let redirectUrl = '';

      switch (true) {
        case DEVICES_STATUSES_NOTIFICATIONS.includes(message_type): {
          handleNavigationUpdate({
            customerId: customer_id,
            officeIds: [office_id],
            territoryIds: [territory_id],
          });
          const url = `/dashboard/client/${unit_id}`;
          const params = {
            tab: 0,
            customerId: customer_id,
            officesIds: office_id,
            territoryIds: territory_id,
          };
          redirectUrl = `${url}?${query.stringify(params)}`;
          navigate({
            url,
            params,
            omit: ['startDate', 'endDate', 'reportId', 'notifiedReportId'],
          });
          break;
        }
        case MANUAL_REPORT_NOTIFICATIONS.includes(message_type): {
          handleNavigationUpdate({
            customerId: customer_id,
            officeIds: [office_id],
            territoryIds: [territory_id],
          });
          const url = `/dashboard/client/${unit_id}`;
          const params = {
            tab: 1,
            notifiedReportId: redirect_data?.report_id,
            customerId: customer_id,
            officesIds: office_id,
            territoryIds: territory_id,
          };
          redirectUrl = `${url}?${query.stringify(params)}`;
          navigate({
            url,
            params,
            omit: ['startDate', 'endDate', 'reportId'],
          });
          break;
        }
        case CARELOGS_ASSIGN_AND_STATUS_NOTIFICATIONS.concat(
          INSIGHTS_ASSIGN_AND_STATUS_NOTIFICATIONS,
        ).includes(message_type):
        case CARELOGS_COMMENTS_NOTIFICATIONS.concat(
          INSIGHTS_COMMENTS_NOTIFICATIONS,
          TODO_ASSIGNEE_NOTIFICATIONS
        ).includes(message_type):
          Object.keys(redirect_data)?.length && NiceModal.show(EventsModal, { insight_id: redirect_data?.insight_id, care_log_id: redirect_data?.carelog_id });
          break;
        default:
          break;
      }
      EventService.fireEvent(EVENT_TYPES.CLICK_ON_NOTIFICATION, {
        redirectUrl,
        title: message
      });
      toggle();
    },
    [
      message_type,
      id,
      unit_id,
      notification,
      handleNotificationUpdate,
      handleNavigationUpdate,
      toggle,
      customer_id,
      office_id,
      territory_id,
    ],
  );

  return (
    <div
      className={`notification-item-container ${isReadOnly ? 'no-hover' : ''}`}
      onClick={(e) => !isReadOnly && notificationClickHandler(e)}
    >
      <div
        key={notification.id}
        className={
          'notification-item relative flex cursor-pointer last-of-type:mb-0'
        }
      >
        {!isReadOnly && (
          <div className="notification-icon-container flex items-center">
            <div
              className={`notification-icon ${message_type} flex items-center justify-center`}
            >
              <NotificationIcon />
            </div>
          </div>
        )}

        <div className="notification-message-container flex flex-col">
          <div className="notification-message flex w-full">
            <div className="notification-text">{message}</div>
          </div>
          <div className="notification-location flex w-full">
            <div className="notification-location-text flex items-center">
              <span>{office_name}</span>
              <VerticalDivideIcon className="text-divide-icon" />
              <span>{territory_name}</span>
            </div>
          </div>
          <div className="notification-time-label flex items-center">
            {dayjs(inserted_at).tz(timezone) <
            dayjs().subtract(21, 'hours').tz(timezone)
              ? dayjs(inserted_at).tz(timezone).format('YYYY MMM DD | h:mm A')
              : dayjs(inserted_at).fromNow()}
          </div>
        </div>
        {!isReadOnly && (
          <>
            <div className="notification-point-container flex items-center justify-center">
              {status !== NOTIFICATION_STATUS_READ ? (
                <div className="notification-point"></div>
              ) : (
                ''
              )}
            </div>
            <div
              onClick={handleNotificationDelete}
              className="notification-delete-icon absolute"
            >
              <ThinCloseIcon />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default NotificationItem;
