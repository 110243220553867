const awsmobile = {
  aws_project_region: process.env.REACT_APP_REGION,
  aws_cognito_region: process.env.REACT_APP_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_COGNITO_POOL_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID,
  aws_cognito_login_mechanisms: [
    'PREFERRED_USERNAME'
  ],
  aws_cognito_signup_attributes: [
    'EMAIL'
  ],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: [
    'SMS'
  ],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: []
  },
  aws_cognito_verification_mechanisms: [
    'EMAIL'
  ],
  Auth: {
    cookieStorage: {
      domain: window.location.hostname,
      secure: window.location.hostname !== 'localhost',
    },
  },
};


export default awsmobile;
