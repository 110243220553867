import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


const LinearProgressWithLabel = (props) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" sx={{
          '& .MuiLinearProgress-bar1Determinate': {
            backgroundColor: '#9a4cec'
          },
          borderRadius: 20,
          minHeight: 6
        }} {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
};

const ProgressBar = ({ show, value }) => {
  return (
    <>
      {
        show && <Box sx={{ width: '100%', marginTop: '10px' }}>
          <LinearProgressWithLabel value={value} />
        </Box>
      }
    </>
  );
};

export default ProgressBar;
