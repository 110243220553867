import React, { useEffect, useMemo, useState } from 'react';
import {
  CheckboxContainer,
  Label,
  Row,
  StyledNextButton,
  StyledStatusSection,
  StyledTaskContentSection,
  StyledTaskDetailsSection,
  StyledLinearProgress,
  StyledProgressContainer,
} from './ActionSection.style';
import { Typography, Grid } from '@mui/material';
import { Checkbox, CheckedIcon, UncheckedIcon } from '../TodoItem/TodoItem.style';
import English from '../../../../languages/English';
import AutocompleteDropdown from '../../../../components/common/AutoCompleteDropdown/AutoCompleteDropdown';
import Colors from '../../../../theme/Colors';
import TextArea from '../../../../components/common/TextArea';
import Toggle from '../../../../components/common/Toggle';
import { options } from '../EventView/EventView.utils';
import { updateActionTakenAPI, updateAssigneeAPI, updateStatusAPI } from '../../../../Api/dashboard.api';
import { selectIsRelatedEvent, updateEvent } from '../../../../redux/Seniors';
import { VIcon } from '../../../../components/icons';
import { DocIcon } from '../../../../assets/icons';
import { createTodo, deleteTodos, editTodo, selectTodos } from '../../../../redux/Todos';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { selectUser } from '../../../../redux/User';
import EventService from '../../../../services/EventService';
import { useCustomerState } from '../../../../contexts/customers';
import EVENT_TYPES from '../../../../services/EVENT_TYPES';
import { TASK_STATUS } from '../../../../utilities/const';
import { findItemByAssigneeId } from '../../dashboard.utils';

const ActionSection = ({ event, next, done, assignees, actionsTaken, eventsLength, index, relatedToInsight }) => {
  const dispatch = useDispatch();
  const [{ currentTerritories, currentCustomer, MixpanelUserData }] = useCustomerState();
  const todos = useSelector(selectTodos);
  const user = useSelector(selectUser);
  const [addToMyToList, setAddToMyToList] = useState(false);
  const isDone = event?.status === TASK_STATUS.DONE.status;
  const id = event?.is_carelog ? { care_log_id: event?.id } : { insight_id: event?.id };
  const page = 'dashboard';
  const isRelatedEvent = useSelector(selectIsRelatedEvent);

  const setEventPropertyAndDispatch = (propertyName, value) => {
    dispatch(updateEvent({
      filter: item => item.vid === event.vid,
      data: { [propertyName]: value }
    }));
  };

  // Common useEffect for setting values based on a specific property
  const useEffectForProperty = (propertyName, value, dependencies = []) => {
    useEffect(() => {
      setEventPropertyAndDispatch(propertyName, value);
    }, dependencies);
  };

  // setIsHelpful
  useEffectForProperty('is_helpful', event?.is_helpful ,[event?.is_helpful, event?.id]);
  // setAddToCareReport
  useEffectForProperty('include_in_report', event?.include_in_report,[event?.id]);
  // setDescription
  useEffectForProperty('action_taken_message',event?.action_taken_message || '', [event?.action_taken_message, event?.id]);
  // setAssignee
  useEffectForProperty('assignee_id',event?.assignee_id ? [...assignees, user].find((assignee) => +assignee.id === +event?.assignee_id || +assignee.id === +event?.assignee_id?.id) : null, [event?.assignee_id, event?.id, assignees]);
  // setActionTaken
  useEffectForProperty('action_taken', actionsTaken.find((actionTaken) => actionTaken.name === event?.action_taken || actionTaken.name === event?.action_taken?.name), [event?.action_taken, event?.id, actionsTaken]);
  // setStatus
  useEffectForProperty('status', event?.status, [event?.status, event?.id]);

  const todoStatus = useMemo(() => {
    if (isDone) {
      return {
        textColor: Colors.sensiGreen,
        text: `${English.status}: ${English.done}`,
        backgroundColor: Colors.sensiLightGreen,
        icon: <VIcon />
      };
    }

    return event?.assignee_id ? {
      textColor: Colors.sensiDarkPurple,
      text: `${English.status}: ${English.todo}`,
      backgroundColor: Colors.sensiLightPurple,
      icon: <DocIcon />
    } : null;
  }, [event?.status, event?.assignee_id, event?.id, todos]);


  const handleToggleChange = async (newAlignment) => {
    if (newAlignment !== null) {
      const eventType = event.is_carelog ? EVENT_TYPES.NOTIFICATION_MARK_HELPFUL : EVENT_TYPES.INSIGHT_MARK_HELPFUL;
      const eventData =   {
        ...MixpanelUserData,
        [event.is_carelog ? 'carelog_id' : 'insight_id']: id[event.is_carelog ? 'care_log_id' : 'insight_id'],
        label: event.title,
        category: event?.senior_category,
        unit_id: event?.unit_id,
        value: newAlignment.value ? 'yes' : 'no',
        page,
      };

      if (event.is_carelog) {
        eventData.is_related_event = isRelatedEvent;
      }

      EventService.fireEvent(eventType, eventData);
      const res = await updateStatusAPI(event?.is_carelog, event?.id, newAlignment.value);
      res && setEventPropertyAndDispatch('is_helpful', newAlignment.value);
    }
  };

  const handleTodo = async (e) => {
    await dispatch(createTodo({ ...id, name: `${English.review} ${event?.unit_name} ${event?.title}` }));
    EventService.fireEvent(EVENT_TYPES.ADD_TO_MY_TODO_LIST, { ...id });
    setAddToMyToList(e.target.checked);
    setEventPropertyAndDispatch('status', 1);
    const res = await updateAssigneeAPI(event?.is_carelog, event?.id, +user?.id || null);
    res && setEventPropertyAndDispatch('assignee_id', +user?.id);
  };

  const handleAssignee = async (_, value) => {
    const eventType = event.is_carelog ? EVENT_TYPES.NOTIFICATION_ASSIGN_USER : EVENT_TYPES.INSIGHT_ASSIGN_USER;
    const eventData = {
      ...MixpanelUserData,
      [event.is_carelog ? 'carelog_id' : 'insight_id']: id[event.is_carelog ? 'care_log_id' : 'insight_id'],
      label: event.title,
      category: event?.senior_category,
      unit_id: event?.unit_id,
      assignee: value?.username,
      page,
    };

    if (event.is_carelog) {
      eventData.is_related_event = isRelatedEvent;
    }

    EventService.fireEvent(eventType, eventData);
    const res = await updateAssigneeAPI(event?.is_carelog, event?.id, value?.id || null);

    res && setEventPropertyAndDispatch('assignee_id', value?.id);
    const todo = todos.find(todo => +todo.insight_id === event?.id || +todo.care_log_id === event?.id);
    if (res && !todo && +value?.id) { // means assign to someone else, and we don't have tasked yet
      //create task to my partner
      dispatch(createTodo({ ...id, user_id: value?.id, name: `${English.review} ${event?.unit_name} ${event?.title}` }));
    } else if (res && todo && +value?.id === +user.id) { // means assign to me
      // create a task and assign it to me
      await dispatch(editTodo(todo.id));
    } else if (res && todo && value?.id === undefined) {
      //remove it from me and not assign to other
      await dispatch(deleteTodos({ ...(event.is_carelog ? { care_log_id: +event?.id } : { insight_id: +event?.id }) }));
      setAddToMyToList(false);
    } else { // means remove the task from me and assign to other
      const territoryItem = findItemByAssigneeId(currentTerritories, +value?.id);
      dispatch(
        editTodo({
          id: todo.id,
          payload: {
            assignee_id: +value?.id,
            territory_id: territoryItem?.id,
            office_id: territoryItem?.office_id,
            timezone: territoryItem?.tz,
            customer_id: currentCustomer?.id,
          },
        }),
      );
    }
  };

  const handleChooseActionTaken = async (_, value) => {
    const res = await updateActionTakenAPI({
      isCarlog: event?.is_carelog,
      eventId: event?.id,
      actionTaken: value?.id || null
    });
    const eventType = event.is_carelog ? EVENT_TYPES.NOTIFICATION_ADD_ACTION_TAKEN : EVENT_TYPES.INSIGHT_ADD_ACTION_TAKEN;
    const eventData = {
      ...MixpanelUserData,
      action_type: value?.name,
      category: event?.senior_category,
      unit_id: event?.unit_id,
      label: event?.title,
      [event.is_carelog ? 'carelog_id' : 'insight_id']: id[event.is_carelog ? 'care_log_id' : 'insight_id'],
      page,
    };

    if (event.is_carelog) {
      eventData.is_related_event = isRelatedEvent;
    }

    EventService.fireEvent(eventType, eventData);
    res && setEventPropertyAndDispatch('action_taken', value?.name);
  };

  const updateActionTakenMessage = debounce(async (value) => {
    await updateActionTakenAPI({
      isCarlog: event?.is_carelog,
      eventId: event?.id,
      actionTakenMessage: value
    });
  }, 1000);

  const handleSetActionTakenMessage = (value) => {
    setEventPropertyAndDispatch('action_taken_message', value);
    updateActionTakenMessage(value);
  };

  const handleAddToCareReport = async (_, value) => {
    EventService.fireEvent(
      event.is_carelog ? EVENT_TYPES.NOTIFICATION_ADD_TO_FAMILY_REPORT : EVENT_TYPES.INSIGHT_ADD_TO_FAMILY_REPORT,
      {
        ...MixpanelUserData,
        [event.is_carelog ? 'carelog_id' : 'insight_id']: id[event.is_carelog ? 'care_log_id' : 'insight_id'],
        label: event.title,
        category: event?.senior_category,
        unit_id: event?.unit_id,
        value,
        page,
        is_related_event: false
      }
    );
    const res = await updateActionTakenAPI({
      isCarlog: event?.is_carelog,
      eventId: event?.id,
      addToFamilyReport: value
    });
    res && setEventPropertyAndDispatch('include_in_report', value);
  };
  const eventsLeft = eventsLength - (index + 1);
  return (
    <StyledTaskDetailsSection container direction='column' wrap='nowrap'>
      {/* Status section */}
      {todoStatus && <StyledStatusSection item background={todoStatus.backgroundColor}>
        <Typography variant='h4' color={todoStatus.textColor} fontSize={10}>{todoStatus.text}</Typography>
        {todoStatus.icon}
      </StyledStatusSection>}
      {/* Action section */}
      <StyledTaskContentSection item>
        <CheckboxContainer container>
          <Checkbox
            disabled={(user.type === 'admin' || (todoStatus && !isDone))}
            checked={addToMyToList || (todoStatus && !isDone)}
            onChange={handleTodo}
            icon={<UncheckedIcon checked={addToMyToList} />}
            checkedIcon={<CheckedIcon checked={addToMyToList} />}
            disableRipple
          />
          <Label style={{ marginLeft: 8 }} variant='h4'>{English.addToDo}</Label>
        </CheckboxContainer>
        <AutocompleteDropdown
          disabled={((event?.assignee_id !== null) && (+event?.assignee_id?.id !== +user?.id)) || isDone}
          titleStyle={{ color: Colors.sensiGrey, fontSize: 10 }}
          containerStyle={{ width: 215, marginTop: 18 }}
          listStyle={{ maxHeight: 200, fontSize: 10 }}
          textStyle={{ fontSize: 10, height: 'auto', padding: '0 !important', lineHeight: '22px' }}
          options={assignees.filter(item => item.id !== +user?.id)}
          label={English?.chooseAssignee}
          title={English.assignee}
          onChange={handleAssignee}
          value={event?.assignee_id}
        />
        <AutocompleteDropdown
          titleStyle={{ color: Colors.sensiGrey, fontSize: 10 }}
          containerStyle={{ width: 215, marginTop: 18 }}
          listStyle={{ maxHeight: 200, fontSize: 10 }}
          textStyle={{ fontSize: 10 }}
          options={actionsTaken}
          label={English?.chooseActionTaken}
          title={English.actionTakenTitle}
          onChange={handleChooseActionTaken}
          value={event?.action_taken}
        />
        <TextArea
          containerStyle={{ marginTop: 13 }}
          text={event?.action_taken_message}
          setText={handleSetActionTakenMessage}
          title="Description"
          titleStyle={{ color: Colors.sensiGrey, fontSize: 10 }}
          placeholder="Describe your actions here"
          maxCharacterLimit={200}
          rows={3}
          color="primary"
          fullWidth
        />
        <CheckboxContainer container marginTop={'18px'}>
          <Checkbox
            checked={event?.include_in_report}
            onChange={handleAddToCareReport}
            icon={<UncheckedIcon checked={event?.include_in_report}/>}
            checkedIcon={<CheckedIcon checked={event?.include_in_report}/>}
            disableRipple
          />
          <Label style={{ marginLeft: 8 }} variant='h4'>{English.addToCareReport}</Label>
        </CheckboxContainer>
        <Row>
          <Label variant='h4'>{English.wasHelpful}</Label>
          <Toggle options={options} onChange={handleToggleChange} alignment={event?.is_helpful} />
        </Row>
      </StyledTaskContentSection>

      <StyledNextButton variant='login-submit' onClick={next}>
        {done ? English.done : English.nextEvent}
      </StyledNextButton>
      {!relatedToInsight && <StyledProgressContainer container spacing={0}>
        <Grid item xs={3}>
          <Typography marginTop={3} color={Colors.sensiGrey} fontSize={10} variant='h4'> {eventsLeft ? `${eventsLeft} ${English.moreToGo}` : `${index + 1} ${English.outOf} ${eventsLength}`} </Typography>
        </Grid>
        <Grid item xs={9}>
          <StyledLinearProgress variant='determinate' value={((index + 1) / eventsLength) * 100} />
        </Grid>
      </StyledProgressContainer>}
    </StyledTaskDetailsSection>
  );
};

export default ActionSection;