import React, { useCallback, useRef } from 'react';
import { SkeletonContainer, Form, IconButton, Input } from './TodoInput.style';

import { CircularProgress, Skeleton } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import { useDispatch, useSelector } from 'react-redux';
import { createTodo, loadTodos } from '../../../../redux/Todos';
import EventService from '../../../../services/EventService';

import EVENT_TYPES from '../../../../services/EVENT_TYPES';

const TodoInput = () => {
  const dispatch = useDispatch();
  const loading = useSelector(loadTodos.isLoading);
  const creating = useSelector(createTodo.isLoading);
  const input = useRef(null);

  const handleSubmit = useCallback((evt) => {
    evt.preventDefault();
    const name = input.current.value;

    if (!name.length) {
      input.current.focus();
    } else {
      EventService.fireEvent(EVENT_TYPES.ADD_TODO_TASK);
      dispatch(createTodo({ name }))
        .unwrap()
        .then(() => {
          if (input.current) {
            input.current.value = '';
            input.current.blur();
          }
        })
        .catch(() => {});
    }
  }, []);

  return loading ? (
    <SkeletonContainer gap="5px" container>
      <Skeleton variant="rounded" width={24} height={24} />
      <Skeleton variant="rounded" width={220} height={24} />
    </SkeletonContainer>
  ) : (
    <Form onSubmit={handleSubmit}>
      <IconButton size="small" type="submit" disabled={creating}>
        {creating ? (
          <CircularProgress size={24} />
        ) : (
          <AddCircleIcon color="primary" />
        )}
      </IconButton>
      <Input
        ref={input}
        name="title"
        placeholder="Click here to add a new task..."
      />
    </Form>
  );
};

export default TodoInput;
