/* eslint-disable max-len */
import React from 'react';

const DeviceIcon = ({
  color = '#555770',
  background = '#E4E4E4',
  ...props
}) => {
  return (
    <svg width={36} height={36} viewBox="0 0 36 36" fill="none" {...props}>
      <circle cx="18" cy="18" r="18" fill={background} />
      <path
        d="M25.321 22.0269V13.7188L18.0085 9.5647L10.696 13.7188V22.0269L18.0085 26.1838L25.321 22.0269Z"
        fill={background}
      />
      <path
        d="M17.9812 17.707V26.0067H17.9924L10.6884 21.8583V13.5586L17.9924 17.707"
        fill={background}
      />
      <path
        d="M18.0113 17.707V26.0067H18L25.3041 21.8583V13.5586L18 17.707"
        fill={background}
      />
      <path
        d="M10.4063 22.5163L17.7188 26.6704C17.8043 26.7197 17.9013 26.7457 18 26.7457C18.0987 26.7457 18.1957 26.7197 18.2812 26.6704L25.5937 22.5163C25.6796 22.4667 25.7508 22.3954 25.8002 22.3094C25.8496 22.2235 25.8754 22.126 25.875 22.0269V13.7188C25.8754 13.6197 25.8496 13.5222 25.8002 13.4363C25.7508 13.3503 25.6796 13.279 25.5937 13.2294L18.2812 9.07536C18.1957 9.02599 18.0987 9 18 9C17.9013 9 17.8043 9.02599 17.7188 9.07536L10.4063 13.2294C10.3204 13.279 10.2492 13.3503 10.1998 13.4363C10.1504 13.5222 10.1246 13.6197 10.125 13.7188V22.0269C10.1246 22.126 10.1504 22.2235 10.1998 22.3094C10.2492 22.3954 10.3204 22.4667 10.4063 22.5163ZM11.25 14.6835L17.4375 18.1935V25.2107L11.25 21.7007V14.6835ZM18.5625 25.2219V18.2075L24.75 14.6835V21.7007L18.5625 25.2219ZM18 10.2088L24.1875 13.7188L18 17.226L11.8125 13.7188L18 10.2088Z"
        fill={color}
      />
    </svg>
  );
};

export default DeviceIcon;
