import React, { useState } from 'react';
import './Input.styles.scss';
import English from '../../../languages/English';

const Input = ({ value, setValue, name, id, onBlur }) => {
  const [focused, setFocused] = useState(false);

  return (
    <div className={`inputContainer flex flex-col self-center ${focused ? 'focus' : ''}`}>
      <span className="caregiverName">{English.CareManagerName}</span>
      <input
        onChange={(e) => setValue(e.target.value)}
        onSelect={() => {}}
        value={value}
        onFocus={() => setFocused(true)}
        onBlur={(event) => {
          setFocused(false);
          onBlur(event);
        }}
        name={name}
        id={id}
        placeholder={English.caregiverNamePlaceholder}
        autoComplete="off"
      />
    </div>
  );
};

export default Input;