import { useEffect, useState } from 'react';
import agent from '../utilities/agent';

const useFetchTakeawayEnum = () => {
  const [takeawayTypes, setTakeawayTypes] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: response } = await agent.get(
          '/familyreport/takeawayEnum',
        );
        setTakeawayTypes(response?.data);
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  return {
    takeawayTypes,
    loading,
  };
};

export default useFetchTakeawayEnum;
