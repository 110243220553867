import { useCallback, useEffect, useMemo, useState } from 'react';
import cs from 'classnames';

import { useAudioContext } from '../contexts/audio';
import agent from '../utilities/agent';
import dayjs from '../utilities/dayjs';
import CircleLoader from './common/loaders/circleLoader';
import { NoMusicIcon, PlayIcon, PauseIcon, CheckLinearIcon } from '../assets/icons';
import { useCustomerState } from '../contexts/customers';
import { CARE_LOG_ACTIONS_TYPES } from '../utilities/const';

const AudioPlayer = ({
  className,
  highlight,
  onUpdate,
  src,
  id = '',
  prefix = '',
  MixPanelTrackFunc
}) => {
  const { audioState, saveAudio, handlePlayPause, clearAudio } =
    useAudioContext();
  const [{ MixpanelUserData }] = useCustomerState();

  const [loading, setLoading] = useState(false);
  const [, setAudioTime] = useState(null);
  const [paused, setPaused] = useState(true);
  const [seekPressed, setSeekPressed] = useState(false);
  const [ended, setEnded] = useState(!!highlight.heard);
  const [wasPlayed, setWasPlayed] = useState(!!highlight.heard);

  const currentAudio = useMemo(() => {
    return audioState[`${prefix}${id}${src}`];
  }, [audioState, id, src, prefix]);

  const handleTimeUpdate = useCallback(
    (evt) => {
      setAudioTime(evt.currentTarget.currentTime);
    },
    []
  );

  const handleEnded = useCallback(
    () => {
      handlePlayPause(`${prefix}${id}${src}`);
      setPaused(true);
      setEnded(true);
      setWasPlayed(true);
    },
    [id, src, prefix]
  );

  const pausePlayHandler = useCallback(
    (audio) => {
      handlePlayPause(`${prefix}${id}${src}`);
      setPaused(audio.paused);
      if (!audio.paused) {
        MixPanelTrackFunc &&
          MixPanelTrackFunc({
            carelog_id: id,
            ...MixpanelUserData,
            label: highlight.title || highlight.labels,
          });
      }
    },
    [
      audioState,
      handlePlayPause,
      id,
      src,
      prefix,
      currentAudio,
      MixPanelTrackFunc,
      MixpanelUserData
    ]
  );

  useEffect(() => {
    return () => {
      clearAudio(`${prefix}${id}${src}`);
      setAudioTime(null);
      setLoading(false);
    };
  }, []);

  const handlePlayButtonClick = useCallback(async () => {
    if (highlight && !highlight?.heard) {
      agent.post(`/carelogAction/${highlight.id}`, { actionTypeId: CARE_LOG_ACTIONS_TYPES.HEARD }).then(() => {
        highlight.heard = dayjs();
        onUpdate(highlight, highlight.id);
      });
    }

    if (!currentAudio) {
      handleLoadAudio();
    } else {
      pausePlayHandler(currentAudio);
    }

    if (ended) {
      setEnded(false);
    }
  }, [
    currentAudio,
    id,
    audioState,
    highlight,
    onUpdate,
    pausePlayHandler,
    ended,
    setEnded,
    MixPanelTrackFunc,
    MixpanelUserData
  ]);

  const handleLoadAudio = useCallback(() => {
    setLoading(true);

    const fileName =
      src.includes('.ogg') || src.includes('mp3') ? src.replace('.ogg', '.mp3') : src.concat('.mp3');

    return agent
      .get(`/carelogs/audio/${id}/${fileName}`, {
        'Content-Type': 'audio/mpeg',
        responseType: 'blob',
      })
      .then(({ data }) => {
        saveAudio(
          `${prefix}${id}${src}`,
          URL.createObjectURL(new Blob([data], { type: 'audio/mpeg' })),
          {
            ontimeupdate: handleTimeUpdate,
            onended: handleEnded,
            onloadeddata: (evt) => {
              const audio = evt.currentTarget;
              audio.play();
              pausePlayHandler(audio);
            }
          }
        );
      })
      .finally(() => setLoading(false));
  }, [audioState, id, src, prefix]);

  const handleSeekClick = (e) => {
    const container = e.target
      .closest('.player-seeker-container')
      .getBoundingClientRect();
    const clickedPos = (e.clientX - container.left) / container.width; // get the cliked position in percentage value
    currentAudio.currentTime = currentAudio.duration * clickedPos; // set the currentTime for audio according to clicked percentage
    setAudioTime(currentAudio.currentTime);
  };

  const onSeekerPress = () => {
    setSeekPressed(true);
  };

  const onSeekerUp = () => {
    setSeekPressed(false);
    if (paused) pausePlayHandler(currentAudio);
  };

  const onSeekerMove = (e) => {
    if (seekPressed) {
      handleSeekClick(e);
      if (!paused) pausePlayHandler(currentAudio);
    }
  };

  // const onSeekerLeave = (e) => {           // NOTE: THIS FUNCTION MAY BE A SOLUTION TO FUTURE REQUEST
  //   if (seekPressed) {
  //     setSeekPressed(false);
  //     if (paused) pausePlayHandler();
  //     handleSeekClick(e);
  //   }
  // };

  return (
    <div
      className={cs('player-container flex w-full relative items-center',
        className,
        wasPlayed && 'heard',
        !src && 'disabled')}
    >
      {src ? (
        <>
          <div
            className="player-play-pause flex items-center justify-center cursor-pointer"
            onClick={handlePlayButtonClick}
          >
            {currentAudio ? (
              paused ? (
                <PlayIcon />
              ) : (
                <PauseIcon />
              )
            ) : (
              <PlayIcon />
            )}
          </div>
          {ended && highlight.heard ? (
            <div className="player-played-container relative flex justify-between items-center">
              <div className="player-played-indicator flex items-center">
                <CheckLinearIcon color='#0FA200' />
                <p>Played</p>
              </div>
              <p className="player-played-date">
                {dayjs(highlight.heard).format('MMM D')}
              </p>
            </div>
          ) : (
            <div
              className="player-seeker-container relative flex items-center ml-6"
              onClick={(e) => currentAudio && handleSeekClick(e)}
              onMouseDown={(e) => currentAudio && onSeekerPress(e)}
              onMouseUp={(e) => currentAudio && onSeekerUp(e)}
              onMouseMove={(e) => currentAudio && onSeekerMove(e)}
            // onMouseLeave={onSeekerLeave}
            >
              <div className="player-seeker-total w-full cursor-pointer relative">
                <div
                  className="player-seeker-passed absolute cursor-pointer"
                  style={{
                    width: `${
                      currentAudio ? (currentAudio.currentTime * 100) / currentAudio.duration : 0
                    }%`
                  }}
                ></div>
              </div>
              <div
                className="player-seeker-pointer absolute cursor-pointer"
                style={{
                  left: `calc(${
                    currentAudio ? (currentAudio.currentTime * 100) / currentAudio.duration : 0
                  }% - 3px)`
                }}
              ></div>
            </div>
          )}
        </>
      ) : (
        <>
          <div className="player-play-pause flex items-center justify-center cursor-default">
            <NoMusicIcon />
          </div>
          <div className="player-seeker-container relative flex items-center ml-4">
            <div className="player-seeker-total w-full"></div>
          </div>
        </>
      )}
      <CircleLoader className="sm round-border" show={loading} />
    </div>
  );
};

export default AudioPlayer;
