import { Autocomplete, Box, styled, TextField, Typography } from '@mui/material';

export const Container = styled(Box)(({ containerStyle }) => ({
  marginBottom: '7px',
  ...containerStyle
}));

export const TitleContainer = styled(Box)({
  fontSize: '13px',
  marginBottom: '4px',
  fontWeight: 700,
});

export const StyledAutocomplete = styled(Autocomplete)({
  '& .MuiAutocomplete-input': {
    height: 7
  }
});

export const TitleText = styled(Typography)(({ titleStyle }) => ({
  fontSize: '13px',
  fontWeight: 700,
  ...titleStyle
}));

export const StyledTextField = styled(TextField)(({ textStyle }) => ({
  '& .MuiInputBase-input': {
    fontSize: '13px',
    fontFamily: 'OpenSans-Custom',
    ...textStyle
  },
}));
