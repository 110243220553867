import { createSlice } from '@reduxjs/toolkit';

import { fetchCarelogsActions } from './thunk';

const initialState = {
  actions: {},
};

const CarelogsActionsSlice = createSlice({
  name: 'carelogsActions',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(
      fetchCarelogsActions.fulfilled,
      (state, { meta, payload = [] }) => {
        const { id } = meta.arg;
        const current = state.actions[id] || [];
        const toAdd = payload.filter(
          (newAction) =>
            !current.find(
              (oldAction) =>
                newAction.ts === oldAction.ts &&
                oldAction.action_type_id === newAction.action_type_id,
            ),
        );
        state.actions[id] = [...current, ...toAdd];
      },
    );
  },
});

export default CarelogsActionsSlice;
