import Colors from '../../../../theme/Colors';
import { Box, styled } from '@mui/material';

const style = {
  cancelDiv: {
    width: '315px',
    height: '53px',
    display: 'flex',
    justifyContent: 'center',
  },
  textArea: {
    width: '315px',
    height: '234px',
    backgroundColor: Colors.white,
  },
  descriptionDiv: {
    marginBottom: '22px',
  },
  createTakeawayDiv: {
    padding: '25px',
  },
  title: {
    fontWeight: 700
  }
};

const ExclamationMarkWrapper = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  height: '16px',
  width: '16px',
  borderRadius: '50%',
  backgroundColor: theme.palette.sensiGrey.main,
}));

export {
  style,
  ExclamationMarkWrapper
};
