import { combineReducers } from '@reduxjs/toolkit';
import meta from './meta/reducer';
import UserSlice from './User/UserSlice';
import ConfigSlice from './Config/ConfigSlice';
import SeniorsSlice from './Seniors/SeniorsSlice';
import TodosSlice from './Todos/TodosSlice';
import DevicesSlice from './Devices/DevicesSlice';
import EnumsSlice from './Enums/EnumsSlice';
import CommentsSlice from './Comments/CommentsSlice';
import CarelogsActions from './CarelogsActions/slice';

const rootReducer = combineReducers({
  meta,
  user: UserSlice,
  config: ConfigSlice,
  seniors: SeniorsSlice,
  todos: TodosSlice,
  devices: DevicesSlice,
  enums: EnumsSlice,
  comments: CommentsSlice,
  [CarelogsActions.name]: CarelogsActions.reducer,
});

export default rootReducer;
