import React, { useEffect, useState } from 'react';
import { Dialog } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getEventsBySenior, selectEvents, setIsRelatedEvent, updateEvents } from '../../../redux/Seniors';
import { useCustomerState } from '../../../contexts/customers';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import EventView from '../../../views/dashboard/components/EventView';
import { getActionsTakenLabels } from '../../../redux/Enums';
import { selectActionsTakenLabels } from '../../../redux/Enums/EnumsSelectors';
import { useAssignees } from '../../../utilities/hooks';
import { EventService } from '../../../services';
import EVENT_TYPES from '../../../services/EVENT_TYPES';
import { debounce } from 'lodash';
import { selectConfig } from '../../../redux/Config';

const EventsModal = NiceModal.create(({ i, unitId, insight_id, care_log_id, ts, cta, relatedToInsight }) => {
  const modal = useModal();
  const [{ currentTerritories, MixpanelUserData  }] = useCustomerState();
  const dispatch = useDispatch();
  const events = useSelector(selectEvents);
  const config = useSelector(selectConfig);
  const actionsTaken = useSelector(selectActionsTakenLabels);
  const [index, setIndex] = useState(i || 0);
  const getAssignees = useAssignees(currentTerritories);
  const event = events[index];
  const idObject = !event ? {} : { [event.is_carelog ? 'carelog_id' : 'insight_id']: event.id };

  useEffect(() => {
    if (currentTerritories && currentTerritories.length > 0) {
      dispatch(getEventsBySenior({
        relatedToInsight,
        insightId: insight_id,
        carelogId: care_log_id,
        unitId,
        ts,
      }));
      dispatch(getActionsTakenLabels());
    }
  }, [currentTerritories, unitId, insight_id, care_log_id, ts]);

  useEffect(() => {
    if (cta && MixpanelUserData?.territories?.length) {
      EventService.fireEvent(EVENT_TYPES.CTA, {
        ...MixpanelUserData,
        insight_id,
        carelog_id: care_log_id,
        label: events?.[0]?.title,
        category: events?.[0]?.senior_category,
        unit_id: events?.[0]?.unit_id,
      });
    }
  }, []);

  const eventOpened = debounce(() => {
    (event?.is_carelog ? EventService.fireEvent(EVENT_TYPES.CARELOG_POPUP) : EventService.fireEvent(EVENT_TYPES.INSIGHT_POPUP));
  }, 100);

  useEffect(() => {
    event && eventOpened();
  }, [event?.id]);

  const handleNext = () => {
    EventService.fireEvent(EVENT_TYPES.GO_TO_NEXT_NOTIFICATION, {
      ...MixpanelUserData,
      ...idObject,
      category: event?.senior_category,
      unit_id: event?.unit_id,
      is_last_notification: index === events?.length - 2
    });
    if (relatedToInsight || (index === events?.length - 1)) {
      handleClose();
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  };

  const handleBack = () => {
    EventService.fireEvent(EVENT_TYPES.GO_TO_PREVIOUS_NOTIFICATION, {
      ...MixpanelUserData,
      ...idObject,
    });
    if (relatedToInsight) {
      dispatch(updateEvents());
      return NiceModal.show(EventsModal,{ unitId: events[0].unit_id, ts: config.time });
    } else if (index === 0) {
      handleClose();
    } else {
      setIndex(prevIndex => prevIndex - 1);
    }
  };

  const handleClose = () => {
    modal.hide();
    setTimeout(() => setIndex(0), 600);
    EventService.fireEvent(EVENT_TYPES.CLOSE_NOTIFICATION_WINDOW, {
      ...MixpanelUserData,
      ...idObject,
      notifications_left: events.length - (index + 1)
    });
    dispatch(setIsRelatedEvent(false));
  };

  return (
    <Dialog
      maxWidth={false}
      open={modal.visible}
      onClose={handleClose}
    >
      <EventView
        eventsLength={events?.length}
        close={handleClose}
        index={index}
        id={insight_id || care_log_id}
        next={handleNext}
        getAssignees={getAssignees}
        actionsTaken={actionsTaken}
        done={relatedToInsight || (index === events?.length - 1)}
        back={(relatedToInsight || ((index !== 0) && events?.length > 1)) && handleBack}
        relatedToInsight={relatedToInsight}
      />
    </Dialog>
  );
});

export default EventsModal;
