import { createAsyncThunk } from '@reduxjs/toolkit';

import agent from '../../utilities/agent';

const getEndpoint = (is_carelog) => (is_carelog ? '/comments' : '/insightsComments');
export const loadComments = createAsyncThunk(
  'dashboard/loadComments',
  async ({ is_carelog, item_id }, { rejectWithValue }) => {
    try {
      const response = await agent.get(`${getEndpoint(is_carelog)}/${item_id}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(
        { message: err?.response?.data || 'Error while loading comments' },
        { toast: true },
      );
    }
  },
);

export const createComment = createAsyncThunk(
  'dashboard/createComment',
  async ({ is_carelog, item_id, comment, tags }, { rejectWithValue }) => {
    try {
      const response = await agent.post(`${getEndpoint(is_carelog)}/${item_id}`, {
        comment,
        tags
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(
        { message: err?.response?.data || 'Error while creating comment' },
        { toast: true },
      );
    }
  },
);

export const editComment = createAsyncThunk(
  'dashboard/editComment',
  async ({ is_carelog, item_id, id, comment = null, tags }) => {
    const response = await agent.patch(`${getEndpoint(is_carelog)}/${item_id}/${id}`, { comment, tags });
    return response.data;
  },
);
