import { styled, Paper, Typography, Box } from '@mui/material';

import Colors from '../../../../theme/Colors';

export const Container = styled(Box)(({ theme }) => ({
  flexGrow: 0,
  margin: 0,
  padding: 0,
}));

export const NameTypography = styled(Typography)({
  color: Colors.black,
  fontWeight: 700,
  fontSize: 10,
  lineHeight: 'normal',
});

export const DateTypography = styled(Typography)({
  color: Colors.sensiGrey,
  fontSize: 10,
  lineHeight: 'normal',
});
