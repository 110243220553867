import { styled, Paper, Typography, Box } from '@mui/material';

import Colors from '../../../../theme/Colors';

export const Wrapper = styled(Paper)(() => ({
  position: 'absolute',
  display: 'none',
  padding: '21px 23px',
  zIndex: 100,
  backgroundColor: 'white',
  boxShadow: '0px 2px 10px 0px rgba(0,0,0,0.25)',
  border: '1px solid #E9E9E9',
  borderRadius: 5,
  width: 265,
  marginTop: 12,
}));

export const TitleWrapper = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  marginBottom: 14,
  marginLeft: -5,
});

export const Title = styled(Typography)({
  color: Colors.sensiGrey,
  fontWeight: 700,
  fontSize: 12,
  marginLeft: 5,
});

export const TooltipContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontSize: 12,
  fontWeight: 400,
  width: '100%',
  marginLeft: -5,
  color: Colors.sensiGrey,
});
