import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { debounce } from 'lodash';

import AudioPlayer from '../../../components/audioPlayer';
import agent from '../../../utilities/agent';
import dayjs from '../../../utilities/dayjs';
import {
  ArrowIcon,
  CalendarIcon,
  EditIcon,
  HighlightBulbIcon,
  TimeIcon,
  WarningIconEmptyColor
} from '../../../assets/icons';
import CheckBox from '../../../components/common/checkbox';
import CircleLoader from '../../../components/common/loaders/circleLoader';
import { getUtcDateTime } from '../../../utilities/dates';
import PopoverSelect from '../../../components/common/popoverSelect';
import { useCustomerState } from '../../../contexts/customers';
import { parseInsightText } from '../../../utilities/helpers';
import { EventService } from '../../../services';
import EVENT_TYPES from '../../../services/EVENT_TYPES';

const InsightItem = ({ highlight, onUpdate, isEdit, isReportAutomated, reportId }) => {
  const { id } = useParams();
  const [{ MixpanelUserData, currentClient, currentTerritories, actionTakenEnums }] =
    useCustomerState();

  const [action, setAction] = useState('');
  const [message, setMessage] = useState('');
  const [insightOpened, setInsightOpened] = useState(false);
  const [loadingChildCarelogs, setLoadingChildCarelogs] = useState(false);
  const [childEvents, setChildEvents] = useState([]);
  const [isEditData, setIsEditData] = useState(false);
  const [insightData, setInsightData] = useState({
    recommendation: highlight.recommendation || '',
    indication: highlight.indication || '',
    description: highlight.description || ''
  });
  const page = 'clients report';
  const assignees = useMemo(() => {
    return currentTerritories
      .reduce((acc, { assignees = [] }) => acc.concat(assignees), [])
      .reduce((acc, item) => {
        if (!acc.find(({ id }) => item.id === id)) acc.push(item);
        return acc;
      }, []);
  }, [currentTerritories]);

  useEffect(() => {
    setAction(highlight?.action_taken_enum?.name || null);
    setMessage(highlight?.action_taken_message || '');
  }, [highlight.action_taken_enum?.name, highlight?.action_taken_message]);

  const applyInput = useCallback(
    async (actionTaken, actionTakenMessage) => {
      const currentActionTaken = actionTakenEnums.find(
        ({ name }) => name === actionTaken,
      );
      if (isEdit) { // update the report in edit mode
        const res = await agent.post(`/insights/actionTaken/${reportId}/${highlight.id}`, {
          actionTaken: currentActionTaken?.id,
          actionTakenMessage,
        });
        if (res && res.status === 200) {
          EventService.fireEvent(EVENT_TYPES.INSIGHT_ADD_ACTION_TAKEN, {
            ...MixpanelUserData,
            insight_id: highlight.id,
            label: highlight.labels,
            action_type: actionTaken,
            action_message: actionTakenMessage,
            category: highlight.senior_category,
            unitId: highlight.unit_id,
            unitName: highlight.unit_name,
            client_id: id,
            loc_id: currentClient?.loc_id
          });
          highlight.action_taken_enum = currentActionTaken;
          highlight.action_taken_message = actionTakenMessage;
          onUpdate(highlight);
        }
      }
      const res = await agent.post(`/insights/addToFamilyReport/${highlight.id}`, {
        actionTaken: currentActionTaken?.id,
        actionTakenMessage,
      });
      if (res && res.status === 200) {
        EventService.fireEvent(EVENT_TYPES.INSIGHT_ADD_ACTION_TAKEN,{
          ...MixpanelUserData,
          insight_id: highlight.id,
          label: highlight.labels,
          action_type: actionTaken,
          action_message: actionTakenMessage,
          category: highlight.senior_category,
          unit_id: highlight.unit_id,
          client_id: id,
          loc_id: currentClient?.loc_id,
        });
        highlight.action_taken_enum = currentActionTaken;
        highlight.action_taken_message = actionTakenMessage;
        onUpdate(highlight);
      }
    },
    [
      isEdit,
      highlight,
      MixpanelUserData,
      onUpdate,
      id,
      currentClient,
      actionTakenEnums,
    ],
  );

  const inputDebounce = useCallback(debounce(applyInput, 1500), []);

  const handleHighlightAdded = useCallback(async () => {
    if (isEdit) { // update the report in edit mode
      const resUpdateReport = await agent.post(`/insights/addToFamilyReport/${reportId}/${highlight.id}`, {
        addToFamilyReport: !highlight.include_in_report,
      });
      if (resUpdateReport && resUpdateReport.status === 200) {
        EventService.fireEvent(EVENT_TYPES.INSIGHT_ADD_TO_FAMILY_REPORT,{
          ...MixpanelUserData,
          insight_id: highlight.id,
          label: highlight.labels || highlight.title,
          value: !highlight.include_in_report,
          category: highlight.senior_category,
          unit_id: highlight.unit_id,
          client_id: id,
          loc_id: currentClient?.loc_id,
          is_automated: isReportAutomated,
          page
        });
        highlight.include_in_report = !highlight.include_in_report;
        onUpdate(highlight);
      }
    } else {  // update the insights in create mode
      const resUpdateInsight = await agent.post(`/insights/addToFamilyReport/${highlight.id}`, {
        addToFamilyReport: !highlight.include_in_report,
      });
      if (resUpdateInsight && resUpdateInsight.status === 200) {
        EventService.fireEvent(EVENT_TYPES.INSIGHT_ADD_TO_FAMILY_REPORT,{
          ...MixpanelUserData,
          insight_id: highlight.id,
          label: highlight.labels || highlight.title,
          value: !highlight.include_in_report,
          category: highlight.senior_category,
          unitId: highlight.unit_id,
          client_id: id,
          loc_id: currentClient?.loc_id,
          is_automated: isReportAutomated,
          page
        });
        highlight.include_in_report = !highlight.include_in_report;
        onUpdate(highlight);
      }
    }

  }, [highlight, isEdit, onUpdate, id, currentClient]);

  const highlightHeader = useMemo(() => {
    return {
      title: highlight.labels || '',
      subtitle: '',
      icon: HighlightBulbIcon
    };
  }, [highlight]);

  const handleInsightsOpen = useCallback(() => {
    setInsightOpened(!insightOpened);
    if (!insightOpened) {
      EventService.fireEvent(EVENT_TYPES.INSIGHT_OPEN_RELATED_EVENTS, {
        ...MixpanelUserData,
        label: highlight.labels || highlight.title,
        category: highlight.senior_category,
        unit_id: highlight.unit_id,
        client_id: id,
        loc_id: currentClient?.loc_id
      });
      setLoadingChildCarelogs(true);
      agent.get(`/insightsCarelogs/${highlight.id}?assignees=${assignees.map(item => item.id).join(',')}`)
        .then(({ data: { data: childCarelogs } }) => {
          highlight.highlights = childCarelogs;
          onUpdate(highlight);
          setChildEvents(
            childCarelogs
              .map(({ care_logs }) => ({
                ...care_logs
              }))
              .map((item) => ({
                ...item,
                ts: getUtcDateTime(item.ts)
              }))
              .sort((a, b) => dayjs(b.ts) - dayjs(a.ts)),
          );
        })
        .finally(() => setLoadingChildCarelogs(false));
    } else {
      setChildEvents([]);
    }
  }, [
    insightOpened,
    highlight.id,
    MixpanelUserData,
    id,
    currentClient,
    assignees
  ]);

  const renderChildrenEvent = useCallback(
    (item) => (
      <div key={`id=${item.id}`} className="highlight-content-body ">
        <div className="highlight-content-header w-full flex justify-between items-center">
          <span className="highlight-text">
            <>
              <strong>{item.labels}</strong>{' '}
              {/* <span className="divider">|</span> Descrpition (HC) */}
            </>
          </span>
          <div className="insight-time-label flex items-center">
            <CalendarIcon className="mx-2" />
            {`${dayjs(item.ts).format('MMMM DD, YYYY')}`}
            <TimeIcon className="mx-2" />
            <span style={{ minWidth: 52, textAlign: 'end' }}>
              {dayjs(item.ts).format('h:mm A')}
            </span>
          </div>
        </div>
        <div className="highlight-content-data flex w-full">
          <div className="highlight-data-item flex-1 mr-4">
            <span>Audio</span>
            <AudioPlayer
              className={item.heard ? 'heard' : ''}
              src={item.file_name}
              id={item.id}
              highlight={item}
              MixPanelTrackFunc={(props) =>
                EventService.fireEvent(EVENT_TYPES.INSIGHT_NOTIFICATION_HEARD,{
                  ...props,
                  insight_id: highlight.id,
                  client_id: id,
                  loc_id: currentClient?.loc_id,
                  page
                })
              }
              onUpdate={(carelog) => onUpdate(null, carelog)}
            />
          </div>
          <div className="highlight-data-item flex-1">
            <span>Status</span>
            <div className="flex items-center">{item.status}</div>
          </div>
        </div>
      </div>
    ),
    [onUpdate, id, currentClient]
  );

  const renderInputData = useCallback(
    (title) => {
      return (
        <div className="flex w-full">
          <textarea
            className="insight-data-textarea w-full"
            onChange={(e) => {
              const updatedData = {
                ...insightData,
                [title.toLowerCase()]: e.target.value
              };
              setInsightData(updatedData);
              highlight = Object.assign(highlight, { ...updatedData });
              onUpdate(highlight);
            }}
            value={insightData[title.toLowerCase()]}
          ></textarea>
        </div>
      );
    },
    [insightData, highlight, onUpdate]
  );

  const handleInsightDataSave = useCallback(() => {
    setIsEditData(!isEditData);
    if (isEdit) { // when we in edit mode save the insight in the report
      agent.patch(`/insights/editInsight/${reportId}/${highlight.id}`, {
        ...insightData,
      });
    } else {
      EventService.fireEvent(EVENT_TYPES.EDIT_INSIGHT_IN_FAMILY_REPORT, {
        ...MixpanelUserData,
        client_id: id,
        loc_id: currentClient?.loc_id
      });
    }
  }, [
    isEdit,
    isEditData,
    insightData,
    highlight,
    MixpanelUserData,
    id,
    currentClient
  ]);

  return (
    <div
      className={'highlight-label highlight-done report-label  w-full flex flex-col relative'}
    >
      <div className="highlight-header relative">
        <div className="icon-container flex items-center justify-center absolute">
          <highlightHeader.icon />
        </div>
        <div className={'label-time-and-type flex items-center'}>
          <div className="highlight-title flex justify-between w-full">
            <div>
              <span className="main-title">{highlightHeader.title}</span>
              {highlightHeader.subtitle ? (
                <>
                  <span className="title-divider">|</span>
                  <span className="sub-title">{highlightHeader.subtitle}</span>
                </>
              ) : (
                ''
              )}
            </div>
            <div className="flex items-center">
              <div className="insight-time-label flex items-center mx-2">
                <CalendarIcon className="mr-2" />
                {highlight.date_label}
              </div>
              <div className="insight-time-label flex items-center">
                <TimeIcon className="mr-2" />
                {dayjs(highlight.ts).format('h:mm A')}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="highlight-content-container w-full flex">
        <div className={'highlight-content w-full relative flex'}>
          <div
            className={'highlight-content-body flex flex-col w-full px-4 py-4'}
          >
            <div className="highlight-content-data flex flex-col w-full">
              {
                isEditData && (
                  <div className="flex justify-start items-center">
                    <div className="insight-edit-alert-icon">
                      <WarningIconEmptyColor />
                    </div>
                    <div className="warning-description"><span className="grey">Note</span> The description you edit will be saved only for the report</div>
                  </div>
                )
              }
              <div className="highlight-data-row flex">
                <div className="highlight-data-col flex flex-col flex-4">
                  {[
                    // {
                    //   title: 'Description',
                    //   text: insightData.description || '',
                    //   visible: isEditData,
                    // },
                    {
                      title: 'Trend Analysis',
                      text: highlight.trend || '',
                      visible: !!highlight.trend
                    },
                    {
                      title: 'Recommendation',
                      text: insightData.recommendation || '',
                      visible: isEditData
                    },
                    {
                      title: 'Indication',
                      text: insightData.indication || '',
                      visible: isEditData
                    }
                  ]
                    .filter(
                      ({ text, visible }) => visible || (text || '').length
                    )
                    .map(({ title, text }, i) => (
                      <div
                        key={`${title}${i}`}
                        className="highlight-data-item !mx-0 no-width-limit"
                      >
                        <span>{title}</span>
                        {isEditData && (title !== 'Trend Analysis') ? renderInputData(title) : parseInsightText({ text })}
                      </div>
                    ))}

                  <div className="highlight-data-row flex flex-row w-full">
                    <div className="highlight-data-col flex flex-1">
                      <div
                        className="highlight-data-item flex-1"
                        style={{
                          width: 160,
                          flex: 'unset'
                        }}
                      >
                        <span>Related events</span>
                        <div
                          className={`related-insights-label flex items-center justify-between cursor-pointer ${
                            insightOpened ? 'opened' : ''
                          }`}
                          onClick={handleInsightsOpen}
                        >
                          Insight Events ({highlight.related_events_count || 0})
                          <ArrowIcon
                            className={`${
                              insightOpened ? 'rotate-270' : 'rotate-90'
                            }`}
                          />
                        </div>
                      </div>

                      <div className="highlight-data-row flex flex-3">
                        <div className="highlight-data-col flex flex-col w-1/2">
                          <div className="highlight-data-item">
                            <span>Were there any actions taken?</span>
                            <PopoverSelect
                              containerClassName={'mt-4'}
                              value={action}
                              options={actionTakenEnums.map(({ name }) => ({
                                label: name,
                                value: name,
                              }))}
                              onChange={(v) => {
                                setAction(v);
                                applyInput(v, message);
                              }}
                              label={'Choose an action'}
                            />
                          </div>
                        </div>

                        <div className="highlight-data-col flex flex-col w-1/2">
                          <div className="highlight-data-item">
                            <span>Notes</span>

                            <div className="input-container relative !py-0">
                              <input
                                id="resolve-input"
                                className="w-full !py-0 !m-0"
                                placeholder="Or type anything..."
                                value={message}
                                onChange={(e) => {
                                  setMessage(e.target.value);
                                  inputDebounce(action, e.target.value);
                                }}
                                autoComplete="off"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {insightOpened ? (
                    <div
                      className={`children-events-container flex flex-col w-full mt-4 relative ${
                        loadingChildCarelogs ? 'loading' : ''
                      }`}
                    >
                      {childEvents.length ? (
                        childEvents.map(renderChildrenEvent)
                      ) : !loadingChildCarelogs ? (
                        <div className="no-events w-full h-full flex items-center justify-center">
                          No related events found
                        </div>
                      ) : (
                        ''
                      )}
                      <CircleLoader show={loadingChildCarelogs} />
                    </div>
                  ) : (
                    ''
                  )}
                </div>

                <div className="highlight-data-col flex flex-col justify-between items-end flex-1">
                  <div
                    className={`insight-edit-button-container ${
                      isEditData ? 'editting' : ''
                    } flex items-center justify-center w-full cursor-pointer`}
                    onClick={handleInsightDataSave}
                  >
                    {isEditData ? (
                      <>Save</>
                    ) : (
                      <>
                        <EditIcon />
                        Edit
                      </>
                    )}
                  </div>

                  <div className="highlight-review-button flex items-end">
                    <div className={'flex items-center'}>
                      <CheckBox
                        value={highlight.include_in_report}
                        onChange={handleHighlightAdded}
                        label={
                          highlight.include_in_report ? 'Added to Report' : 'Include to Report'
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InsightItem;
