
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Lottie from 'react-lottie';
import NiceModal from '@ebay/nice-modal-react';

import {
  Container,
  Row,
  Checkbox,
  UncheckedIcon,
  CheckedIcon,
  Title,
  ActionButton,
  Progress,
  AnimationContainer,
} from './TodoItem.style';

import EventsModal from '../../../../components/modals/EventsModal';
import { SelectInput } from './components';
import { useQueryObject } from '../../../../utilities/hooks';

import VisibilityIcon from '@mui/icons-material/Visibility';
import animations from '../../../../assets/lottie';

import { editTodo, deleteTodo } from '../../../../redux/Todos';
import { selectUser } from '../../../../redux/User';
import EventService from '../../../../services/EventService';
import { useCustomerState } from '../../../../contexts/customers';

import English from '../../../../languages/English';
import EVENT_TYPES from '../../../../services/EVENT_TYPES';
import { updateEvent } from '../../../../redux/Seniors';
import { findItemByAssigneeId } from '../../dashboard.utils';
import { TASK_STATUS } from '../../../../utilities/const';

const TodoItem = ({
  index,
  id,
  name,
  assignee_id,
  assignees,
  care_log_id,
  insight_id,
}) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [assignee, setAssignee] = useState(assignee_id || '');
  const [isDone, setIsDone] = useState(false);
  const [progress, setProgress] = useState(0);
  const [removeAnimation, setRemoveAnimation] = useState(false);
  const [{ currentTerritories }] = useCustomerState();
  const { customerId } = useQueryObject();

  const handleAssign = (assigneeId) => {
    if (assigneeId === assignee_id) return;
    setAssignee(assigneeId);
    const territoryItem = findItemByAssigneeId(currentTerritories, assigneeId);
    dispatch(
      editTodo({
        id,
        payload: {
          assignee_id: assigneeId,
          territory_id: territoryItem?.id,
          office_id: territoryItem?.office_id,
          timezone: territoryItem?.tz,
          customer_id: +customerId,
        },
      }),
    )
      .unwrap()
      .then(() => {
        (care_log_id || insight_id) && dispatch(updateEvent({
          filter: item => (item.is_carelog ? item.id === +care_log_id : +item.id === +insight_id),
          data: { assignee_id: assigneeId }
        }));
      })
      .catch(() => {
        setAssignee(assignee_id);
      });
    const assignee = assignees.find((item) => item.id === assigneeId);
    EventService.fireEvent(EVENT_TYPES.ASSIGN_TODO_TASK, {
      assignee: assignee.username,
    });
  };

  const handleIsDone = (evt) => {
    const checked = evt.target.checked;
    if (!checked || removeAnimation) return;

    setIsDone(true);
    dispatch(
      editTodo({
        id,
        onProgress: (evt) => {
          const percent = Math.floor((evt.loaded / evt.total) * 100).toFixed();
          setProgress(+percent);
        },
        payload: { is_done: true },
      }),
    )
      .unwrap()
      .then(() => {
        setRemoveAnimation(true);
        dispatch(updateEvent({
          filter: item => (item.is_carelog ? item.id === +care_log_id : +item.id === +insight_id),
          data: { ['status']: TASK_STATUS.DONE.status }
        }));
      })
      .catch(() => {
        setProgress(0);
        setIsDone(false);
      });

    EventService.fireEvent(EVENT_TYPES.MARK_TASK_AS_DONE);
  };

  const handleReview = () => {
    return NiceModal.show(EventsModal, { insight_id, care_log_id });
  };

  const lottie = useMemo(
    () => ({
      options: {
        loop: false,
        autoplay: false,
        animationData: animations.done,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      },
      events: [
        {
          eventName: 'complete',
          callback: () => {
            dispatch(deleteTodo(id));
          },
        },
      ],
    }),
    [],
  );

  return (
    <Container index={index}>
      <Progress value={progress} variant="determinate" />
      <Row>
        <Checkbox
          checked={isDone}
          onChange={handleIsDone}
          icon={<UncheckedIcon checked={isDone} />}
          checkedIcon={<CheckedIcon checked={isDone} />}
          disableRipple
        />
        <Title variant="body2">{name}</Title>
      </Row>
      <AnimationContainer>
        <Lottie
          options={lottie.options}
          eventListeners={lottie.events}
          isStopped={!removeAnimation}
          isPaused={false}
        />
      </AnimationContainer>
      <Row>
        {(care_log_id || insight_id) && (
          <ActionButton
            onClick={handleReview}
            startIcon={<VisibilityIcon />}
            background="#d3eaff"
            variant="contained"
          >
            {English.review}
          </ActionButton>
        )}
        <SelectInput
          name="assignee"
          label="Assignee"
          defaultValue=""
          value={assignee === +user.id ? '' : assignee}
          onChange={handleAssign}
          options={assignees}
        />
      </Row>
    </Container>
  );
};

export default TodoItem;
