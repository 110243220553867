/* eslint-disable no-loop-func */
import { useCallback, useState } from 'react';

export const useForm = (options) => {
  const [data, setData] = useState(options?.initialValues || {});
  const [errors, setErrors] = useState({});

  const resetForm = useCallback(() => {
    setData(options?.initialValues || {});
  }, [options?.initialValues]);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      const validations = options?.validations;
      if (validations) {
        let valid = true;
        const newErrors = {};
        for (const key in validations) {
          const value = data[key];
          const validation = validations[key];

          if (validation?.required?.value && !value) {
            valid = false;
            newErrors[key] = [validation?.required?.message];
            continue;
          }

          const pattern = validation?.pattern;
          if (pattern?.value && !RegExp(pattern.value).test(value)) {
            valid = false;
            newErrors[key] = pattern.message;
            continue;
          }

          const patterns = validation?.patterns;
          if (patterns?.length) {
            const localErrors = [];
            patterns.forEach((patt) => {
              if (patt?.value && !RegExp(patt.value).test(value)) {
                valid = false;
                localErrors.push(patt.message);
              }
            });
            newErrors[key] = localErrors.length ? localErrors : '';
            continue;
          }

          const custom = validation?.custom;
          if (custom?.value && !custom.value(value)) {
            newErrors[key] = custom.message;
            valid = false;
            continue;
          }
        }

        if (!valid) {
          return;
        }
      }

      setErrors({});

      if (options?.onSubmit) {
        options.onSubmit(data);
      }
    },
    [data, options]
  );

  const handleValidate = useCallback(
    (e) => {
      const validations = options?.validations;

      if (validations) {
        const newErrors = {};

        const value = e.target.value;
        const validation = validations[e.target.name];

        if (validation?.required?.value && !value) {
          newErrors[e.target.name] = [validation?.required?.message];
          setErrors({ ...errors, ...newErrors });
          return;
        }

        const pattern = validation?.pattern;
        if (pattern?.value && !RegExp(pattern.value).test(value)) {
          newErrors[e.target.name] = pattern.message;
          setErrors({ ...errors, ...newErrors });
          return;
        }

        const patterns = validation?.patterns;
        if (patterns?.length) {
          const localErrors = [];
          patterns.forEach((patt) => {
            if (patt?.value && !RegExp(patt.value).test(value)) {
              localErrors.push(patt.message);
            }
          });

          if (localErrors.length) {
            newErrors[e.target.name] = localErrors.length ? localErrors : '';
            setErrors({ ...errors, ...newErrors });
            return;
          }
        }

        const custom = validation?.custom;
        if (custom?.value && !custom.value(value)) {
          newErrors[e.target.name] = custom.message;
          setErrors({ ...errors, ...newErrors });
          return;
        }

        delete errors[e.target.name];

        setErrors({ ...errors });
      }
    },
    [errors, options?.validations]
  );

  const handleChange = useCallback(
    (e) => {
      setData({
        ...data,
        [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
      });

      errors[e.target.name] && handleValidate(e);
    },
    [data, errors, handleValidate]
  );

  const resetErrors = useCallback(() => {
    setErrors({});
  }, []);

  const addErrors = useCallback(
    (data) => {
      setErrors({
        ...errors,
        ...data
      });
    },
    [errors]
  );

  return {
    data,
    errors,
    handleChange,
    handleSubmit,
    handleValidate,
    resetForm,
    resetErrors,
    addErrors
  };
};

export default useForm;
