import React from 'react';
import { Typography } from '@mui/material';

import English from '../../../../languages/English';
import { EventTrend, Wrapper } from './EventAccordion.style';
import { Comment, LookingGlass, Star, TrendIcon } from '../../../../assets/icons';
import { Comments } from '../Comments';
import Recommendations from '../Recommendations';
import EventAccordionItem from '../EventAccordionItem';
import Copy from '../Copy';
import RelatedEvents from '../RelatedEvents/RelatedEvents';

import EventService from '../../../../services/EventService';
import EVENT_TYPES from '../../../../services/EVENT_TYPES';

const EventAccordion = ({ event, assignees }) => {
  const trackInteraction = (eventType) => (expanded) => {
    EventService.fireEvent(eventType, {
      insight_id: event.id,
      action: Number(expanded)
    });
  };

  return (
    <Wrapper variant='container'>
      {event?.trend && !event?.is_carelog &&
        <EventAccordionItem
          summary={<> <TrendIcon /> <Typography variant="body3" color="inherit" component="span">{English.trendAnalysis}</Typography> </> }
          onChange={trackInteraction(EVENT_TYPES.TOGGLE_INSIGHT_TREND_ANALYSIS_BAR)}
          showCount={false}
        >
          <EventTrend>{event?.trend}</EventTrend>
        </EventAccordionItem>}
      {!event?.is_carelog && <>
        <EventAccordionItem
          summary={
            <>
              <LookingGlass />
              {English.recommendations}
              <Copy
                text={`${event?.title}\n\n${event?.recommendation}\n\n${event?.indication}`}
                fireEvent={() => EventService.fireEvent(EVENT_TYPES.INSIGHT_COPY_RECOMMENDATIONS, { label: event.title, office: event.office_name, territory: event.territory_name, category: event.senior_category })}
                style={{ position: 'absolute', right: 40, top: 25 }}
              />
            </>}
          onChange={trackInteraction(EVENT_TYPES.TOGGLE_INSIGHT_RECOMMENDATIONS_BAR)}
        >
          <Recommendations recommendation={event?.recommendation} />
        </EventAccordionItem>
        <EventAccordionItem
          summary={<> <Star /> {English.relatedEvents} </>}
          onChange={trackInteraction(EVENT_TYPES.TOGGLE_INSIGHT_RELATED_EVENTS_BAR)}
        >
          <RelatedEvents insight_id={event?.id} />
        </EventAccordionItem>
      </>}
      <EventAccordionItem summary={<> <Comment /> {English.comments} </>}>
        <Comments key={`comments_${event?.id}`} title={event.title} is_carelog={event?.is_carelog} item_id={event?.id} assignees={assignees} />
      </EventAccordionItem>
    </Wrapper>
  );
};

export default EventAccordion;
