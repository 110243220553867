import React from 'react';

import { Skeleton, Paper, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const ItemSkeleton = () => {
  const theme = useTheme();

  return (
    <Paper
      variant="container"
      sx={{
        width: '100%',
        height: 110,
        padding: '20px',
        border: `1px solid ${theme.palette.border.main}`,
      }}
    >
      <Grid
        alignItems="center"
        gap="4px"
        wrap="nowrap"
        sx={{ marginBottom: '20px' }}
        container
      >
        <Skeleton variant="rounded" width={18} height={19} />
        <Skeleton variant="rounded" width={146} height={20} />
      </Grid>
      <Grid justifyContent="space-between" wrap="nowrap" container>
        <Skeleton variant="rounded" width={142} height={30} />
        <Skeleton
          variant="rounded"
          width={29}
          height={30}
          sx={{ borderRadius: '12px' }}
        />
      </Grid>
    </Paper>
  );
};

const CATEGORIES_AMOUNT = 4;

const SeniorCategorySkeleton = () => {
  let i = 0;
  const content = [];

  while (i < CATEGORIES_AMOUNT) {
    content.push(
      <Grid key={i} sm={12} md={6} lg={3} item>
        <ItemSkeleton />
      </Grid>,
    );
    i++;
  }

  return content;
};

export default SeniorCategorySkeleton;
