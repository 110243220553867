import { styled } from '@mui/system';
import { Paper, Stack, Box, Typography, Grid } from '@mui/material';
import colors from '../../../../theme/Colors';

export const StyledContainer = styled(Paper)(
  ({ bgcolor = 'inherit', borderColor }) => ({
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    color: 'inherit !important',
    background: bgcolor,
    border: `1px solid ${borderColor}`,
    padding: '9px',
    margin: '6px',
  }),
);

export const StyledTitleContainer = styled(Box)({
  color: colors.skyBlue,
  fontSize: '12px',
  fontWeight: 700,
  cursor: 'pointer',
  marginBottom: '5px',
  '&:hover': {
    textDecoration: 'underline'
  }
});

export const StyledTitleContainerText = styled(Typography)({
  color: colors.sensiGrey,
  fontSize: '10px',
  fontWeight: 400,
  marginLeft: '4px',
  marginRight: '10px',
});

export const StyledRow = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center',
});

export const StyledColumn = styled(Grid)({
  flexDirection: 'column',
  margin: '6px',
});

export const PlayerContainer = styled(Box)({
  width: '50%',
  marginRight: '5px'
});
