import React, { useMemo } from 'react';
import cs from 'classnames';
import styles from './TakeawaysList.module.scss';

import IconButton from '@mui/material/IconButton';
import Scrollable from 'simplebar-react';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import EmptyTakeawaysImage from '../../../../assets/images/empty-takeaways';

import { useTakeawaysContext } from '../../../../contexts/takeaways';

const EmptyTakeaways = () => (
  <div className="flex flex-col justify-center items-center h-full">
    <EmptyTakeawaysImage className="takeaways-section__empty-image" />
    <p className="takeaways-section__title-text">
      Any takeaways you add will appear here
    </p>
  </div>
);

const TakeawayItem = ({ onEdit, onDelete, index, ...item }) => {
  const { id: typeId, description } = item;
  const { takeawayTypes, loadingTakeawayTypes } = useTakeawaysContext();

  const type = useMemo(
    () => takeawayTypes.find((item) => item.id === typeId),
    [typeId],
  );

  return (
    !loadingTakeawayTypes && (
      <div className={styles.takeaway}>
        <img
          className={styles.takeaway__icon}
          src={type?.icon}
          alt={type?.name}
        />
        <p className={styles.takeaway__description}>{description}</p>
        <div className={styles.takeaway__actions}>
          <IconButton
            classes={{ root: styles.takeaway__button }}
            onClick={() => onEdit(index)}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            classes={{ root: styles.takeaway__button }}
            onClick={() => onDelete(index)}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      </div>
    )
  );
};

const TakeawaysList = (props) => {
  const { takeaways } = useTakeawaysContext();

  return takeaways.length === 0 ? (
    <EmptyTakeaways />
  ) : (
    <div className={cs(styles.takeawaysList, 'flex flex-col')}>
      <p className="takeaways-section__title-text">Main Takeaways:</p>
      <Scrollable className={styles.takeawaysList__container}>
        {takeaways.map((item, i) => (
          <TakeawayItem key={i} index={i} {...item} {...props} />
        ))}
      </Scrollable>
    </div>
  );
};

export default TakeawaysList;
