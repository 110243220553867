import { useCallback, useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import { useParams } from 'react-router-dom';

import AudioPlayer from '../../../components/audioPlayer';
import agent from '../../../utilities/agent';
import dayjs from '../../../utilities/dayjs';
import {
  CalendarIcon,
  HighlightAlertIcon,
  HighlightCareIcon,
  HighlightHealthIcon,
  HighlightSystemIcon,
  TimeIcon,
} from '../../../assets/icons';
import CheckBox from '../../../components/common/checkbox';
import { careTitles, healthTitles } from '../../../utilities/const';
import PopoverSelect from '../../../components/common/popoverSelect';
import { useCustomerState } from '../../../contexts/customers';
import EventService from '../../../services/EventService';
import EVENT_TYPES from '../../../services/EVENT_TYPES';

const CarelogItem = ({ highlight, onUpdate, isEdit, isReportAutomated }) => {
  const { id } = useParams();

  const [{ MixpanelUserData, currentClient, actionTakenEnums }] =
    useCustomerState();

  const [action, setAction] = useState('');
  const [message, setMessage] = useState('');
  const page = 'clients report';

  useEffect(() => {
    setAction(highlight?.action_taken_enum?.name || null);
    setMessage(highlight?.action_taken_message || '');
  }, [highlight.action_taken_enum?.name, highlight?.action_taken_message]);

  const applyInput = useCallback(
    (actionTaken, actionTakenMessage) => {
      const currentActionTaken = actionTakenEnums.find(
        ({ name }) => name === actionTaken,
      );
      agent.post(`/carelogs/addToFamilyReport/${highlight.id}`, {
        actionTaken: currentActionTaken?.id,
        actionTakenMessage,
      }).then(() => {
        EventService.fireEvent(EVENT_TYPES.NOTIFICATION_ADD_ACTION_TAKEN,{
          ...MixpanelUserData,
          label: highlight.labels,
          action_type: actionTaken,
          action_message: actionTakenMessage,
          carelog_id: highlight.id,
          category: highlight.senior_category,
          unit_id: highlight.unit_id,
          client_id: id,
          loc_id: currentClient?.loc_id,
          page,
          is_related_event: false
        });
        highlight.action_taken_enum = currentActionTaken;
        highlight.action_taken_message = actionTakenMessage;
        onUpdate(highlight);
      });
    },
    [highlight, id, MixpanelUserData, onUpdate, currentClient]
  );

  const inputDebounce = useCallback(debounce(applyInput, 1500), []);

  const handleHighlightAdded = useCallback(() => {
    if (isEdit) {
      highlight.include_in_report = !highlight.include_in_report;
      onUpdate(highlight);
      EventService.fireEvent(EVENT_TYPES.NOTIFICATION_ADD_TO_FAMILY_REPORT, {
        ...MixpanelUserData,
        carelog_id: highlight.id,
        label: highlight.labels,
        value: !highlight.include_in_report,
        client_id: id,
        loc_id: currentClient?.loc_id,
        is_automated: isReportAutomated,
        page,
        is_related_event: false
      });
      return;
    }

    agent.post(`/carelogs/addToFamilyReport/${highlight.id}`, {
      addToFamilyReport: !highlight.include_in_report,
    }).then(() => {
      EventService.fireEvent(EVENT_TYPES.NOTIFICATION_ADD_TO_FAMILY_REPORT, {
        ...MixpanelUserData,
        carelog_id: highlight.id,
        label: highlight.labels,
        value: !highlight.include_in_report,
        client_id: id,
        loc_id: currentClient?.loc_id,
        is_automated: isReportAutomated,
        page,
        is_related_event: false
      });
      highlight.include_in_report = !highlight.include_in_report;
      onUpdate(highlight);
    });
  }, [
    onUpdate,
    highlight,
    isEdit,
    id,
    currentClient,
    isReportAutomated,
  ]);

  const highlightHeader = useMemo(() => {
    return highlight.type === 'alert' ? {
      title: 'High severity alert',
      subtitle: highlight.labels || '',
      icon: HighlightAlertIcon
    } : {
      title: highlight.labels || '',
      subtitle: '',
      icon: healthTitles.includes((highlight.labels || '').toLowerCase()) ? HighlightHealthIcon : careTitles.includes((highlight.labels || '').toLowerCase()) ? HighlightCareIcon : HighlightSystemIcon
    };
  }, [highlight]);

  return (
    <div
      className={'highlight-label highlight-done report-label  w-full flex flex-col relative'}
    >
      <div className="highlight-header relative">
        <div className="icon-container flex items-center justify-center absolute">
          <highlightHeader.icon />
        </div>
        <div className={'label-time-and-type flex items-center'}>
          <div className="highlight-title flex justify-between w-full">
            <div>
              <span className="main-title">{highlightHeader.title}</span>
              {highlightHeader.subtitle ? (
                <>
                  <span className="title-divider">|</span>
                  <span className="sub-title">{highlightHeader.subtitle}</span>
                </>
              ) : (
                ''
              )}
            </div>
            <div className="flex items-center">
              <div className="notification-time-label flex items-center mx-2">
                <CalendarIcon className="mr-2" />
                {highlight.date_label}
              </div>
              <div className="notification-time-label flex items-center">
                <TimeIcon className="mr-2" />
                {dayjs(highlight.ts).format('h:mm A')}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="highlight-content-container w-full flex">
        <div className={'highlight-content w-full relative flex'}>
          <div
            className={'highlight-content-body flex flex-col w-full px-4 py-4'}
          >
            <div className="highlight-content-data flex w-full">
              <div className="highlight-data-col flex flex-1">
                <div className="highlight-data-item w-full">
                  <span>Audio</span>
                  <AudioPlayer
                    src={highlight.file_name}
                    id={highlight.id}
                    MixPanelTrackFunc={(props) =>
                      EventService.fireEvent(EVENT_TYPES.NOTIFICATION_HEARD, {
                        ...props,
                        client_id: id,
                        loc_id: currentClient?.loc_id,
                        page: 'clients report',
                        is_related_event: false
                      })
                    }
                    highlight={highlight}
                    onUpdate={onUpdate}
                  />
                </div>
              </div>

              <div className="highlight-data-row flex flex-3">
                <div className="highlight-data-col flex flex-col w-1/2">
                  <div className="highlight-data-item">
                    <span>Were there any actions taken?</span>
                    <PopoverSelect
                      containerClassName={'mt-4'}
                      value={action}
                      options={actionTakenEnums.map(({ name }) => ({
                        label: name,
                        value: name,
                      }))}
                      onChange={(v) => {
                        setAction(v);
                        applyInput(v, message);
                      }}
                      label={'Choose an action'}
                    />
                  </div>
                </div>

                <div className="highlight-data-col flex flex-col w-1/2">
                  <div className="highlight-data-item">
                    <span>Notes</span>

                    <div className="input-container relative !py-0">
                      <input
                        id="resolve-input"
                        className="w-full !py-0 !m-0"
                        placeholder="Or type anything..."
                        value={message}
                        onChange={(e) => {
                          setMessage(e.target.value);
                          inputDebounce(action, e.target.value);
                        }}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="highlight-data-col flex- flex justify-end items-center flex-1">
                <div className="highlight-review-button p-0 flex items-end">
                  <div className={'flex items-center'}>
                    <CheckBox
                      value={highlight.include_in_report}
                      onChange={handleHighlightAdded}
                      label={
                        highlight.include_in_report ? 'Added to Report' : 'Include to Report'
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarelogItem;
