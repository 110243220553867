import { CloseIcon } from '../../assets/icons';

const SharedModal = ({
  className = '',
  toggle = () => {},
  title = '',
  children,
  withBackgroundToggle = true
}) => {
  return (
    <div
      className={`${className} shared-modal`}
      onClick={() => withBackgroundToggle && toggle()}
    >
      <div
        className={'modal-container flex flex-col z-50 relative'}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={'modal-title text-center relative'}>
          <span>{typeof title === 'string' ? title : title()}</span>

          {withBackgroundToggle ? (
            <button
              className="modal-close-button absolute"
              onClick={toggle}
            >
              <CloseIcon />
            </button>
          ) : (
            ''
          )}
        </div>
        {children}
      </div>
    </div>
  );
};

export default SharedModal;
