import {
  styled,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import Colors from '../../../../theme/Colors';

export const StyledSummary = styled(AccordionSummary)(() => ({
  color: Colors.sensiGrey,
  fontWeight: 700,
  fontSize: '12px',
  '& > .MuiAccordionSummary-content > svg': {
    marginRight: '11px',
    marginLeft: '-9px'
  }
}));

export const StyledDetails = styled(AccordionDetails)(() => ({
}));