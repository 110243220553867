import React, { useEffect } from 'react';

import { Box } from '@mui/material';
import RelatedEvent from '../RelatedEvent/RelatedEvent';
import { getRelatedEvents, selectRelatedEvents } from '../../../../redux/Seniors';
import { useDispatch, useSelector } from 'react-redux';

const RelatedEvents = ({ insight_id, setCount }) => {
  const dispatch = useDispatch();
  const relatedEvents = useSelector(selectRelatedEvents);

  useEffect(() => {
    const handleGetRelatedEvents = () =>
      dispatch(
        getRelatedEvents({
          insight_id,
        }),
      ).abort;

    return handleGetRelatedEvents();
  }, [insight_id]);
  setCount(relatedEvents?.length);

  return (
    <Box>
      {relatedEvents.map((event, i) => {
        return (
          <RelatedEvent
            key={i}
            label={event.tagging_classes.name}
            time={event.ts}
            room={event.devices?.hardware_locations_enum?.status}
            filename={event?.file_name}
            itemId={event.id}
            insightId={insight_id}
            heard={event.heard}
            read={event.read_transcription}
            transcription={event.transcription}
          />
        );
      })}
    </Box>
  );
};

export default RelatedEvents;
