import { styled, Grid, IconButton as MuiIconButton } from '@mui/material';

const SkeletonContainer = styled(Grid)(({ theme }) => ({
  padding: '20px 24px',
  borderBottom: `1px solid ${theme.palette.border.main}`,
}));

const Form = styled('form')(({ theme }) => ({
  display: 'flex',
  padding: '20px 24px',
  borderBottom: `1px solid ${theme.palette.border.main}`,
}));

const IconButton = styled(MuiIconButton)(() => ({
  padding: '0px',
}));

const Input = styled('input')(({ theme }) => ({
  flex: '1 0',
  marginLeft: '6px',
  padding: 0,
  border: 'none',
  '&::placeholder': {
    color: theme.palette.sensiGrey.main,
  },
}));

export { SkeletonContainer, Form, Input, IconButton };
