import React, { useEffect } from 'react';

import { Snackbar, Stack } from '@mui/material';

import Toast from './Toast';

import { useDispatch, useSelector } from 'react-redux';
import { selectErrors } from '../../../redux/meta/selectors';
import { dismissError } from '../../../redux/meta/actions';

const NotificationsContainer = () => {
  const dispatch = useDispatch();
  const errors = useSelector(selectErrors);

  useEffect(() => {
    if (errors.length > 3) {
      dispatch(dismissError(errors[0].id));
    }
  }, [errors]);

  return (
    <Snackbar
      open={errors.length !== 0}
      autoHideDuration={null}
      transitionDuration={0}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Stack direction="column" gap={1}>
        {errors.map((err) => (
          <Toast key={err.id} {...err} />
        ))}
      </Stack>
    </Snackbar>
  );
};

export default NotificationsContainer;
