import React, { useEffect } from 'react';

import { Alert, Grow } from '@mui/material';

import { useDispatch } from 'react-redux';
import { dismissError } from '../../../redux/meta/actions';

const Toast = ({ id, message, type, timeout = 3000 }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(dismissError(id));
    }, timeout);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const handleClose = (evt, reason) => {
    if (reason !== 'clickaway') {
      dispatch(dismissError(id));
    }
  };

  return (
    <Grow in>
      <Alert severity={type || 'error'} onClose={handleClose} variant="filled">
        {message}
      </Alert>
    </Grow>
  );
};

export default Toast;
