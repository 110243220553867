import { getEventsBySeniorAPI, getRelatedEventsAPI, getSeniorsByCategoriesAPI } from '../../Api/dashboard.api';
import { createAsyncAction } from '../../utilities/redux';

export const getSeniorsByCategories = createAsyncAction(
  'dashboard/seniorsCategories',
  async ({ territoriesIds, ts }) => {
    const seniors = await getSeniorsByCategoriesAPI(territoriesIds, ts);
    return seniors;
  },
);

export const getEventsBySenior = createAsyncAction(
  'dashboard/seniorEvents',
  async ({ relatedToInsight, insightId, carelogId, unitId, ts }, { rejectWithValue }) => {
    try {
      const response = await getEventsBySeniorAPI({
        ts,
        unitId,
        insightId,
        carelogId,
        relatedToInsight
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(
        { message: err?.response?.data?.message || 'Error while loading senior events' },
        { toast: true },
      );
    }
  },
);
export const getRelatedEvents = createAsyncAction(
  'dashboard/getRelatedEvents',
  async ({ insight_id }, { rejectWithValue }) => {
    try {
      return await getRelatedEventsAPI({ insight_id });
    } catch (err) {
      return rejectWithValue(
        { message: err?.response?.data || 'Error while loading related events' },
        { toast: true },
      );
    }
  },
);
