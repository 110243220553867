import { useEffect, useState } from 'react';
import {
  handleCustomerSelect,
  handleOfficesSelect,
  handleTerritoriesSelect,
  useCustomerState
} from '../contexts/customers';
import { useNavigateTo, useQueryObject } from '../utilities/hooks';
import { SECONDARY_QUERY_PARAMS } from '../utilities/const';
import { useLocalStorage } from '@uidotdev/usehooks';

export const useLoadInitialData = () => {
  const {
    territoryIds = '',
    officesIds = '',
    customerId = ''
  } = useQueryObject();
  const [{ territories, offices, customers }, dispatch] = useCustomerState();

  const navigate = useNavigateTo();

  const [isInitialLoad, setInitialLoad] = useState(true);

  const [lastSelectedCustomer] = useLocalStorage('lastSelectedCustomer', []);
  const [lastSelectedOffice] = useLocalStorage('lastSelectedOffice', []);
  const [lastSelectedTerritory] = useLocalStorage('lastSelectedTerritory', []);

  useEffect(() => {
    if (!isInitialLoad) {
      setInitialLoad(true);
    }
  }, [
    territoryIds,
    officesIds,
    customerId
  ]);

  useEffect(() => {
    if (
      customers.length &&
      offices.length &&
      territories.length &&
      isInitialLoad
    ) {
      const terrIds = territoryIds
        .split(',')
        .filter((v) => v)
        .map((v) => +v);
      const offIds = officesIds
        .split(',')
        .filter((v) => v)
        .map((v) => +v);

      const currentTerrs = territories.filter(({ id: terrId }) =>
        terrIds.includes(terrId)
      );
      const currentOffs = offices.filter(({ id }) => offIds.includes(id));
      const currentCust = customers.find(({ id }) => id === +customerId);

      if (!currentTerrs.length || !currentOffs.length || !currentCust) {
        let index = 0;
        let initialCustomer = lastSelectedCustomer?.length ? customers.find(({ id }) => lastSelectedCustomer.includes(id)) : customers[index];
        let initialOffices = offices.filter(
          ({ id, customer_id }) => lastSelectedOffice.includes(id) || (!lastSelectedOffice?.length && customer_id === initialCustomer?.id)
        );

        if (!initialOffices.length) {
          while (!initialOffices.length) {
            index += 1;
            if (index > customers.length) {
              initialOffices = [];
              break;
            } else {
              initialCustomer = customers[index];
              initialOffices = offices.filter(
                ({ id, customer_id }) => lastSelectedOffice.includes(id) || (!lastSelectedOffice?.length && customer_id === initialCustomer?.id)
              );
            }
          }
          index = 0;
        }

        let initialTerritories = territories.filter(
          ({ id, office_id }) => initialOffices.map(({ id }) => id).includes(office_id) && (lastSelectedTerritory?.includes(id) || !lastSelectedTerritory?.length)
        );

        if (!initialTerritories.length) {
          while (!initialTerritories.length) {
            index += 1;
            if (
              index >
              offices.filter(
                ({ customer_id }) => customer_id === initialCustomer?.id
              ).length
            ) {
              initialTerritories = [];
              break;
            } else {
              initialOffices = offices.filter(
                ({ customer_id }) => customer_id === initialCustomer?.id
              );

              initialTerritories = territories.filter(
                ({ office_id }) => initialOffices.map(({ id }) => id).includes(office_id)
              );
            }
          }
        }

        handleTerritoriesSelect(dispatch, initialTerritories);
        handleOfficesSelect(
          dispatch,
          initialOffices.filter(({ id }) => id)
        );
        handleCustomerSelect(dispatch, initialCustomer);
        setInitialLoad(false);

        const nextUrl = window.location.pathname.includes('/client') ? '/dashboard/territory' : '';

        navigate({
          url: nextUrl,
          params: {
            customerId: initialCustomer.id,
            officesIds: initialOffices.map(({ id }) => id).join(','),
            territoryIds: initialTerritories.map(({ id }) => id).join(',')
          },
          omit: SECONDARY_QUERY_PARAMS,
          replace: true
        });

        return;
      }

      handleTerritoriesSelect(dispatch, currentTerrs);
      handleOfficesSelect(dispatch, currentOffs);
      handleCustomerSelect(dispatch, currentCust);
      setInitialLoad(false);
    }
  }, [
    territoryIds,
    officesIds,
    customerId,
    territories,
    offices,
    customers,
    isInitialLoad,
    navigate
  ]);
};
