import { useState, useEffect, useRef } from 'react';
import agent from '../utilities/agent';

export const usePollUnreadNotificationsCount = () => {
  const [data, setData] = useState(0);
  const [initialLoad, setInitialLoad] = useState(true);

  const pollRef = useRef();

  const loadUnreadCount = async () => {
    try {
      const result = await agent.get('/notifications/getUnreadNotificationsCount');
      setData(result?.data?.totalUnreadCount || 0);
    } catch (e) {
      console.log(`ERROR occured while executing loadUnreadCount hook: ${ e }`);
    }
  };

  useEffect(() => {
    if (initialLoad) {
      loadUnreadCount();
      setInitialLoad(false);
    }

    pollRef.current = setInterval(loadUnreadCount, 60000);
    
    return () => {
      clearInterval(pollRef.current);
    };
  }, [initialLoad]);

  return data;
};

export const useLoadUserNotifications = (open, trigger) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  
  const reqController = useRef();

  useEffect(() => {
    (async () => {
      try {
        
        reqController.current?.abort();
        reqController.current = new AbortController();

        if (open) {
          setLoading(true);

          const result = await agent.get('/notifications/getNotifications', {
            signal: reqController.current?.signal,
          });
          
          setData(result?.data || []);
          
          reqController.current = null;
          setLoading(false);
        }
      } catch (e) {
        reqController.current = null;
        console.log(`ERROR occured while executing useLoadUserNotifications hook: ${ e }`);
      }
    })();
  }, [open, trigger]);

  return [data, loading];
};
