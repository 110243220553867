const NotificationWrapper = ({ className = '', toggle = () => {}, children }) => {
  return (
    <div className={`${className} shared-notification flex pr-8`} onClick={toggle}>
      <div className={'notification-container flex flex-col w-full'} onClick={(e) => e.stopPropagation()}>
        {children}
      </div>
    </div>
  );
};

export default NotificationWrapper;
