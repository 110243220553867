import React, { useState, useEffect } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Auth } from 'aws-amplify';

import { clearUserData, setUserData } from '../../../redux/User';

const ProtectedRoute = () => {
  const user =  useSelector(state => state.user.user?.signInUserSession?.idToken);
  const [checkedAuth, setCheckedAuth] = useState();
  const dispatch = useDispatch();

  const checkAuthState = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      dispatch(setUserData(user));
    } catch (err) {
      dispatch(clearUserData());
      console.log(err);
    } finally {
      setCheckedAuth(true);
    }
  };

  useEffect(() => {
    checkAuthState();
  }, [user?.jwtToken]);

  if (checkedAuth === undefined) return null;
  
  return user?.jwtToken ? <Outlet/> : <Navigate to="/login"/>;
};

export default ProtectedRoute;