import React, { useEffect, useState } from 'react';
import { BuilderComponent, builder, Builder } from '@builder.io/react';
import BuilderSlider from '../../../../components/builder/Slider/BuilderSlider';

builder.init(process.env.REACT_APP_BUILDER_API_KEY);

export const BuilderSideBar = () => {
  const [builderContentJson, setBuilderContentJson] = useState(null);

  useEffect(() => {
    builder.get(process.env.REACT_APP_BUILDER_MODEL, { url: location.pathname })
      .promise().then(setBuilderContentJson);
  }, []);

  return <BuilderComponent model={process.env.REACT_APP_BUILDER_MODEL} content={builderContentJson} />;
};

Builder.registerComponent(BuilderSlider, {
  name: 'Slider',
  inputs: [
    {
      name: 'slides',
      type: 'list',
      subFields: [
        {
          name: 'content',
          type: 'uiBlocks',
          defaultValue: [],
        },
      ],
      defaultValue: [
        {
          content: [],
        },
      ],
    },
  ],
});


export default BuilderSideBar;

