import React, { useState } from 'react';
import { BuilderBlocks } from '@builder.io/react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './BuilderSlider.css';


const BuilderSlider = (props) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const settings = {
    dots: true,
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (currentSlide) => setActiveSlide(currentSlide)
  };

  return (
    <Slider {...settings}>
      {props.slides?.map((item, index) => (
        <BuilderBlocks
          key={index}
          parentElementId={props.builderBlock.id}
          dataPath={`component.options.slides.${activeSlide}.content`}
          blocks={item.content}
        />
      ))}
    </Slider>
  );
};

export default BuilderSlider;