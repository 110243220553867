import React from 'react';
import { Dialog } from '@mui/material';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useSelector } from 'react-redux';
import {
  CloseButton,
  StyledDialogTitle,
  StyledGridContainer,
  StyledGridItem,
  StyledTypographyContent,
  StyledTypographyUnitName,
  StyledGridRow,
  StyledSupportButton,
  StyledDoneButton,
  StyledDialog,
  StyledHeadline,
} from './CoverageModalStyle';
import { XSign, DeviceIcon, SupportIcon } from '../../../assets/icons';
import colors from '../../../theme/Colors';
import englishText from '../../../languages/English';
import EVENT_TYPES from '../../../services/EVENT_TYPES';
import EventService from '../../../services/EventService';
import { selectMessageByUnits } from '../../../redux/Devices';

const CoverageModal = NiceModal.create(({ unitId, unitName }) => {
  const modal = useModal();
  const unitsMessages = useSelector(selectMessageByUnits);
  const handleClose = () => {
    modal.hide();
  };

  const openSupportEmail = () => {
    const link = document.createElement('a');
    link.href = `mailto:support@sensi.ai?subject=Low Data Coverage for ${unitName}'s home`;
    link.target = '_blank';
    EventService.fireEvent(EVENT_TYPES.COVERAGE_MODAL_CONTACT_SUPPORT, { unit_id: unitId });
    link.click();
    link.remove();
  };

  return (
    <Dialog
      maxWidth={false}
      open={modal.visible}
      onClose={handleClose}
    >
      <StyledDialog>
        <StyledGridContainer container>
          <StyledGridItem item>
            <StyledDialogTitle>
              <CloseButton onClick={handleClose}>
                <XSign />
              </CloseButton>
              <StyledGridRow>
                <DeviceIcon color={colors.sensiError} background={colors.lightRose}/>
                <StyledHeadline>{englishText.lowDataCoverage}</StyledHeadline>
              </StyledGridRow>
            </StyledDialogTitle>
            <StyledGridItem item>
              <StyledTypographyUnitName>
                {unitName}
              </StyledTypographyUnitName>
              <StyledTypographyContent>
                {unitsMessages[unitId]}
              </StyledTypographyContent>
            </StyledGridItem>
            <StyledGridRow>
              <StyledDoneButton onClick={handleClose}>
                {englishText.done}
              </StyledDoneButton>
              <StyledSupportButton onClick={openSupportEmail}>
                <SupportIcon color={colors.white} style={{ marginRight: '8px' }}/>Contact Support
              </StyledSupportButton>
            </StyledGridRow>
          </StyledGridItem>
        </StyledGridContainer>
      </StyledDialog>
    </Dialog>
  );
});

export default CoverageModal;
