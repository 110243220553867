import { useCallback, useEffect, useState } from 'react';

const CheckBox = ({
  className = '',
  value: customValue,
  onChange,
  label = ''
}) => {
  const [value, setValue] = useState(false);

  useEffect(() => setValue(customValue), [customValue]);

  const handleChange = useCallback(() => {
    setValue(!value);
    onChange(!value);
  }, [onChange, value]);

  return (
    <div
      className={`${className} checkbox-container flex items-center`}
      onClick={handleChange}
    >
      <div className="checkbox-input mr-4 cursor-pointer">
        <div className={`checkbox-check ${value ? 'checked' : ''}`}></div>
      </div>
      <label className="cursor-pointer">{label}</label>
    </div>
  );
};

export default CheckBox;
