import { useState, useEffect } from 'react';

import agent from '../utilities/agent';

export const useLoadReportData = ({ id: reportId }) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (reportId) {
      setLoading(true);
      agent.get(`/familyreport/getReport/${reportId}`)
        .then(({ data: { data } }) => setData(data))
        .finally(() => setLoading(false));
    }
  }, [reportId]);

  return [data, loading];
};
