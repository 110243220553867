import React from 'react';
import { Menu, MenuItem, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import English from '../../../../languages/English';

export const menuId = 'primary-search-account-menu';
export const renderMenu = (anchorEl, isMenuOpen, handleMenuClose, handleSignOut) => (
  <Menu
    anchorEl={anchorEl}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    id={menuId}
    keepMounted
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    sx={{ marginTop: '40px' }}
    open={isMenuOpen}
    onClose={handleMenuClose}
  >
    <MenuItem onClick={handleSignOut}>{English.logout}</MenuItem>
  </Menu>
);

export const mobileMenuId = 'primary-search-account-menu-mobile';
export const renderMobileMenu = (isMobileMenuOpen, mobileMoreAnchorEl, handleMobileMenuClose, handleProfileMenuOpen) => (
  <Menu
    anchorEl={mobileMoreAnchorEl}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    id={mobileMenuId}
    keepMounted
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    open={isMobileMenuOpen}
    onClose={handleMobileMenuClose}
  >
    {/*<MenuItem>*/}
    {/*  <IconButton*/}
    {/*    size="large"*/}
    {/*    color="inherit"*/}
    {/*  >*/}
    {/*    <Badge badgeContent={17} color="error">*/}
    {/*      <NotificationNewIcon />*/}
    {/*    </Badge>*/}
    {/*  </IconButton>*/}
    {/*  <Typography>Notifications</Typography>*/}
    {/*</MenuItem>*/}
    <MenuItem onClick={handleProfileMenuOpen}>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="primary-search-account-menu"
        aria-haspopup="true"
        color="inherit"
      >
        <AccountCircle />
      </IconButton>
      <Typography>{English.profile}</Typography>
    </MenuItem>
  </Menu>
);
