import React from 'react';

const DevicesIcon = ({
  width = '11',
  height = '15'
}) =>
  (
    <svg width={width} height={height} viewBox="0 0 11 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 2C0 0.89543 0.895431 0 2 0H9C10.1046 0 11 0.895431 11 2V15H0V2Z" fill="#555770"/>
      <circle cx="3.5" cy="11.5" r="1.5" fill="white"/>
      <rect x="8" width="1" height="15" fill="white"/>
    </svg>
  );


export default DevicesIcon;