import React from 'react';

import { Skeleton, Grid, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import MoreIcon from '@mui/icons-material/MoreHoriz';

import Colors from '../../../../../theme/Colors';

const ItemSkeleton = ({ index }) => {
  const theme = useTheme();

  return (
    <Grid
      sx={{
        borderBottom: `1px solid ${theme.palette.border.main}`,
        backgroundColor: index % 2 === 0 ? undefined : Colors.whiteGrey,
        padding: '21px',
        '&:last-child': {
          borderBottom: 'none',
        },
      }}
      justifyContent="space-between"
      alignItems="center"
      wrap="nowrap"
      container
    >
      <Grid item container>
        <Grid gap="17px" wrap="nowrap" container item>
          <Skeleton
            variant="circular"
            width={36}
            height={36}
            sx={{ flexShrink: 0 }}
          />
          <Grid direction="column" gap="3px" container item>
            <Skeleton variant="rounded" width={63} height={16} />
            <Skeleton variant="rounded" width={289} height={16} />
          </Grid>
        </Grid>
      </Grid>

      <IconButton
        sx={{
          padding: '2px',
          borderRadius: '5px',
          border: `1px solid ${theme.palette.border.light}`,
        }}
      >
        <MoreIcon />
      </IconButton>
    </Grid>
  );
};

const DevicesCoverageSkeleton = ({ amount = 4 }) => {
  let i = 0;
  const content = [];

  while (i < amount) {
    content.push(<ItemSkeleton key={i} index={i} />);
    i++;
  }

  return content;
};

export default DevicesCoverageSkeleton;
