import React from 'react';
import { StyledToggleButton, StyledToggleButtonGroup } from './Toggle.style';
import { Typography } from '@mui/material';

const Toggle = ({ options, alignment, onChange, containerStyle = {} }) =>
  <StyledToggleButtonGroup
    value={alignment}
    exclusive
    onChange={(_, value) => {
      onChange(typeof value === 'object' ? value : { value });
    }}
    style={containerStyle}
    aria-label='Helpful'
  >
    {options?.map((option) => {
      const Icon = option?.icon;
      return <StyledToggleButton
        style={option.style}
        key={option.value?.value || option.value}
        value={option.value}
        selected={alignment === (option.value?.value || option.value)}
        selectedColor={option.color}
      >
        {option?.icon && <Icon color={alignment === option.value && 'white'} />}
        {option.text && (
          <Typography style={option.textStyle}>{option.text}</Typography>
        )}
      </StyledToggleButton>;
    })}
  </StyledToggleButtonGroup>;

export default Toggle;
