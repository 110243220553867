import {
  styled,
} from '@mui/material';
import { ContentCopy } from '@mui/icons-material';

export const StyledContentCopy = styled(ContentCopy)(({ style }) => ({
  fontSize: '12px',
  cursor: 'pointer',
  ...style,
}));
