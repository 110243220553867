import { TIMES } from '../../utilities/const';
import English from '../../languages/English';
import Colors from '../../theme/Colors';

export const options = [
  {
    value: {
      value: TIMES.TWO_DAYS,
      label: 'last 48 hours',
    },
    text: English.lastDay,
    style: {
      padding: 15,
      width: 90
    },
    textStyle: {
      whiteSpace: 'nowrap',
      fontSize: 10,
      textTransform: 'capitalize',
      fontWeight: 700
    },
    color: Colors.sensiGrey,
  },
  {
    value: {
      value: TIMES.WEEK,
      label: 'last week'
    },
    text: English.lastWeek,
    style: {
      padding: 15,
      width: 90
    },
    textStyle: {
      textTransform: 'capitalize',
      fontSize: 10,
      fontWeight: 700
    },
    color: Colors.sensiGrey
  },
];

export const findItemByAssigneeId = (list, assigneeId) => {
  for (let i = 0; i < list.length; i++) {
    const item = list[i];
    if (item.assignees.some((assignee) => assignee.id === assigneeId)) {
      return item;
    }
  }
  return null;
};