import React from 'react';
import {
  Wrapper,
  Title,
  CoverageContainer,
  CoverageItem,
  ItemDivider,
  CoverageIcon,
  CoverageTitle,
  CoverageText,
} from './CoverageTooltip.style';
import English from '../../../../languages/English';
import { IconColors } from '../DevicesCoverage/components/DevicesCoverageItem/DevicesCoverageItem';

const CoverageTooltip = () => {
  return (
    <Wrapper variant="container">
      <Title variant="subtitle2" align="left">
        {English.dataCoverageTooltip}
      </Title>
      <CoverageContainer>
        <CoverageItem>
          <CoverageIcon {...IconColors.active} />
          <CoverageTitle>High Coverage</CoverageTitle>
          <CoverageText>
            more then 80% of the <br /> time frame coverage
          </CoverageText>
        </CoverageItem>
        <ItemDivider />
        <CoverageItem>
          <CoverageIcon {...IconColors.medium} />
          <CoverageTitle>Medium Coverage</CoverageTitle>
          <CoverageText>
            50%-80% of the time
            <br /> frame coverage
          </CoverageText>
        </CoverageItem>
        <ItemDivider />
        <CoverageItem>
          <CoverageIcon {...IconColors.inactive} />
          <CoverageTitle>Low Coverage</CoverageTitle>
          <CoverageText>
            less then 50% of the time
            <br /> frame and no coverage
          </CoverageText>
        </CoverageItem>
      </CoverageContainer>
    </Wrapper>
  );
};

export default CoverageTooltip;
