import { styled } from '@mui/system';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
export const StyledContainer = styled(Paper)(({ bgcolor = 'inherit', borderColor = '#E9E9E9' }) => ({
  width: '100%',
  height: 110,
  color: 'inherit !important',
  background: bgcolor,
  border: `1px solid ${borderColor}`
}));

export const StyledTitleContainer = styled(Box)({
  margin: '15px 19px 3px 22px',
  display: 'flex',
  '& > svg': {
    display: 'inline-block',
  },
});
export const StyledIconContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center'
});

export const StyledTitleContainerText = styled(Typography)({
  whiteSpace: 'nowrap',
  display: 'inline-block',
  fontWeight: '600 !important',
  margin: '0 5px !important',
});

export const StyledBodyText = styled(Typography)({
  whiteSpace: 'nowrap',
  color: '#000',
  fontSize: '30px !important',
  margin: '13px 23px !important',
  fontWeight: '700 !important',
});

