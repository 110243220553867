
export const getDefaultFilterOpts = () => [
  {
    id: 'byType',
    title: 'Filter by Type',
    name: 'notificationType',
    type: 'select',
    options: ['High Severity Notification', 'Notification', 'Insight'].map(str => ({ label: str, value: str.toLowerCase().split(' ').join('_') })),
    sort: true
  },
  {
    id: 'byStatus',
    title: 'Filter by Status',
    name: 'status',
    type: 'select',
    options: ['To Do', 'In Progress', 'Done'].map((str) => ({
      value: str,
      label: str
    })),
    sort: false
  },
  {
    id: 'byDate',
    title: 'Filter by Date',
    name: 'date',
    type: 'date'
  },
  {
    id: 'byAssign',
    title: 'Filter by Assignee',
    name: 'assigneeId',
    type: 'select',
    options: [],
    sort: true
  },
  {
    id: 'byLabel',
    title: 'Filter by Label',
    name: 'labels',
    type: 'select',
    options: [],
    sort: true
  }
];

export const initialState = {
  accNotificationsList: [],
  highLevelAlertList: [],
  highlights: [],
  filterOpts: [...getDefaultFilterOpts()],
  filter: {},
  currentHighlight: null,
  resolveModalOpen: false,
  showNoMore: false,
  allowDataSave: true,
  offset: 0
};

export const stateReducer = (state, data) => {
  return {
    ...state,
    ...data
  };
};
