import { useCallback, useEffect, useState, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useNavigateTo } from '../../utilities/hooks';

const TabsPanel = ({ tabs = [], initialTabIndex = 0, originURL = '' }) => {
  const [params] = useSearchParams({});
  const navigate = useNavigateTo();

  const [selectedIndex, setSelectedIndex] = useState(initialTabIndex);
  const [sliderStyles, setSliderStyles] = useState({});

  const tab = useMemo(() => params.get('tab'), [params]);

  useEffect(() => {
    setSelectedIndex(Number(tab));
    tabs[Number(tab)]?.onClick(false);
  }, [tab]);

  useEffect(() => {
    setTimeout(() => {
      const selectTabs = document.getElementsByClassName('tab-item');
      const selectedTab = selectTabs[selectedIndex]?.getBoundingClientRect();
      let left = 0;
      for (let i = 0; i < selectTabs.length; i++) {
        if (i === selectedIndex) break;
        left += selectTabs[i].getBoundingClientRect().width;
      }
      setSliderStyles({ width: selectedTab.width, left });
    }, 100);
  }, [selectedIndex, tab]);

  const handleTabClick = useCallback(
    (index, callback) => {
      navigate({
        url: originURL,
        params: {
          tab: index
        },
        replace: true
      });
      setSelectedIndex(index);
      selectedIndex !== index && callback && callback();
    },
    [navigate, originURL, selectedIndex]
  );

  return (
    <div className="tab-list-container flex w-full mt-6 relative">
      {tabs.map((tab, i) => (
        <div
          key={i}
          className={`tab-item py-2 px-8 cursor-pointer ${
            selectedIndex === i ? 'selected' : ''
          }`}
          onClick={() => handleTabClick(i, tab.onClick)}
        >
          {tab.render()}
        </div>
      ))}
      <div className="select-slider absolute" style={sliderStyles}></div>
    </div>
  );
};

export default TabsPanel;
