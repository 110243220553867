import React from 'react';
import { Box, CardContent,
  Divider, Grid, Typography, Button, CardHeader } from '@mui/material';
import English from '../../../../languages/English';
import { Link } from 'react-router-dom';
import { StyledCard } from './LoginCard.style';
import Colors from '../../../../theme/Colors';
import { SensiLogo, BackArrowIcon } from '../../../../assets/icons';
import BuilderSideBar from './BuilderSideBar';
import { EventService } from '../../../../services';
import EVENT_TYPES from '../../../../services/EVENT_TYPES';

const LoginCard = ({ children, displayReturn, handleReturn }) =>
  <StyledCard elevation={5}>
    <Grid container className="root">
      <Grid item xs={6} className="leftSide">
        <Grid pt={3} pr={2} pl={1}>
          <CardHeader
            title={
              <Grid container mt={2}>
                {!!displayReturn && (
                  <Grid item mt={-1}>
                    <Button onClick={handleReturn} variant="login-return">
                      <BackArrowIcon />
                    </Button>
                  </Grid>
                )}
                <Grid item xs display="flex" alignItems="center" justifyContent="center">
                  <Box mr={2}>
                    <SensiLogo />
                  </Box>
                </Grid>
              </Grid>
            }
          />
        </Grid>

        <CardContent className="cardContent">
          {children}
        </CardContent>
        <CardContent className="cardFooter">
          <Grid container>
            <Typography fontWeight={700} variant="body2" mr={1} className="supportFooter">{English.helpWithSignIn}</Typography>
            <Link
              to='#'
              onClick={(e) => {
                EventService.fireEvent(EVENT_TYPES.CONTACT_SUPPORT_VIA_EMAIL);
                window.location.href = `mailto:${English.supportEmail}`;
                e.preventDefault();
              }}
            >
              <Typography variant="body2" mr={1} color={Colors.sensiGrey}>{English.supportEmail}</Typography>
            </Link>

            <Divider color={Colors.sensiGrey} flexItem orientation="vertical"/>
            <Link
              to='#'
              onClick={(e) => {
                EventService.fireEvent(EVENT_TYPES.CONTACT_SUPPORT_VIA_PHONE);
                window.location.href = `tel:${English.supportPhone}`;
                e.preventDefault();
              }}
            >
              <Typography variant="body2" color={Colors.sensiGrey} ml={1}>{English.supportPhone}</Typography>
            </Link>

          </Grid>
        </CardContent>
      </Grid>
      <Grid item xs={6} className="rightSide">
        <BuilderSideBar/>
      </Grid>
    </Grid>
  </StyledCard>;

export default LoginCard;