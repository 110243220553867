import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import DateReportModal from './components/modals/DateReportModal';
import { useCustomerState, handleClientSelect } from '../../contexts/customers';
import TabsPanel from '../../components/general/TabsPanel';
import ClientHistoryTab from './components/ClientHistoryTab';
import FamilyReportTab from './components/FamilyReportTab';
import agent from '../../utilities/agent';
import { useSelector } from 'react-redux';
import { selectUser } from '../../redux/User';
import EVENT_TYPES from '../../services/EVENT_TYPES';
import { EventService } from '../../services';

const DashboardView = () => {
  const { id } = useParams();
  const user =  useSelector(selectUser);

  const [{ currentClient, MixpanelUserData }, dispatch] = useCustomerState();
  const [childFunc, setChildFunc] = useState(() => () => {});
  
  const [currClient, setCurrClient] = useState({});

  useEffect(() => {
    if (!currentClient?.id || currentClient?.id !== id) {
      setCurrClient({});
      agent.get(`/units?unitId=${id}`).then(
        ({
          data: {
            data: [client]
          }
        }) => {
          setCurrClient(client);
          handleClientSelect(dispatch, client);
        }
      );
    }
  }, [id]);

  const [reportModalOpen, setReportModalOpen] = useState(false);
  const [pageState, setPageState] = useState('clientHistory');

  useEffect(() => {
    if (pageState === 'familyReports') {
      setChildFunc(() => () => {});
    }
  }, [pageState]);

  const switchReportModalHandler = useCallback(() => {
    setReportModalOpen(!reportModalOpen);
    if (reportModalOpen) {
      EventService.fireEvent(EVENT_TYPES.CANCEL_GENERATE_FAMILY_REPORT_WIZARD,{
        client_id: id,
        loc_id: currClient?.loc_id,
        ...MixpanelUserData
      });
    } else {
      EventService.fireEvent(EVENT_TYPES.OPEN_GENERATE_FAMILY_REPORT_WIZARD, {
        client_id: id,
        loc_id: currClient?.loc_id,
        ...MixpanelUserData
      });
    }
  }, [
    reportModalOpen,
    currClient,
    id,
    MixpanelUserData
  ]);

  const pageContent = useMemo(() => {
    switch (pageState) {
      case 'familyReports':
        return <FamilyReportTab currClient={currClient} />;
      default:
        return <ClientHistoryTab setChildFunc={setChildFunc} currentClient={currClient} />;
    }
  }, [pageState, setChildFunc, currClient]);

  return (
    <div className="page-container relative" onScroll={childFunc}>
      <div className="client-info-container">
        <div className="page-header flex flex-row items-center justify-between">
          <span className="text-3xl font-bold z-20">
            Hello {user?.username},
            <br />
            Here is your {currClient.name} family history
          </span>
          <div className="flex h-full">
            <div
              className="generate-button flex items-center cursor-pointer"
              onClick={switchReportModalHandler}
            >
              Generate Care Report
            </div>
          </div>
        </div>

        <TabsPanel
          tabs={[
            {
              render: () => <div>History</div>,
              onClick: (trackEvent = true) => {
                trackEvent && EventService.fireEvent(EVENT_TYPES.GO_TO_CLIENTS_HISTORY);
                setPageState('');
              }
            },
            {
              render: () => <div>Care Reports</div>,
              onClick: (trackEvent = true) => {
                trackEvent && EventService.fireEvent(EVENT_TYPES.GO_TO_CARE_REPORTS);
                setPageState('familyReports');
              }
            }
          ]}
          originURL={`/dashboard/client/${currClient?.id}`}
        />

        {pageContent}

        {reportModalOpen ? (
          <DateReportModal toggle={switchReportModalHandler} />
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default DashboardView;
