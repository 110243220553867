import colors from '../../../theme/Colors';

const style = {
  background: 'none!important',
  border: 'none',
  padding: '0!important',
  textDecoration: 'underline',
  cursor: 'pointer',
  color: colors.sensiGrey,
  fontFamily: 'OpenSans-Custom',
  fontSize: '14px',
  fontWeight: 700
};

export default style;
