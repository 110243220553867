import { useCallback, useEffect, useReducer, useMemo, useRef } from 'react';

import ResolveModal from '../../components/modals/ResolveModal';
import HighlightItem from '../../components/general/HighlightItem/HighlightItem';
import CircleLoader from '../../components/common/loaders/circleLoader';
import { useCustomerState } from '../../contexts/customers';
import { WarningIcon } from '../../assets/icons';
import HeaderFilter from '../../components/general/HeaderFilter/HeaderFilter';
import { useQueryObject } from '../../utilities/hooks';
import { useLoadNotificationsHistory } from '../../hooks/carelogs_and_insights';
import { dashboardOffsetStep } from '../../utilities/const';
import { onBottomScrollHandler, sortByName } from '../../utilities/helpers';

import { getDefaultFilterOpts, initialState, stateReducer } from './pageUtils';
import dayjs from '../../utilities/dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../redux/User';
import { EventService } from '../../services';
import EVENT_TYPES from '../../services/EVENT_TYPES';
import { loadTodos } from '../../redux/Todos';

const DashboardView = () => {
  const user =  useSelector(selectUser);

  const [{
    currentTerritories,
    currentOffices,
    clients,
    triggerCarelogsReload,
  }] = useCustomerState();
  const params = useQueryObject();

  const localAssignees = useMemo(() => {
    return currentTerritories
      .reduce((acc, { assignees = [] }) => acc.concat(assignees), [])
      .reduce((acc, item) => {
        if (!acc.find(({ id }) => item.id === id)) acc.push(item);
        return acc;
      }, []);
  }, [currentTerritories]);

  const [
    {
      accNotificationsList,
      highLevelAlertList,
      highlights,
      filterOpts,
      filter,
      currentHighlight,
      resolveModalOpen,
      offset,
      allowDataSave
    },
    dispatch
  ] = useReducer(stateReducer, initialState);
  const reduxDispatch = useDispatch();

  useEffect(() => {
    reduxDispatch(loadTodos());
  }, []);

  const listContainerRef = useRef();

  useEffect(() => {
    dispatch({
      filter: {},
      filterOpts: getDefaultFilterOpts()
    });
  }, [currentTerritories, currentOffices]);

  useEffect(() => {
    dispatch({
      ...initialState
    });
  }, [params.territoryIds]);

  useEffect(() => {
    dispatch({
      offset: 0
    });
  }, [triggerCarelogsReload]);

  const [
    loadedCarelogsAndInsights,
    loadingCarelogsAndInsights,
    totalNotificationsCount
  ] = useLoadNotificationsHistory({
    offset,
    filter,
    territoryIds: currentTerritories.map(({ id }) => id).join(','),
    officesIds: currentOffices.map(({ id }) => id).join(','),
  });

  const loadedNotificationTitles = useMemo(() => {
    return accNotificationsList
      .filter(({ is_carelog }) => is_carelog)
      .reduce((acc, { title }) => {
        if (!acc.includes(title)) acc.push(title);
        return acc;
      }, [])
      .sort((a, b) => sortByName(a, b));
  }, [accNotificationsList]);

  useEffect(() => {
    dispatch({ allowDataSave: true });
  }, [loadedCarelogsAndInsights]);

  useEffect(() => {
    if (allowDataSave) {
      // NOTE: THIS PART IS RESPONSIBLE TO UNDERSTAND - SHOULD IT FILL FIRST DATA, ACCUMULATE WITH NEW DATA OR STAY AS IT IS
      const total = offset === 0 ? [...loadedCarelogsAndInsights]
        : !accNotificationsList.find(({ id }) => id === loadedCarelogsAndInsights[0]?.id)
          ? [...accNotificationsList, ...loadedCarelogsAndInsights.filter(({ is_critical_alert }) => !is_critical_alert)]
          : accNotificationsList;

      const tempHighLevelAlertList = total.filter(({ is_critical_alert }) => is_critical_alert);

      const notifications = total.filter(({ is_critical_alert }) => !is_critical_alert);
  
      dispatch({
        accNotificationsList: total,
        highLevelAlertList: tempHighLevelAlertList,
        highlights: notifications,
        allowDataSave: false
      });

    }
  }, [
    offset,
    loadedCarelogsAndInsights,
    totalNotificationsCount,
    allowDataSave,
    accNotificationsList
  ]);

  useEffect(() => {
    const officesFilter = filterOpts.find(({ id }) => id === 'byOffice');
    const territoriesFilter = filterOpts.find(({ id }) => id === 'byTerritory');
    const labelFilter = filterOpts.find(({ id }) => id === 'byLabel');
    const familyFilter = filterOpts.find(({ id }) => id === 'byFamily');
    const asigneesFilter = filterOpts.find(({ id }) => id === 'byAssign');

    const currentFilteredTerritories = currentTerritories.filter(
      ({ office_id, id }) => {
        if (filter.territoryId) {
          return id === filter.territoryId.value;
        }

        if (filter.officeId) {
          const currFilterOffice = currentOffices.find(
            ({ id }) => id === filter.officeId.value
          );
          return office_id === currFilterOffice?.id;
        }

        return true;
      }
    );

    const currentFilteredTerritoriesIds = currentFilteredTerritories.map(
      ({ id }) => id
    );

    officesFilter.options = currentOffices.map(({ id, name }) => ({
      value: id,
      label: name
    }));
    territoriesFilter.options = currentTerritories
      .filter(({ office_id }) => {
        if (filter.officeId) {
          const currFilterOffice = currentOffices.find(
            ({ id }) => id === filter.officeId.value
          );
          return office_id === currFilterOffice?.id;
        }
        return true;
      })
      .map(({ id, name }) => ({ value: id, label: name }));
    labelFilter.options = loadedNotificationTitles.map((v) => ({
      value: v,
      label: v
    }));
    familyFilter.options = clients
      .filter(({ territories: { id } }) =>
        currentFilteredTerritoriesIds.includes(id)
      )
      .reduce((acc, { id, name }) => {
        !acc.find(({ id: sid }) => sid === id) && acc.push({ value: id, label: name });
        return acc;
      }, []);

    asigneesFilter.options = localAssignees.map(({ id, username }) => ({
      value: id,
      label: username
    }));
    dispatch({ filterOpts });
  }, [
    clients,
    localAssignees,
    filterOpts,
    currentOffices,
    currentTerritories,
    filter.officeId,
    filter.territoryId,
    loadedNotificationTitles
  ]);

  const showNoMore = useMemo(() => {
    return accNotificationsList.filter(({ is_critical_alert }) => !is_critical_alert)?.length === totalNotificationsCount;
  }, [accNotificationsList, totalNotificationsCount]);

  const omitFilterOptions = useMemo(() => {
    const tempOmitArr = [];

    if (currentTerritories.length <= 1) {
      tempOmitArr.push('territoryId');
    }

    if (currentOffices.length <= 1) {
      tempOmitArr.push('officeId');
    }

    return tempOmitArr;
  }, [currentTerritories.length, currentOffices.length]);

  const handleSetFilter = useCallback((v = {}, field) => {
    dispatch({ filter: { ...v }, accNotificationsList: [], offset: 0 });

    if (field) {
      const payload = {
        value: v[field]?.value,
      };

      switch (field) {
        case 'officeId':
          EventService.fireEvent(EVENT_TYPES.FILTER_BY_OFFICE_HOME_PAGE, payload);
          break;
        case 'territoryId':
          EventService.fireEvent(EVENT_TYPES.FILTER_BY_TERRITORY_HOME_PAGE, payload);
          break;
        case 'unitId':
          EventService.fireEvent(EVENT_TYPES.FILTER_BY_CLIENT_HOME_PAGE, payload);
          break;
        case 'notificationType':
          EventService.fireEvent(EVENT_TYPES.FILTER_BY_TYPE_HOME_PAGE, payload);
          break;
        case 'status':
          EventService.fireEvent(EVENT_TYPES.FILTER_BY_STATUS_HOME_PAGE, payload);
          break;
        case 'assigneeId':
          EventService.fireEvent(EVENT_TYPES.FILTER_BY_ASSIGNEE_HOME_PAGE, payload);
          break;
        case 'labels':
          EventService.fireEvent(EVENT_TYPES.FILTER_BY_LABEL_HOME_PAGE, payload);
          break;
        case 'date':
          v[field]?.value?.startDate && v[field]?.value?.endDate &&
            EventService.fireEvent(EVENT_TYPES.FILTER_BY_DATE_HOME_PAGE, {
              start_date: dayjs(v[field]?.value?.startDate).format('YYYY-MM-DD'),
              end_date: dayjs(v[field]?.value?.endDate).format('YYYY-MM-DD')
            });
          break;
        default:
          break;
      }
    }
  }, []);

  const switchResolveModalHandler = useCallback(
    (highl) => {
      dispatch({
        currentHighlight: highl || null,
        resolveModalOpen: !resolveModalOpen
      });
    },
    [resolveModalOpen]
  );

  const handleUpdateHighlights = useCallback(
    (highlight, globalUpdateId) => {
      if (highlight) {
        const updatedHighlight = accNotificationsList.find(
          ({ id, is_carelog: IC }) =>
            id === highlight.id && IC === highlight.is_carelog
        );
        accNotificationsList[
          accNotificationsList.indexOf(updatedHighlight)
        ] = highlight;

        if (globalUpdateId) {
          const currentHighlight = accNotificationsList
            .filter(({ children_events }) => !children_events)
            .find(
              (event) =>
                event.id === globalUpdateId &&
                event.is_carelog === highlight.is_carelog
            );
          if (currentHighlight) {
            currentHighlight.heard = highlight.heard;
          }
          accNotificationsList
            .filter(({ children_events }) => children_events)
            .forEach(({ children_events }) => {
              children_events.forEach((event) => {
                if (event.id === globalUpdateId && event.is_carelog === highlight.is_carelog) {
                  event.heard = highlight.heard;
                }
              });
            });
        }
      }
      dispatch({
        accNotificationsList
      });
    },
    [accNotificationsList]
  );

  const handleHighlightDone = useCallback(
    ({ added, actionTaken, actionTakenMessage }) => {
      currentHighlight.status = 'Done';
      currentHighlight.include_in_report = added;
      currentHighlight.action_taken = actionTaken;
      currentHighlight.action_taken_message = actionTakenMessage;

      handleUpdateHighlights(currentHighlight);
    },
    [currentHighlight, handleUpdateHighlights]
  );

  const isEmpty = useMemo(() => {
    return !accNotificationsList.length;
  }, [accNotificationsList]);

  const getDataOnBottomScroll = useCallback(() => {
    if (
      listContainerRef.current &&
      currentTerritories.length &&
      !showNoMore &&
      !loadingCarelogsAndInsights
    ) {
      onBottomScrollHandler({
        callback: () => {
          dispatch({
            offset: offset + dashboardOffsetStep
          });
        },
        event: listContainerRef.current
      });
    }
  }, [
    listContainerRef,
    currentTerritories,
    showNoMore,
    offset,
    loadingCarelogsAndInsights
  ]);

  const handleFilterButtonClick = useCallback((isOpened) => {
    isOpened && EventService.fireEvent(EVENT_TYPES.FILTER_HOME_PAGE);
  }, []);

  return (
    <div className="page-container relative" ref={listContainerRef} onScroll={getDataOnBottomScroll}>
      <header className="page-header flex flex-row items-center justify-between">
        <span className="text-3xl font-bold z-20">
          Hello {user.username},
          <br />
          Here are your main highlights
        </span>
      </header>

      <HeaderFilter
        filterOpts={filterOpts.filter(
          ({ name }) => !omitFilterOptions.includes(name)
        )}
        filter={filter}
        setFilter={handleSetFilter}
        onFilterBtnClick={handleFilterButtonClick}
      />

      <div
        className={'highlights-list-container mt-2 flex flex-col relative z-40'}
      >
        {highLevelAlertList && highLevelAlertList.length ? (
          <div
            className={`highlights-list ${
              isEmpty ? '!border-0' : ''
            } critical-list w-full !min-h-0 pt-0 flex flex-col relative`}
          >
            {highLevelAlertList.map((highlight, i) => (
              <HighlightItem
                highlight={highlight}
                key={`${highlight.familyName}${i}`}
                onUpdate={handleUpdateHighlights}
                switchResolveModalHandler={switchResolveModalHandler}
              />
            ))}
          </div>
        ) : (
          ''
        )}
        <div className={'highlights-list w-full flex flex-col relative'}>
          {highlights.map((highlight, i) => (
            <HighlightItem
              highlight={highlight}
              key={`${highlight.familyName}${i}`}
              onUpdate={handleUpdateHighlights}
              switchResolveModalHandler={switchResolveModalHandler}
            />
          ))}
        </div>
      </div>

      {loadingCarelogsAndInsights ? (
        <div
          className="getting-data-label flex items-center p-4"
        >
          <CircleLoader show={loadingCarelogsAndInsights} className="!static mr-4" />
          Getting more data
        </div>
      ) : (
        ''
      )}

      {!loadingCarelogsAndInsights && showNoMore ? (
        <div className="no-more-data-label flex items-center p-4">
          <WarningIcon /> No more data to display
        </div>
      ) : (
        ''
      )}

      {resolveModalOpen ? (
        <ResolveModal
          toggle={switchResolveModalHandler}
          highlight={currentHighlight}
          callback={handleHighlightDone}
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default DashboardView;
