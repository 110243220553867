import { useDispatch } from 'react-redux';
import {
  StyledContainer,
  StyledTitleContainer,
  StyledTitleContainerText,
  StyledRow,
  StyledColumn,
  PlayerContainer,
} from './RelatedEvent.style';

import Player from '../../../../components/common/Player/Player';

import { Calendar } from '../../../../assets/icons';

import dayjs from 'dayjs';
import 'dayjs/locale/en';
import EventService from '../../../../services/EventService';

import Colors from '../../../../theme/Colors';
import EVENT_TYPES from '../../../../services/EVENT_TYPES';
import DevicesIcon from '../../../../components/icons/DevicesIcon';
import Transcription from '../Transcription';
import NiceModal from '@ebay/nice-modal-react';
import EventsModal from '../../../../components/modals/EventsModal';
import { setIsRelatedEvent } from '../../../../redux/Seniors';

const RelatedEvent = ({ label, time, room, filename, itemId, insightId, heard, transcription, read }) => {
  const dispatch = useDispatch();
  const handleInsightTrack = (data) => {
    EventService.fireEvent(EVENT_TYPES.INSIGHT_NOTIFICATION_HEARD, {
      ...data,
      insight_id: insightId,
      page: 'dashboard'
    });
  };

  const handleClick = () => {
    EventService.fireEvent(EVENT_TYPES.RELATED_EVENT_CLICKED, { page: 'dashboard', label, carelog_id: itemId });
    dispatch(setIsRelatedEvent(true));
    return NiceModal.show(EventsModal, { care_log_id: itemId, relatedToInsight: insightId, i: 0  });
  };

  return (
    <StyledContainer
      variant="container"
      bgcolor={Colors.whiteGrey}
      borderColor={Colors.borderGrey}
    >
      <StyledRow container>
        <StyledColumn item xs={8} sm={8} md={8} lg={8} xl={8}>
          <StyledTitleContainer onClick={handleClick}>{label}</StyledTitleContainer>
          <StyledRow>
            <Calendar />
            <StyledTitleContainerText>
              {dayjs.utc(time).format('MMM D, YYYY, hh:mm A')}
            </StyledTitleContainerText>
            <DevicesIcon height={11} />
            <StyledTitleContainerText>{room}</StyledTitleContainerText>
          </StyledRow>
        </StyledColumn>
        <PlayerContainer item>
          <Player
            key={filename}
            variant="outlined"
            filename={filename}
            carelogId={itemId}
            heard={heard}
            label={label}
            onTrack={handleInsightTrack}
          />
        </PlayerContainer>
      </StyledRow>
      {transcription && <Transcription
        key={itemId}
        transcription={transcription} read={read}
        fromRelatedEvent={true}
        event={{
          id: itemId,
          label,
          time,
          room,
          filename,
          insightId,
          is_related_event: true,
          page: 'dashboard',
        }} />}
    </StyledContainer>
  );
};

export default RelatedEvent;
