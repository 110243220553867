import { createSlice } from '@reduxjs/toolkit';

import { loadTodos, createTodo, editTodo, deleteTodos } from './TodosThunk';

const initialState = {
  todos: [],
};

const todosSlice = createSlice({
  name: 'todos',
  initialState,
  reducers: {
    deleteTodo: (state, action) => {
      const id = action.payload;
      state.todos = state.todos.filter((item) => item.id !== id);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadTodos.pending, (state) => {
      state.todos = [];
    });
    builder.addCase(loadTodos.fulfilled, (state, action) => {
      state.todos = action.payload;
    });
    builder.addCase(createTodo.fulfilled, (state, action) => {
      if (action.payload) state.todos = [action.payload, ...state.todos];
    });
    builder.addCase(deleteTodos.fulfilled, (state, action) => {
      const ids = [action.meta.arg].map((todo) => todo.insight_id || todo.care_log_id);
      state.todos = state.todos.filter((item) => !ids.includes(item.insight_id || item.care_log_id));
    });
    builder.addCase(editTodo.fulfilled, (state, action) => {
      const { payload } = action;
      const i = state.todos.findIndex((item) => item.id === payload.id);
      if (payload?.assignee_id !== state.todos[i]?.assignee_id) {
        state.todos.splice(i, 1);
      } else {
        state.todos[i] = payload;
      }
    });
  },
});

export const { deleteTodo } = todosSlice.actions;
export default todosSlice.reducer;
