import { asyncWithLDProvider, LDClient } from 'launchdarkly-react-client-sdk';

let ldClient = null;

const init = async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCHDARKLY_KEY,
    context: {
      kind: 'user',
      anonymous: true
    },
  });
  ldClient = LDProvider.client;
  return LDProvider;
};

const register = ({ user }) => {
  if (ldClient) {
    ldClient.identify({
      kind: 'user',
      key: user.id,
      name: user.username,
      email: user.email,
      custom: {
        userType: user.type,
        platform: 'Dashboard',
      },
    });
  }
};

const reset = () => {
  if (ldClient) {
    ldClient.identify({
      kind: 'user',
      anonymous: true
    });
  }
};

export default {
  init,
  register,
  reset,
};
