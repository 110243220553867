import React, { useCallback, useState, useEffect, useMemo } from 'react';
import {
  FormControl,
  Button,
  Box,
} from '@mui/material';

import CircleLoader from '../../../../components/common/loaders/circleLoader';
import useForm from '../../../../hooks/form-state';
import { emailRegex } from '../../../../utilities/const';
import {
  StyledFormContainer,
  StyledInput, StyledOutlinedInputHelperText,
  StyledSubtitle,
} from '../../defaultStyled/default.style';
import English from '../../../../languages/English';
import { useDispatch } from 'react-redux';
import { forgotRequest, setEmail } from '../../../../redux/User';

const formTemplate = {
  email: ''
};

const ForgotPasswordForm = ({ handleFormState }) => {
  const dispatch = useDispatch();


  const [loading, setLoading] = useState(false);

  const handleFormSubmit = useCallback(
    async (data, isInvalid) => {
      if (!isInvalid) {
        setLoading(true);
        await dispatch(setEmail(data.email));
        handleFormState('forgotPasswordConfirm');
        setLoading(false);
      }
    },
    [forgotRequest, handleFormState]
  );

  const {
    data,
    errors,
    handleChange,
    handleValidate,
    handleSubmit,
    resetForm
  } = useForm({
    validations: {
      email: {
        pattern: {
          value: emailRegex,
          message: English.pleaseEnterValidEmail
        },
        required: {
          value: true,
          message: English.fieldRequired
        }
      }
    },
    onSubmit: (data) => handleFormSubmit(data, invalidForm),
    initialValues: formTemplate
  });

  const invalidForm = useMemo(() => {
    return !!Object.keys(errors).length || !data.email;
  }, [errors, data]);

  useEffect(
    () => () => {
      resetForm();
      setLoading(false);
    },
    []
  );

  return (
    <StyledFormContainer onSubmit={handleSubmit}>
      <Box className="login-form-header">
        <Box component="h3">{English.forgotPasswordFirstFormTitle}</Box>
        <StyledSubtitle component="span">{English.forgotPasswordFirstFormSubtitle}</StyledSubtitle>
      </Box>

      <FormControl>
        <StyledInput
          sx={{ marginBottom: '1em' }}
          label={English.email}
          value={data.email}
          onChange={handleChange}
          onBlur={handleValidate}
          onSelect={handleChange}
          name="email"
          id="email"
          disabled={loading}
          error={!!errors.email}
          helperText={errors.email}
          autoComplete="off"
        />
      </FormControl>

      <Button disabled={invalidForm} className={loading ? 'loading' : ''} type="submit" variant="login-submit">
        {
          loading ? <CircleLoader className="sm" show={true} /> : English.resetPassword
        }
      </Button>
      <StyledOutlinedInputHelperText>
        {typeof errors.general === 'string' ? errors.general : errors.general?.map((msg) => <div key={Math.random(0, 1) * 1000}>{msg}</div>)}
      </StyledOutlinedInputHelperText>
    </StyledFormContainer>
  );
};

export default ForgotPasswordForm;
