import { createSelector } from '@reduxjs/toolkit';

const selectSeniorsSlice = (state) => state.seniors;

export const selectSeniors = createSelector(
  selectSeniorsSlice,
  (state) => state.seniors || [],
);

export const selectSeniorsTotal = createSelector(selectSeniors, (state) =>
  state.reduce((sum, item) => sum + item.seniors.length, 0),
);

export const selectEvents = createSelector(
  selectSeniorsSlice,
  (state) => state.events || [],
);

export const selectRelatedEvents = createSelector(
  selectSeniorsSlice,
  (state) => state.relatedEvents?.data || [],
);

export const selectIsRelatedEvent = createSelector(
  selectSeniorsSlice,
  (state) => state.isRelatedEvent,
);