import { createSlice } from '@reduxjs/toolkit';

import { loadComments, editComment } from './CommentsThunk';

const initialState = {
  comments: { data: [], total_count: 0 },
};

const commentsSlice = createSlice({
  name: 'comments',
  initialState,
  reducers: {
    deleteComment: (state, action) => {
      const id = action.payload;
      state.comments = state.comments.filter((item) => item.id !== id);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadComments.fulfilled, (state, action) => {
      state.comments = action.payload;
    });
    builder.addCase(editComment.fulfilled, (state, action) => {
      const { payload } = action;
      state.comments.data = state.comments.data.map((item) => {
        return item.id === payload.id ? { ...item, ...payload } : item;
      });
    });
  },
});

export const { deleteComment } = commentsSlice.actions;
export default commentsSlice.reducer;
