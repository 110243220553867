const CircleLoader = ({ show, className = '' }) => {
  return (
    <>
      {show ? (
        <div
          className={`loader-container ${className} absolute inset-0 flex items-center justify-center w-full h-full z-20`}
        >
          <div className="sbl-circ"></div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default CircleLoader;
