import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Button,
  Container,
  Info,
  CoverageIcon,
  Menu,
  MenuItem,
  Name,
  Text,
} from './DevicesCoverageItem.style';
import { Box } from '@mui/material';

import MoreIcon from '@mui/icons-material/MoreHoriz';
import {
  PeopleIcon,
  SupportIcon,
} from '../../../../../../assets/icons';

import { useNavigate } from 'react-router-dom';
import EventService from '../../../../../../services/EventService';
import { useQueryObject } from '../../../../../../utilities/hooks';
import { formatDate } from '../../../../../../utilities/dates';
import dayjs from '../../../../../../utilities/dayjs';
import { setMessageByUnits } from '../../../../../../redux/Devices/DevicesSlice';

import Colors from '../../../../../../theme/Colors';
import EVENT_TYPES from '../../../../../../services/EVENT_TYPES';
import englishText from '../../../../../../languages/English';

export const IconColors = {
  active: {
    color: Colors.sensiGreen,
    background: Colors.sensiLightGreen,
  },
  muted: {
    color: Colors.sensiGrey,
    background: Colors.lightGrey,
  },
  inactive: {
    color: Colors.sensiError,
    background: Colors.lightRose,
  },
  medium: {
    color: Colors.orange,
    background: Colors.lightOrange,
  },
};

const DevicesCoverageItem = ({ unit_name, devices_status, unit_id, index }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [menuAnchor, setMenuAnchor] = useState(null);
  const { customerId, officesIds, territoryIds } = useQueryObject();

  const menuOptions = useMemo(
    () => [
      {
        name: 'Client Page',
        icon: <PeopleIcon />,
        onClick: () => {
          EventService.fireEvent(EVENT_TYPES.COVERAGE_CLIENT_PAGE);
          navigate(
            `/dashboard/client/${unit_id}?customerId=${customerId}&officesIds=${officesIds}&territoryIds=${territoryIds}`,
          );
        },
      },
      {
        name: 'Contact Support',
        icon: <SupportIcon />,
        onClick: () => {
          const link = document.createElement('a');
          link.href =
            'mailto:support@sensi.ai?subject=Help with devices issues';
          link.rel = 'noopener noreferrer';
          link.target = '_blank';
          EventService.fireEvent(EVENT_TYPES.COVERAGE_CONTACT_SUPPORT);
          link.click();
          link.remove();
        },
      },
    ],
    [],
  );

  const { message, iconProps } = useMemo(() => {
    const priorities = ['inactive', 'muted', 'active'];

    let keys = Object.keys(devices_status).sort(
      (a, b) => priorities.indexOf(a) - priorities.indexOf(b),
    );
    const deviceCount = keys.length;
    // filter out 'active' status if there are multiple statuses
    if (keys.length > 1) {
      keys = keys.filter((key) => key !== 'active');
    }
    const latestTime = keys.reduce((maxTimestamp, key) => {
      const timestamp = dayjs(devices_status[key].timestamp);
      if (!timestamp.isValid()) {
        return devices_status[key].timestamp || englishText.moreThanAWeek;
      }
      return timestamp.isAfter(maxTimestamp)
        ? new Date(timestamp.format('YYYY-MM-DD HH:mm:ss'))
        : maxTimestamp;
    }, dayjs(0));

    const time = dayjs(latestTime).isValid()
      ? ` since ${formatDate(latestTime)}`
      : ` ${latestTime}`;

    let message = '';
    if (deviceCount === 1) message = `All the devices are ${keys[0]}`;
    else {
      message = keys
        .map((key) => {
          return devices_status[key].device_uid_or_room
            .map((device) => {
              return `${device} device is ${key}`;
            })
            .join(' and ');
        })
        .join(' and ');
    }
    message += time;
    dispatch(setMessageByUnits({ unit_id, message }));
    return {
      message,
      iconProps: IconColors[keys[0]],
    };
  }, []);

  return (
    <Container index={index}>
      <Info>
        <Box>
          <CoverageIcon {...iconProps} />
        </Box>
        <Box sx={{ alignSelf: 'center' }}>
          <Name variant="body2">{unit_name}</Name>
          <Text variant="body2">{message}</Text>
        </Box>
      </Info>
      <Button
        onClick={(evt) => {
          EventService.fireEvent(
            EVENT_TYPES.CLICK_ON_POSSIBLE_COVERAGE_ACTIONS,
          );
          setMenuAnchor(evt.currentTarget);
        }}
      >
        <MoreIcon />
      </Button>
      <Menu
        anchorEl={menuAnchor}
        open={!!menuAnchor}
        onClose={() => setMenuAnchor(null)}
        slotProps={{
          paper: {
            style: { boxShadow: '0px 8px 10px rgba(0, 0, 0, 0.2)' },
          },
        }}
      >
        {menuOptions.map((option) => (
          <MenuItem key={option.name} onClick={() => option.onClick()}>
            {option.icon} {option.name}
          </MenuItem>
        ))}
      </Menu>
    </Container>
  );
};

export default DevicesCoverageItem;
