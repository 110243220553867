import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ArrowDropDownIcon = (props) => (
  <SvgIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M 8 9.5833 L 11.1346 12.7115 L 11.4231 13 L 14.8462 9.5769 L 14.2692 9 L 11.4231 11.8397 L 8.5833 9 L 8 9.5833 Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default ArrowDropDownIcon;
