import { AppBar as MuiAppBar, Box, styled } from '@mui/material';

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme }) => ({
  backgroundColor: '#1C2538',
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

export const NavigationView = styled('div')`
  position: absolute;
  display: flex;
  justify-content: space-between;
`;

export const UserNotificationContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: `${theme.spacing(1)}`
}));

export const Flex = styled(Box)(() => ({
  flexGrow: 1
}));
