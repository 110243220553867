import get from 'lodash/get';

import { CircleIcon } from '../assets/icons';
import dayjs from '../utilities/dayjs';

export const buildQueryString = (options) => {
  return Object.entries(options)
    .filter(([, v]) => (typeof v === 'object' ? Object.keys(v).length : v || Number(v) === 0))
    .reduce(
      (acc, [k, v]) =>
        `${acc}${k}=${
          typeof v === 'object' ? encodeURIComponent(JSON.stringify(v)) : v
        }&`,
      '?'
    )
    .slice(0, -1);
};

export const orderByIdAndDate = (a, b, date_field, direction = 'asc') => {
  const id_diff = direction === 'asc' ? a.id - b.id : b.id - a.id;
  const date_diff =
    direction === 'asc'
      ? dayjs(a[date_field]) - dayjs(b[date_field])
      : dayjs(b[date_field]) - dayjs(a[date_field]);
  return date_diff + id_diff;
};

export const buildMultilineText = ({
  className,
  text,
  emptyLineHeight = '18px'
}) => {
  return (
    <>
      {text
        .trim()
        .split('\n')
        .map((text) => (
          <span
            key={Math.random(0, 1) * 1000}
            className={className}
            style={{ height: !text ? emptyLineHeight : 'unset' }}
          >
            {text}
          </span>
        ))}
    </>
  );
};

export const parseInsightText = ({
  className,
  text,
  emptyLineHeight = '18px'
}) => {
  if (!text.includes('*')) {
    return (
      <div className={`${className} flex  flex-col w-full`}>
        {buildMultilineText({ text })}
      </div>
    );
  }
  return (
    <div
      className={`${className} flex flex-col w-full`}
      style={{ alignItems: 'unset' }}
    >
      {text
        .split('*')
        .filter((v) => v)
        .map((bulletText, i) => (
          <div
            key={`${bulletText}${i}`}
            className="insight-text-bullet-item flex relative mb-2 last-of-type:mb-0"
          >
            <div className="insight-text-subicon">
              <CircleIcon />
            </div>
            <div className="insight-bullet-text flex flex-col">
              {buildMultilineText({ text: bulletText, emptyLineHeight })}
            </div>
          </div>
        ))}
    </div>
  );
};

export const sortByName = (a, b, field) => {
  return get(a, field, '').localeCompare(get(b, field, ''), undefined, { numeric: true, sensitivity: 'base' });
};

export const onBottomScrollHandler = ({ callback, event }) => {
  const { scrollTop, scrollHeight, clientHeight } = event;
  if ((Math.floor(scrollHeight - clientHeight - scrollTop) <= 1)) {
    callback();
  }
};

export const isPreviewMode = () => {
  return localStorage.getItem('FEATURES') === 'preview';
};