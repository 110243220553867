import React from 'react';
import style from './UnderlineButton.style';

const UnderlineButton = ({ buttonText = '', onClick, ...props }) => {
  return (
    <button style={style} onClick={onClick} {...props}>
      {buttonText}
    </button>
  );
};

export default UnderlineButton;
