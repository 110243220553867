import dayjs from '../../../../utilities/dayjs';
import { CloseIcon } from '../../../../assets/icons';

const SelectedFilterDateLabel = ({ name, value, removeSelectedFilter }) => {
  return (
    <>
      {!!(value.startDate && value.endDate) && (
        <div className="filter-selected-label flex items-center justify-center relative mr-4 mb-3 last-of-type:mr-0">
          {`${dayjs(value.startDate).format(
            'DD.MM.YYYY',
          )} - ${dayjs(value.endDate).format('DD.MM.YYYY')}`}
          <button
            className="clear-button flex items-center justify-center absolute"
            onClick={(e) => {
              e.stopPropagation();
              removeSelectedFilter(name);
            }}
          >
            <CloseIcon />
          </button>
        </div>
      )}
    </>
  );
};

export default SelectedFilterDateLabel;
