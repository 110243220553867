import React, { useState, useRef } from 'react';

import TakeawaysList from '../TakeawaysList';
import CreateTakeaway from '../CreateTakeaway';

import SubmitCareReportModal from '../../../../components/modals/SubmitCareReportModal';
import DeleteMainTakeawayModal from '../../../../components/modals/DeleteMainTakeawayModal';
import EditMainTakeawayModal from '../../../../components/modals/EditMainTakeawayModal';
import FinishAddTakeaways from '../../../../components/general/FinishAddTakeaways';
import {
  useTakeawaysContext,
  TAKEAWAY_MODALS,
} from '../../../../contexts/takeaways';
import English from '../../../../languages/English';
import { EventService } from '../../../../services';
import EVENT_TYPES from '../../../../services/EVENT_TYPES';
import { useCustomerState } from '../../../../contexts/customers';

const TakeawaysStage = ({ isDisabled, onReportGenerate = () => {}, reportData }) => {
  const {
    takeaways,
    setEditedTakeaway,
    takeawayInput,
    editedTakeaway,
    deleteTakeaway,
    modal,
    setModal,
  } = useTakeawaysContext();
  const [{ MixpanelUserData }] = useCustomerState();

  const [createDone, setCreateDone] = useState(false);
  const [editedIndex, setEditedIndex] = useState('');
  const confirmIndex = useRef(null);

  const handleCloseModal = () => {
    setModal(TAKEAWAY_MODALS.NONE);
  };

  const handleClickEdit = (i) => {
    if (takeawayInput.length > 0 && !editedTakeaway) {
      setEditedIndex(i);
      confirmIndex.current = i;
      setModal(TAKEAWAY_MODALS.DISCARD_DRAFT);
    } else if (editedTakeaway) {
      confirmIndex.current = i;
      setModal(TAKEAWAY_MODALS.DISCARD_DRAFT);
    } else {
      setEditedIndex(i);
      setEditedTakeaway(takeaways[i]);
      setCreateDone(false);
    }
  };

  const handleClickDelete = (i) => {
    confirmIndex.current = i;
    setModal(TAKEAWAY_MODALS.DELETE);
  };

  const handleDiscard = (isConfirmed) => {
    if (isConfirmed) {
      setEditedIndex(confirmIndex.current);
      setEditedTakeaway(takeaways[confirmIndex.current]);
      setCreateDone(false);
    }

    confirmIndex.current = '';
  };

  const handleDelete = (isConfirmed) => {
    if (isConfirmed) {
      deleteTakeaway(confirmIndex.current);
      EventService.fireEvent(EVENT_TYPES.DELETE_MAIN_TAKEAWAYS, {
        ...MixpanelUserData,
        description: takeawayInput
      });
    }
    confirmIndex.current = '';
  };

  return (
    <div className="takeaways-section">
      <div className="generate-report-panel flex">
        <div className="generate-report-body flex p-3 content-container ">
          {createDone ? (
            <FinishAddTakeaways onClose={() => setCreateDone(false)} />
          ) : (
            <CreateTakeaway
              editedIndex={editedIndex}
              setEditedIndex={setEditedIndex}
              setCreateDone={setCreateDone}
            />
          )}
        </div>
        <div className="generate-report-body p-3 content-container flex flex-col">
          <TakeawaysList
            onEdit={handleClickEdit}
            onDelete={handleClickDelete}
          />
        </div>
      </div>
      <EditMainTakeawayModal
        title={English.editTakeaway}
        open={modal === TAKEAWAY_MODALS.DISCARD_DRAFT}
        onClose={handleCloseModal}
        onAction={handleDiscard}
        btnText={English.continueEditingTakeaway}
        secBtnText={English.yesSure}
      />
      <DeleteMainTakeawayModal
        title={English.deleteTakeawayTitle}
        open={modal === TAKEAWAY_MODALS.DELETE}
        onClose={handleCloseModal}
        onAction={handleDelete}
        btnText={English.deleteTakeaway}
        secBtnText={English.back}
      />
      <SubmitCareReportModal
        reportData={reportData}
        open={modal === TAKEAWAY_MODALS.SUBMIT}
        onClose={handleCloseModal}
        onSubmit={onReportGenerate}
        isDisabled={isDisabled}
      />
    </div>
  );
};

export default TakeawaysStage;
