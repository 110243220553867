import { useCallback, useState, useEffect } from 'react';

import { ArrowIcon, FilterIcon } from '../../../assets/icons';

import FilterItem from '../FilterItem/FilterItem';
import SelectedFilterDateLabel from './components/SelectedFilterDateLabel';
import SelectedFilterLabel from './components/SelectedFilterLabel';

const HeaderFilter = ({
  filter = {},
  filterOpts: activeFilters = [],
  setFilter = () => {},
  onFilterBtnClick = () => {},
}) => {
  const [filterState, setFilterState] = useState({});
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setFilterState(
      Object.entries(filter).reduce((acc, [filter, value]) => {
        acc[filter] = value;
        return acc;
      }, {})
    );
  }, [filter]);

  const handleFilterUpdate = useCallback(
    (field, val) => {
      if (filterState[field]?.value === val.value) {
        delete filterState[field];
        setFilterState(filterState);
        setFilter(filterState);
      } else {
        const newData = { ...filterState, [field]: val };
        setFilterState(newData);
        setFilter(newData, field);
      }
    },
    [filterState, setFilter]
  );

  const removeSelectedFilter = useCallback(
    (field) => {
      delete filterState[field];
      setFilterState(filterState);
      setFilter(filterState);
    },
    [filterState, setFilter]
  );

  return (
    <div className="filter-section w-full pt-6 pb-0 flex flex-col z-50">
      <div className="flex flex-col justify-between items-start">
        <div className="flex items-center">
          <button
            className={`filter-open-button ${
              open ? 'opened' : ''
            } self-start flex items-center justify-between cursor-pointer mr-8`}
            onClick={() => {
              setOpen(!open);
              onFilterBtnClick(!open);
            }}
          >
            <FilterIcon
              className={`filter-btn-icon ${
                Object.keys(filter).length ? 'active' : ''
              }`}
            />
            <span>Filter</span>
            <ArrowIcon
              className={`arrow-icon ${!open ? 'rotate-90' : 'rotate-270'}`}
            />
          </button>
          <div className={'flex w-full items-center flex-wrap'}>
            {Object.entries(filter).map(([name, { value, label }]) => {
              return name === 'date' ? (
                <SelectedFilterDateLabel key={Math.random(0, 1) * 1000} name={name} value={value} removeSelectedFilter={removeSelectedFilter} />
              ) : (
                <SelectedFilterLabel key={Math.random(0, 1) * 1000} name={name} label={label} removeSelectedFilter={removeSelectedFilter} />
              );
            })}
          </div>
        </div>
        {!!open && (
          <div className="filter-container pb-0 flex flex-wrap w-full z-50">
            {activeFilters.map((filter, i) => (
              <FilterItem
                filter={filter}
                filterState={filterState}
                filterUpdate={handleFilterUpdate}
                filterClear={removeSelectedFilter}
                key={`${filter.title}${i}`}
                zIndex={activeFilters.length - i}
                id={filter.id}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default HeaderFilter;
