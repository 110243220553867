import { useMemo, useState } from 'react';
import SimpleBar from 'simplebar-react';

import NotificationItem from './NotificationItem';

import NotificationWrapper from '../../common/notificationWrapper';
import ToggleButton from '../../common/toggleButton';
import NotificationsLoader from '../../common/loaders/notificationsLoader';

import { NOTIFICATION_STATUS_DELETED, NOTIFICATION_STATUS_UNREAD } from '../../../utilities/const';
import { EndOfNotificationsImage, NoNotificationsImage } from '../../../assets/icons';
import { EventService } from '../../../services';
import EVENT_TYPES from '../../../services/EVENT_TYPES';

const NotificationList = ({
  notifications,
  loading,
  toggle,
  handleReadAll,
  handleNotificationUpdate,
}) => {
  const [showUnreadOnly, setShowUnreadOnly] = useState(false);

  const viewedNotifications = useMemo(() => {
    return notifications?.filter(({ notification_status_enum }) => {
      return !showUnreadOnly || notification_status_enum?.status === NOTIFICATION_STATUS_UNREAD;
    });
  }, [showUnreadOnly, notifications]);

  return (
    <NotificationWrapper className="main-notification" toggle={toggle}>
      <div className="notification-header relative flex items-center justify-between">
        <div className="header-title">Notifications</div>
        <div className="header-toggle-container flex items-end">
          <span className="mr-2">Only show unread</span>
          <ToggleButton onToggle={showUnread => {
            setShowUnreadOnly(showUnread);
            if (showUnread) {
              EventService.fireEvent(EVENT_TYPES.SHOW_ONLY_UNREAD_NOTIFICATIONS_ON);
            } else {
              EventService.fireEvent(EVENT_TYPES.SHOW_ONLY_UNREAD_NOTIFICATIONS_OFF);
            }
          }} />
        </div>
      </div>

      <div className="notification-sub-header relative flex items-end justify-between">
        <span>LATEST</span>

        <NotificationsLoader show={loading} />

        <button onClick={handleReadAll}>Mark all as read</button>
      </div>

      <SimpleBar className={`notifications-list ${loading ? '!pb-0' : ''}`}>
        { viewedNotifications.length && !loading ? (
          <>
            {
              viewedNotifications
                .filter(({ notification_status_enum: { status } }) => status !== NOTIFICATION_STATUS_DELETED)
                .map((item) => (
                  <NotificationItem
                    key={item.id}
                    notification={item}
                    handleNotificationUpdate={handleNotificationUpdate}
                    toggle={toggle}
                  />
                ))
            }
            <div className="end-of-notifications-label flex flex-col items-center justify-center">
              <EndOfNotificationsImage className="end-of-notification-image" />
              <div className="end-of-notifications-header">
                That&apos;s all your unread notifications
              </div>
              <div className="end-of-notifications-text">
                from the last 14 days
              </div>
            </div>
          </>
        ) : (
          <div className="no-notifications-label flex flex-col items-center justify-center">
            <NoNotificationsImage className="no-notification-image" />
            <div className="no-notifications-header">
              You finished all of your notifications!
            </div>
            <div className="no-notifications-text">
              Not all heroes wear capes, but you deserve one for improving seniors’ lives!
            </div>
          </div>
        )}
      </SimpleBar>
    </NotificationWrapper>
  );
};

export default NotificationList;
