import React, { useState, useEffect } from 'react';
import English from '../../../languages/English';
import { Tooltip, Typography } from '@mui/material';

const Timer = ({
  countdownTime,
  children,
  interval = 1000,
  onEnd = () => {},
}) => {
  const [timeRemaining, setTimeRemaining] = useState(countdownTime);
  const [timerEnded, setTimerEnded] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeRemaining((time) => {
        if (time <= 0) {
          setTimerEnded(true);
          clearInterval(timer);
          onEnd();
          return 0;
        } else {
          return time - 1;
        }
      });
    }, interval);
    return () => clearInterval(timer);
  }, [countdownTime, interval, onEnd]);

  const resetTimer = () => {
    setTimeRemaining(countdownTime);
    setTimerEnded(false);
  };

  const renderChild = (child) => {
    const childProps = !timerEnded && { sx: { cursor: 'no-drop' } };

    if (child.props && child.props.disabled) {
      return React.cloneElement(child, childProps);
    } else {
      return React.cloneElement(child, {
        onClick: () => child.props.onClick() && resetTimer(),
        disabled: !timerEnded,
        ...childProps,
      });
    }
  };
  const childrenMap = React.Children.map(children, (child) => renderChild(child));
  return (
    <>
      { timeRemaining > 0 ?
        <Tooltip title={<Typography variant='tooltip'>{English.timerTooltipTextStart} {timeRemaining} {English.timerTooltipTextEnd}</Typography>}>
          <span>{childrenMap}</span>
        </Tooltip> : <span>{childrenMap}</span> }
    </>
  );
};

export default Timer;
