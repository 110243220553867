import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import { Grid, MenuItem, MenuList, Typography } from '@mui/material';

export const StyledMenuContainer = styled(Box)({
  position: 'relative',
  background: 'inherit'
});

export const StyledContainer = styled(Grid)({
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'space-between'
});

export const StyledExpandContainer = styled(Box)({
  float: 'right',
  width: 30,
  height: 30,
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  marginRight: 19,
  marginBottom: 13,
  borderRadius: '12px',
  color: '#1C2538',
  background: '#F6F8FA',
  cursor: 'pointer',
  transform: 'rotate(180deg)',
  transition: 'transform 0.3s ease',
  '&.open': {
    transform: 'rotate(0deg)',
  },
});

export const StyledMenuList = styled(MenuList) ({
  float: 'left',
  background: 'inherit',
  position: 'absolute',
  padding: 0,
  marginTop: '-5px',
  marginLeft: '-1px',
  overflowY: 'auto',
  overflowX: 'hidden',
  maxHeight: 0,
  zIndex: 100,
  transition: 'max-height 0.3s ease-in-out',
  '&.open': {
    maxHeight: '50vh',
    zIndex: 10
  }
});

export const StyledMenuItem = styled(MenuItem)(({ theme, border, cursor }) => ({
  border: border || `1px solid ${theme.palette.border.main}`,
  color: '#1C2538',
  padding: '12px 20px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontWeight: 600,
  background: 'inherit',
  cursor,
}));

export const StyledText = styled(Typography) ({
  fontSize: 14,
  fontWeight: 700,
  marginLeft: 8
});
export const StyledIconContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between'
});

export const StyledTrendIconContainer = styled(Box)({
  marginRight: 9
});
