import mixpanel from 'mixpanel-browser';
import { adminUserString, commonUserString } from '../utilities/const';
import dayjs from '../utilities/dayjs';

const isMixpanelActive = () => {
  return Boolean(process.env.REACT_APP_MIXPANEL_KEY);
};


const init = () => {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY, {
    debug: process.env.REACT_APP_BUILD_ENV === 'develop' || window.location.hostname === 'localhost',
    ignore_dnt: true,
  });
  window.mixpanel = mixpanel;
};

const register = ({ user }) => {
  if (isMixpanelActive) {
    mixpanel.identify(user.id);
    mixpanel.people.set({
      id: user.id,
      $name: user.username,
      $email: user.email,
    });
    mixpanel.register({
      user_type: user.type
        ? user.type === 'admin'
          ? adminUserString
          : commonUserString
        : 'Unknown',
      platform: 'Dashboard',
    });
  }
};

const reset = () => {
  isMixpanelActive && mixpanel.reset();
};

const track = (event, props = {}) => {
  if (isMixpanelActive) {
    mixpanel.track(event, {
      ...props,
      time: dayjs().format('x'),
    });
  }
};

export default {
  init,
  register,
  reset,
  track
};