import { createSlice } from '@reduxjs/toolkit';

import { loadDevicesCoverage } from './DevicesThunk';

const initialState = {
  coverage: {
    low: [],
    medium: [],
    high: [],
  },
  messageByUnits: {},
};

const devicesSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    setMessageByUnits: (state, { payload }) => {
      state.messageByUnits[payload.unit_id] = payload.message;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(loadDevicesCoverage.fulfilled, (state, { payload }) => {
      state.coverage = payload;
    });
  },
});

export const {
  setMessageByUnits,
} = devicesSlice.actions;

export default devicesSlice.reducer;
