import { createSlice } from '@reduxjs/toolkit';
import { getActionsTakenLabels } from './EnumsThunk';

const initialState = {
  actionsTaken: null,
};

export const enumsSlice = createSlice({
  name: 'enums',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getActionsTakenLabels.fulfilled, (state, { payload }) => {
      state.actionsTaken = payload;
    });
  }
});

export default enumsSlice.reducer;
