import { useCallback, useEffect, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { get } from 'lodash';
import DatePicker from 'react-datepicker';

import { defaultDateFormat } from '../../utilities/const';
import dayjs from '../../utilities/dayjs';

const DatePickerComponent = ({
  className = '',
  value: customValue,
  onChange,
  isRange = false,
  button: Button,
  footer: Footer,
  inline = false
}) => {
  const [value, setValue] = useState({});

  useEffect(() => {
    isRange
      ? setValue(
        Object.entries(customValue)
          .filter(([, v]) => v)
          .map(([k, v]) => [k, dayjs(v).toDate()])
          .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {}),
      )
      : setValue(dayjs(customValue).toDate());
  }, [customValue, isRange]);

  const handleValueUpdate = useCallback(
    (val) => {
      setValue(dayjs(val).toDate());
      onChange(val);
    },
    [onChange]
  );

  const handleRangeValueUpdate = useCallback(
    ([start, end]) => {
      setValue({ startDate: start, endDate: end });
      onChange([start, end]);
    },
    [onChange]
  );

  return (
    <>
      {!inline ? (
        <Menu
          as="div"
          className={`${className} datepicker-container relative inline-block z-10`}
        >
          {({ open }) => (
            <>
              <Menu.Button className={'w-full h-full'}>
                {Button ? (
                  <Button />
                ) : (
                  <div
                    className={`datepicker-button ${
                      open ? 'active' : ''
                    } w-full px-2 py-2 justify-center`}
                  >
                    {isRange
                      ? `${dayjs(value.startDate).format(
                        defaultDateFormat,
                      )} - ${dayjs(value.endDate).format(defaultDateFormat)}`
                      : dayjs(value).format(defaultDateFormat)}
                  </div>
                )}
              </Menu.Button>
              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Menu.Items
                  as="div"
                  className={'absolute date-picker-container h-0 mt-2 focus:outline-none'}
                ></Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      ) : (
        <div
          className={`${className} datepicker-container relative inline-block z-10`}
        >
          {isRange ? (
            <DatePicker
              renderCustomHeader={({
                date,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled
              }) => (
                <>
                  <div className="react-datepicker__current-month">
                    <button
                      className="react-datepicker__navigation react-datepicker__navigation--previous"
                      onClick={decreaseMonth}
                      disabled={prevMonthButtonDisabled}
                    >
                      <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous">
                        Previous month
                      </span>
                    </button>

                    <div className="react-datepicker__current-month--month-text">
                      {dayjs(date).format('MMMM')}
                    </div>
                    <div className="react-datepicker__current-month--year-text">
                      {dayjs(date).format('YYYY')}
                    </div>

                    <button
                      className="react-datepicker__navigation react-datepicker__navigation--next"
                      onClick={increaseMonth}
                      disabled={nextMonthButtonDisabled}
                    >
                      <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next">
                        Next month
                      </span>
                    </button>
                  </div>

                  <div className="react-datepicker__day-names__display">
                    <div className="react-datepicker__day-name">Sun</div>
                    <div className="react-datepicker__day-name">Mon</div>
                    <div className="react-datepicker__day-name">Tue</div>
                    <div className="react-datepicker__day-name">Wed</div>
                    <div className="react-datepicker__day-name">Thu</div>
                    <div className="react-datepicker__day-name">Fri</div>
                    <div className="react-datepicker__day-name">Sat</div>
                  </div>
                </>
              )}
              selected={get(value, 'startDate')}
              onChange={handleRangeValueUpdate}
              startDate={get(value, 'startDate')}
              endDate={get(value, 'endDate')}
              selectsRange
              inline
            >
              {Footer ? <Footer /> : ''}
            </DatePicker>
          ) : (
            <DatePicker
              renderCustomHeader={({
                date,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled
              }) => (
                <>
                  <div className="react-datepicker__current-month">
                    <button
                      className="react-datepicker__navigation react-datepicker__navigation--previous"
                      onClick={decreaseMonth}
                      disabled={prevMonthButtonDisabled}
                    >
                      <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous">
                        Previous month
                      </span>
                    </button>

                    <div className="react-datepicker__current-month--month-text">
                      {dayjs(date).format('MMMM')}
                    </div>
                    <div className="react-datepicker__current-month--year-text">
                      {dayjs(date).format('YYYY')}
                    </div>

                    <button
                      className="react-datepicker__navigation react-datepicker__navigation--next"
                      onClick={increaseMonth}
                      disabled={nextMonthButtonDisabled}
                    >
                      <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next">
                        Next month
                      </span>
                    </button>
                  </div>

                  <div className="react-datepicker__day-names__display">
                    <div className="react-datepicker__day-name">Sun</div>
                    <div className="react-datepicker__day-name">Mon</div>
                    <div className="react-datepicker__day-name">Tue</div>
                    <div className="react-datepicker__day-name">Wed</div>
                    <div className="react-datepicker__day-name">Thu</div>
                    <div className="react-datepicker__day-name">Fri</div>
                    <div className="react-datepicker__day-name">Sat</div>
                  </div>
                </>
              )}
              calendarClassName={'single-select'}
              selected={value}
              onChange={handleValueUpdate}
              inline
            >
              {Footer ? <Footer /> : ''}
            </DatePicker>
          )}
        </div>
      )}
    </>
  );
};

export default DatePickerComponent;
