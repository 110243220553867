import { createTheme } from '@mui/material/styles';
import Colors from './Colors';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1100,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: Colors.sensiDarkPurple,
      secondary: Colors.white,
    },
    font: {
      main: Colors.sensiPurple,
    },
    border: {
      main: Colors.borderGrey,
      light: Colors.borderLightGrey,
    },
    black: {
      main: Colors.black,
    },
    sensiGrey: {
      main: Colors.sensiGrey,
    },
    error: {
      main: Colors.sensiError,
    },
  },
  typography: {
    h2: {
      fontFamily: 'OpenSans-Custom',
      fontSize: '20px',
      fontWeight: 700,
    },
    h3: {
      fontFamily: 'OpenSans-Custom',
      fontSize: '18px',
      fontWeight: 700,
    },
    h4: {
      fontFamily: 'OpenSans-Custom',
      fontSize: '16px',
      fontWeight: 700,
    },
    body1: {
      fontFamily: 'OpenSans-Custom',
      fontSize: '16px',
    },
    body2: {
      fontFamily: 'OpenSans-Custom',
      fontSize: '14px',
    },
    subtitle2: {
      fontFamily: 'OpenSans-Custom',
      fontSize: '12px',
    },
    tooltip: {
      whiteSpace: 'pre-line',
      color: Colors.white,
      fontSize: '12px',
    },
    fontFamily: [
      'OpenSans-Custom',
      'Caveat-Custom',
      'Caveat-SemiBold',
      'Caveat-Medium',
      'Caveat-Regular',
    ].join(','),
  },
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          backgroundColor: Colors.white,
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${Colors.sensiPurple} !important`,
          },
        },
        input: {
          padding: '8px 4px 8px 5px !important',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: Colors.white,
          '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${Colors.sensiPurple} !important`,
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: Colors.sensiLightGrey,
        },
      },
    },
    MuiFormControlLabel: {
      variants: [
        {
          props: { variant: 'login-form-control-label' },
          style: {
            '& .MuiFormControlLabel-label': {
              fontWeight: 400,
              fontSize: '12px',
            },
            '& .MuiCheckbox-root': {
              padding: '0 9px 0 11px',
            },
            '& .MuiCheckbox-root svg': {
              fontSize: '19px',
            },
          },
        },
      ],
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'login-submit' },
          style: {
            width: '100%',
            background: Colors.sensiPurple,
            color: Colors.white,
            borderRadius: '5px',
            textAlign: 'center',
            textTransform: 'none',
            padding: '13.5px 0',
            marginTop: '31px',
            fontWeight: 600,
            position: 'relative',
            '&:disabled': {
              color: Colors.white,
              backgroundColor: Colors.disableButtonGrey,
            },
            '&.loading': {
              padding: '29px 0',
            },
            '&:hover': {
              backgroundColor: Colors.sensiPurpleHover,
            },
            '& .loader-container': {
              background: 'none',
              '& .sbl-circ': {
                color: Colors.white,
              },
            },
          },
        },
        {
          props: { variant: 'login-return' },
          style: {
            minWidth: 'unset',
            backgroundColor: Colors.lightViolet,
            color: Colors.sensiGrey,
            position: 'absolute',
            borderRadius: '50%',
            padding: '12px',
          },
        },
      ],
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: Colors.black,
        },
        tooltipPlacementTop: {
          marginTop: 15,
        },
        arrow: {
          color: Colors.black,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          color: Colors.black,
          backgroundColor: '#BCC0F4',
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          left: 3,
          top: 5,
          width: 23,
          height: 23,
          borderRadius: 15,
          fontSize: 11.5,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          fontWeight: 700,
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
          '&.Mui-selected': {
            backgroundColor: '#EBF3FF',
          },
        },
      },
    },
    MuiPaper: {
      variants: [
        {
          props: { variant: 'container' },
          style: {
            border: `1px solid ${Colors.borderGrey}`,
            borderRadius: '10px',
          },
        },
      ],
    },
    MuiChip: {
      variants: [
        {
          props: { variant: 'square' },
          style: {
            background: '#EBF3FF',
            borderRadius: '5px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: Colors.sensiGrey,
          },
        },
      ],
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          ':after': {
            position: 'absolute',
            left: 0,
            top: '100%',
            right: 0,
            height: '1px',
            content: '""',
            opacity: 1,
            backgroundColor: 'rgba(0, 0, 0, 0.12)',
            transition:
              'opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          },
          ':first-of-type': {
            ':after': {
              top: '-1px',
            },
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          margin: '22px 0',
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        rounded: {
          borderRadius: '5px',
        },
      },
    },
  },
});

export default theme;
