import { useState, useCallback } from 'react';
import { Switch } from '@headlessui/react';

import { CheckLinearIcon, CloseIcon } from '../../assets/icons';

const ToggleButton = ({ className, defaultToggle = false, onToggle = () => {} }) => {
  const [toggled, setToggled] = useState(defaultToggle);

  const handleToggler = useCallback(
    (v) => {
      setToggled(v);
      onToggle(v);
    },
    [onToggle],
  );

  return (
    <div className={className}>
      <Switch
        checked={toggled}
        onChange={handleToggler}
        className={`toggle-button ${toggled ? 'enabled' : ''}
          relative flex items-center justify-between cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
      >
        <CheckLinearIcon className="check-icon" />
        <CloseIcon className="close-icon" />
        <span
          aria-hidden="true"
          className={`
            toggle-pointer pointer-events-none absolute h-[14px] w-[14px] rounded-full bg-white shadow-lg ring-0
          `}
        />
      </Switch>
    </div>
  );
};

export default ToggleButton;
