import { useEffect, useState } from 'react';
import ReactTable from 'react-table';

import CircleLoader from '../../../components/common/loaders/circleLoader';
import { useCustomerState } from '../../../contexts/customers';
import {
  useLoadAgencyDevices,
  useLoadAgencyInventoryDevices
} from '../../../hooks/devices';
import { OnlineIcon, MutedIcon } from '../../../assets/icons';

const DevicesKitsTab = () => {
  const [{ currentTerritories, territories, offices }] = useCustomerState();

  const [totalList, setTotalList] = useState([]);

  const [data, loading] = useLoadAgencyDevices({
    id: currentTerritories.map(({ id }) => id).join(','),
  });
  const [inventoryData, inventoryLoading] = useLoadAgencyInventoryDevices({
    id: currentTerritories.map(({ id }) => id).join(','),
  });

  useEffect(() => {
    const temp = data
      .map((device) => {
        const terr = territories.find(({ id }) => id === device.territory_id);
        const office = offices.find(({ id }) => id === terr?.office_id);
        return {
          client_name: device.unit_name,
          device_mac_id: device.uid,
          status: device.status.split(' ').join('_'),
          location: device.unit_type,
          router: device.router_sn,
          territory: terr?.name || '-',
          office: office?.name || '-'
        };
      })
      .reduce((acc, item) => {
        const label = `${item.office},${item.territory},${item.client_name}`;
        if (!acc[label]) {
          acc[label] = {};
        }

        if (!acc[label][item.router]) {
          acc[label][item.router] = [];
        }

        acc[label][item.router].push(item);
        return acc;
      }, {});

    inventoryData
      .map((device) => {
        const terr = territories.find(({ id }) => id === device.territory_id);
        const office = offices.find(({ id }) => id === terr.office_id);
        return {
          client_name: device.unit_name,
          device_mac_id: device.uid,
          status: device.status.split(' ').join('_'),
          router: device.router_sn,
          territory: terr?.name || '',
          office: office?.name || '',
          location: 'N/A'
        };
      })
      .forEach((item) => {
        const label = `${item.office},${item.territory},Inventory`;

        if (!temp[label]) {
          temp[label] = {};
        }

        if (!temp[label][item.router]) {
          temp[label][item.router] = [];
        }

        temp[label][item.router].push(item);
      }, {});

    setTotalList(
      Object.entries(temp)
        .map(([client_name, data]) => ({
          office: client_name.split(',')[0],
          territory: client_name.split(',')[1],
          client_name: client_name.split(',')[2],
          data
        }))
        .sort(
          (a, b) =>
            a.office.localeCompare(b.office) +
            a.territory.localeCompare(b.territory)
        )
    );
  }, [offices, territories, data, inventoryData]);

  return (
    <div className="container content-container highlights-list-container mt-8 flex flex-col relative">
      <ReactTable
        className={'table-auto devices-kits-table'}
        columns={[
          {
            Header: () => (
              <div className="flex items-center justify-center">Office</div>
            ),
            accessor: 'office',
            Cell: (row) => (
              <div
                title={row.value}
                className="flex items-center justify-center w-full"
              >
                {row.value || '—'}
              </div>
            )
          },
          {
            Header: () => (
              <div className="flex items-center justify-center">Territory</div>
            ),
            accessor: 'territory',
            Cell: (row) => (
              <div
                title={row.value}
                className="flex items-center justify-center w-full"
              >
                {row.value || '—'}
              </div>
            )
          },
          {
            Header: () => (
              <div className="flex items-center justify-center">Location</div>
            ),
            accessor: 'client_name',
            Cell: (row) => (
              <div
                title={row.value}
                className="flex items-center justify-center w-full"
              >
                {row.value || '—'}
              </div>
            )
          },
          {
            Header: () => (
              <div className="flex items-center justify-center">Status</div>
            ),
            accessor: 'statuses',
            Cell: (row) => (
              <div className="flex flex-col items-center justify-center w-full p-0">
                {Object.values(row.original.data)
                  .reduce((acc, arr) => acc.concat(arr), [])
                  .sort((a, b) => +a.router - +b.router)
                  .map(({ status }) => (
                    <div
                      key={Math.random(0, 1) * 1000}
                      className={'inner-cell-row w-full flex justify-center items-center'}
                    >
                      {['active', 'not_active'].includes(status) ? (
                        <OnlineIcon
                          className={`device-status-icon ${status}`}
                        />
                      ) : status === 'Deployment_Ready' ? (
                        <OnlineIcon className={'device-status-icon muted'} />
                      ) : (
                        <MutedIcon className={`device-status-icon ${status}`} />
                      )}
                    </div>
                  ))}
              </div>
            )
          },
          {
            Header: () => (
              <div className="flex items-center justify-center">Room</div>
            ),
            accessor: 'rooms',
            Cell: (row) => (
              <div className="flex flex-col items-center justify-center w-full p-0">
                {Object.values(row.original.data)
                  .reduce((acc, arr) => acc.concat(arr), [])
                  .sort((a, b) => +a.router - +b.router)
                  .map(({ location }) => (
                    <div key={Math.random(0, 1) * 1000} className={'inner-cell-row w-full text-center'}>
                      {location || ''}
                    </div>
                  ))}
              </div>
            )
          },
          {
            Header: () => (
              <div className="flex items-center justify-center">
                Device Mac ID
              </div>
            ),
            accessor: 'deviceMacIds',
            Cell: (row) => (
              <div className="flex flex-col items-center justify-center w-full p-0">
                {Object.values(row.original.data)
                  .reduce((acc, arr) => acc.concat(arr), [])
                  .sort((a, b) => +a.router - +b.router)
                  .map(({ device_mac_id }) => (
                    <div key={Math.random(0, 1) * 1000} className={'inner-cell-row w-full text-center'}>
                      {device_mac_id || '—'}
                    </div>
                  ))}
              </div>
            )
          },
        ]}
        data={totalList}
        showPagination={false}
        pageSize={totalList.length || 3}
        resizable={false}
        sortable={false}
      />

      <CircleLoader show={loading || inventoryLoading} />
    </div>
  );
};

export default DevicesKitsTab;
