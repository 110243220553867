import { createAsyncAction } from '../../utilities/redux';
import agent from '../../utilities/agent';
import { selectUser } from '../User';

export const loadTodos = createAsyncAction(
  'loadTodos',
  async (_, { rejectWithValue }) => {
    try {
      const response = await agent.get('/todos');

      return response.data;
    } catch (err) {
      return rejectWithValue(
        { message: err?.response?.data || 'Error while loading todos' },
        { toast: true },
      );
    }
  },
);

export const createTodo = createAsyncAction(
  'createTodo',
  async (payload, { rejectWithValue, getState }) => {
    const { name, care_log_id, insight_id, user_id = null } = payload;
    try {
      const response = await agent.post('/todos', {
        user_id,
        name,
        care_log_id,
        insight_id,
      });
      return +selectUser(getState()).id === +response.data?.assignee_id ? response.data : null;
    } catch (err) {
      return rejectWithValue(
        { message: err?.response?.data || 'Error while creating todo' },
        { toast: true },
      );
    }
  },
);
export const deleteTodos = createAsyncAction(
  'deleteTodos',
  async ({ care_log_id, insight_id }, { rejectWithValue }) => {
    try {
      const response = await agent.delete('/todos', { data: { care_log_id, insight_id } });
      return response.data;
    } catch (err) {
      return rejectWithValue(
        { message: err?.response?.data || 'Error while deleting todos' },
        { toast: true },
      );
    }
  },
);

export const editTodo = createAsyncAction(
  'editTodo',
  async ({ id, onProgress = () => {}, payload }) => {
    const response = await agent.patch(`/todos/${id}`, payload, {
      onDownloadProgress: onProgress,
    });
    return response.data;
  },
);
