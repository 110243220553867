import agent from '../utilities/agent';

export const getSeniorsByCategoriesAPI = async (territoriesIds, ts) => {
  const seniorsByCategories = await agent.get(`/carelogsAndInsights/seniorsCategoriesAggregation/${territoriesIds}/${ts}`);
  return seniorsByCategories?.data?.data;  
};

export const getEventsBySeniorAPI = async ({ ts, unitId, insightId, carelogId, assignees, relatedToInsight }) => {
  return agent.get('/carelogsAndInsights/events',{
    params: {
      ts,
      unitId,
      insightId,
      carelogId,
      assignees,
      relatedToInsight
    }
  });
};

export const getActionsTakenLabelsAPI = async () => {
  const actionsTaken = await agent.get('/getActionTakenLabels');
  return actionsTaken?.data?.data;
};

export const updateStatusAPI = async (isCarlog, id, isHelpful) => {
  try {
    const endpoint = isCarlog ? '/carelogs' : '/insights';
    const updateStatus = await agent.post(`${endpoint}/status/${id}`,{
      isHelpful
    });
    return updateStatus?.status === 200;
  } catch (error) {
    console.log(error);
    // TODO - handle error
  }
};

export const updateAssigneeAPI = async (isCarlog, eventId, assigneeId) => {
  try {
    const endpoint = isCarlog ? '/carelogs' : '/insights';
    const updateAssignee = await agent.post(`${endpoint}/assignee/${eventId}`, {
      assignee: assigneeId,
    });
    return updateAssignee?.status === 200;
  } catch (error) {
    console.log(error);
    // TODO - handle error
  }
};

export const updateActionTakenAPI = async ({ isCarlog, eventId, actionTaken, actionTakenMessage, addToFamilyReport }) => {
  try {
    const endpoint = isCarlog ? '/carelogs' : '/insights';
    const updateActionTaken = await agent.post(`${endpoint}/addToFamilyReport/${eventId}`, {
      actionTaken,
      actionTakenMessage,
      addToFamilyReport,
    });
    return updateActionTaken?.status === 200;
  } catch (error) {
    console.log(error);
    // TODO - handle error
  }
};

export const getRelatedEventsAPI = async ({ insight_id }) => {
  const res = await agent.get(`/insightsCarelogs/${insight_id}`);
  return res.data.data.map((item) => ({ ...item.care_logs, is_carelog: true }));
};