import React, { useEffect, useRef, memo } from 'react';
import { Container, Text } from './EmptyTodos.style';

import { Fade, Box } from '@mui/material';
import { AllDoneImage, EmptyImage, TodosSkeleton } from './components';

import { useSelector } from 'react-redux';
import { loadTodos, selectTodos } from '../../../../redux/Todos';

const EmptyTodos = () => {
  const todos = useSelector(selectTodos);
  const loading = useSelector(loadTodos.isLoading);
  const prevLength = useRef(todos.length);

  useEffect(() => {
    prevLength.current = todos.length;
  }, [todos.length]);

  const title =
    prevLength.current > 0 ? 'Congratulations!' : 'Boost your productivity!';
  const subtitle =
    prevLength.current > 0 ? (
      <>
        You&apos;ve completed all your tasks.
        <br />
        Enjoy the satisfaction of a job well done!
      </>
    ) : (
      <>
        Stay organized and get things done
        <br />
        effortlessly with your To Do list
      </>
    );

  return loading ? (
    <TodosSkeleton />
  ) : todos.length !== 0 ? null : (
    <Container>
      <Fade in>
        <Box>
          {prevLength.current > 0 ? (
            <AllDoneImage style={{ margin: '0 auto 26px' }} />
          ) : (
            <EmptyImage style={{ margin: '0 auto 30px' }} />
          )}
          <Text variant="h4">{title}</Text>
          <Text variant="subtitle2">{subtitle}</Text>
        </Box>
      </Fade>
    </Container>
  );
};

export default memo(EmptyTodos);
