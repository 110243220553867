import MixpanelService from './MixpanelService';

const fireEvent = (data, params) => {
  try {
    const { name, additionalData } = data;
    const additionalParams = { ...(additionalData || {}), ...(params || {}) };
    MixpanelService.track(name, additionalParams);
    window.Appcues.track(name, additionalParams);
  } catch (e) {
    console.log('Error occurred while attempting to fire event', e);
  }
};

export default {
  fireEvent
};
