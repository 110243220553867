import { createAsyncAction } from '../../utilities/redux';
import agent from '../../utilities/agent';

export const loadDevicesCoverage = createAsyncAction(
  '/loadDevicesCoverage',
  async ({ time, territoryIds }, { rejectWithValue }) => {
    try {
      const response = await agent.get(
        `/devices/coverage/${territoryIds}/${time}`,
      );
      return response.data.data;
    } catch (err) {
      return rejectWithValue(
        {
          message:
            err?.response?.data || 'Error while loading devices coverage',
        },
        { toast: true },
      );
    }
  },
);
