import React, { useMemo } from 'react';
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';
import LoginView from '../views/login/login';
import DashboardRouter from './dashboardRouter';
import ProtectedRoute from '../components/common/ProtectedRoute/ProtectedRoute';
import DashboardView from '../views/dashboard/dashboard';
import TerritoryView from '../views/territory/territory';
import ClientView from '../views/clients/clients';
import ClientReportView from '../views/clientsReport/clientsReport';
import DeviceView from '../views/device/device';
import { setNavigateLinkAfterLogin } from '../redux/User';
import { useDispatch } from 'react-redux';

const ApplicationRouter = () => {
  const dispatch = useDispatch();
  dispatch(setNavigateLinkAfterLogin(window.location.pathname + window.location.search));

  const router = useMemo(() => {

    return createBrowserRouter([
      {
        path: '/',
        element: <ProtectedRoute />,
        children: [
          { path: '/', element: <Navigate to='/dashboard' replace /> },
          {
            path: 'dashboard',
            element: <DashboardRouter />,
            children: [
              { index: true, element: <DashboardView /> },
              { path: 'territory', element: <TerritoryView /> },
              { path: 'client/*',
                children: [
                  { path: ':id', element: <ClientView /> },
                  { path: ':id/report', element: <ClientReportView /> }
                ]
              },
              { path: 'device', element: <DeviceView /> },
              { path: '*', element: <Navigate to="/" replace /> }
            ]
          }
        ]
      },
      { path: '/login', element: <LoginView /> }
    ]);
  }, []);

  return <RouterProvider router={router} />;
};

export default ApplicationRouter;