import { Typography, styled, Box } from '@mui/material';
import Colors from '../../../../theme/Colors';

export const ReadContainer = styled(Box)(({ }) => ({
  display: 'inline-flex',
  alignItems: 'center',
}));

export const ReadText = styled(Typography)({
  color: Colors.sensiGreen,
  fontWeight: 600,
  fontSize: 11,
  marginLeft: '5px'
});

export const ReadContainerSpan = styled(Typography)(({ }) => ({
  position: 'relative',
  whiteSpace: 'nowrap',
  lineHeight: '16px',
  marginLeft: '5px',
  cursor: 'default',
  zIndex: 10
}));
