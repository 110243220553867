import { createAsyncAction } from '../../utilities/redux';
import agent from '../../utilities/agent';

export const fetchCarelogsActions = createAsyncAction(
  '/fetchCarelogsActions',
  async ({ id, actionTypeId }, { rejectWithValue }) => {
    try {
      const response = await agent.get(`/carelogAction/${id}/${actionTypeId}`);

      return response.data.actions;
    } catch (err) {
      return rejectWithValue(
        {
          message:
            err?.response?.data || 'Error while fetching carelog actions',
        },
        { toast: true },
      );
    }
  },
);
