import axios from 'axios';
import { Auth } from 'aws-amplify';
import { AMPLIFY_ERRORS } from './const';
import { MixpanelService } from '../services';

const { REACT_APP_BASE_API_URL } = process.env;

const localStorage = window.localStorage;

const axiosInstance = axios.create({
  baseURL: REACT_APP_BASE_API_URL,
  timeout: 10000,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    try {
      const token = (await Auth.currentSession()).idToken?.jwtToken;
      config.headers.Authorization = `Bearer ${token}`;
    } catch (error) {
      console.error('Error retrieving token:', error);
      throw error;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (AMPLIFY_ERRORS.includes(error) || AMPLIFY_ERRORS.includes(error?.name)) {
      MixpanelService.reset();
      localStorage.removeItem('persist:root');
    }
    else {
      return Promise.reject(error);
    }
  }
);

export default axiosInstance;

