import * as React from 'react';

import { ExclamationIcon } from './components/ExclamationIcon';
import { EyeIcon, CheckIcon } from '../../../../assets/icons';
import { SeniorMenu } from './components/SeniorMenu/SeniorMenu';
import {
  StyledContainer,
  StyledIconContainer,
  StyledTitleContainer,
  StyledTitleContainerText,
} from './SeniorCategoryCard.style';
import Colors from '../../../../theme/Colors';

const iconMap = {
  'High Priority': <ExclamationIcon fill="#BF0000" />,
  'Needs Your Attention': <ExclamationIcon fill="#F49136" />,
  'Good To Know' : <EyeIcon />,
  'No News - Good News' : <CheckIcon />,
};

const SeniorCategoryCard = ({ category, seniors }) => {
  const isHighPriority = React.useMemo(() => {
    return category === 'High Priority' && seniors.length > 0;
  }, [category, seniors.length]);

  return (
    <StyledContainer variant="container"
      bgcolor={ isHighPriority && seniors.length > 0 ? Colors.highPriority : Colors.white }
      sx={{ borderColor: isHighPriority ? Colors.borderHighPriority : Colors.borderGrey }}
    >
      <StyledTitleContainer>
        <StyledIconContainer>
          { iconMap[category || 'default'] }
        </StyledIconContainer>
        <StyledTitleContainerText variant="body2">
          {category || 'No News - Good News'}
        </StyledTitleContainerText>
      </StyledTitleContainer>
      <SeniorMenu
        seniors={seniors}
        clickable={category !== 'No News - Good News'}
        category={category} />
    </StyledContainer>
  );
};

export default SeniorCategoryCard;
