import {
  styled,
  Box
} from '@mui/material';
import Colors from '../../../../theme/Colors';

const ForgotPasswordLabel = styled(Box)(() => ({
  fontSize: '12px',
  fontWeight: 700,
  color: Colors.sensiPurple,
  textDecoration: 'none',
  width: 'fit-content',
  alignSelf: 'flex-end',
  cursor: 'pointer',
  marginTop: '5px',
  '&:hover': {
    textDecoration: 'underline'
  },
}));

export {
  ForgotPasswordLabel,
};