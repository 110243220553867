import React, { useCallback, useState } from 'react';

import SharedModal from '../../../../components/common/modal';
import DatePickerComponent from '../../../../components/common/datePicker';
import { useNavigateTo } from '../../../../utilities/hooks';
import dayjs from '../../../../utilities/dayjs';

const DateReportModal = ({ toggle }) => {
  const [reportRange, setReportRange] = useState({
    startDate: dayjs().subtract(2, 'weeks').toDate(),
    endDate: dayjs().toDate(),
  });

  const navigate = useNavigateTo();

  const handleReportRange = useCallback(([start, end]) => {
    setReportRange({
      startDate: start,
      endDate: end
    });
  }, []);

  const handleToReport = useCallback(() => {
    if (reportRange.startDate && reportRange.endDate) {
      navigate({
        url: 'report',
        params: Object.entries(reportRange).reduce(
          (acc, [k, v]) => ({
            ...acc,
            [k]: dayjs(v).format('YYYY-MM-DD'),
          }),
          {}
        ),
        omit: ['notifiedReportId', 'tab'],
      });
    }
  }, [navigate, reportRange]);

  return (
    <SharedModal
      className={'report-modal'}
      toggle={toggle}
      title={() => (
        <span>
          Please choose the <br /> dates for the report
        </span>
      )}
    >
      <div className="modal-body">
        <DatePickerComponent
          className="datepicker-modal-element"
          value={reportRange}
          onChange={handleReportRange}
          footer={() => (
            <div
              className={`to-report-button ${
                reportRange.startDate && reportRange.endDate ? '' : 'disabled'
              } w-full flex items-center justify-center px-4 cursor-pointer`}
              onClick={handleToReport}
            >
              Continue
            </div>
          )}
          isRange={true}
          inline={true}
        />
      </div>
    </SharedModal>
  );
};

export default DateReportModal;
