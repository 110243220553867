import { styled, Box, Typography } from '@mui/material';
import Colors from '../../../../../../theme/Colors';

export const Container = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.border.main}`,
  borderRadius: '5px',
  padding: '8px',
  marginBottom: '13px',
  overflow: 'auto'
}));

export const StyledTop = styled(Box)({
  color: Colors.sensiGrey,
  marginLeft: '0.5rem'
});

export const StyledName = styled(Typography)({
  fontWeight: 700,
  fontSize: '10px',
  display: 'inline-block'
});

export const StyledDate = styled(Typography)({
  display: 'inline-block',
  fontSize: '10px',
  marginLeft: '0.5rem'
});

export const StyledActions = styled(Box)({
  float: 'right',
  marginTop: '-25px'
});

export const IconContainer = styled(Box)(({ bgcolor }) => ({
  borderRadius: '5px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: bgcolor || Colors.pink,
  height: '20px',
  width: '20px',
  margin: '0 2px',
  cursor: 'pointer',
  '& > svg': {
    height: '10.4px',
    width: '10.4px',
  }
}));