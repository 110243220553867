import { styled, Paper } from '@mui/material';
import Colors from '../../../../theme/Colors';

export const Wrapper = styled(Paper)({
  width: '100%',
  height: 383,
  overflow: 'hidden',
});

export const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  position: 'relative',
  width: '100%',
  height: 'calc(100% - 65px)',
});

export const List = styled('div')({
  position: 'relative',
  height: '100%',
  overflow: 'hidden auto',
  '&::-webkit-scrollbar': {
    width: 8,
  },
  '&::-webkit-scrollbar-track': {
    WebkitBoxShadow: 'none',
    backgroundColor: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#d9d9d9',
    borderRadius: 20,
  },
});

export const ListBlur = styled('div')({
  position: 'sticky',
  bottom: 0,
  width: '100%',
  height: 0,
  overflow: 'visible',
  zIndex: 2,
  pointerEvents: 'none',
  '&::after': {
    display: 'block',
    position: 'absolute',
    bottom: 0,
    left: 5,
    width: 'calc(100% - 10px)',
    height: 23,
    backgroundColor: Colors.white,
    content: '""',
    filter: 'blur(10px)',
  },
});
