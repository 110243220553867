/* eslint-disable no-useless-escape */
import React, { useCallback, useState, useEffect, useMemo } from 'react';
import {
  FormControl,
  InputAdornment,
  IconButton,
  Button,
  Box,
  Typography,
  Tooltip,
} from '@mui/material';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

import CircleLoader from '../../../../components/common/loaders/circleLoader';
import useForm from '../../../../hooks/form-state';
import { codeRegex } from '../../../../utilities/const';
import {
  ResendButtonsContainer,
  StyledFormContainer,
  StyledInputLabel,
  StyledOutlinedInputHelperText,
  StyledOutlinedInputWithTooltip,
} from '../../defaultStyled/default.style';
import English from '../../../../languages/English';
import Timer from '../../../../components/common/Timer/Timer';
import { useDispatch, useSelector } from 'react-redux';
import { forgotConfirm, forgotRequest, setPassword, setUserData } from '../../../../redux/User';
import { useNavigate } from 'react-router-dom';
import { EventService } from '../../../../services';
import EVENT_TYPES from '../../../../services/EVENT_TYPES';

const formTemplate = {
  code: '',
};

const ForgotPasswordForm = ({ handleFormState }) => {
  const dispatch = useDispatch();
  const email = useSelector(state => state.user.email);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleFormSubmit = useCallback(
    async (data, isInvalid, addErrors) => {
      try {
        if (!isInvalid) {
          const user = await dispatch(forgotConfirm(data));
          if (user.error) {
            throw user.error;
          }
          await dispatch(setUserData(user?.payload));
          EventService.fireEvent(EVENT_TYPES.SUCCESSFULLY_RESET_PASSWORD);
          await dispatch(setPassword(null));
          if (user?.payload?.challengeName === 'SMS_MFA') {
            return handleFormState('codeVerification');
          }
          setLoading(false);
          if (user && !user?.payload?.challengeName) {
            navigate('/dashboard');
          } else {
            addErrors({ code: [English.somethingWrong] });
            setLoading(false);
          }
        }
      } catch (error) {
        console.log(error);
        EventService.fireEvent(EVENT_TYPES.FAILED_SETTING_NEW_PASSWORD, {
          ...error,
          email
        });
        switch (error.code) {
          case 'ExpiredCodeException':
            addErrors({ code: English.expiredCodeException });
            break;
          case 'CodeDeliveryFailureException':
            addErrors({ code: English.codeDeliveryFailureException });
            break;
          case 'CodeMismatchException':
            addErrors({ code: English.codeMismatchException });
            break;
          case 'LimitExceededException':
            addErrors({ code: English.tooManyAttempts });
            break;
          default:
            addErrors({ code: English.generalError });
        }
      }
    },
    [handleFormState],
  );

  const {
    data,
    errors,
    handleChange,
    handleValidate,
    handleSubmit,
    resetForm,
    addErrors,
  } = useForm({
    validations: {
      code: {
        pattern: {
          value: codeRegex,
          message: 'Enter valid verification code',
        },
        custom: {
          value: (val) => val.length === 6,
          message: 'Enter valid verification code',
        },
        required: {
          value: true,
          message: 'This field is required',
        },
      },
    },
    onSubmit: (data) => handleFormSubmit(data, invalidForm, addErrors),
    initialValues: formTemplate,
  });

  const invalidForm = useMemo(() => {
    return !!Object.keys(errors).length || !data.code;
  }, [errors, data]);

  useEffect(
    () => () => {
      resetForm();
      setLoading(false);
    },
    [],
  );

  const resendCodeViaEmail = async () => {
    EventService.fireEvent(EVENT_TYPES.RESET_PASSWORD_RESEND_CODE_VIA_EMAIL, {
      email
    });
    await dispatch(forgotRequest());
  };

  const handleOpen = () => {
    EventService.fireEvent(EVENT_TYPES.RESET_PASSWORD_HELP, {
      email
    });
  };


  return (
    <StyledFormContainer onSubmit={handleSubmit}>
      <Box className="login-form-header">
        <Box component="h3">{English.forgotPasswordSecondFormTitle}</Box>
        <Box component="span">{English.forgotPasswordSecondFormSubtitle}</Box>
      </Box>

      <FormControl>
        <StyledInputLabel
          className={errors.code ? 'error' : ''}
          htmlFor="password"
        >
          {English.code}
        </StyledInputLabel>
        <StyledOutlinedInputWithTooltip
          id="code"
          name="code"
          label={English.code}
          type={'text'}
          value={data.password}
          onChange={handleChange}
          onBlur={handleValidate}
          endAdornment={
            <InputAdornment position="end">
              <Tooltip
                PopperProps={{
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [60, 10]
                      }
                    }
                  ]
                }}
                title={
                  <Typography variant="tooltip">
                    {English.forgotPasswordSecondFormTooltip}
                  </Typography>
                }
                arrow
                placement="right"
                onOpen={handleOpen}
              >
                <IconButton disableRipple edge="end">
                  <QuestionMarkIcon color="black"/>
                </IconButton>
              </Tooltip>
            </InputAdornment>
          }
          error={!!errors.code}
          disabled={loading}
          autoComplete="off"
        />
        {!!errors.code && (
          <StyledOutlinedInputHelperText>
            {typeof errors.code === 'string' ? errors.code : errors.code?.map((msg) => (
              <div key={Math.random(0, 1) * 1000}>{msg}</div>
            ))}
          </StyledOutlinedInputHelperText>
        )}
      </FormControl>

      <Button
        className={loading ? 'loading' : ''}
        type="submit"
        variant="login-submit"
        disabled={invalidForm}
      >
        {loading ? <CircleLoader className="sm" show={true} /> : English.submit}
      </Button>

      <Timer countdownTime={10}>
        <ResendButtonsContainer component="button" onClick={() => resendCodeViaEmail()}>
          <Typography variant="subtitle2">
            {English.sendCodeVia} <strong>{English.email}</strong>
          </Typography>
        </ResendButtonsContainer>
      </Timer>
    </StyledFormContainer>
  );
};

export default ForgotPasswordForm;
