import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  FormControl,
  InputAdornment,
  IconButton,
  Button,
  Box,
  Tooltip,
  Typography,
} from '@mui/material';

import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

import CircleLoader from '../../../../components/common/loaders/circleLoader';
import useForm from '../../../../hooks/form-state';
import { codeRegex } from '../../../../utilities/const';
import {
  StyledFormContainer,
  StyledInputLabel,
  StyledOutlinedInputHelperText,
  StyledOutlinedInputWithTooltip,
  StyledGeneralErrorTextContainer,
  ResendButtonsContainer,
} from '../../defaultStyled/default.style';
import English from '../../../../languages/English';
import { useDispatch, useSelector } from 'react-redux';
import { signIn, verifyCode } from '../../../../redux/User';
import Timer from '../../../../components/common/Timer/Timer';
import { EventService } from '../../../../services';
import EVENT_TYPES from '../../../../services/EVENT_TYPES';

const formTemplate = {
  code: ''
};

const CodeApproveForm = ({ navigateLinkAfterLogin }) => {
  const dispatch = useDispatch();
  const user =  useSelector(state => state.user);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigateLinkAfterLogin ? navigateLinkAfterLogin === '/login' ? navigate('/dashboard/') : navigate(navigateLinkAfterLogin) : navigate('/dashboard/');
  };

  const handleFormSubmit = useCallback(
    async (data, isInvalid) => {
      try {
        if (!isInvalid) {
          setLoading(true);
          const loggedUser = await dispatch(verifyCode(data));
          if (loggedUser?.payload) {
            EventService.fireEvent(EVENT_TYPES.SUCCESSFUL_IDENTIFICATION);
            handleNavigate();
          } else if (loggedUser.error) {
            throw loggedUser.error;
          }
          setLoading(false);
        }
      } catch (error) {
        addErrors({ code: English.codeNotValid });
        EventService.fireEvent(EVENT_TYPES.UNSUCCESSFUL_IDENTIFICATION, {
          error_message: error.message
        });
        setLoading(false);
      }
    },
    [navigate, verifyCode]
  );

  const {
    data,
    errors,
    handleChange,
    handleValidate,
    handleSubmit,
    resetForm,
    addErrors
  } = useForm({
    validations: {
      code: {
        pattern: {
          value: codeRegex,
          message: English.enterValidCode
        },
        custom: {
          value: (val) => val.length === 6,
          message: English.enterValidCode
        },
        required: {
          value: true,
          message: English.fieldRequired
        }
      }
    },
    onSubmit: (data) => handleFormSubmit(data, invalidForm),
    initialValues: formTemplate
  });

  const invalidForm = useMemo(() => {
    return !!Object.keys(errors).length || !data.code;
  }, [errors, data]);

  useEffect(
    () => () => {
      resetForm();
      setLoading(false);
    },
    []
  );

  const resendCodeViaSMS = async () => {
    EventService.fireEvent(EVENT_TYPES.RESEND_CODE_VIA_SMS, {
      email: user.email
    });
    await dispatch(signIn(user));
  };

  const handleOpen = () => {
    EventService.fireEvent(EVENT_TYPES.HELP_WITH_VERIFICATION_CODE, {
      email: user.email
    });
  };

  return (
    <StyledFormContainer onSubmit={handleSubmit}>
      <Box className="login-form-header">
        <Box component="h3">{English.codeApproveTitle}</Box>
        <Box component="span">{English.codeApproveSubtitle}</Box>
      </Box>

      <FormControl>
        <StyledInputLabel className={errors.code ? 'error' : ''} htmlFor="password">{English.code}</StyledInputLabel>
        <StyledOutlinedInputWithTooltip
          id="code"
          name="code"
          label={English.code}
          type={'text'}
          value={data.password}
          onChange={handleChange}
          onBlur={handleValidate}
          autofocus
          endAdornment={
            <InputAdornment position="end">
              <Tooltip
                PopperProps={{
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [40, 5]
                      }
                    }
                  ]
                }}
                onOpen={handleOpen}
                title={<Typography variant="tooltip">{English.codeApproveTooltip}</Typography>}
                arrow
                placement="right"
              >
                <IconButton
                  disableRipple
                  edge="end"
                >
                  <QuestionMarkIcon color="black" />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          }
          error={!!errors.code}
          disabled={loading}
          autoComplete='off'
        />
        {
          !!errors.code && (
            <StyledOutlinedInputHelperText>
              {typeof errors.code === 'string' ? errors.code : errors.code?.map((msg) => <div key={Math.random(0, 1) * 1000}>{msg}</div>)}
            </StyledOutlinedInputHelperText>
          )
        }

      </FormControl>

      <Button disabled={invalidForm} className={loading ? 'loading' : ''} type="submit" variant="login-submit">
        {
          loading ? <CircleLoader className="sm" show={true} /> : English.submit
        }
      </Button>
      <StyledGeneralErrorTextContainer>
        {typeof errors.general === 'string' ? errors.general : errors.general?.map((msg) => <div key={Math.random(0, 1) * 1000}>{msg}</div>)}
      </StyledGeneralErrorTextContainer>
      <Timer countdownTime={10}>
        <ResendButtonsContainer component="button" onClick={() => resendCodeViaSMS()}>
          <Typography variant="subtitle2">
            {English.sendCodeVia} <strong>{English.sms}</strong>
          </Typography>
        </ResendButtonsContainer>

        {/*<VerticalDivideIcon />*/}
        {/*<Box component="span">*/}
        {/*  {English.sendCodeVia} <strong>{English.email}</strong>*/}
        {/*</Box>*/}
      </Timer>
    </StyledFormContainer>
  );
};

export default CodeApproveForm;
