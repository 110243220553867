import { useEffect, useState, useCallback, useRef } from 'react';
import { debounce } from 'lodash';

import { getUtcDateTime } from '../utilities/dates';
import dayjs from '../utilities/dayjs';
import { buildQueryString } from '../utilities/helpers';
import { dashboardOffsetStep } from '../utilities/const';
import agent from '../utilities/agent';

export const useLoadNotificationsHistory = ({
  clientId: id,
  territoryIds,
  officesIds,
  filter = {},
  limit = dashboardOffsetStep,
  offset = '0',
}) => {
  const [data, setData] = useState([]);
  const [dataCount, setDataCount] = useState([]);
  const [loading, setLoading] = useState(false);

  const getNotifications = (
    id,
    territoryIds,
    officesIds,
    limit,
    offset,
    filter
  ) => {
    const {
      territoryId: filterTerritory,
      officeId: filterOffice,
      unitId: filterFamily,
      date,
      ...filterParams
    } = filter;
    
    const filterStartDate = date?.value?.startDate;
    const filterEndDate = date?.value?.endDate;

    if (
      (filterStartDate && !filterEndDate)
      || (!filterStartDate && filterEndDate)
      || (filterEndDate && dayjs() < dayjs(filterStartDate))
    ) return;

    if (territoryIds || territoryIds?.length || filterTerritory?.value) {
      setData([]);
      setLoading(true);
      
      const params = {
        unitId: filterFamily?.value || id,
        limit,
        offset,
        officeId: filterOffice?.value || officesIds,
        fromDate: filterStartDate
          ? dayjs(filterStartDate).format('YYYY-MM-DD')
          : undefined,
        toDate: filterEndDate
          ? dayjs(filterEndDate).format('YYYY-MM-DD')
          : undefined,
        ...Object.entries(filterParams).reduce((acc, [k, { value }]) => {
          acc[k] = value;
          return acc;
        }, {})
      };

      Promise.all([
        agent
          .get(
            `/carelogsAndInsights/${
              filterTerritory?.value || territoryIds
            }${buildQueryString(params)}`,
          ),
        agent
          .get(
            `/highSeverityAlerts/${
              filterTerritory?.value || territoryIds
            }${buildQueryString(params)}`,
          ),
      ])
        .then(
          ([
            {
              data: {
                data: [notifications, notifications_count]
              }
            },
            {
              data: {
                data: [highSeverityAlerts]
              }
            }
          ]) => {
            const notificationsArr = notifications.concat(highSeverityAlerts).map((item) => ({
              ...item,
              notification_type: item.is_carelog 
                ? item.is_real_time
                  ? 'alert'
                  : 'label' 
                : 'care',
              children_events: item.is_carelog ? null : [],
              ts: getUtcDateTime(item.ts)
            }));

            setData(notificationsArr);
            setDataCount(notifications_count);
          }
        )
        .finally(() => setLoading(false));
    }
  };

  const debounceGetNotification = useCallback(
    debounce(getNotifications, 100),
    []
  );

  useEffect(() => {
    debounceGetNotification(
      id,
      territoryIds,
      officesIds,
      limit,
      offset,
      filter
    );
  }, [id, territoryIds, officesIds, limit, offset, filter]);

  return [data, loading, dataCount];
};

// NOTE: this hook is necessary to load all carelogs labels for resired territory + unit :: WARN! THIS IS NOT USER FOR NOW ANYMORE
export const useLoadNotificationTitles = ({ territoryIds, unitId }) => {
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (territoryIds || territoryIds?.length) {
      setLoading(true);
      agent
        .get(`/carelogsLabels/${territoryIds}${buildQueryString({ unitId })}`)
        .then(({ data: { data: titles } }) => {
          setData(titles);
        })
        .finally(() => setLoading(false));
    }
  }, [territoryIds, unitId]);

  return [data, loading];
};

export const useLoadEventData = (eventId, { notificationType }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const reqController = useRef();

  useEffect(() => {
    if (eventId && notificationType) {
      setLoading(true);
      setData({});

      reqController.current?.abort();
      reqController.current = new AbortController();

      agent
        .get(
          `/carelogsAndInsights/getById/${eventId}${buildQueryString({ notificationType })}`,
          {
            signal: reqController.current?.signal,
          }
        )
        .then(({ data: { data } }) => {
          data.ts = data.ts.replace('Z', '');
          setData(data);
        })
        .finally(() => {
          reqController.current = null;
          setLoading(false);
        });
    }
    return () => {
      reqController.current?.abort();
      reqController.current = null;
    };
  }, [eventId, notificationType]);

  return [data, loading];
};