import React, { useEffect, useMemo, useState, useCallback } from 'react';
import CodeApproveForm from './components/CodeApproveForm/CodeApproveForm';
import ForgotPasswordFirstForm from './components/ForgotPasswordFirstForm/ForgotPasswordFirstForm';
import ForgotPasswordSecondForm from './components/ForgotPasswordSecondForm/ForgotPasswordSecondForm';
import LoginForm from './components/LoginForm/LoginForm';
import NewPasswordForm from './components/NewPasswordForm/NewPasswordForm';
import LoginCard from './components/LoginCard/LoginCard';
import { BoxWrapper } from './components/LoginCard/LoginCard.style';
import { useSelector } from 'react-redux';

const LoginView = () => {
  const [formState, setFormState] = useState('');
  const navigateLinkAfterLogin = useSelector(state => state.user.navigateLinkAfterLogin);

  useEffect(() => {
    document.title = 'Sensi.ai | 2 FA page';
  }, []);

  const handleReturn = useCallback(() => {
    setFormState('');
  }, []);

  // TODO: refactor common parts of these forms to avoid code repetition
  const currentForm = useMemo(() => {
    switch (formState) {
      case 'forgotPasswordSecond':
        return <ForgotPasswordSecondForm handleFormState={setFormState} />;
      case 'forgotPasswordFirst':
        return <ForgotPasswordFirstForm handleFormState={setFormState} />;
      case 'codeVerification':
        return <CodeApproveForm navigateLinkAfterLogin={navigateLinkAfterLogin} handleFormState={setFormState} />;
      case 'newPassword':
        return <NewPasswordForm handleFormState={setFormState} formState={formState} />;
      case 'forgotPasswordConfirm':
        return <NewPasswordForm handleFormState={setFormState} formState={formState} />;
      default:
        return <LoginForm navigateLinkAfterLogin={navigateLinkAfterLogin} handleFormState={setFormState} />;
    }
  }, [formState]);

  return (
    <BoxWrapper>
      <LoginCard displayReturn={!!formState.length} handleReturn={handleReturn}>
        {currentForm}
      </LoginCard>
    </BoxWrapper>
  );
};

export default LoginView;
