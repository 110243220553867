import { createReducer } from '@reduxjs/toolkit';

import { dismissError } from './actions';

const initialState = {
  loading: {},
  errors: [],
};

const isAsyncAction = (action) => {
  const regex = /\/(pending|fulfilled|rejected)$/;
  return regex.test(action.type);
};

const isLoading = (action) => {
  return action.type.endsWith('/pending');
};

const isError = (action) => {
  return action.type.endsWith('/rejected');
};

const getLoaderName = (action) => {
  const parts = action.type.split('/');
  return parts.slice(0, -1).join('/');
};

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(dismissError, (state, { payload: errorId }) => {
    state.errors = state.errors.filter((item) => item.id !== errorId);
  });
  builder.addMatcher(isAsyncAction, (state, action) => {
    state.loading[getLoaderName(action)] = isLoading(action);
  });
  builder.addMatcher(isError, (state, { payload, meta }) => {
    if (meta?.toast) {
      state.errors.push({
        id: meta.requestId,
        message: payload.message,
      });
    }
  });
});

export default reducer;
