import { styled, Typography } from '@mui/material';

export const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
});

export const Text = styled(Typography)(({ theme }) => ({
  marginBottom: '7px',
  color: theme.palette.sensiGrey.main,
  textAlign: 'center',
}));
