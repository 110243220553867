export default {
  LOGIN: { name: 'Login' },
  LOGIN_FAILED: { name: 'Login Failed' },
  LOGOUT: { name: 'Logout' },
  NOTIFICATION_HEARD: { name: 'Notification Heard' },
  NOTIFICATION_MARK_REVIEWED: { name: 'Notification Mark Reviewed' },
  NOTIFICATION_ADD_ACTION_TAKEN: { name: 'Notification Add Action Taken' },
  NOTIFICATION_ADD_COMMENT: { name: 'Notification Add Comment' },
  NOTIFICATION_MARK_HELPFUL: { name: 'Notification Mark Helpful' },
  NOTIFICATION_ADD_TO_FAMILY_REPORT: { name: 'Notification Add to Family Report' },
  NOTIFICATION_ASSIGN_USER: { name: 'Notification Assign User' },
  INSIGHT_MARK_REVIEWED: { name: 'Insight Mark Reviewed' },
  INSIGHT_ADD_ACTION_TAKEN: { name: 'Insight Add Action Taken' },
  INSIGHT_MARK_HELPFUL: { name: 'Insight Mark Helpful' },
  INSIGHT_ADD_COMMENT: { name: 'Insight Add Comment' },
  INSIGHT_OPEN_RELATED_EVENTS: { name: 'Insight Open Related Events' },
  INSIGHT_NOTIFICATION_HEARD: { name: 'Insight Notification Heard' },
  INSIGHT_ADD_TO_FAMILY_REPORT: { name: 'Insight Add to Family Report' },
  INSIGHT_ASSIGN_USER: { name: 'Insight Assign User' },
  OPEN_GENERATE_FAMILY_REPORT_WIZARD: { name: 'Open Generate Family Report Wizard' },
  CANCEL_GENERATE_FAMILY_REPORT_WIZARD: { name: 'Cancel Generate Family Report Wizard' },
  GENERATE_FAMILY_REPORT: { name: 'Generate Family Report' },
  EDIT_FAMILY_REPORT: { name: 'Edit Family Report' },
  EDIT_INSIGHT_IN_FAMILY_REPORT: { name: 'Edit Insight in Family Report' },
  DOWNLOAD_FAMILY_REPORT: { name: 'Download Family Report' },
  GENERATE_FAILURE_FAMILY_REPORT: { name: 'Generate Failure Family Report' },
  OPEN_NOTIFICATION_CENTER: { name: 'Open Notification Center' },
  SHOW_ONLY_UNREAD_NOTIFICATIONS_ON: { name: 'Show Only Unread Notifications On' },
  SHOW_ONLY_UNREAD_NOTIFICATIONS_OFF: { name: 'Show Only Unread Notifications Off' },
  MARK_ALL_NOTIFICATION_AS_READ: { name: 'Mark All Notifications as Read' },
  REMOVE_NOTIFICATION: { name: 'Remove Notification' },
  CLICK_ON_NOTIFICATION: { name: 'Click on Notification' },
  GO_TO_CLIENTS_PAGE: { name: 'Go to Clients Page' },
  GO_TO_DEVICES_PAGE: { name: 'Go to Devices' },
  GO_TO_HOME_PAGE: { name: 'Go to Home Page' },
  GO_TO_DASHBOARD: { name: 'Go to Dashboard' },
  GO_TO_DEVICES_KITS: { name: 'Go to Devices Kits' },
  GO_TO_ROUTERS: { name: 'Go to Routers' },
  CHOOSE_CLIENT: { name: 'Choose Client' },
  GO_TO_CARE_REPORTS: { name: 'Go to Care Reports' },
  DASHBOARD_VISIT: { name: 'Dashboard Visit' },
  DASHBOARD_VISIT_END: { name: 'Dashboard Visit End' },
  GO_TO_CLIENTS_HISTORY: { name: 'Go to Clients History' },
  FILTER_CLIENTS_HISTORY: { name: 'Filter Clients History' },
  FILTER_BY_TYPE_CLIENT_HISTORY: { name: 'Filter by Type Client History' },
  FILTER_BY_STATUS_CLIENT_HISTORY: { name: 'Filter by Status Client History' },
  FILTER_BY_DATE_CLIENT_HISTORY: { name: 'Filter by Date Client History' },
  FILTER_BY_ASSIGNEE_CLIENT_HISTORY: { name: 'Filter by Assignee Client History' },
  FILTER_BY_LABEL_CLIENT_HISTORY: { name: 'Filter by Label Client History' },
  FILTER_HOME_PAGE: { name: 'Filter Home Page' },
  FILTER_BY_OFFICE_HOME_PAGE: { name: 'Filter by Office Home Page' },
  FILTER_BY_TERRITORY_HOME_PAGE: { name: 'Filter by Territory Home Page' },
  FILTER_BY_CLIENT_HOME_PAGE: { name: 'Filter by Client Home Page' },
  FILTER_BY_TYPE_HOME_PAGE: { name: 'Filter by Type Home Page' },
  FILTER_BY_STATUS_HOME_PAGE: { name: 'Filter by Status Home Page' },
  FILTER_BY_DATE_HOME_PAGE: { name: 'Filter by Date Home Page' },
  FILTER_BY_ASSIGNEE_HOME_PAGE: { name: 'Filter by Assignee Home Page' },
  FILTER_BY_LABEL_HOME_PAGE: { name: 'Filter by Label Home Page' },
  FILTER_BY_DATE_DASHBOARD_PAGE: { name: 'Filter by Date Dashboard Page' },
  DELETE_CARE_REPORT: { name: 'Delete Care Report' },
  FORGOT_PASSWORD: { name: 'Forgot Password' },
  SUCCESSFULLY_RESET_PASSWORD: { name: 'Successfully Reset Password' },
  FAILED_RESET_PASSWORD: { name: 'Failed Reset Password' },
  RESET_PASSWORD_HELP: { name: 'Reset Password Help' },
  SUCCESSFULLY_SETTING_NEW_PASSWORD: { name: 'Successfully Setting New Password' },
  FAILED_SETTING_NEW_PASSWORD: { name: 'Failed Setting New Password' },
  SHOW_PASSWORD: { name: 'Show Password' },
  REMEMBER_ME: { name: 'Remember Me' },
  SUCCESSFUL_IDENTIFICATION: { name: 'Successful Identification' },
  UNSUCCESSFUL_IDENTIFICATION: { name: 'Unsuccessful Identification' },
  HELP_WITH_VERIFICATION_CODE: { name: 'Help with Verification Code' },
  RESET_PASSWORD_RESEND_CODE_VIA_EMAIL: { name: 'Reset Password Resend Code via Email' },
  RESEND_CODE_VIA_SMS: { name: 'Resend Code via SMS' },
  CONTACT_SUPPORT_VIA_EMAIL: { name: 'Contact Support via Email' },
  CONTACT_SUPPORT_VIA_PHONE: { name: 'Contact Support via Phone' },
  CTA: { name: 'CTA - Link Was Used' },
  CHOOSE_MAIN_TAKEAWAY: { name: 'Choose Main Takeaway' },
  MAIN_TAKEAWAYS_ADD_DESCRIPTION: { name: 'Main Takeaways Add Description' },
  USING_MAIN_TAKEAWAYS_DESCRIPTION_TOOLTIP: { name: 'Using Main Takeaways Description Tooltip' },
  EDIT_MAIN_TAKEAWAYS: { name: 'Edit Main Takeaways' },
  DELETE_MAIN_TAKEAWAYS: { name: 'Delete Main Takeaways' },
  ADD_MAIN_TAKEAWAYS: { name: 'Add Main Takeaways' },
  ADD_ANOTHER_TAKEAWAY: { name: 'Add Another Takeaway' },
  GENERATE_CARE_REPORT: { name: 'Generate Care Report' },
  ADD_CAREGIVER_NAME: { name: 'Add Caregiver Name' },
  INCLUDE_END_REPORT_TEXT: { name: 'Include End Report Text' },
  INCLUDE_SIGNATURE: { name: 'Include Signature' },
  DONE_CREATING_CARE_REPORT: { name: 'Done Creating Care Report' },
  TOGGLE_INSIGHT_RECOMMENDATIONS_BAR: { name: 'Toggle Insight Recommendations Bar' },
  TOGGLE_INSIGHT_RELATED_EVENTS_BAR: { name: 'Toggle Insight Related Events Bar' },
  TOGGLE_INSIGHT_TREND_ANALYSIS_BAR: { name: 'Toggle Insight Trend Analysis Bar' },
  INSIGHT_COPY_RECOMMENDATIONS: { name: 'Insight Copy Recommendations' },
  TOGGLE_SENIORS_CATEGORY: { name: 'Toggle Seniors Category' },
  ADD_TODO_TASK: { name: 'Add Todo Task' },
  ASSIGN_TODO_TASK: { name: 'Assign Todo Task' },
  MARK_TASK_AS_DONE: { name: 'Mark Task as Done' },
  CHANGE_COVERAGE_FILTER: { name: 'Change Coverage Filter' },
  ADD_TO_MY_TODO_LIST: { name: 'Add to My Todo List' },
  CLICK_ON_POSSIBLE_COVERAGE_ACTIONS: { name: 'Click on Possible Coverage Actions' },
  COVERAGE_CONTACT_SUPPORT: { name: 'Coverage Contact Support' },
  COVERAGE_CLIENT_PAGE: { name: 'Coverage Client Page' },
  COVERAGE_MODAL_CONTACT_SUPPORT: { name: 'Coverage Modal Contact Support' },
  CHOOSE_SENIOR_IN_CATEGORY: { name: 'Choose Senior in Category' },
  GO_TO_NEXT_NOTIFICATION: { name: 'Go to Next Notification' },
  GO_TO_PREVIOUS_NOTIFICATION: { name: 'Go to Previous Notification' },
  CLOSE_NOTIFICATION_WINDOW: { name: 'Close Notification Window' },
  INSIGHT_POPUP: { name: 'Insight Popup Opened' },
  CARELOG_POPUP: { name: 'Carelog Popup Opened' },
  VIEW_TRANSCRIPT_CLICKED: { name: 'View Transcript Clicked' },
  COPY_TRANSCRIPT_CLICKED: { name: 'Copy Transcript Clicked' },
  RELATED_EVENT_CLICKED: { name: 'Related Event Clicked' },
  INSIGHT_COPY_TRANSCRIPT_CLICKED: { name: 'Insight Copy Transcript Clicked' },
  INSIGHT_VIEW_TRANSCRIPT_CLICKED: { name: 'Insight View Transcript Clicked' },
  HOVER_PLAYED_ICON: { name: 'Hover on the Played Indication' },
  HOVER_READ_ICON: { name: 'Hover on the Read Indication' }
};