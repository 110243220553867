import { useMemo, useState } from 'react';

import TabsPanel from '../../components/general/TabsPanel';
import DevicesKitsTab from './components/DevicesKitsTab';
import RoutersTab from './components/RoutersTab';
import { EventService } from '../../services';
import EVENT_TYPES from '../../services/EVENT_TYPES';

const DeviceView = () => {
  const [pageState, setPageState] = useState('devicesKits');

  const pageContent = useMemo(() => {
    switch (pageState) {
      case 'routers':
        return <RoutersTab />;
      default:
        return <DevicesKitsTab />;
    }
  }, [pageState]);

  return (
    <div className="page-container relative">
      <div className="devices-container">
        <div className="page-header flex flex-row items-center justify-between">
          <div className="device-header flex items-center text-3xl font-bold">
            <span className="ml-4">Agency Devices</span>
          </div>
        </div>

        <TabsPanel
          tabs={[
            {
              render: () => <div>Devices Kits</div>,
              onClick: (trackFunction = true) => {
                setPageState('');
                trackFunction && EventService.fireEvent(EVENT_TYPES.GO_TO_DEVICES_KITS);
              }
            },
            {
              render: () => <div>Routers</div>,
              onClick: (trackFunction = true) => {
                setPageState('routers');
                trackFunction && EventService.fireEvent(EVENT_TYPES.GO_TO_ROUTERS);
              }
            }
          ]}
        />

        {pageContent}
      </div>
    </div>
  );
};

export default DeviceView;
