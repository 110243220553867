import { styled, ToggleButton, ToggleButtonGroup } from '@mui/material';
import Colors from '../../../theme/Colors';


export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ style }) => ({
  backgroundColor: Colors.white,
  ...style
}));

export const StyledToggleButton = styled(ToggleButton)(({ theme, selectedColor, style }) => ({
  '&&': {
    padding: 5,
    borderRadius: 5,
    borderColor: theme.palette.border.main,
    '&.Mui-selected': {
      color: 'white',
      backgroundColor: selectedColor,
    },
  },
  ...style
}));
