import React, { useEffect, useState } from 'react';

import SharedModal from '../../../../components/common/modal';
import { ArrowIcon } from '../../../../assets/icons';
import ProgressBar from '../../../../components/common/progressBar';

const SuccessModal = ({ title, toggle, showProgressBar = true, subTitle }) => {
  const [progress, setProgress] = React.useState(0);
  const [showProgress, setShowProgress] = useState(showProgressBar);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        return prevProgress >= 100 ? 0 : prevProgress + 20;
      });
      if (progress === 100) {
        setShowProgress(false);
        timer && clearInterval(timer);
      }
    }, 600);
    return () => timer && clearInterval(timer);
  }, [progress]);

  return (
    <SharedModal
      className={'generate-modal'}
      toggle={toggle}
      title={() => (
        <div className="text-center">
          {title && <h3>{title}</h3>}
          {subTitle && <div className='subHeadline'>
            {subTitle} {showProgress && 'Loading data...'}
          </div>}
          <div
            className="back-arrow rotate-180 absolute p-2 cursor-pointer"
            onClick={toggle}
          >
            <ArrowIcon />
          </div>
        </div>
      )}
      withBackgroundToggle={false}
    >
      {showProgress ? (
        <div className="progressbar-container">
          <ProgressBar show={showProgress} value={progress} />
        </div>
      ) : (
        <div className="modal-body">
          <div
            className="report-button w-full flex items-center justify-center px-4 cursor-pointer"
            onClick={toggle}
          >
            Back to reports
          </div>
        </div>
      )}
    </SharedModal>
  );
};

export default SuccessModal;
