import { styled, Box, IconButton, Typography } from '@mui/material';

import Colors from '../../../theme/Colors';

export const Container = styled(Box)(({ theme, variant }) => ({
  display: 'flex',
  flexWrap: 'nowrap',
  alignItems: 'center',
  gap: variant === 'filled' ? 14 : 5,
  padding: variant === 'filled' ? '9px 12px 9px 17px' : 7,
  borderRadius: 5,
  border:
    variant === 'filled' ? 'none' : `1px solid ${theme.palette.border.main}`,
  background: variant === 'outlined' ? Colors.white : Colors.gray,
}));

export const Progress = styled(Box)(({ variant }) => ({
  flexGrow: 1,
  display: 'flex',
  flexWrap: 'nowrap',
  alignItems: 'center',
  gap: variant === 'filled' ? 19 : 4,
}));

export const Control = styled(IconButton)({
  width: 19,
  height: 19,
  padding: 0,
  '& > svg': {
    width: '100%',
    height: '100%',
  },
});

export const Time = styled('time')(({ theme, variant }) => ({
  color: theme.palette.sensiGrey.main,
  fontSize: variant === 'filled' ? 12 : 8,
  lineHeight: '16px',
  whiteSpace: 'nowrap',
}));

export const ActionContainer = styled(Box)({
  position: 'absolute',
  top: -5,
});

export const WaveformContainer = styled(Box)(({ variant }) => ({
  position: 'relative',
  minHeight: variant === 'filled' ? 28 : 18,
  width: '100%',
}));

export const Waveform = styled(Box)({
  width: '100%',
  height: '100%',
});
