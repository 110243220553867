import { styled, Box, Paper, Menu as MuiMenu } from '@mui/material';

export const Wrapper = styled(Paper)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: 383,
  overflow: 'hidden',
}));

export const Tabs = styled(Box)(({ theme }) => ({
  flexGrow: 0,
  display: 'flex',
  justifyContent: 'stretch',
  flexWrap: 'nowrap',
  width: '100%',
  borderBottom: `1px solid ${theme.palette.border.main}`,
}));

export const Container = styled(Box)(() => ({
  flexGrow: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: 313,
}));

export const DataList = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  width: '100%',
  height: '100%',
  overflow: 'hidden auto',
  '&::-webkit-scrollbar': {
    width: 8,
  },
  '&::-webkit-scrollbar-track': {
    WebkitBoxShadow: 'none',
    backgroundColor: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#d9d9d9',
    borderRadius: 20,
  },
}));

export const Menu = styled(MuiMenu)(() => ({}));
