import {
  Typography,
  styled,
  IconButton,
  Grid,
  DialogContent,
  DialogTitle,
  Box,
} from '@mui/material';
import colors from '../../../../theme/Colors';

export const LoaderContainer = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const CloseButton = styled(IconButton)({
  position: 'absolute',
  top: 0,
  right: 0,
  margin: 8,
});

export const BackButton = styled(IconButton)({
  marginBottom: '11px',
});

export const BackText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '12px',
  marginLeft: 8,
}));

export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  padding: 0,
  marginBottom: theme.spacing(2),
}));

export const StyledDialogContent = styled(DialogContent)({
  width: 838,
  height: 603,
});

export const StyledGridContainer = styled(Grid)({
  height: '100%',
  justifyContent: 'center',
});

export const StyledGridItem = styled(Grid)({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
});

export const StyledActionSectionContainer = styled(Grid)({
  marginTop: 12,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
});

export const StyledTaskSection = styled(Grid)({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
});

export const TooltipHover = styled(Box)({
  '&:hover > .MuiPaper-root': {
    display: 'block',
  },
});

export const LinkText = styled(Typography)({
  color: colors.skyBlue,
  textDecoration: 'underline',
  lineHeight: '16px',
  fontSize: '10px',
});

export const BetaText = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(0.25, 0.75),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: colors.azure,
  marginLeft: 10,
  color: colors.skyBlue,
  fontSize: '10px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '16px',
}));

export const StyledTrendIconContainer = styled(Box)({
  marginLeft: 5
});
