import { useCallback, useState, createContext, useContext } from 'react';

export const AudioContext = createContext({});

const AudioContextWrapper = ({ children }) => {
  const [audioData, setAudioData] = useState({});

  const saveAudio = useCallback(
    (id, src, events = {}) => {
      const audio = new Audio(src);
      for (const [event, handler] of Object.entries(events)) {
        audio[event] = handler;
      }
      setAudioData({ ...audioData, [id]: audio });
      return audio;
    },
    [audioData]
  );

  const clearAudio = useCallback(() => {
    Object.values(audioData).forEach((v) => {
      v.pause();
      v.src = null;
    });
    setAudioData({});
  }, [audioData]);

  const handlePlayPause = useCallback(
    (id) => {
      Object.entries(audioData)
        .filter(([k]) => k !== id)
        .forEach(([, v]) => v.pause());
      audioData[id]?.paused ? audioData[id]?.play() : audioData[id]?.pause();

      return audioData[id]?.paused;
    },
    [audioData]
  );

  return (
    <AudioContext.Provider
      value={{ audioState: audioData, saveAudio, handlePlayPause, clearAudio }}
    >
      {children}
    </AudioContext.Provider>
  );
};

export const useAudioContext = () => {
  return useContext(AudioContext);
};

export default AudioContextWrapper;
