import { createSlice } from '@reduxjs/toolkit';
import { TIMES } from '../../utilities/const';

const initialState = {
  time: TIMES.TWO_DAYS
};

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setTime: (state, { payload }) => {
      state.time = payload;
    },
  },
});

export const {
  setTime,
} = configSlice.actions;

export default configSlice.reducer;
