import React from 'react';
import './SignatureInput.style.scss';


const SignatureInput = ({ title, value, renderRightTitle, customStyle, containerStyle }) => {

  return (
    <div className={`${containerStyle}`}>
      <div className="textareaTitleContainer">
        {title && <span className="textareaInputTitle">{title}</span>}
        {renderRightTitle && renderRightTitle()}
      </div>
      <div className={`signature-area ${customStyle}`}>
        <span>
          {value}
        </span>
      </div>
    </div>
  );
};


export default SignatureInput;