import { useState, useEffect } from 'react';

import agent from '../utilities/agent';

export const useLoadClientDevices = ({ id: unitId }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (unitId) {
      setLoading(true);
      agent
        .get(`/units/devices/${unitId}`)
        .then(({ data: { data } }) => setData(data))
        .finally(() => setLoading(false));
    }
  }, [unitId]);

  return [data, loading];
};

export const useLoadAgencyDevices = ({ id: territoryIds }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (territoryIds.length) {
      setLoading(true);

      agent
        .get(`/devices/${territoryIds}`)
        .then(({ data: { data } }) => setData(data))
        .finally(() => setLoading(false));
    }
  }, [territoryIds]);

  return [data, loading];
};

export const useLoadAgencyInventoryDevices = ({ id: territoryIds }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (territoryIds.length) {
      setLoading(true);

      agent
        .get(`/devicesInventory/${territoryIds}`)
        .then(({ data: { data } }) => setData(data))
        .finally(() => setLoading(false));
    }
  }, [territoryIds]);

  return [data, loading];
};

export const useLoadAgencyRouters = ({ id: territoryIds }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (territoryIds.length) {
      setLoading(true);

      agent
        .get(`/routers/${territoryIds}`)
        .then(({ data: { data } }) => setData(data))
        .finally(() => setLoading(false));
    }
  }, [territoryIds]);

  return [data, loading];
};
