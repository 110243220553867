import {
  Typography,
  styled,
  IconButton,
  Grid,
  DialogTitle,
  Button,
  DialogContent,
} from '@mui/material';
import colors from '../../../theme/Colors';

export const StyledDialog = styled(DialogContent)({
  width: 490,
  height: 245,
});

export const CloseButton = styled(IconButton)({
  position: 'absolute',
  top: 0,
  right: 0,
  margin: 8,
});

export const StyledSupportButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(3),
  width: 200,
  height: 40,
  fontSize: 12,
  fontWeight: 700,
  color: colors.white,
  background: 'var(--gradient, linear-gradient(176deg, #555770 0%, #555770 100%))',
  marginLeft: '10px',
  textTransform: 'none'
}));

export const StyledDoneButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(3),
  width: 200,
  height: 40,
  fontSize: 12,
  fontWeight: 700,
  color: colors.white,
  background: 'var(--gradient, linear-gradient(176deg, #6C5CE7 0%, #5120E1 100%))',
  textTransform: 'none'
}));

export const StyledDialogTitle = styled(DialogTitle)(() => ({
  padding: 0,
  marginTop: 15,
}));

export const StyledHeadline = styled(Typography)({
  marginLeft: '5px',
  fontWeight: 700,
});

export const StyledTypographyContent = styled(Typography)({
  fontSize: 12,
  marginTop: 9,
});

export const StyledTypographyUnitName = styled(Typography)({
  fontSize: 14,
  fontWeight: 700,
  marginTop: 25,
});

export const StyledGridContainer = styled(Grid)({
  height: '100%',
  justifyContent: 'center',
});

export const StyledGridItem = styled(Grid)({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
});

export const StyledGridRow = styled(Grid)({
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
});
