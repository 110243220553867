import React, { useState, useCallback, useEffect } from 'react';

import { NotificationNewIcon } from '../../../assets/icons';
import { usePollUnreadNotificationsCount, useLoadUserNotifications } from '../../../hooks/notifications'; 
import NotificationList from './NotificationList';
import agent from '../../../utilities/agent';
import { NOTIFICATION_STATUS_UNREAD, NOTIFICATION_STATUS_READ } from '../../../utilities/const';
import { EventService } from '../../../services';
import EVENT_TYPES from '../../../services/EVENT_TYPES';
import IconButton from '@mui/material/IconButton';
import { Badge } from '@mui/material';
import { useDispatch } from 'react-redux';
import { loadTodos } from '../../../redux/Todos';
const UserNotification = () => {
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [notifsList, setNotifsList] = useState([]);
  const [unreadNotifsCount, setUnreadNotifsCount] = useState(0);
  const unreadCount = usePollUnreadNotificationsCount();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadTodos());
  }, []);

  const [notifications, loadingNotifications] = useLoadUserNotifications(notificationOpen, unreadNotifsCount);


  useEffect(() => {
    setNotifsList(notifications);
  }, [notifications]);

  useEffect(() => {
    if (unreadNotifsCount !== unreadCount) {
      setUnreadNotifsCount(unreadCount);
    }
  }, [unreadCount]);

  useEffect(() => {
    setUnreadNotifsCount(
      notifsList?.filter(({ notification_status_enum }) => notification_status_enum.status === NOTIFICATION_STATUS_UNREAD).length
    );
  }, [notifsList]);

  const handleNotificationUpdate = useCallback((notification) => {
    setNotifsList(notifications?.map((item) => {
      if (item.id === notification?.id) {
        return notification;
      }
      return item;
    }));
    setUnreadNotifsCount(notifications?.filter(({ notification_status_enum: { status } }) => {
      return status === NOTIFICATION_STATUS_UNREAD;
    }));
  }, [notifications]);

  const handleReadAll = useCallback(() => {
    EventService.fireEvent(EVENT_TYPES.MARK_ALL_NOTIFICATION_AS_READ);
    Promise.all(notifsList
      ?.filter(({ notification_status_enum: { status } }) => status === NOTIFICATION_STATUS_UNREAD)
      .map((item) => {
        return agent.post(`/notifications/updateStatus/${item.id}`, { status: NOTIFICATION_STATUS_READ });
      })
    ).then(() => {
      setNotifsList(notifications.map((item) => {
        item.notification_status_enum.status = NOTIFICATION_STATUS_READ;
        return item;
      }));
    });
  }, [notifsList, notifications]);

  return (
    <div className="main-page-notification-btn mr-0">
      <IconButton
        size="large"
        color="inherit"
        onClick={() => {
          if (!notificationOpen) {
            EventService.fireEvent(EVENT_TYPES.OPEN_NOTIFICATION_CENTER);
          }
          setNotificationOpen(!notificationOpen);
        }}
      >
        <Badge badgeContent={unreadNotifsCount} color="error">
          <NotificationNewIcon />
        </Badge>
      </IconButton>

      {notificationOpen && (
        <NotificationList
          notifications={notifsList}
          loading={loadingNotifications}
          toggle={() => setNotificationOpen(!notificationOpen)}
          handleNotificationUpdate={handleNotificationUpdate}
          handleReadAll={handleReadAll}
        />
      )}
    </div>
  );
};

export default UserNotification;