import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { buildQueryString } from './helpers';

export const useQueryObject = () => {
  const { search } = useLocation();

  const queryParams = useMemo(() => {
    return search
      .slice(1, search.length)
      .split('&')
      .filter((v) => v)
      .map((prop) => prop.split('='))
      .reduce((acc, [key, val]) => {
        acc[key] = val;
        return acc;
      }, {});
  }, [search]);

  return queryParams;
};

export const useNavigateTo = () => {
  const navigate = useNavigate();
  const queryParams = useQueryObject();

  const navTo = useCallback(
    ({ url, params = {}, omit = [], replace = false }) => {
      if (url || url === '') {
        const freshQueryParams = Object.entries(
          Object.assign(queryParams, params)
        )
          .filter(([k]) => !omit.includes(k))
          .reduce((acc, [k, v]) => {
            acc[k] = v;
            return acc;
          }, {});
        const fullUrl = `${url}${buildQueryString({
          ...freshQueryParams
        })}`;
        navigate(fullUrl, { replace });
      }
    },
    [queryParams]
  );

  return navTo;
};

export const useAssignees = (currentTerritories) => useCallback((territoryId = null) => {
  const territories = territoryId ? currentTerritories.filter(({ id }) => id === territoryId) : currentTerritories;
  const allAssignees = territories.flatMap(({ assignees = [] }) => assignees);
  const uniqueAndFilteredAssignees = allAssignees.filter((item, index, self) =>
    self.findIndex(({ id }) => id === item.id) === index
  );

  return uniqueAndFilteredAssignees.map(item => ({ ...item, id: +item.id }));
}, [currentTerritories]);