/* eslint-disable max-len */
import React from 'react';

const CoverageIcon = ({
  color = '#555770',
  background = '#E4E4E4',
  ...props
}) => {
  return (
    <svg width={25} height={25} viewBox="0 0 25 25" fill="none" {...props}>
      <circle cx="12.5" cy="12.5" r="12.5" fill={background}/>
      <path d="M12.5 6.8667C12.3867 6.86671 12.278 6.91106 12.197 6.99027L7.4701 10.8564C7.46465 10.8605 7.45928 10.8647 7.45402 10.8691L7.43794 10.8826V10.8835C7.39448 10.924 7.35981 10.973 7.33608 11.0274C7.31234 11.0819 7.30006 11.1406 7.29999 11.2C7.29999 11.315 7.34564 11.4252 7.42691 11.5064C7.50817 11.5877 7.61839 11.6334 7.73332 11.6334H8.16665V16.4C8.16665 16.8789 8.55449 17.2667 9.03332 17.2667H15.9667C16.4455 17.2667 16.8333 16.8789 16.8333 16.4V11.6334H17.2667C17.3816 11.6334 17.4918 11.5877 17.5731 11.5064C17.6543 11.4252 17.7 11.315 17.7 11.2C17.7 11.1405 17.6878 11.0816 17.6641 11.027C17.6403 10.9724 17.6056 10.9232 17.562 10.8826L17.5553 10.8776C17.5458 10.869 17.5359 10.8608 17.5256 10.853L16.8333 10.2868V8.60003C16.8333 8.36083 16.6392 8.1667 16.4 8.1667H15.9667C15.7275 8.1667 15.5333 8.36083 15.5333 8.60003V9.2238L12.7937 6.98265C12.7138 6.90844 12.609 6.86704 12.5 6.8667ZM13.8 12.5H15.5333V15.9667H13.8V12.5Z" fill={color}/>
    </svg>
  );
};

export default CoverageIcon;
