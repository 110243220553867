import { Box, styled, TextField, Typography } from '@mui/material';

export const Container = styled(Box)(({ containerStyle }) => ({
  ...containerStyle
}));

export const StyledTextField = styled(TextField)(({ theme, width = '214px', height = '61px' }) => ({
  '& .MuiInputBase-root': {
    backgroundColor: theme.palette.primary.secondary,
    width,
    height,
    fontSize: 10,
  },
}));

export const TitleText = styled(Typography)(({ theme, titleStyle }) => ({
  fontSize: 10,
  fontWeight: 700,
  color: theme.palette.sensiGrey.main,
  ...titleStyle
}));