import React, { useEffect, useState, useMemo } from 'react';
import { Wrapper, Tabs, Container, DataList } from './DevicesCoverage.style';

import {
  DevicesCoverageItem,
  Tab,
  DevicesCoverageSkeleton,
} from './components';

import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import {
  loadDevicesCoverage,
  selectDevicesCoverage,
} from '../../../../redux/Devices';
import { selectStartTime } from '../../../../redux/Config';

const TABS = ['low', 'medium', 'high'];

const DevicesCoverage = () => {
  const dispatch = useDispatch();
  const time = useSelector(selectStartTime);
  const loading = useSelector(loadDevicesCoverage.isLoading);
  const devicesCoverage = useSelector(selectDevicesCoverage);
  const [query] = useSearchParams();
  const [activeTab, setActiveTab] = useState(TABS[0]);

  const data = useMemo(
    () => devicesCoverage[activeTab],
    [devicesCoverage, activeTab],
  );

  useEffect(() => {
    const territoryIds = query.get('territoryIds');
    territoryIds && dispatch(loadDevicesCoverage({ time, territoryIds }));
  }, [time, query.get('territoryIds')]);

  return (
    <Wrapper variant="container">
      <Tabs>
        <Tab
          id={TABS[0]}
          text={`Low Coverage ${
            !loading ? `(${devicesCoverage[TABS[0]].length})` : ''
          }`}
          color="#FFE1E6"
          isActive={activeTab === TABS[0]}
          setActiveTab={setActiveTab}
        />
        <Tab
          id={TABS[1]}
          text={`Medium Coverage ${
            !loading ? `(${devicesCoverage[TABS[1]].length})` : ''
          }`}
          color="#F7E7D9"
          isActive={activeTab === TABS[1]}
          setActiveTab={setActiveTab}
        />
        <Tab
          id={TABS[2]}
          text={`High Coverage ${
            !loading ? `(${devicesCoverage[TABS[2]].length})` : ''
          }`}
          color="#D8F6DE"
          isActive={activeTab === TABS[2]}
          setActiveTab={setActiveTab}
        />
      </Tabs>
      <Container>
        <DataList>
          {loading && data.length === 0 ? (
            <DevicesCoverageSkeleton />
          ) : (
            data.map((item, i) => (
              <DevicesCoverageItem
                key={`${item.unit_name}-${i}`}
                {...item}
                index={i}
              />
            ))
          )}
        </DataList>
      </Container>
    </Wrapper>
  );
};

export default DevicesCoverage;
