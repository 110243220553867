import React, { useEffect, useRef, useState } from 'react';
import { StyledMenuContainer, StyledContainer, StyledExpandContainer, StyledMenuItem, StyledMenuList, StyledText, StyledIconContainer, StyledTrendIconContainer } from './SeniorMenu.style';
import NiceModal from '@ebay/nice-modal-react';
import EventsModal from '../../../../../../components/modals/EventsModal';
import { StyledBodyText } from '../../SeniorCategoryCard.style';
import { Grid } from '@mui/material';
import English from '../../../../../../languages/English';
import { ExpandMore, TrendIcon } from '../../../../../../assets/icons';
import { useSelector } from 'react-redux';
import { selectConfig } from '../../../../../../redux/Config';
import EventService from '../../../../../../services/EventService';
import CoverageModal from '../../../../../../components/modals/CoverageModal/CoverageModal';
import EVENT_TYPES from '../../../../../../services/EVENT_TYPES';
import Colors from '../../../../../../theme/Colors';

export const SeniorMenu = ({ seniors, clickable, category }) => {
  const [open, setOpen] = useState(false);
  const menuRef = useRef();
  const [menuWidth, setMenuWidth] = useState(0);
  const grandparentRef = useRef();
  const config = useSelector(selectConfig);

  useEffect(() => {
    // Define a function to update the menu width
    const updateWidth = () => {
      if (menuRef.current) {
        grandparentRef.current = menuRef.current.parentElement.parentElement;
        if (grandparentRef.current) {
          setMenuWidth(grandparentRef.current.getBoundingClientRect().width);
        }
      }
    };

    // Call it once immediately
    updateWidth();

    const menuNode = menuRef.current;

    const handleTransitionEnd = () => menuNode.style.overflowY = menuNode.classList.contains('open') ? 'auto' : 'hidden';
    const handleTransitionStart = () => menuNode.style.overflowY = 'hidden';
    // Set up the event listener
    menuNode.addEventListener('transitionend', handleTransitionEnd);
    menuNode.addEventListener('transitionstart', handleTransitionStart);
    window.addEventListener('resize', updateWidth);

    // Clean up the event listener on unmount
    return () => {
      menuNode.removeEventListener('transitionend', handleTransitionEnd);
      menuNode.removeEventListener('transitionstart', handleTransitionStart);
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  const handleTogglerClick = () => {
    EventService.fireEvent(EVENT_TYPES.TOGGLE_SENIORS_CATEGORY, { action: Number(!open), category });
    setOpen(!open);
  };

  const handleOnClick = (senior) => {
    if (clickable) {
      EventService.fireEvent(EVENT_TYPES.CHOOSE_SENIOR_IN_CATEGORY, { unit_id: senior?.id || senior?.unit_id, category, coverage_related: !!senior?.redirect });
      if (senior?.redirect) {
        // Redirect to client's page
        NiceModal.show(CoverageModal, { unitId: senior?.id || senior?.unit_id, unitName: senior?.unit_name });
      } else {
        NiceModal.show(EventsModal, { unitId: senior?.id || senior?.unit_id, i: 0, ts: config?.time });
      }
    }
  };

  return (
    <StyledMenuContainer>
      <StyledContainer container>
        <Grid item xs={9} md={10}>
          <StyledBodyText variant="h2">{seniors.length} {English.senior}{seniors.length === 1 ? '' : 's'}</StyledBodyText>
        </Grid>
        {!!seniors?.length && <Grid item xs={3} md={2}>
          <StyledExpandContainer className={open ? 'open' : ''} onClick={handleTogglerClick}>
            <ExpandMore />
          </StyledExpandContainer>
        </Grid>}
      </StyledContainer>
      <StyledMenuList className={open ? 'open' : ''} sx={{ width: menuWidth }} ref={menuRef}>
        {seniors.map((senior, i) => (
          <StyledMenuItem
            onClick={() => handleOnClick(senior)}
            key={i}
            border={(grandparentRef?.current && window.getComputedStyle(grandparentRef?.current).border)}
            cursor={clickable ? 'pointer' : 'default'}>
            <StyledIconContainer width="100%">
              <StyledText>{senior.unit_name || senior.name}</StyledText>
              <StyledIconContainer>
                {senior?.trend && <StyledTrendIconContainer>
                  <TrendIcon width={21} height={21} color={Colors.skyBlue} />
                </StyledTrendIconContainer>}
                {clickable && <ExpandMore style={{ transform: 'rotate(90deg)', marginRight: 9 }} />}
              </StyledIconContainer>
            </StyledIconContainer>
          </StyledMenuItem>
        ))}
      </StyledMenuList>
    </StyledMenuContainer>
  );
};