import { useEffect } from 'react';
import MobileDetect from 'mobile-detect';
import { ThemeProvider } from '@mui/material/styles';
import AudioContextWrapper from './contexts/audio';
import CustomersStateWrapper from './contexts/customers';
import ApplicationRouter from './routers/applicationRouter';
import theme from './theme/defaultTheme';
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import NotificationsContainer from './components/general/NotificationsContainer';
import { persistor, store } from './redux';
import { MixpanelService } from './services';
import NiceModal from '@ebay/nice-modal-react';

Amplify.configure(awsExports);
MixpanelService.init();

const App = () => {
  useEffect(() => {
    const md = new MobileDetect(window.navigator.userAgent);
    if (md.mobile()) {
      document.location = `${process.env.REACT_APP_DEEPLINKS_REDIRECT_URL}/${document.location.search}`;
    }
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <CustomersStateWrapper>
            <AudioContextWrapper>
              <NiceModal.Provider>
                <ApplicationRouter />
              </NiceModal.Provider>
            </AudioContextWrapper>
          </CustomersStateWrapper>
          <NotificationsContainer />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
