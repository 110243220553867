import React from 'react';
import English from '../../../languages/English';
import Lottie from '../../../assets/lottie';
import LottieComponent from '../../common/LottieComponent/LottieComponent';
import BasicButtons from '../../common/Button/Button';
import Colors from '../../../theme/Colors';
import { EventService } from '../../../services';
import EVENT_TYPES from '../../../services/EVENT_TYPES';
import { useCustomerState } from '../../../contexts/customers';

const FinishAddTakeaways = ({ onClose }) => {
  const [{ MixpanelUserData }] = useCustomerState();

  const handleClick = () => {
    EventService.fireEvent(EVENT_TYPES.ADD_ANOTHER_TAKEAWAY, {
      ... MixpanelUserData
    });
    onClose();
  };
  return (
    <div className="flex flex-col justify-center text-center">
      <LottieComponent containerStyle="mb-4" loop width={48} height={48} lottieSrc={Lottie.confetti}/>
      <span className="font-bold">{English.successAddTakeaway}</span>
      <BasicButtons
        customStyle={{
          marginTop: '25px',
          marginBottom: '25px',
          alignSelf: 'center',
          fontWeight: 600,
          backgroundColor: Colors.sensiPurple }}
        onClick={handleClick}
        text={English.addAnotherTakeaway}
      />
    </div>
  );
};

export default FinishAddTakeaways;