import { styled } from '@mui/system';
import { Box, Typography } from '@mui/material';
import Colors from '../../theme/Colors';

export const Wrapper = styled(Box)({
  flex: '3 1',
  height: '100vh',
  padding: '6.3em 2.5em',
  overflow: 'hidden auto',
});

export const Top = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: 10,
});

export const TopTitle = styled(Typography)({
  alignSelf: 'flex-start',
  margin: '0 0 10px 3px',
});

export const TopWidgets = styled(Box)({
  alignSelf: 'flex-end',
  height: '57px',
});

export const Body = styled(Box)({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignContent: 'stretch',
  gap: '19px',
  '&:not(:last-of-type)': {
    marginBottom: 50,
  },
});

export const BodySection = styled(Box)({
  width: '50%',
});

export const BodyHeader = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '21px',
  height: 21,
});

export const TooltipCircle = styled(Box)(({ theme }) => ({
  background: Colors.white,
  border: `1px solid ${theme.palette.border.main}`,
  borderRadius: '50%',
  fontSize: '12px',
  width: '2em',
  height: '2em',
  fontWeight: 700,
  lineHeight: '24px',
  textAlign: 'center',
  color: Colors.sensiPurple,
  marginLeft: '1em',
  '&:hover > .MuiPaper-root': {
    display: 'block',
  },
}));
