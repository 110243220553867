import {
  createContext,
  useState,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import useFetchTakeawayEnum from '../hooks/mainTakeawaysEnum';

const TakeawaysContext = createContext({});

export const TAKEAWAY_MODALS = {
  SUBMIT: 'submit',
  DISCARD_DRAFT: 'discard draft',
  DELETE: 'delete',
  NONE: '',
};

const initTakeawaysContext = () => {
  const { takeawayTypes, loading: loadingTakeawayTypes } =
    useFetchTakeawayEnum();

  const [takeaways, setTakeaways] = useState([]);
  const [editedTakeaway, setEditedTakeaway] = useState(null);
  const [takeawayInput, setTakeawayInput] = useState('');
  const [takeawayType, setTakeawayType] = useState(null);
  const [modal, setModal] = useState(TAKEAWAY_MODALS.NONE);

  useEffect(() => {
    if (!editedTakeaway) {
      setTakeawayInput('');
      setTakeawayType(null);
    } else {
      setTakeawayInput(editedTakeaway.description);
      setTakeawayType(
        takeawayTypes.find((item) => item.id === editedTakeaway.id),
      );
    }
  }, [editedTakeaway, takeawayTypes, setTakeawayInput, setTakeawayType]);

  const deleteTakeaway = useCallback(
    (index) => {
      setTakeaways((state) => state.filter((item, i) => i !== index));
    },
    [setTakeaways],
  );

  const editTakeaway = useCallback(
    (index, payload) => {
      setTakeaways((state) =>
        state.map((item, i) => (i === index ? payload : item)),
      );
      setTakeawayInput('');
      setTakeawayType(null);
      setEditedTakeaway(null);
    },
    [setTakeaways, setTakeawayInput, setTakeawayType],
  );

  const addTakeaway = useCallback(
    (payload) => {
      setTakeaways((state) => [...state, payload]);
      setTakeawayInput('');
      setTakeawayType(null);
    },
    [setTakeaways, setTakeawayInput, setTakeawayType],
  );

  const reset = useCallback(() => {
    setTakeaways([]);
    setEditedTakeaway(null);
    setTakeawayInput('');
    setTakeawayType(null);
    setModal(TAKEAWAY_MODALS.NONE);
  }, [
    setTakeaways,
    setEditedTakeaway,
    setTakeawayInput,
    setTakeawayType,
    setModal,
  ]);

  return {
    takeawayTypes,
    loadingTakeawayTypes,
    takeaways,
    setTakeaways,
    deleteTakeaway,
    editTakeaway,
    addTakeaway,
    editedTakeaway,
    setEditedTakeaway,
    takeawayInput,
    setTakeawayInput,
    takeawayType,
    setTakeawayType,
    reset,
    modal,
    setModal,
  };
};

const TakeawaysProvider = ({ children }) => {
  const {
    takeawayTypes,
    loadingTakeawayTypes,
    takeaways,
    setTakeaways,
    editedTakeaway,
    setEditedTakeaway,
    deleteTakeaway,
    editTakeaway,
    addTakeaway,
    takeawayInput,
    setTakeawayInput,
    takeawayType,
    setTakeawayType,
    reset,
    modal,
    setModal,
  } = initTakeawaysContext();
  const contextValue = useMemo(
    () => ({
      takeawayTypes,
      loadingTakeawayTypes,
      takeaways,
      setTakeaways,
      editedTakeaway,
      setEditedTakeaway,
      deleteTakeaway,
      editTakeaway,
      addTakeaway,
      takeawayInput,
      setTakeawayInput,
      takeawayType,
      setTakeawayType,
      reset,
      modal,
      setModal,
    }),
    [
      takeawayTypes,
      loadingTakeawayTypes,
      takeaways,
      setTakeaways,
      editedTakeaway,
      setEditedTakeaway,
      deleteTakeaway,
      editTakeaway,
      addTakeaway,
      takeawayInput,
      setTakeawayInput,
      takeawayType,
      setTakeawayType,
      reset,
      modal,
      setModal,
    ],
  );

  return (
    <TakeawaysContext.Provider value={contextValue}>
      {children}
    </TakeawaysContext.Provider>
  );
};

export const useTakeawaysContext = () => {
  const context = useContext(TakeawaysContext);

  return context;
};

export default TakeawaysProvider;
