/* eslint-disable max-len */
import React from 'react';
import colors from '../../theme/Colors';
const SupportIcon = ({
  color = colors.sensiGrey,
  ...props
}) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.46667 1.06665V5.40832C8.38507 5.64618 9.06667 6.47358 9.06667 7.46665C9.06667 7.80318 8.98191 8.11783 8.84271 8.40103L10.2375 9.61665C10.2898 9.60545 10.344 9.59998 10.4 9.59998C10.8416 9.59998 11.2 9.95838 11.2 10.4C11.2 10.8416 10.8416 11.2 10.4 11.2C9.98667 11.2 9.64994 10.8849 9.60834 10.4823L8.15209 9.21353C7.66729 9.55273 7.03908 9.7004 6.37188 9.52707C5.62575 9.334 5.02796 8.71761 4.8573 7.96561C4.58316 6.76028 5.33121 5.68565 6.40001 5.40832V1.16769C3.66987 1.67009 1.60001 4.05812 1.60001 6.93332C1.60001 9.08052 2.80076 10.5582 3.59063 11.2979C4.02263 11.7022 4.26667 12.2637 4.26667 12.8552V14.9333H9.60001V14.1021C9.60001 13.6429 9.89344 13.2357 10.3292 13.0906L11.7063 12.6323C12.3596 12.4136 12.8 11.8021 12.8 11.1135V9.46353L14.0094 9.04686C14.1217 9.01568 14.2206 8.9486 14.2912 8.85586C14.3617 8.76312 14.3999 8.64983 14.4 8.53332C14.3996 8.4106 14.357 8.29177 14.2792 8.19686L14.2646 8.17915C14.2595 8.17313 14.2543 8.16723 14.249 8.16144L12.7792 6.39894C12.5619 3.40966 10.5263 1.06665 7.46667 1.06665ZM6.93334 6.39998C6.65044 6.39998 6.37913 6.51236 6.17909 6.7124C5.97905 6.91244 5.86667 7.18375 5.86667 7.46665C5.86667 7.74955 5.97905 8.02086 6.17909 8.2209C6.37913 8.42094 6.65044 8.53332 6.93334 8.53332C7.21624 8.53332 7.48755 8.42094 7.68759 8.2209C7.88763 8.02086 8.00001 7.74955 8.00001 7.46665C8.00001 7.18375 7.88763 6.91244 7.68759 6.7124C7.48755 6.51236 7.21624 6.39998 6.93334 6.39998Z"
        fill={color}
      />
    </svg>
  );
};

export default SupportIcon;
