import {
  styled,
  Box,
  Typography,
  IconButton,
  Menu as MuiMenu,
  MenuItem as MuiMenuItem,
} from '@mui/material';
import Colors from '../../../../../../theme/Colors';
import { CoverageIcon as UnstyledCoverageIcon } from '../../../../../../assets/icons';

export const Container = styled(Box)(({ theme, index }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: 21,
  borderBottom: `1px solid ${theme.palette.border.main}`,
  backgroundColor: index % 2 === 0 ? undefined : '#FAFAFA',
}));

export const Info = styled(Box)({
  flexGrow: 1,
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'nowrap',
  gap: 16,
});

export const CoverageIcon = styled(UnstyledCoverageIcon)({
  width: 36,
  height: 36,
});

export const Text = styled(Typography)(() => ({
  color: Colors.sensiGrey,
  lineHeight: '16px',
}));

export const Name = styled(Text)({
  fontWeight: 700,
});

export const Button = styled(IconButton)(({ theme }) => ({
  padding: 2,
  borderRadius: '5px',
  border: `1px solid ${theme.palette.border.light}`,
}));

export const Menu = styled(MuiMenu)(({ theme }) => ({
  '& .MuiMenu-paper': {
    border: `1px solid ${theme.palette.border.light}`,
    boxShadow: 'none',
    marginLeft: -60,
    marginTop: '9px',
  },
  '& .MuiMenu-list': {
    padding: 0,
  },
}));

export const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: 149,
  gap: 7,
  height: 35,
  padding: '10px 8px',
  fontSize: 10,
  fontWeight: 600,
  color: Colors.sensiGrey,
  '&:not(:last-of-type)': {
    borderBottom: `1px solid ${theme.palette.border.light}`,
  },
  '&:hover': {
    backgroundColor: '#EBF3FF',
  },
}));
