import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import {
  useLoadClientCarelogs,
  useLoadClientInsights,
} from '../../hooks/clients';
import CircleLoader from '../../components/common/loaders/circleLoader';
import SuccessModal from './components/modals/SuccessModal';
import { ArrowIcon } from '../../assets/icons';
import agent from '../../utilities/agent';
import { handleClientSelect, useCustomerState } from '../../contexts/customers';
import StageIndicator from './components/StageIndicator';
import CarelogsStage from './components/stages/CarelogsStage';
import RecommendationStage from './components/stages/RecommendationStage';
import InsightsStage from './components/stages/InsightsStage';
import { useLoadReportData } from '../../hooks/reports';
import { useNavigateTo } from '../../utilities/hooks';
import { useAudioContext } from '../../contexts/audio';
import { getUtcDateTime } from '../../utilities/dates';
import { autoReportIds } from '../../utilities/const';
import dayjs from '../../utilities/dayjs';
import { useTakeawaysContext } from '../../contexts/takeaways';
import English from '../../languages/English';
import { EventService } from '../../services';
import EVENT_TYPES from '../../services/EVENT_TYPES';

const DashboardView = () => {
  const { id } = useParams();
  const [{ currentTerritories, MixpanelUserData, currentClient, triggerCarelogsReload }, dispatch] =
    useCustomerState();
  const [params] = useSearchParams();
  const navigate = useNavigateTo();
  const { clearAudio } = useAudioContext();
  const { reset, setTakeaways } = useTakeawaysContext();

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  useEffect(() => {
    if (!currentClient.id) {
      agent.get(`/units?unitId=${id}`).then(
        ({
          data: {
            data: [client],
          },
        }) => {
          handleClientSelect(dispatch, client);
        },
      );
    }
  }, [id]);

  const reportId = useMemo(() => {
    return params.get('reportId');
  }, [params]);

  const [highlightsData, loadingHighlights] = useLoadClientCarelogs({
    clientId: id,
    territoryId: currentTerritories.map(({ id }) => id).join(','),
    startDate: params.get('startDate'),
    endDate: params.get('endDate'),
    triggerReload: triggerCarelogsReload,
  });

  const [insightsData, loadingInsights] = useLoadClientInsights({
    clientId: id,
    territoryId: currentTerritories.map(({ id }) => id).join(','),
    reportId,
    startDate: params.get('startDate'),
    endDate: params.get('endDate'),
    triggerReload: triggerCarelogsReload,
  });

  const [reportData, loadingReportData] = useLoadReportData({
    id: reportId,
  });

  const [reportStage, setReportStage] = useState('');
  const [groupedHighs, setGroupedHighs] = useState([]);
  const [groupedInsights, setGroupedInsights] = useState([]);
  const [successOpen, setSuccessOpen] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);

  const isGenerateDisabled = useMemo(() => {
    const carelogsIds = groupedHighs
      .filter(({ include_in_report }) => include_in_report)
      .map(({ id }) => id);
    const insightsIds = groupedInsights
      .filter(({ include_in_report }) => include_in_report)
      .map(({ id }) => id);
    return !!(!carelogsIds.length && !insightsIds.length);
  }, [groupedHighs, groupedInsights]);

  useEffect(() => clearAudio(), [reportStage]);

  useEffect(
    () =>
      setTakeaways(reportData?.report?.main_takeaways || []),
    [reportData?.report?.main_takeaways],
  );

  useEffect(() => {
    const total = reportData.id
      ? highlightsData
        .map((highlights) => {
          highlights.include_in_report =
              !!reportData?.report?.notifications?.find(
                ({ id }) => id === highlights.id,
              );
          return highlights;
        })
        .sort((a, b) => dayjs(b.ts) - dayjs(a.ts))
      : highlightsData.sort((a, b) => dayjs(b.ts) - dayjs(a.ts));
    setGroupedHighs(
      total.map(
        (item) => ({
          ...item,
          date_label: `${dayjs(item.ts).format('MMMM DD, YYYY')}`,
        }),
        {},
      ),
    );
  }, [highlightsData, reportData]);

  useEffect(() => {
    const total = reportData.id
      ? insightsData
        .map((insight) => {
          insight.include_in_report = !!reportData?.report?.insights?.find(
            ({ id }) => id === insight.id,
          );
          return insight;
        })
        .sort((a, b) => dayjs(b.ts) - dayjs(a.ts))
      : insightsData.sort((a, b) => dayjs(b.ts) - dayjs(a.ts));
    setGroupedInsights(
      total.map(
        (item) => ({
          ...item,
          date_label: `${dayjs(item.ts).format('MMMM DD, YYYY')}`,
        }),
        {},
      ),
    );
  }, [insightsData, reportData]);

  const handleNavToClientReports = useCallback(() => {
    navigate({
      url: `/dashboard/client/${currentClient.id}`,
      params: {
        tab: 1,
      },
      omit: ['reportId', 'startDate', 'endDate', 'notifiedReportId'],
    });
  }, [currentClient.id, navigate]);

  const switchSuccessHandler = useCallback(() => {
    setSuccessOpen(!successOpen);
    if (successOpen || (groupedHighs?.length === 0 && groupedInsights?.length === 0)) {
      handleNavToClientReports();
    }
  }, [successOpen, groupedHighs, groupedInsights, handleNavToClientReports]);

  const handleReportGenerate = useCallback(
    (mainTakeaways) => {
      if (isGenerateDisabled) return;
      const data = {
        carelogsIds: groupedHighs
          .filter(({ include_in_report }) => include_in_report)
          .map(({ id }) => id),
        insightsIds: groupedInsights
          .filter(({ include_in_report }) => include_in_report)
          .map(({ id, recommendation, indication }) => {
            return {
              id,
              recommendation,
              indication,
            };
          }),
        show_report_end_text: mainTakeaways?.includeEndReport,
        show_signature: mainTakeaways?.includeSignature,
        report_end_text: mainTakeaways?.personalNote,
        caregiver_name: mainTakeaways?.signature,
        main_takeaways: mainTakeaways?.takeaways,
        reportCreationTime: getUtcDateTime(Date.now()),
        reportUpdatedTime: getUtcDateTime(Date.now()),
      };

      const URL = reportId
        ? `/familyreport/updateReport/${reportId}/${currentClient?.territories?.customer_id}`
        : `/familyreport/create/${id}/${params.get('startDate')}/${params.get('endDate',)}/${currentClient?.territories?.customer_id}`;

      setCreateLoading(true);
      agent
        .post(URL, data)
        .then(() => {
          !reportId
            ? EventService.fireEvent(EVENT_TYPES.GENERATE_FAMILY_REPORT, {
              ...MixpanelUserData,
              client_id: id,
              loc_id: currentClient?.loc_id,
            })
            : EventService.fireEvent(EVENT_TYPES.EDIT_FAMILY_REPORT,{
              ...MixpanelUserData,
              report_id: reportId,
              client_id: id,
              loc_id: currentClient?.loc_id,
            });
          switchSuccessHandler();
        })
        .finally(() => setCreateLoading(false));
    },
    [
      isGenerateDisabled,
      groupedHighs,
      groupedInsights,
      reportId,
      id,
      params,
      MixpanelUserData,
      switchSuccessHandler,
      currentClient,
      reportData,
    ],
  );

  const handleInsightStatus = useCallback(
    (insight, childCarelog) => {
      if (insight) {
        groupedInsights.forEach((ins) => {
          if (ins.id === insight.id) {
            ins = insight;
          }
        });
        setGroupedInsights([...groupedInsights]);
      }

      if (childCarelog) {
        groupedHighs.forEach((event) => {
          if (event.id === childCarelog.id) {
            event = { ...event, ...childCarelog };
          }
        });
        setGroupedHighs([...groupedHighs]);
      }
    },
    [groupedInsights, groupedHighs],
  );

  const handleHighStatus = useCallback(
    (carelog, globalUpdateId) => {
      groupedHighs.forEach((carel) => {
        if (carel.id === carelog.id) {
          carel = carelog;
        }
      });
      setGroupedHighs([...groupedHighs]);

      if (globalUpdateId) {
        groupedHighs.forEach((event) => {
          if (event.id === globalUpdateId) {
            event.heard = carelog.heard;
          }
        });
        setGroupedHighs([...groupedHighs]);
      }
    },
    [groupedHighs],
  );

  const reportStageContent = useMemo(() => {
    switch (reportStage) {
      case 'carelogsStage':
        return (
          <CarelogsStage
            groupedHighs={groupedHighs}
            onUpdate={handleHighStatus}
            isEdit={!!reportId}
            isReportAutomated={autoReportIds.includes(reportData?.inserted_by)}
          />
        );
      case 'agencyRecommendations':
        return (
          <RecommendationStage
            reportData={reportData?.report}
            onReportGenerate={handleReportGenerate}
            isDisabled={isGenerateDisabled}
          />
        );
      default:
        return (
          <InsightsStage
            groupedHighs={groupedInsights}
            onUpdate={handleInsightStatus}
            isEdit={!!reportId}
            reportId={reportId}
            isReportAutomated={autoReportIds.includes(reportData?.inserted_by)}
          />
        );
    }
  }, [
    reportId,
    reportStage,
    groupedHighs,
    groupedInsights,
    isGenerateDisabled,
    handleReportGenerate,
    handleHighStatus,
    handleInsightStatus,
    reportData,
  ]);

  const notLoading = !(loadingHighlights ||
    loadingInsights ||
    loadingReportData ||
    createLoading);

  return (
    <div className="page-container relative">
      <div className="family-report-generate-container">
        <header className="page-header flex flex-row items-center justify-between">
          <div className="flex align-items">
            <span className="text-2xl font-bold z-20">
              {English.generateCareReport} For {currentClient.name}
            </span>
          </div>
        </header>
        <div className="mt-2 text-regular underline">
          {dayjs(params.get('startDate'), 'YYYY-MM-DD').format('MMM D, YYYY')}{' - '}
          {dayjs(params.get('endDate'), 'YYYY-MM-DD').format('MMM D, YYYY')}
        </div>
        <div
          className="header-link-replica flex items-center mt-6 cursor-pointer"
          onClick={handleNavToClientReports}
        >
          <ArrowIcon className="rotate-180 mr-2" />
          Go back
        </div>

        <StageIndicator
          className={'!my-11'}
          currentStage={reportStage}
          setReportStage={setReportStage}
          isDisabled={isGenerateDisabled}
        />

        {reportStageContent}

        <CircleLoader
          className="create-report-loader"
          show={
            loadingHighlights ||
            loadingInsights ||
            loadingReportData ||
            createLoading
          }
        />

        { (notLoading && !(groupedHighs?.length || groupedInsights?.length)) || successOpen ?
          <SuccessModal
            subTitle={successOpen ? English.workingOnIt : English.noInsightsOrCarlogs}
            title={successOpen ? English.generateCareReport : English.adjustDates }
            showProgressBar={successOpen}
            toggle={switchSuccessHandler} /> : ''}
      </div>
    </div>
  );
};

export default DashboardView;
