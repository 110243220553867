import {
  styled,
  Paper,
  Box,
} from '@mui/material';
import Colors from '../../../../theme/Colors';

export const Wrapper = styled(Paper)(() => ({
  border: 0,
  fontSize: '12px',
  color: Colors.sensiGrey,
  whiteSpace: 'pre-wrap'
}));

export const Bullet = styled(Box)(() => ({
  display: 'inline-block',
  margin: '0 5px 10px 0'
}));
