import React, { useCallback, useMemo, useState } from 'react';
import {
  handleClientSelect,
  useCustomerState
} from '../../../contexts/customers';
import {
  AgencyIcon,
  DashboardMenu,
  PeopleIcon,
} from '../../../assets/icons';
import SearchPopupContent from './SearchPopupContent';
import { useNavigateTo } from '../../../utilities/hooks';
import { sortByName } from '../../../utilities/helpers';
import { SECONDARY_QUERY_PARAMS } from '../../../utilities/const';
import EVENT_TYPES from '../../../services/EVENT_TYPES';
import { EventService } from '../../../services';
import {
  Box,
  CssBaseline,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
  Popover, Chip, Typography,
} from '@mui/material';
import English from '../../../languages/English';
import {
  DrawerHeader,
  SideMenuList,
  StyledDrawer
} from './MainSidebar.style';
import { DevicesIcon } from '../../icons';


const MainSidebar = ({ open }) => {
  const navigate = useNavigateTo();
  const theme = useTheme();
  // State to control popover open/close
  const [isPopoverOpen, setPopoverOpen] = useState(false);

  // Handler for opening the popover
  const handlePopoverOpen = () => {
    setPopoverOpen(true);
    EventService.fireEvent(EVENT_TYPES.GO_TO_CLIENTS_PAGE);
  };

  // Handler for closing the popover
  const handlePopoverClose = () => {
    setPopoverOpen(false);
  };


  const goToDevicesPage = (data) => {
    EventService.fireEvent(EVENT_TYPES.GO_TO_DEVICES_PAGE, data);
  };

  const goToHomePage = (data) => {
    EventService.fireEvent(EVENT_TYPES.GO_TO_HOME_PAGE, data);
  };

  const goToDashboard = (data) => {
    EventService.fireEvent(EVENT_TYPES.GO_TO_DASHBOARD, data);
  };

  const [
    { territories, clients, currentClient, currentTerritories },
    dispatch
  ] = useCustomerState();

  const activeItem = useMemo(() => {
    return window.location.pathname.split('/').filter((v) => v)[1];
  });

  const menuItems = useMemo(
    () => ([
      {
        title: 'Dashboard',
        type: 'dashboard',
        icon: DashboardMenu,
        url: '/dashboard',
        trackFunction: goToDashboard,
      },
      {
        title: 'Events History',
        type: 'territory',
        icon: AgencyIcon,
        url: 'territory',
        trackFunction: goToHomePage,
      },
      {
        title: 'Seniors',
        type: 'client',
        icon: PeopleIcon,
        // options: clients,
        options: currentTerritories.length ? currentTerritories.length > 1 ? clients
          .filter(({ territories: { id: terr_id } }) =>
            currentTerritories.map(({ id }) => id).includes(terr_id)
          )
          .reduce((acc, client) => {
            const currentTerr = territories.find(
              ({ id }) => id === client.territories.id
            );
            if (
              !acc.find(([terrName]) => terrName === currentTerr?.name)
            ) {
              acc.push([currentTerr?.name, [client]]);
            } else {
              acc
                .find(
                  ([currentTerrName]) =>
                    currentTerrName === currentTerr?.name
                )[1]
                .push(client);
              acc
                .find(
                  ([currentOffName]) =>
                    currentOffName === currentTerr?.name
                )[1]
                .sort((a, b) => sortByName(a, b, 'name'));
            }
            return acc;
          }, [])
          .sort((a, b) => sortByName(a, b, '0')) : clients.filter(({ territories: { id } }) =>
          currentTerritories.map(({ id }) => id).includes(id)
        ) : [],
        isGrouped: currentTerritories.length > 1,
        selected: window.location.pathname.includes('/client') // NOTE: this is done to prevent displaying of selected client for any page except client page only
          ? currentClient
          : {},
        counter: clients.length
      },
      // {
      //   title: 'Care Givers',
      //   type: 'care-giver',
      //   icon: RiServiceLine,
      // },
      {
        title: 'Devices',
        type: 'device',
        icon: DevicesIcon,
        url: 'device',
        trackFunction: goToDevicesPage,
      }
    ]),
    [
      territories,
      clients,
      currentClient,
      currentTerritories,
      window.location.pathname
    ]
  );

  const handleSelectItem = useCallback((type, val, close) => {
    switch (type) {
      // case 'territory':
      //   handleTerritoriesSelect(dispatch, val);
      //   break;
      case 'client':
        handleClientSelect(dispatch, val);
        EventService.fireEvent(EVENT_TYPES.CHOOSE_CLIENT);
        close && close();
        return val;
      default:
        break;
    }
  }, []);

  const handleNavigateMenuItem = useCallback(
    (item) => {
      if (item.url) {
        navigate({
          url: item.url,
          omit: SECONDARY_QUERY_PARAMS,
        });
        item.trackFunction && item.trackFunction();
      }
    },
    [navigate]
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />

      <StyledDrawer variant="permanent" open={open}>
        <DrawerHeader />
        <SideMenuList open={open}>
          {menuItems.map((item) => (
            <Box key={item.type}>
              {item.options?.length ? (
                <>
                  <ListItemButton
                    selected={activeItem === item.type}
                    onClick={handlePopoverOpen}
                  >
                    <ListItemIcon>
                      <item.icon />
                    </ListItemIcon>
                    <ListItemText primary={ <Typography color={theme.palette.sensiGrey.main} variant="h3" fontSize={14}>{item.title}</Typography>} />
                  </ListItemButton>
                  <Popover
                    open={isPopoverOpen}
                    onClose={handlePopoverClose}
                    anchorReference="anchorPosition"
                    PaperProps={{
                      style: { maxHeight: 600, overflowY: 'auto' },
                    }}
                    anchorPosition={{
                      top: 220,
                      left: open ? 240 : 68
                    }}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                  >
                    <SearchPopupContent
                      selected={item.selected}
                      type={item.type}
                      onSelect={(type, val) => handleSelectItem(type, val, handlePopoverClose)}
                      isGrouped={item.isGrouped}
                      options={item.options}
                    />
                  </Popover>
                </>
              ) : (
                <ListItemButton
                  selected={activeItem === item.type}
                  onClick={() => handleNavigateMenuItem(item)}
                >
                  <ListItemIcon>
                    <item.icon />
                  </ListItemIcon>
                  <ListItemText primary={ <Typography color={theme.palette.sensiGrey.main} variant="h3" fontSize={14}>{item.title}</Typography>} />
                  {item.isNew && <Chip size='small' label={English.newText} sx={{ fontSize: 9, backgroundColor: '#6C5CE7', color: 'white' }} />}
                </ListItemButton>
              )}
            </Box>
          ))}
        </SideMenuList>
      </StyledDrawer>
    </Box>
  );
};

export default MainSidebar;
