const englishText = {
  chooseTakeaway: 'Choose Main Takeaway',
  mainTakeawaysTip: 'Creating a takeaway is a great tool for care managers to communicate specific steps to improve the senior\'s life. It helps \
    establish clear to-do steps, identify potential challenges and track progress for a better quality of care coordination and communication',
  cancel: 'Cancel',
  description: 'Description',
  chooseTopic: 'Choose Topic',
  saveTakeaway: 'Save Takeaway',
  done: 'Done',
  almostDone: 'Almost done!',
  endReport: 'Personal note',
  doneGeneratorTitle: 'Before finishing creating your care report, here is an opportunity to add a personal touch. Write your own personal note or use the suggested text below.',
  CareManagerName: 'Care manager name',
  caregiverNamePlaceholder: 'Please add your name',
  signature: 'Signature',
  generateCareReport: 'Generate Care Report',
  workingOnIt: 'We are working on it!',
  noInsightsOrCarlogs: 'No notifications or insights were found on the selected dates.\n Please adjust the dates in order to generate a report.',
  next: 'Next',
  nextEvent: 'Next Event >',
  previous:'Previous',
  mainTakeaways: 'Main Takeaways',
  notableEvents: 'Notable Events',
  deleteTakeaway: 'Delete Takeaway',
  back: 'Back',
  backToInsight: 'Back to insight',
  continueEditingTakeaway: 'Continue editing takeaway',
  yesSure: 'Yes I\'m sure',
  topCareRecommendations: 'Top Care Recommendations',
  editTakeaway: 'You are editing a takeaway.\n Are you sure you want to delete\n this takeaway?',
  deleteTakeawayTitle: 'Are you sure you want to delete \nthis Takeaway?',
  successAddTakeaway: 'Your takeaway was added',
  addAnotherTakeaway: 'Add another takeaway',
  adjustDates: 'Adjust the report dates',
  defaultPersonalNoteText: 'In continued commitment to providing you with the best care possible, and ensuring you stay healthy and happy at home,',
  helpWithSignIn: 'Need help signing in?',
  supportEmail: 'Support@sensi.ai',
  supportPhone: '+1 844 754 1333',
  signInTitle: 'Welcome back!',
  signInSubtitle: 'Sign in with your email and password',
  password: 'Password',
  signInTooltipText: 'Are you having \ntrouble with \nyour password? \nYou can use \nthe “Forgot \npassword?”\n option to easily \nchange your \npassword',
  timerTooltipTextStart: 'Please wait',
  timerTooltipTextEnd: 'seconds',
  forgotPassword: 'Forgot password?',
  remember30Days: 'Remember me for 30 days',
  fieldRequired: 'This field is required',
  pleaseEnterValidEmail: 'Please enter a valid email address',
  emailMayBeWrong: 'Incorrect email address',
  passwordMayBeWrong: 'Incorrect password',
  emailOrPasswordMayBeWrong: 'Email or password may be wrong',
  loginFailed: 'Login process failed.',
  tooManyAttempts: 'Too many sign-in attempts. Please try again later',
  generalError: 'An error occurred. Please try again or connect with Support',
  codeDeliveryFailureException: 'We were unable to send the verification code. Please try again',
  expiredCodeException: 'The verification code has expired',
  codeMismatchException: 'Invalid verification code provided, please try again',
  email: 'Email',
  code: 'Code',
  signIn: 'Sign In',
  codeNotValid: 'Code is not valid',
  enterValidCode: 'The code needs to be 6 characters long',
  codeApproveTitle: 'Two-step verification',
  codeApproveSubtitle: 'A verification code was sent via SMS to your mobile phone. Please enter the code below',
  codeApproveTooltip: 'You can find \nthe verification \ncode sent via \nSMS on your \nmobile phone.\nIt will come from \n+18559295279',
  submit: 'Submit',
  sendCodeVia: 'Resend code via ',
  sms: 'SMS',
  forgotPasswordFirstFormTitle: 'Forgot password?',
  forgotPasswordSecondFormTitle: 'Forgot password?',
  forgotPasswordFirstFormSubtitle: 'No worries, enter your email and we`ll send you a code to reset your password',
  forgotPasswordSecondFormSubtitle: 'Please enter the code sent via email and create a new password',
  forgotPasswordSecondFormTooltip: 'You can find \nthe verification \ncode sent via \nemail in your \nmailbox, the \nSubject Line will \nbe “Your \nverification code \nfrom Sensi“',
  resetPassword: 'Reset my password',
  setNewPassword: 'Set a new password',
  passwordRules: 'Password must contain:',
  eightPlusChar: '8+',
  characters: 'Characters',
  uppercase: 'Uppercase',
  lowercase: 'Lowercase',
  aUppercase: 'A',
  aLowercase: 'a',
  numbers123: '123',
  numbers: 'Number',
  somethingWrong: 'Something went wrong please try again later',
  newText: 'New!',
  profile: 'Profile',
  logout: 'Logout',
  lastDay: 'Last 48 Hours',
  lastWeek: 'Last 7 Days',
  myToDoList: 'My To Do List',
  addToDo: 'Add to my To Do list',
  addToCareReport: 'Add to Care Report',
  status: 'Status',
  todo: 'To Do',
  dataCoverageTooltip: 'Home Data Coverage overview takes into account all devices in the home. Will help you identify anomalies and suggests actions for you to address data coverage issues.',
  assignee: 'Assignee',
  chooseAssignee: 'Choose Assignee',
  actionTakenTitle: 'Was there any action taken?',
  chooseActionTaken: 'Choose an action',
  addInternalComment: 'Add an internal comment',
  recommendations: 'Recommendations',
  relatedEvents: 'Related Events',
  comments: 'Comments',
  copiedToClipboard: 'Copied to clipboard',
  wasHelpful: 'Was this helpful?',
  review: 'Review',
  senior: 'Senior',
  moreThanAWeek: 'more than a week.',
  learnMore: 'Learn More',
  lowDataCoverage: 'Low Data Coverage',
  moreToGo: 'more to go!',
  outOf: 'out of',
  beta: 'Beta',
  trendAnalysis: 'Trend Analysis',
  viewTranscript: 'View Transcript',
  read: 'Read',
  played: 'Played',
  by: 'by',
};

export default englishText;
