import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectConfig, setTime } from '../../../../redux/Config';
import Toggle from '../../../../components/common/Toggle';
import dayjs from '../../../../utilities/dayjs';
import EventService from '../../../../services/EventService';

import EVENT_TYPES from '../../../../services/EVENT_TYPES';

const OptionToggler = ({ options }) => {
  const dispatch = useDispatch();
  const config = useSelector(selectConfig);
  const isBiggerThanThreeDaysAgo = dayjs(config?.time).isAfter(dayjs().subtract(3, 'day'));
  const time = isBiggerThanThreeDaysAgo ? options[0].value?.value : options[1].value?.value;
  const [defaultTime, setDefaultTime] = useState(time);

  useEffect(() => {
    dispatch(setTime(defaultTime));
  },[]);

  const handleToggleChange = async (newAlignment) => {
    if (newAlignment !== null) {
      EventService.fireEvent(EVENT_TYPES.FILTER_BY_DATE_DASHBOARD_PAGE, { value: newAlignment.label });
      setDefaultTime(newAlignment.value);
      dispatch(setTime(newAlignment.value));
    }
  };
  return (
    <Toggle
      options={options}
      onChange={handleToggleChange}
      alignment={defaultTime}
    />
  );
};

export default OptionToggler;
