import React, { useState } from 'react';
import { StyledContentCopy } from './Copy.style';
import { Snackbar } from '@mui/material';
import English from '../../../../languages/English';


const Copy = ({ text, fireEvent, style = {} }) => {
  const [ copiedOpen, setCopiedOpen ] = useState(false);
  const handleCopy = (e) => {
    e.preventDefault();
    e.stopPropagation();
    navigator.clipboard.writeText(text);
    fireEvent && fireEvent();
    setCopiedOpen(true);
  };

  return <>
    <StyledContentCopy onClick={handleCopy} style={style} />
    <Snackbar open={copiedOpen} onClose={() => setCopiedOpen(false)} message={English.copiedToClipboard} autoHideDuration={3000}/>
  </>;
};

export default Copy;
