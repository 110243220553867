import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  user: null,
  loading: false,
  error: null,
  email: null,
  password: null,
  remember30Days: null,
  navigateLinkAfterLogin: window.location.pathname + window.location.search,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setNavigateLinkAfterLogin: (state, { payload }) => {
      state.navigateLinkAfterLogin = payload;
    },
    setRemember30Days: (state, { payload }) => {
      state.remember30Days = payload;
      state.loading = false;
      state.error = null;
    },
    setUserData: (state, { payload }) => {
      state.user = payload;
      state.loading = false;
      state.error = null;
    },
    setEmail: (state, { payload }) => {
      state.email = payload.toLowerCase();
      state.loading = false;
      state.error = null;
    },
    clearUserData: (state) => {
      state.user = null;
    },
    setError: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    setPassword: (state, { payload }) => {
      state.loading = false;
      state.password = payload;
    },
  },
  extraReducers: (builder) => {},
});

export const {
  setLoading,
  setUserData,
  setError,
  setPassword,
  setEmail,
  clearUserData,
  setRemember30Days,
  setNavigateLinkAfterLogin
} = userSlice.actions;

export default userSlice.reducer;
