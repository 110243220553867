import React, { useEffect, useMemo } from 'react';
import { Wrapper, Container, List, ListBlur } from './TodoList.style';

import TodoInput from '../TodoInput';
import EmptyTodos from '../EmptyTodos';
import TodoItem from '../TodoItem';

import { useDispatch, useSelector } from 'react-redux';
import { loadTodos, selectTodos } from '../../../../redux/Todos';
import { selectUser } from '../../../../redux/User';
import { useCustomerState } from '../../../../contexts/customers';

const TodoList = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const todos = useSelector(selectTodos);

  const [{ currentTerritories }] = useCustomerState();

  const assignees = useMemo(() => {
    const opts = currentTerritories
      .reduce((acc, { assignees = [] }) => acc.concat(assignees), [])
      .reduce((acc, item) => {
        if (!acc.find(({ id }) => item.id === id) && item.id !== +user.id) {
          acc.push({ ...item, id: +item.id });
        }
        return acc;
      }, []);
    return opts;
  }, [currentTerritories]);

  useEffect(() => {
    dispatch(loadTodos());
  }, []);

  return (
    <Wrapper variant="container">
      <TodoInput />
      <Container>
        <EmptyTodos />
        {todos.length > 0 && (
          <List>
            {todos.map((item, i) => (
              <TodoItem
                key={item.id}
                index={i}
                assignees={assignees}
                {...item}
              />
            ))}
          </List>
        )}
        <ListBlur />
      </Container>
    </Wrapper>
  );
};

export default TodoList;
