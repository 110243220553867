import { DeviceBoxIcon } from '../../../assets/icons';

const DeviceLocation = ({ location }) => {
  return (
    <div className="highlight-content-data location-label flex w-full items-center my-2">
      <div className="highlight-device-location__icon flex items-center justify-center mr-2">
        <DeviceBoxIcon />
      </div>
      <span className="highlight-device-location__title mr-1">
        Device Location
      </span>
      <span className="highlight-device-location__label">{location}</span>
    </div>
  );
};

export default DeviceLocation;
