import React, { useEffect } from 'react';
import UnderlineButton from '../../../../components/common/UnderlineButton/UnderlineButton';
import English from '../../../../languages/English';
import BasicButtons from '../../../../components/common/Button/Button';
import TextAreaInput from '../../../../components/TextAreaInput/TextAreaInput';
import AutocompleteDropdown from '../../../../components/common/AutoCompleteDropdown/AutoCompleteDropdown';
import { useTakeawaysContext } from '../../../../contexts/takeaways';
import { style, ExclamationMarkWrapper } from './CreateTakeaway.style';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { ExclamationMark } from '../../../../assets/icons';
import { EventService } from '../../../../services';
import EVENT_TYPES from '../../../../services/EVENT_TYPES';
import { useCustomerState } from '../../../../contexts/customers';

const CreateTakeaway = ({ editedIndex, setEditedIndex, setCreateDone }) => {
  const {
    takeawayTypes,
    loadingTakeawayTypes,
    takeawayInput,
    setTakeawayInput,
    takeawayType,
    setTakeawayType,
    editedTakeaway,
    setEditedTakeaway,
    addTakeaway,
    editTakeaway,
  } = useTakeawaysContext();
  const [{ MixpanelUserData }] = useCustomerState();

  useEffect(() => {
    if (editedIndex) {
      setCreateDone(false);
    }
  }, [editedIndex, setCreateDone]);

  const onChangeAutocomplete = (_, value) => {
    if (value && takeawayType !== value) {
      setTakeawayType(value);
      EventService.fireEvent(EVENT_TYPES.CHOOSE_MAIN_TAKEAWAY, {
        ...MixpanelUserData,
        name: value?.name
      });
    }
  };

  const onChangeText = (event) => {
    setTakeawayInput(event?.target?.value);
  };

  const handleCreate = () => {
    const payload = {
      id: takeawayType.id,
      description: takeawayInput,
    };

    if (editedTakeaway) {
      editTakeaway(editedIndex, payload);
      setEditedIndex('');
      EventService.fireEvent(EVENT_TYPES.EDIT_MAIN_TAKEAWAYS, {
        ...MixpanelUserData,
        takeaway: takeawayType.name
      });
      //TODO - ask yuval if she want to check for description was change
      // & explain that the create of main takeaways is the same for to add description or she prefer to fire the event for every char input onChange
      // EventService.fireEvent(EVENT_TYPES.MAIN_TAKEAWAYS_ADD_DESCRIPTION, {
      //   ...MixpanelUserData,
      //   Description: takeawayInput
      // });
    } else {
      addTakeaway(payload);
      setCreateDone(true);
      EventService.fireEvent(EVENT_TYPES.ADD_MAIN_TAKEAWAYS, {
        ...MixpanelUserData,
        name: takeawayType.name,
        description: takeawayInput
      });
    }
  };

  const onCancel = () => {
    if (editedTakeaway) {
      setEditedTakeaway(null);
      setEditedIndex('');
    }

    setTakeawayType(null);
    setTakeawayInput('');
  };

  const handleOpen = () => {
    EventService.fireEvent(EVENT_TYPES.USING_MAIN_TAKEAWAYS_DESCRIPTION_TOOLTIP, {
      ...MixpanelUserData,
    });
  };

  return (
    <div style={style?.createTakeawayDiv}>
      <AutocompleteDropdown
        options={takeawayTypes}
        loading={loadingTakeawayTypes}
        label={English?.chooseTopic}
        title={English?.chooseTakeaway}
        onChange={onChangeAutocomplete}
        value={takeawayType}
      />
      <br />
      <div style={style?.descriptionDiv}>
        <TextAreaInput
          style={style?.textArea}
          title={English?.description}
          titleStyle={style.title}
          renderRightTitle={() => (
            <Tooltip
              title={<Typography variant='tooltip'>{English.mainTakeawaysTip}</Typography>}
              arrow
              placement='bottom-end'
              onOpen={handleOpen}
            >
              <ExclamationMarkWrapper>
                <IconButton disableRipple>
                  <ExclamationMark />
                </IconButton>
              </ExclamationMarkWrapper>
            </Tooltip>
          )}
          value={takeawayInput}
          onChange={onChangeText}
        />
      </div>

      <BasicButtons
        text={English?.saveTakeaway}
        onClick={handleCreate}
        disabled={takeawayType === null || takeawayInput === ''}
      />
      <br />
      <div style={style?.cancelDiv}>
        <UnderlineButton buttonText={English?.cancel} onClick={onCancel} />
      </div>
    </div>
  );
};

export default CreateTakeaway;
