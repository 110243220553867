import React from 'react';
import {
  Container,
  NameTypography,
  DateTypography
} from './ActionTooltipItem.style';
import { formatDate } from '../../../../utilities/dates';
const ActionTooltipItem = ({ carelogAction }) =>
  <Container>
    <DateTypography>{formatDate(new Date(carelogAction.ts))}</DateTypography>
    <NameTypography>{carelogAction.dashboard_users.username}</NameTypography>
  </Container>;

export default ActionTooltipItem;
