import React from 'react';
import './DeleteMainTakeawayModal.style.scss';
import MUIModal from '../MUIModal';
import { XSign } from '../../../assets/icons';
import BasicButtons from '../../common/Button/Button';
import Colors from '../../../theme/Colors';

const DeleteMainTakeawayModal = ({
  open,
  onClose,
  onAction,
  title,
  btnText,
  secBtnText,
}) => {
  const handleAction = (confirm) => {
    onAction(confirm);
    onClose();
  };

  return (
    <MUIModal open={open} handleClose={onClose}>
      <div className="deleteMainTakeawayContainer">
        <div className="closeButton" onClick={onClose}>
          <XSign />
        </div>
        {title && <span className="titleContainer subTitle delete-title">{title}</span>}
        <div className="flex items-center flex-col mt-4">
          <BasicButtons
            customStyle={{
              alignSelf: 'center',
              backgroundColor: Colors.sensiError,
            }}
            onClick={() => handleAction(true)}
            text={btnText}
          />
          <BasicButtons
            customStyle={{
              marginTop: '25px',
              marginBottom: '25px',
              alignSelf: 'center',
              backgroundColor: Colors.sensiPurple,
            }}
            onClick={() => handleAction(false)}
            text={secBtnText}
          />
        </div>
      </div>
    </MUIModal>
  );
};

export default DeleteMainTakeawayModal;
