import React from 'react';
import { Pointer, PointerContainer, StyledTab, Title } from './Tab.style';

import { useTheme } from '@mui/material';
import EventService from '../../../../../../services/EventService';

import EVENT_TYPES from '../../../../../../services/EVENT_TYPES';

const Tab = ({ id, text, color, isActive, setActiveTab }) => {
  const theme = useTheme();

  return (
    <StyledTab
      background={isActive ? color : undefined}
      onClick={() => {
        const level = text.split(' ')[0];
        EventService.fireEvent(EVENT_TYPES.CHANGE_COVERAGE_FILTER, {
          value: `${level} Coverage`,
        });
        setActiveTab(id);
      }}
    >
      <Title
        variant="body2"
        xs={{ color: isActive ? theme.palette.black.main : '#999' }}
      >
        {text}
      </Title>
      {isActive && (
        <PointerContainer>
          <Pointer color={color} />
        </PointerContainer>
      )}
    </StyledTab>
  );
};

export default Tab;
