import React, { useRef, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  Container,
  Progress,
  Time,
  ActionContainer,
  WaveformContainer,
  Waveform,
  Control,
} from './Player.style';

import { Play, Pause } from './components';
import WaveSurfer from 'wavesurfer.js';
import { updateEvent } from '../../../redux/Seniors';
import agent from '../../../utilities/agent';

import { useCustomerState } from '../../../contexts/customers';
import Colors from '../../../theme/Colors';
import { CARE_LOG_ACTIONS_TYPES } from '../../../utilities/const';
import CarelogAction from '../../../views/dashboard/components/CarelogAction';
import { fetchCarelogsActions } from '../../../redux/CarelogsActions';

const renderTime = (seconds) => {
  if (!seconds && seconds !== 0) return '--:--';

  const minutes = (seconds / 60).toFixed();
  const renderNumber = (num) => (num < 10 ? `0${num}` : num);
  return `${renderNumber(minutes)}:${renderNumber(seconds)}`;
};

const COLOR_OPTIONS = {
  PLAYING: {
    cursorColor: Colors.sensiDarkPurple,
    waveColor: Colors.skyBlue,
    progressColor: Colors.sensiDarkPurple,
  },
  ENDED: {
    cursorColor: 'transparent',
    waveColor: '#6C5CE733',
    progressColor: '#6C5CE733',
  },
};

const Player = ({
  variant = 'outlined',
  heard,
  filename,
  carelogId,
  onTrack = () => {},
  label
}) => {
  const player = useRef();
  const waveformContainer = useRef(null);
  const src = useRef('');
  const [{ MixpanelUserData }] = useCustomerState();
  const [currentTime, setCurrentTime] = useState();
  const [duration, setDuration] = useState();
  const [playing, setPlaying] = useState(false);
  const [ended, setEnded] = useState(!!heard);
  const dispatch = useDispatch();

  useEffect(() => {
    setEnded(!!heard);
  }, [heard]);

  const handlePlayPause = (evt) => {
    if (ended && player.current) {
      setEnded(false);
      player.current?.setOptions(COLOR_OPTIONS.PLAYING);
    }
    if (!playing && player.current) {
      onTrack({ ...MixpanelUserData, carelog_id: carelogId });
    }
    player?.current?.playPause(evt);
  };

  useEffect(() => {
    if (filename && carelogId && waveformContainer?.current) {
      const loadSrc = async (carelogId, filename) => {
        const res = await agent.get(
          `/carelogs/audio/${carelogId}/${filename}`,
          {
            'Content-Type': 'audio/mpeg',
            responseType: 'blob',
          },
        );
        return URL.createObjectURL(
          new Blob([res.data], { type: 'audio/mpeg' }),
        );
      };

      (async () => {
        try {
          const url = await loadSrc(carelogId, filename);
          player.current = WaveSurfer.create({
            container: waveformContainer.current,
            ...(ended ? COLOR_OPTIONS.ENDED : COLOR_OPTIONS.PLAYING),
            height: variant === 'outlined' ? 18 : 28,
            barHeight: 10,
            barMinHeight: 2,
            barWidth: 2,
            barGap: variant === 'filled' ? 4 : undefined,
            responsive: true,
          });
          player.current.on('ready', (duration) => {
            setCurrentTime(0);
            setDuration(duration.toFixed());
          });
          player.current.on('timeupdate', (time) => {
            setCurrentTime(time.toFixed());
          });
          player.current.on('pause', () => {
            setPlaying(false);
          });
          player.current.on('play', () => {
            setPlaying(true);
          });
          player.current.on('finish', () => {
            setEnded(true);
            player.current.setOptions(COLOR_OPTIONS.ENDED);
            agent
              .post(`/carelogAction/${carelogId}`, {
                actionTypeId: CARE_LOG_ACTIONS_TYPES.HEARD
              }).then(() => {
                dispatch(fetchCarelogsActions({
                  id: carelogId,
                  actionTypeId: CARE_LOG_ACTIONS_TYPES.HEARD,
                }));
              }).catch(console.error);
            dispatch(updateEvent({
              filter: item => item.is_carelog && item.id === carelogId,
              data: {
                heard: new Date(),
              }
            }));
          });

          player.current.load(url);

          const revokeUrl = src.current;
          src.current = url;
          if (revokeUrl) {
            URL.revokeObjectURL(revokeUrl);
          }
        } catch (err) {
          console.error(err);
        }
      })();
    }
    if (carelogId) {
      dispatch(
        fetchCarelogsActions({
          id: carelogId,
          actionTypeId: CARE_LOG_ACTIONS_TYPES.READ,
        }),
      );
      dispatch(
        fetchCarelogsActions({
          id: carelogId,
          actionTypeId: CARE_LOG_ACTIONS_TYPES.HEARD,
        }),
      );
    }
    return () => {
      player?.current?.destroy();
      if (src.current) {
        URL.revokeObjectURL(src.current);
      }
    };
  }, []);

  return (
    <Container variant={variant}>
      <Progress>
        <Time variant={variant}>{renderTime(currentTime)}</Time>
        <WaveformContainer ref={waveformContainer} variant={variant}>
          <Waveform />
          { ended && <ActionContainer><CarelogAction carelogId={carelogId} actionTypeId={CARE_LOG_ACTIONS_TYPES.HEARD} is_related_event={variant === 'outlined'} label={label}/></ActionContainer> }
        </WaveformContainer>
        <Time variant={variant}>{renderTime(duration)}</Time>
      </Progress>
      <Control onClick={handlePlayPause}>
        { playing ? <Pause /> : <Play /> }
      </Control>
    </Container>
  );
};

export default Player;
